import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import vivaCardsData from "../../data/vivaCards";
import styled from "styled-components";
import CardsList from "../../components/CardsList";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-69d48g" }) `
  margin-inline-end: 3rem;
`;
const VivaCards = () => {
    return (_jsxs(Container, { children: [_jsx("h1", { children: "Viva Parts" }), _jsx(CardsList, { source: vivaCardsData, path: "/intranet/viva" })] }));
};
export default VivaCards;
