import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { styled } from "styled-components";
import ConeIcon from "/public/Cone.svg";
const UnderConstructionDiv = styled.div.withConfig({ displayName: "UnderConstructionDiv", componentId: "-1vypwkc" }) `
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  justify-content: center;
  height: 80%;
`;
export const ConeIconImg = styled.img.withConfig({ displayName: "ConeIconImg", componentId: "-1yd59eq" }) `
  font-size: 60px;
  width: 60px;
`;
const UnderConstruction = (prop) => {
    const onButtonClick = () => {
        window.open(prop.buttonLink, "_blank");
    };
    return (_jsxs(UnderConstructionDiv, { children: [_jsx(ConeIconImg, { src: ConeIcon }), _jsx("h1", { children: "Stay tuned, soon you'll find this section here!" }), _jsx("label", { children: "We're working hard to bring this section avaliable soon." }), _jsx("label", { children: "Please check back later for updates, or explore other parts of Operations Center." }), prop.buttonLink && prop.buttonText && prop.buttonLink !== "" && (_jsx("button", Object.assign({ className: "lt-style lt-purple", style: { maxWidth: "200px" }, onClick: onButtonClick }, { children: prop.buttonText })))] }));
};
export default UnderConstruction;
