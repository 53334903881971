var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
var _b;
import envVarConfig from "../../../env/envVarConfig";
import { instance } from "../../../index";
const userRegion = sessionStorage.getItem("Region");
const apiPath = envVarConfig.reactApiUrl + "/EverywhereWidget/myarea";
const tenantId = (_a = JSON.parse(sessionStorage.getItem("User"))) === null || _a === void 0 ? void 0 : _a.tenantId;
export class MyAreaService {
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            const token = yield instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [envVarConfig.backOfficeAppScope],
            });
            return token.accessToken;
        });
    }
}
_b = MyAreaService;
MyAreaService.GetMyArea = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _b.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
        };
        const response = yield fetch(`${apiPath}/${tenantId}`, { headers });
        if (!response.ok) {
            return null;
        }
        const myarea = yield response.json();
        return myarea;
    }
    catch (error) {
        console.error("Error fetching my area data:", error);
        return null;
    }
});
MyAreaService.SaveGeneral = (myAreaGeneral) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _b.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-Region": userRegion,
        };
        const body = JSON.stringify(myAreaGeneral);
        const response = yield fetch(`${apiPath}/${tenantId}/general`, {
            method: "POST",
            headers: headers,
            body: body,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return yield response.json();
    }
    catch (error) {
        console.error("Error saving/updating general sector of my area:", error);
        throw error;
    }
});
MyAreaService.SaveSession = (myAreaSession) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _b.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-Region": userRegion,
        };
        const body = JSON.stringify(myAreaSession);
        const response = yield fetch(`${apiPath}/${tenantId}/sessions`, {
            method: "POST",
            headers: headers,
            body: body,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return yield response.json();
    }
    catch (error) {
        console.error("Error saving/updating session sector of my area:", error);
        throw error;
    }
});
