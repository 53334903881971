import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Accordion, Button, Dialog, Dropdown, Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Option, } from "@fluentui/react-components";
import { AddRegular, SearchRegular, SettingsRegular, } from "@fluentui/react-icons";
import { DragDropContext } from "react-beautiful-dnd";
import { handleDrag } from "../dragUtils";
import QuicklinkCategory from "./QuicklinkCategory";
import EditQuicklinkModal from "./modals/EditQuicklinkModal";
import EditCategoryModal from "./modals/EditCategoryModal";
import ManageEditorsModal from "./modals/ManageEditorsModal";
import ManageIconsModal from "./modals/ManageIconsModal";
const FiltersContainer = styled.div.withConfig({ displayName: "FiltersContainer", componentId: "-ovwzmc" }) `
  display: flex;
  justify-content: space-between;
`;
const FiltersGroupContainer = styled.div.withConfig({ displayName: "FiltersGroupContainer", componentId: "-oysvfi" }) `
  display: flex;
`;
const SearchInput = styled(Input).withConfig({ displayName: "SearchInput", componentId: "-12qw566" }) `
  margin-right: 16px;
`;
const AudiencesDropdown = styled(Dropdown).withConfig({ displayName: "AudiencesDropdown", componentId: "-wdc1ej" }) `
  min-width: 187px !important;
`;
const NewLinkButton = styled.div.withConfig({ displayName: "NewLinkButton", componentId: "-1x3d7q4" }) `
  margin-left: 25px;
  margin-right: 10px;
`;
const BodyContainer = styled.div.withConfig({ displayName: "BodyContainer", componentId: "-440t4t" }) `
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  overflow: auto;
  max-height: calc(100vh - 400px);
`;
const QuicklinksLayout = ({ links, linkCategories, audiences, refresh, notifyError, }) => {
    var _a;
    const [quicklinks, setQuicklinks] = useState(links);
    const [categories, setCategories] = useState(linkCategories);
    const [selectedLinksMap, setSelectedLinksMap] = useState({});
    const [isDraggingLinkSelected, setIsDraggingLinkSelected] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [showManageEditorsModal, setShowManageEditorsModal] = useState(false);
    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
    const [showEditQuicklinkModal, setShowEditQuicklinkModal] = useState(false);
    const [showManageIconsModal, setShowManageIconsModal] = useState(false);
    const [selectedAudiences, setSelectedAudiences] = useState([]);
    const selectedLinksNr = Object.values(selectedLinksMap).reduce((acc, selectedLinks) => acc + selectedLinks.length, 0);
    useEffect(() => {
        const filteredSearchQuicklinks = quicklinks.filter((quicklink) => quicklink.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase()) ||
            quicklink.url
                .toLocaleLowerCase()
                .includes(searchInput.toLocaleLowerCase()));
        const filteredQuicklinks = selectedAudiences.length > 0
            ? filteredSearchQuicklinks.filter((link) => link.audiences.some((aud) => selectedAudiences.some((aud2) => aud2 === aud)))
            : filteredSearchQuicklinks;
        const newCategories = Object.values(filteredQuicklinks.reduce((acc, quicklink) => {
            var _a, _b;
            const id = (_b = (_a = quicklink.category) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "uncategorized";
            return acc[id]
                ? Object.assign(Object.assign({}, acc), { [id]: [...acc[id], quicklink] }) : Object.assign(Object.assign({}, acc), { [id]: [quicklink] });
        }, {})).map((quicklinkList) => {
            var _a, _b, _c, _d;
            return ({
                quicklinks: quicklinkList,
                // for uncategorized quicklinks, id and name are set to these values bc they have to be defined
                // for various purposes (drag and drop, UI labels, ...). These should be handled whenever an action
                // is performed on an uncategorized link
                id: (_b = (_a = quicklinkList[0].category) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "uncategorized",
                name: (_d = (_c = quicklinkList[0].category) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "Uncategorized",
            });
        });
        categories === null || categories === void 0 ? void 0 : categories.forEach((category) => {
            if (newCategories.filter((cat) => cat.id === category.id).length === 0)
                newCategories.push({
                    id: category.id,
                    name: category.name,
                    quicklinks: [],
                });
        });
        newCategories.forEach((category) => {
            category.quicklinks.forEach((link) => {
                let selectedAudiences = [];
                link.audiences.forEach((aud) => {
                    const audiencesFiltered = audiences.filter((sub) => sub === aud);
                    if (audiencesFiltered.length > 0)
                        selectedAudiences.push(audiencesFiltered[0]);
                });
                if (selectedAudiences.length > 0)
                    link.audiences = selectedAudiences.map((aud) => aud);
            });
        });
        setCategories(newCategories);
    }, [quicklinks, searchInput, selectedAudiences]);
    const handleSelectedLinksChange = (categoryId, links) => {
        setSelectedLinksMap(Object.assign(Object.assign({}, selectedLinksMap), { [categoryId]: links }));
    };
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
        setSelectedLinksMap({});
    };
    const handleSearchAudiencesChange = (_, data) => {
        setSelectedAudiences(data.selectedOptions);
    };
    const handleDragStart = (start) => {
        setIsDraggingLinkSelected(Object.values(selectedLinksMap).some((links) => links.some((link) => link.id === start.draggableId)));
    };
    const handleDragEnd = (result) => {
        handleDrag(result, categories, selectedLinksMap, setCategories, setSelectedLinksMap);
        setIsDraggingLinkSelected(false);
    };
    const handleManageEditorsOpen = () => {
        setShowManageEditorsModal(true);
    };
    const handleManageEditorsClose = () => {
        setShowManageEditorsModal(false);
    };
    const handleEditCategoryModalOpen = () => {
        setShowEditCategoryModal(true);
    };
    const handleEditCategoryModalClose = () => {
        setShowEditCategoryModal(false);
    };
    const handleEditQuicklinkModalOpen = () => {
        setShowEditQuicklinkModal(true);
    };
    const handleEditQuicklinkModalClose = () => {
        setShowEditQuicklinkModal(false);
    };
    const handleManageIconsModalOpen = () => {
        setShowManageIconsModal(true);
    };
    const handleManageIconsModalClose = () => {
        setShowManageIconsModal(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(FiltersContainer, { children: [_jsxs(FiltersGroupContainer, { children: [_jsx(SearchInput, { contentBefore: _jsx(SearchRegular, {}), placeholder: "Search for links and URLs", value: searchInput, onChange: handleSearchInputChange, style: { width: "357px" } }), _jsx(AudiencesDropdown, Object.assign({ multiselect: true, placeholder: "Audiences", selectedOptions: selectedAudiences, onOptionSelect: handleSearchAudiencesChange }, { children: audiences.map((item) => (_jsx(Option, Object.assign({ value: item.audienceId }, { children: item.audienceName }), item.audienceId))) })), _jsx(NewLinkButton, { children: _jsx(Button, Object.assign({ appearance: "primary", icon: _jsx(AddRegular, {}), onClick: handleEditQuicklinkModalOpen }, { children: "Add new link" })) }), _jsx(Button, Object.assign({ style: { color: "#512da8" }, appearance: "subtle", onClick: handleEditCategoryModalOpen }, { children: "Create new category" }))] }), _jsxs(Menu, Object.assign({ positioning: "below-end" }, { children: [_jsx(MenuTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, Object.assign({ icon: _jsx(SettingsRegular, {}) }, { children: "Settings" })) })), _jsx(MenuPopover, { children: _jsxs(MenuList, { children: [_jsx(MenuItem, Object.assign({ onClick: handleManageEditorsOpen }, { children: "Manage editors" })), _jsx(MenuItem, Object.assign({ onClick: handleManageIconsModalOpen }, { children: "Manage icons" }))] }) })] }))] }), _jsx(BodyContainer, { children: _jsx(DragDropContext, Object.assign({ onDragEnd: handleDragEnd, onDragStart: handleDragStart }, { children: categories ? (_jsx(Accordion, Object.assign({ collapsible: true, defaultOpenItems: categories.map((category) => category.id), multiple: true }, { children: categories.map((category) => {
                            var _a;
                            return (_jsx(QuicklinkCategory, { audiences: audiences, category: category, isDraggingLinkSelected: isDraggingLinkSelected, selectedLinks: selectedLinksMap[category.id], selectedLinksNr: selectedLinksNr, onSelectedLinksChange: handleSelectedLinksChange, uncategorizedLinks: (_a = categories.filter((cat) => cat.id === "uncategorized")[0]) === null || _a === void 0 ? void 0 : _a.quicklinks, refresh: refresh }, category.id));
                        }) }))) : null })) }), _jsx(Dialog, Object.assign({ open: showManageEditorsModal, onOpenChange: handleManageEditorsClose }, { children: _jsx(ManageEditorsModal, { onClose: handleManageEditorsClose }) })), _jsx(Dialog, Object.assign({ open: showEditCategoryModal, onOpenChange: handleEditCategoryModalClose }, { children: _jsx(EditCategoryModal, { onClose: handleEditCategoryModalClose, refresh: refresh, uncategorizedLinks: (_a = categories.filter((cat) => cat.id === "uncategorized")[0]) === null || _a === void 0 ? void 0 : _a.quicklinks }) })), _jsx(Dialog, Object.assign({ open: showEditQuicklinkModal, onOpenChange: handleEditQuicklinkModalClose }, { children: _jsx(EditQuicklinkModal, { onClose: handleEditQuicklinkModalClose, refresh: refresh, notifyError: notifyError }) })), _jsx(Dialog, Object.assign({ open: showManageIconsModal, onOpenChange: handleManageIconsModalClose }, { children: _jsx(ManageIconsModal, { onClose: handleManageIconsModalClose, refresh: refresh }) }))] }));
};
export default QuicklinksLayout;
