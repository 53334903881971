import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { AccordionItem, AccordionPanel } from "@fluentui/react-components";
import PolicySourceHeader from "./PolicySourceHeader";
import PolicyItemList from "./PolicyItemList";
const StyledAccordionPanel = styled(AccordionPanel).withConfig({ displayName: "StyledAccordionPanel", componentId: "-1h5yv1o" }) `
  margin: 0;
`;
const InnerContainer = styled.div.withConfig({ displayName: "InnerContainer", componentId: "-19fiw80" }) `
  border-radius: 4px;
  margin-top: 12px;
  min-height: 50px;
`;
const PolicySource = ({ policySource, onMandatoryPolicyItemChange, onEnabledAssistantChange, onSave, }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id, name } = policySource;
    return (_jsx(InnerContainer, { children: _jsxs(AccordionItem, Object.assign({ title: name, value: id, disabled: isModalOpen }, { children: [_jsx(PolicySourceHeader, { onModalOpenChange: setIsModalOpen, policySource: policySource, onSave: onSave }), _jsx(StyledAccordionPanel, { children: _jsx(PolicyItemList, { policySource: policySource, onMandatoryPolicyItemChange: onMandatoryPolicyItemChange, onEnabledAssistantChange: onEnabledAssistantChange, onSave: onSave }) })] })) }));
};
export default PolicySource;
