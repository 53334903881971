import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
const IxpDiv = styled.div.withConfig({ displayName: "IxpDiv", componentId: "-14f562b" }) `
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  justify-content: center;
`;
const RealmAdvise = () => {
    const navigate = useNavigate();
    return (_jsxs(IxpDiv, { children: [_jsx("h1", { children: "Insert the Realm to which we should redirect you" }), _jsx("button", Object.assign({ className: "lt-style lt-purple", style: { maxWidth: "200px" }, onClick: () => {
                    navigate("/settings/ixp");
                } }, { children: "Insert Realm" }))] }));
};
export default RealmAdvise;
