import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useBranding } from "./BrandingContext";
import { BrandingAPIService } from "../util/BrandingAPIService/BrandingAPIService";
import { Menu, MenuItemRadio, MenuList, MenuPopover, MenuTrigger, Spinner, } from "@fluentui/react-components";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-p8f8zw" }) `
  display: ${(props) => (props.insideCard ? "flex" : "inline-block")};
  position: relative;
  justify-content: ${(props) => (props.insideCard ? "flex-end" : "unset")};
`;
const StatusBadge = styled.div.withConfig({ displayName: "StatusBadge", componentId: "-yzev6y" }) `
  cursor: pointer;
  padding: ${(props) => (props.insideCard ? "2px 6px" : "4px 12px")};
  font-size: ${(props) => (props.insideCard ? "12px" : "unset")};
  border-radius: 12px;
  background-color: #fff;
  color: ${(props) => (props.isPublished ? "#512DA8" : "#0F0E0E")};
  border: ${(props) => props.isPublished ? "1px solid #512DA8" : "1px solid #0F0E0E"};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ArrowDown = styled.span.withConfig({ displayName: "ArrowDown", componentId: "-13wzlz4" }) `
  display: inline-block;
  margin-left: 8px;
  transform: rotate(45deg);
  border: solid ${(props) => (props.isPublished ? "#512DA8" : "#0F0E0E")};
  border-width: 0 2px 2px 0;
  padding: 2px;
`;
const Publisher = ({ themeId, insideCard, onPublish, onPublishNoLogo, onUpdateTryToSave, }) => {
    const { themes, selectedTheme, refreshThemes } = useBranding();
    const currentTheme = themeId
        ? themes.find((theme) => theme.id === themeId)
        : selectedTheme;
    const [isPublished, setIsPublished] = useState(currentTheme === null || currentTheme === void 0 ? void 0 : currentTheme.publishedVersion);
    const [publishState, setPublishState] = useState({
        publishState: [
            (currentTheme === null || currentTheme === void 0 ? void 0 : currentTheme.publishedVersion) ? "published" : "notpublished",
        ],
    });
    const [isLoading, setIsLoading] = useState(false);
    const handlePublish = () => {
        var _a, _b;
        onUpdateTryToSave === null || onUpdateTryToSave === void 0 ? void 0 : onUpdateTryToSave(true);
        if (!((_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _a === void 0 ? void 0 : _a.name) ||
            !((_b = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _b === void 0 ? void 0 : _b.size) ||
            Object.keys(selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo).length === 0) {
            onPublishNoLogo === null || onPublishNoLogo === void 0 ? void 0 : onPublishNoLogo();
            return;
        }
        if (currentTheme) {
            const updatedTheme = Object.assign(Object.assign({}, currentTheme), { publishedVersion: true });
            setIsLoading(true);
            BrandingAPIService.publishTheme(updatedTheme)
                .then(() => {
                onPublish();
                refreshThemes();
                setIsLoading(false);
                onUpdateTryToSave === null || onUpdateTryToSave === void 0 ? void 0 : onUpdateTryToSave(false);
            });
        }
    };
    useEffect(() => {
        setIsPublished(currentTheme === null || currentTheme === void 0 ? void 0 : currentTheme.publishedVersion);
        setPublishState({
            publishState: [
                (currentTheme === null || currentTheme === void 0 ? void 0 : currentTheme.publishedVersion) ? "published" : "notpublished",
            ],
        });
    }, [currentTheme]);
    return (_jsx(_Fragment, { children: _jsx(Container, Object.assign({ insideCard: insideCard }, { children: _jsxs(Menu, Object.assign({ checkedValues: publishState }, { children: [_jsx(MenuTrigger, Object.assign({ disableButtonEnhancement: true }, { children: isLoading ? (_jsx(Spinner, { size: "small", label: "Publishing..." })) : (_jsxs(StatusBadge, Object.assign({ isPublished: isPublished, insideCard: insideCard }, { children: [currentTheme.publishedVersion ? "Published" : "Not published", _jsx(ArrowDown, { isPublished: isPublished })] }))) })), _jsx(MenuPopover, { children: _jsxs(MenuList, { children: [_jsx(MenuItemRadio, Object.assign({ name: "publishState", value: "published", onClick: handlePublish }, { children: "Publish" })), _jsx(MenuItemRadio, Object.assign({ name: "publishState", value: "notpublished", disabled: currentTheme.publishedVersion }, { children: "Not Published" }))] }) })] })) })) }));
};
export default Publisher;
