var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { styled } from "styled-components";
import { Button, Checkbox, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner, } from "@fluentui/react-components";
import { QuicklinksService } from "../QuicklinksService";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-9seuc9" }) `
  padding-bottom: 20px;
  color: #3D3A3B;
`;
const StyledCheckbox = styled(Checkbox).withConfig({ displayName: "StyledCheckbox", componentId: "-mzvms3" }) `
  margin-bottom: 16px;
  margin-top: 32px;
`;
const DeleteCategoryModal = ({ category, onClose, refresh, }) => {
    const { DeleteCategorie, DeleteLinks, UpdateLinks } = QuicklinksService();
    const [shouldDeleteLinks, setShouldDeleteLinks] = useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const handleCategoryDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsSaving(true);
        category.quicklinks.forEach(link => {
            link.categoryId = null;
        });
        try {
            const categoryLinks = category.quicklinks.map(s => { var _a, _b; return { id: s.id, name: s.name, icon: s.icon, description: s.description, url: s.url, categoryId: s.categoryId, mobileHide: (_a = s.mobileHide) !== null && _a !== void 0 ? _a : (_b = s.category) === null || _b === void 0 ? void 0 : _b.id, audiences: s.audiences.length > 0 ? s.audiences.map(s => { return s.audienceId; }) : null }; });
            if (shouldDeleteLinks)
                yield DeleteLinks(categoryLinks);
            else
                yield UpdateLinks(categoryLinks);
            yield DeleteCategorie(category);
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
        setIsSaving(false);
        refresh();
        onClose();
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(StyledDialogTitle, { children: "Delete category" }), _jsxs(DialogContent, { children: ["You will be removing ", _jsxs("b", { children: [category.quicklinks.length, " links"] }), " by deleting ", _jsx("b", { children: category.name }), " category.", _jsx(StyledCheckbox, { label: "Don't delete links, move to them to 'Uncategorized'.", size: "large", onChange: (_, data) => {
                                setShouldDeleteLinks(!data.checked);
                            } })] }), _jsx(DialogActions, { children: isSaving ?
                        (_jsx(Spinner, { size: "small", label: "Saving..." })) :
                        (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "primary", onClick: handleCategoryDelete }, { children: "Delete" })), _jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" }))] })) })] }) }));
};
export default DeleteCategoryModal;
