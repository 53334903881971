import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import AdvancedSettings from "../AdvancedSettings";
const HeaderContainer = styled.div.withConfig({ displayName: "HeaderContainer", componentId: "-l8htzj" }) `
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  h1 {
    margin-block: 0 10px;
  }

  p {
    margin-block: 0 20px;
  }
`;
const HeaderLeft = styled.div.withConfig({ displayName: "HeaderLeft", componentId: "-18ofpb3" }) `
  margin-left: auto;
  flex: 1 1 100%;
`;
const HeaderRight = styled.div.withConfig({ displayName: "HeaderRight", componentId: "-vhakni" }) `
  padding-right: 2rem;
`;
const BrandingHeader = (props) => {
    return (_jsxs(HeaderContainer, { children: [_jsxs(HeaderLeft, { children: [props.title && _jsx("h1", { children: props.title }), props.summary && _jsx("p", { children: props.summary })] }), props.advancedSettings && (_jsx(HeaderRight, { children: _jsx(AdvancedSettings, { wizardMode: true }) }))] }));
};
export default BrandingHeader;
