import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Button } from "@fluentui/react-components";
const DeleteButton = styled(Button).withConfig({ displayName: "DeleteButton", componentId: "-iezinw" }) `
  && {
    color: var(--red-lighter-text);
    border: 1px solid var(--red-lighter-text);
    background-color: var(--red-lighter-bg);
    margin-right: .5rem;
  }
`;
const DeleteModal = (props) => {
    function deleteItem(itemId) {
        const item = {
            itemId: itemId,
            dimensionId: props.dimensionId,
        };
        props.deleteItem(item);
    }
    return (_jsx(Dialog, Object.assign({ open: props.showModal }, { children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Delete Navigation" }), _jsx(DialogContent, { children: _jsxs("p", { children: ["You are about to delete navigation ", _jsx("b", { children: props.node.text }), ", continue?"] }) }), _jsxs(DialogActions, Object.assign({ className: "lt-style" }, { children: [_jsx(DeleteButton, Object.assign({ onClick: () => {
                                    deleteItem(props.node.itemId);
                                } }, { children: "Delete" })), _jsx(Button, Object.assign({ onClick: () => {
                                    props.closeModal();
                                } }, { children: "Cancel" }))] }))] }) }) })));
};
export const ModalPortal = (props) => {
    return ReactDOM.createPortal(props.children, document.body);
};
export default DeleteModal;
