import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { IdentityType } from "../Picker/Picker";
import styled from "styled-components";
import EllipseIcon from "/public/Ellipse.svg";
const SuggestionWrapper = styled.div.withConfig({ displayName: "SuggestionWrapper", componentId: "-1huk28k" }) `
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  padding-block: 6px;

  &:last-of-type {
    border-bottom: 0;
  }
 
`;
const ProfileImage = styled.img.withConfig({ displayName: "ProfileImage", componentId: "-rfhebo" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
`;
const ProfileWrapper = styled.div.withConfig({ displayName: "ProfileWrapper", componentId: "-152u2r8" }) `
  display: flex;
  padding-left: 10px;
`;
const ProfileName = styled.span.withConfig({ displayName: "ProfileName", componentId: "-55p3id" }) `
  ${(p) => p.isFromInvite ? `
  color: var(--NuetralPrimary, #3D3A3B);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Segoe UI;
font-size: 14px;
padding-top: 4px;
font-style: normal;
font-weight: 600;
line-height: normal;
  ` : ` font-weight: 600;
  min-width: 125px;`}
 
`;
const Elipse = styled.img.withConfig({ displayName: "Elipse", componentId: "-1t1co38" }) `
 width:11px;
 margin-left: 10px;
 margin-right: 10px;
`;
const EmailWrapper = styled.div.withConfig({ displayName: "EmailWrapper", componentId: "-1vmfyls" }) `
color: var(--NuetralSecondary, #707070);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Segoe UI;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top: 7px
`;
const ProfileIdentity = styled.span.withConfig({ displayName: "ProfileIdentity", componentId: "-w645cp" }) `
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.6;
`;
const MicrosoftPallete = [
    "#A4262C",
    "#CA5010",
    "#8F7034",
    "#407855",
    "#038387",
    "#0078d4",
    "#40587C",
    "#4052AB",
    "#854085",
    "#8764B8",
    "#737373",
    "#867365",
];
const NoImagePlaceHolder = styled.div.withConfig({ displayName: "NoImagePlaceHolder", componentId: "-5fcsbk" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${(p) => p.color};
  display: flex;
  justify-content: center;
`;
const NoImageName = styled.span.withConfig({ displayName: "NoImageName", componentId: "-7z7c91" }) `
  color: white;
  padding-top: 2.5px;
  font-weight: 600;
`;
const ProfileDetails = styled.div.withConfig({ displayName: "ProfileDetails", componentId: "-1uwlh1j" }) `
  display: flex;
  flex-direction: ${(p) => p.isFromInvite ? 'null' : 'column'};
  padding-inline: 8px;
  line-height: 1rem;
`;
export const Suggestion = (props) => {
    const [userColor, setUserColor] = React.useState(null);
    React.useEffect(() => {
        if (props.user.displayName) {
            const lastLetter = props.user.displayName.charAt(props.user.displayName.length - 1);
            const index = lastLetter.charCodeAt(0) % MicrosoftPallete.length;
            setUserColor(MicrosoftPallete[index]);
        }
    }, [props.user.displayName]);
    function getNameNoPhoto() {
        const split = props.user.displayName.split(" ");
        if (split.length > 1) {
            return split[0].charAt(0) + split[1].charAt(0);
        }
        else {
            return split[0].charAt(0);
        }
    }
    function addSuggestion() {
        props.addSuggestion(props.user);
    }
    return (_jsxs(SuggestionWrapper, Object.assign({ isFromInvite: props.isFromInvite, onClick: addSuggestion }, { children: [_jsx(ProfileWrapper, { children: props.user.image ? (_jsx(ProfileImage, { src: props.user.image })) : (_jsx(NoImagePlaceHolder, Object.assign({ color: userColor }, { children: _jsx(NoImageName, { children: getNameNoPhoto() }) }))) }), _jsxs(ProfileDetails, Object.assign({ isFromInvite: props.isFromInvite }, { children: [_jsx(ProfileName, Object.assign({ isFromInvite: props.isFromInvite }, { children: props.user.displayName })), props.isFromInvite ? null :
                        _jsx(ProfileIdentity, { children: props.user.type === IdentityType.User ? "User" : "Group" }), props.isFromInvite ?
                        _jsx(Elipse, { src: EllipseIcon }) : null, props.isFromInvite ?
                        _jsx(EmailWrapper, { children: props.user.loginName }) : null] }))] })));
};
