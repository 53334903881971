export const demoMenu = {
    siteCollection: [
        {
            menuName: "Demo Menu",
            status: "Draft",
            styles: {
                firstLevelBackgroundColor: "#383838",
                firstLevelFontColor: "#ffffff",
                firstLevelTextFormat: "uppercase",
                secondLevelTextFormat: "uppercase",
                thirdLevelTextFormat: "uppercase",
                menuStyle: "compact",
            },
            nodes: [
                {
                    useSecondLevel: true,
                    labels: [{ languageCode: "default", value: "Work" }],
                    nodes: [
                        {
                            labels: [{ languageCode: "default", value: "Production" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Hardware" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Screwdrivers" },
                                            ],
                                        },
                                        {
                                            labels: [{ languageCode: "default", value: "Pickaxes" }],
                                        },
                                        {
                                            labels: [{ languageCode: "default", value: "Nails" }],
                                        },
                                        {
                                            labels: [{ languageCode: "default", value: "Home" }],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Outdoor" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Miscellaneous" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Garden hoses" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Accessories" },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    useSecondLevel: true,
                    labels: [{ languageCode: "default", value: "Organization" }],
                    nodes: [
                        {
                            labels: [{ languageCode: "default", value: "Marketing" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "ABOUT US" }],
                                    nodes: [
                                        {
                                            labels: [{ languageCode: "default", value: "Vacancies" }],
                                        },
                                        {
                                            labels: [{ languageCode: "default", value: "Our Teams" }],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "New Joiners" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Management" },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Collateral" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Print Material" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Online Resources" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Logo Builder" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Image Library" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Branding Guidelines",
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Campaigns" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Special Offers" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Product Launches" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Image Marketing" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Commercial Ads" },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Events" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Release Parties" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Local Events" },
                                            ],
                                        },
                                        {
                                            labels: [{ languageCode: "default", value: "Lectures" }],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Internal Updates" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Fairs & Tradeshows",
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [
                                        { languageCode: "default", value: "Public Relations" },
                                    ],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "TV Stations" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Radio Stations" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Online Media" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Newspapers" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Communities" },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Trainings" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Product Basics" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Marketing Planning",
                                                },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Fair Management" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Creative Skills" },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Analytics" }],
                                    nodes: [
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Website Statistics",
                                                },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Visitor Tracking" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Social Listening" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "News Monitor" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Conversion Rates" },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            labels: [{ languageCode: "default", value: "Global Market" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "IMPORTS" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Categories" },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "EXPORTS" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Structures" },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            labels: [
                                { languageCode: "default", value: "Research & Development" },
                            ],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Github" }],
                                    nodes: [],
                                },
                            ],
                        },
                        {
                            labels: [{ languageCode: "default", value: "Cross Functions" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Partners" }],
                                    nodes: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    useSecondLevel: true,
                    labels: [{ languageCode: "default", value: "Services" }],
                    nodes: [
                        {
                            labels: [{ languageCode: "default", value: "Consultancy" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Portofolio" }],
                                    nodes: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    labels: [{ languageCode: "default", value: "Locations" }],
                    useSecondLevel: false,
                    nodes: [
                        {
                            labels: [{ languageCode: "default", value: "Europe" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Switzerland" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Spain" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Portugal" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Netherlands" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Luxemburg" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Italy" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Germany" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "France" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Belgium" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Austria" }],
                                    nodes: [],
                                },
                            ],
                        },
                        {
                            labels: [{ languageCode: "default", value: "North America" }],
                            nodes: [
                                {
                                    labels: [
                                        { languageCode: "default", value: "United States West" },
                                    ],
                                    nodes: [],
                                },
                                {
                                    labels: [
                                        { languageCode: "default", value: "United States South" },
                                    ],
                                    nodes: [],
                                },
                                {
                                    labels: [
                                        { languageCode: "default", value: "United States North" },
                                    ],
                                    nodes: [],
                                },
                                {
                                    labels: [
                                        { languageCode: "default", value: "United States East" },
                                    ],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Canada" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Mexico" }],
                                    nodes: [],
                                },
                            ],
                        },
                        {
                            labels: [{ languageCode: "default", value: "South America" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Venezuela" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Paraguay" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Columbia" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Chile" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Brazil" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Argentina" }],
                                    nodes: [],
                                },
                            ],
                        },
                        {
                            labels: [
                                { languageCode: "default", value: "Middle East & Africa" },
                            ],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "South Africa" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Libya" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Israel" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Iran" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Egypt" }],
                                    nodes: [],
                                },
                            ],
                        },
                        {
                            labels: [{ languageCode: "default", value: "Asia Pacific" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "South Korea" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "New Zeeland" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Japan" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "India" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "China" }],
                                    nodes: [],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Australia" }],
                                    nodes: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    labels: [{ languageCode: "default", value: "Google" }],
                    nodes: [],
                },
                {
                    labels: [{ languageCode: "default", value: "Company Utilities" }],
                    useSecondLevel: true,
                    nodes: [
                        {
                            labels: [
                                { languageCode: "default", value: "Security & Support" },
                            ],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "IT Security" }],
                                    nodes: [
                                        {
                                            labels: [{ languageCode: "default", value: "Trainings" }],
                                        },
                                        { labels: [{ languageCode: "default", value: "Videos" }] },
                                        { labels: [{ languageCode: "default", value: "Tests" }] },
                                        {
                                            labels: [{ languageCode: "default", value: "Webinars" }],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Support" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Software Support" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Hardware Support" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Maintenance Announcements",
                                                },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Report a Network Problem",
                                                },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Recently Closed Tickets",
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            labels: [{ languageCode: "default", value: "Perks" }],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Groceries" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Food Stamps" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Supermakert Vouchers",
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    labels: [{ languageCode: "default", value: "Medical" }],
                                    nodes: [
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Pharmaceutical offers",
                                                },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Doctor Appointments",
                                                },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Medical Coverage" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Medical Expenses" },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            labels: [
                                { languageCode: "default", value: "Employee Shortcuts" },
                            ],
                            nodes: [
                                {
                                    labels: [{ languageCode: "default", value: "Useful Links" }],
                                    nodes: [
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Seat Access" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Parking Access" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Company Software" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "Lunch Offerings" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                { languageCode: "default", value: "New Documents" },
                                            ],
                                        },
                                        {
                                            labels: [
                                                {
                                                    languageCode: "default",
                                                    value: "Compliance Agreements",
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    generalOptions: {},
};
