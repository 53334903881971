import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDown24Regular } from "@fluentui/react-icons";
import React from "react";
import styled from "styled-components";
import { useBranding } from "../BrandingContext";
const PreviewContainer = styled.div.withConfig({ displayName: "PreviewContainer", componentId: "-1po7aym" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 5%; 

  h4 {
    text-align: center;
    color: #CCCCCC;
    font-size: 14px;
    font-weight: 600;
  }

  &.style-intense {
    --header-background-color: var(--primary-color, #3D3A3B);
    --header-text-color: #fff;
  }

  &.style-soft {
    --header-background-color: #f7f7f7;
    --header-text-color: var(--primary-color, #3D3A3B);
  }
`;
const Preview = styled.div.withConfig({ displayName: "Preview", componentId: "-ug5cjy" }) `
  display: var(--container-display, flex);
  grid-template-columns: 1fr auto 1fr;
  position: relative;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  margin-inline: auto;
  width: 100%;
  max-width: 1000px;
  padding-inline: 10px;
  background: var(--header-background-color, #3D3A3B);

  img {
    max-width: 100%;
    max-height: 80px;
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 10px 15px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 7px 18px;
    font-weight: 600;
    font-size: 14px;
    color: var(--header-text-color, #ffffff);
    transition: all 0.3s ease-in-out;

    &.active {
      border-radius: var(--button-border-radius, 0);
      margin-inline: 15px;
    }

    .style-intense & {
      &.active {
        background: #f7f7f7;
        color: var(--primary-color, #3D3A3B);
      }
    }

    .style-soft & {
      color: var(--textSettings-color, #3D3A3B);

      &.active {
        background: var(--primary-color, #3D3A3B);
        color: #ffffff;
      }
    }

    svg {
      max-width: 14px;
      margin-left: 4px;
    }
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: var(--header-background-color, #3D3A3B);
    border-top: 0;
    margin-left: -9px;
    margin-top: -9px;
  }

  &:before {
    border-bottom-color: #CCCCCC;
    margin-top: -10px;
    z-index: 0;
  }
`;
const PreviewLogo = styled.div.withConfig({ displayName: "PreviewLogo", componentId: "-1dwi4r4" }) `
  display: flex;
  align-items: center;
`;
const HeaderPreview = (props) => {
    var _a, _b, _c, _d, _e;
    const { selectedTheme } = useBranding();
    const [themeLoaded, setThemeLoaded] = React.useState(false);
    const preview = React.useRef(null);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        const previewContainer = preview.current;
        if (preview.current && ((_b = (_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.primaryColor)) {
            previewContainer.style.setProperty("--primary-color", (_d = (_c = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _c === void 0 ? void 0 : _c.color) === null || _d === void 0 ? void 0 : _d.primaryColor);
        }
        if (preview.current && ((_f = (_e = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _e === void 0 ? void 0 : _e.menuStyle) === null || _f === void 0 ? void 0 : _f.buttonRadius)) {
            previewContainer.style.setProperty("--button-border-radius", (_h = (_g = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _g === void 0 ? void 0 : _g.menuStyle) === null || _h === void 0 ? void 0 : _h.buttonRadius);
        }
        if (preview.current && selectedTheme !== null) {
            previewContainer.style.setProperty("--container-display", ((_l = (_k = (_j = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _j === void 0 ? void 0 : _j.menuStyle) === null || _k === void 0 ? void 0 : _k.textSettings) === null || _l === void 0 ? void 0 : _l.textAlignCenter) ? "grid" : "flex");
            previewContainer.style.setProperty("--textSettings-color", ((_p = (_o = (_m = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _m === void 0 ? void 0 : _m.menuStyle) === null || _o === void 0 ? void 0 : _o.textSettings) === null || _p === void 0 ? void 0 : _p.textColorPrimary) ? (_r = (_q = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _q === void 0 ? void 0 : _q.color) === null || _r === void 0 ? void 0 : _r.primaryColor : "#3D3A3B");
        }
        setThemeLoaded(true);
    }, [selectedTheme]);
    return (_jsxs(PreviewContainer, Object.assign({ ref: preview, className: `style-${((_b = (_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.style) ? selectedTheme.themeSettings.color.style : "intense"}` }, { children: [_jsx("h4", { children: "Menu Preview" }), themeLoaded && (_jsxs(Preview, { children: [_jsx(PreviewLogo, { children: ((_c = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _c === void 0 ? void 0 : _c.base64) && selectedTheme.logo.base64 !== "data:;base64," && typeof selectedTheme.logo.base64 === 'string' ? (_jsx("img", { src: selectedTheme.logo.base64, alt: "Logo" })) : null }), _jsxs("ul", { children: [_jsx("li", Object.assign({ className: ((_e = (_d = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _d === void 0 ? void 0 : _d.menuStyle) === null || _e === void 0 ? void 0 : _e.buttonRadius) ? "active" : "" }, { children: "Home" })), _jsxs("li", { children: ["Work ", _jsx(ChevronDown24Regular, {})] }), _jsxs("li", { children: ["Organization ", _jsx(ChevronDown24Regular, {})] }), _jsxs("li", { children: ["Locations ", _jsx(ChevronDown24Regular, {})] }), _jsxs("li", { children: ["Products ", _jsx(ChevronDown24Regular, {})] })] })] }))] })));
};
export default HeaderPreview;
