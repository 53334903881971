import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import ReactDOM from "react-dom";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Radio, RadioGroup, Text } from "@fluentui/react-components";
export const SettingsModal = (props) => {
    const [settings, setSettings] = React.useState(props.settings);
    const setTextFormat = (e) => {
        setSettings(Object.assign(Object.assign({}, settings), { textFormat: e.target.value }));
    };
    const setNavigationInteraction = (e) => {
        setSettings(Object.assign(Object.assign({}, settings), { navigationInteraction: e.target.value }));
    };
    return (_jsx(Dialog, Object.assign({ open: props.showModal }, { children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Settings" }), _jsxs(DialogContent, { children: [_jsx("p", { children: "Customize menu text appearance and interaction behavior" }), _jsx(Field, Object.assign({ label: _jsx("h3", { children: "Text Format" }) }, { children: _jsxs(RadioGroup, Object.assign({ defaultValue: settings.textFormat, onChange: setTextFormat }, { children: [_jsx(Radio, { value: "uppercase", label: _jsx("strong", { children: "Uppercase letters only" }) }), _jsx(Radio, { value: "capitalize", label: _jsx("strong", { children: "Capitalize first letter" }) })] })) })), _jsx("br", {}), _jsx(Field, Object.assign({ label: _jsx("h3", { children: "Navigation interaction mode" }) }, { children: _jsxs(RadioGroup, Object.assign({ defaultValue: settings.navigationInteraction, onChange: setNavigationInteraction }, { children: [_jsx(Radio, { value: "click", label: _jsxs(_Fragment, { children: [_jsx("strong", { children: "Click to expand" }), _jsx("br", {}), _jsx(Text, Object.assign({ size: 200 }, { children: "Users must click on menu items to expand or access submenus." }))] }) }), _jsx(Radio, { value: "hover", label: _jsxs(_Fragment, { children: [_jsx("strong", { children: "Hover to expand" }), _jsx("br", {}), _jsx(Text, Object.assign({ size: 200 }, { children: "Submenus are revealed when users hover over menu items." }))] }) })] })) }))] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                    props.closeModal();
                                } }, { children: "Cancel" })), _jsx(Button, Object.assign({ onClick: () => {
                                    props.updateSettings(settings);
                                }, appearance: "primary" }, { children: "Save" }))] })] }) }) })));
};
export const ModalPortal = (props) => {
    return ReactDOM.createPortal(props.children, document.body);
};
