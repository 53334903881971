import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { IdentityType } from "../../components/AudienceTargeting/Picker/Picker";
import { styled } from "styled-components";
import { MegaMenuAPIService } from "../../util/MegaMenuAPIService/MegaMenuAPIService";
import { MicrosoftPallete } from "../../components/AudienceTargeting/Picker/PickedUser";
import TrashcanIcon from "/public/Trashcan.svg";
import EllipseIcon from "/public/Ellipse.svg";
const ProfileDetails = styled.div.withConfig({ displayName: "ProfileDetails", componentId: "-17sgbjc" }) `
  display: flex;
  padding-inline: 8px;
  line-height: 1rem;
  padding-top:20px
`;
const ProfileName = styled.span.withConfig({ displayName: "ProfileName", componentId: "-bmwv7h" }) `
  color: var(--NuetralPrimary, #3D3A3B);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Segoe UI;
font-size: 14px;
padding-top: 5px;
font-style: normal;
font-weight: 600;
line-height: normal;
min-width:120px;
`;
const Elipse = styled.img.withConfig({ displayName: "Elipse", componentId: "-106p6up" }) `
 width:11px;
 margin-left: 10px;
 margin-right: 10px;
 margin-top:2.5px;
`;
const EmailWrapper = styled.div.withConfig({ displayName: "EmailWrapper", componentId: "-t836kt" }) `
min-width:300px;
color: var(--NuetralSecondary, #707070);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Segoe UI;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top: 5px;
`;
const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "-1b4flo8" }) `
  padding-top:6px;
  display: flex; 
  justify-content: end;
  position: relative;
  margin-left: 50px;
`;
const Trashcan = styled.img.withConfig({ displayName: "Trashcan", componentId: "-nwxm72" }) `
width: 13px;
height: 14px;
cursor:pointer;
`;
const InvitedUserWrapper = styled.div.withConfig({ displayName: "InvitedUserWrapper", componentId: "-uss6cy" }) `
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;
  padding: 6px 8px;
  background-color: white;
  border-radius: 4px;
`;
const ProfileWrapper = styled.div.withConfig({ displayName: "ProfileWrapper", componentId: "-elp9n7" }) `
  display: flex;
  padding-top: 20px;
`;
const ProfileImage = styled.img.withConfig({ displayName: "ProfileImage", componentId: "-10wdmnz" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
`;
const NoImagePlaceHolder = styled.div.withConfig({ displayName: "NoImagePlaceHolder", componentId: "-lvlxr1" }) `
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: ${(p) => p.color};
    display: flex;
    justify-content: center;
  `;
const NoImageName = styled.span.withConfig({ displayName: "NoImageName", componentId: "-5fi5fe" }) `
  color: white;
  padding-top: 2.5px;
  font-weight: 600;
`;
export const InvitedUser = (props) => {
    const [userInfo, setUserInfo] = React.useState(null);
    const [userColor, setUserColor] = React.useState(null);
    const [image, setImage] = React.useState(props.user.image);
    function getNameNoPhoto() {
        if (!userInfo)
            return null;
        const split = userInfo.displayName.split(" ");
        if (split.length > 1) {
            return split[0].charAt(0) + split[1].charAt(0);
        }
        else {
            return split[0].charAt(0);
        }
    }
    React.useEffect(() => {
        var _a;
        if ((_a = props.user) === null || _a === void 0 ? void 0 : _a.displayName)
            setUserInfo(props.user);
        else {
            MegaMenuAPIService.getEntity(props.user.id, props.user.type).then((result) => {
                setUserInfo(result);
            });
        }
    }, []);
    React.useEffect(() => {
        if (userInfo) {
            const lastLetter = userInfo.displayName.charAt(userInfo.displayName.length - 1);
            const index = lastLetter.charCodeAt(0) % MicrosoftPallete.length;
            setUserColor(MicrosoftPallete[index]);
        }
        if (props.user.type === IdentityType.User) {
            MegaMenuAPIService.getGraphUserPhoto(props.user.id).then(result => {
                setImage(result);
            });
        }
    }, [userInfo]);
    return (_jsxs(InvitedUserWrapper, { children: [_jsx(ProfileWrapper, { children: image ? (_jsx(ProfileImage, { src: image })) : (_jsx(NoImagePlaceHolder, Object.assign({ color: userColor }, { children: _jsx(NoImageName, { children: getNameNoPhoto() }) }))) }), _jsxs(ProfileDetails, { children: [_jsx(ProfileName, { children: props.user.displayName }), _jsx(Elipse, { src: EllipseIcon }), _jsx(EmailWrapper, { children: props.user.loginName }), props.isAdmin && _jsx(ButtonWrapper, { children: _jsx(Trashcan, { onClick: () => {
                                props.delete(props.user);
                            }, src: TrashcanIcon }) })] })] }));
};
