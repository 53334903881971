export const draftStatus = "draft";
export const publishedStatus = "published";
export const publishedUpper = "PUBLISHED";
export const draftUpper = "DRAFT";
export const matrixView = "matrix";
export const megamenuView = "megamenu";
export const configView = "configuration";
export const viewerRole = "Viewers.Read";
export const adminRole = "Admins.Group";
export const editorRole = "Editors.Group";
export const successCode = 200;
