import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { Title } from "../../../Nav.styles";
import { ActivePath, Separator, NewPathWrapper, PathItemLabel, } from "./PathwaysTab.styles";
import { Button, Field, Input } from "@fluentui/react-components";
const PathwaysTabWrapper = styled.div.withConfig({ displayName: "PathwaysTabWrapper", componentId: "-q6npqm" }) `
  width: 100%;
  padding: 0;
`;
const ActionButtons = styled.fieldset.withConfig({ displayName: "ActionButtons", componentId: "-1y2ha6t" }) `
  justify-content: flex-end;

  button + button {
    margin-left: .5rem;
  }
`;
const DeleteButton = styled(Button).withConfig({ displayName: "DeleteButton", componentId: "-dxbgnf" }) `
  && {
    color: var(--red-lighter-text);
    border: 1px solid var(--red-lighter-text);
    background-color: var(--red-lighter-bg);
  }
`;
export const PathwaysTab = (props) => {
    const [editPathUrl, setEditPathUrl] = React.useState("");
    React.useEffect(() => {
        function getEditPathUrl() {
            if (props.editPath.length === 0)
                return "";
            const selectedPath = props.paths.filter((path) => {
                let selection = path.selection;
                return selection.toString() === props.editPath.toString();
            });
            return selectedPath.length ? selectedPath[0].url : "";
        }
        setEditPathUrl(getEditPathUrl);
    }, [props.editPath]);
    function onChangeURLValue(e) {
        setEditPathUrl(e.target.value);
    }
    function labelLookup(itemId, dimension) {
        var _a;
        const label = (_a = props.dimensions[dimension].items.find((item) => item.itemId === itemId)) === null || _a === void 0 ? void 0 : _a.text;
        return label;
    }
    function createPath() {
        props.updateEditPath(editPathUrl);
    }
    function updatePath() {
        props.updateEditPath(editPathUrl);
    }
    function deletePath() {
        props.deleteEditPath();
    }
    function isNewPathDisplayed(paths, editPath) {
        if (editPath.length === 0)
            return false;
        const filteredPaths = paths.filter((path) => {
            let selection = path.selection;
            return selection.toString() === editPath.toString();
        });
        return filteredPaths.length > 0 ? false : true;
    }
    return (_jsxs(PathwaysTabWrapper, { children: [_jsx(Title, { children: "Pathways" }), _jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "New Path" }), _jsx(NewPathWrapper, Object.assign({ className: "newPath" }, { children: _jsx(ActivePath, { children: props.editPath.map((itemId, i) => (_jsxs(_Fragment, { children: [_jsx(PathItemLabel, { children: labelLookup(itemId, i) }, i), i + 1 < props.editPath.length && _jsx(Separator, {})] }))) }) }))] })), _jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "New Path URL" }), _jsx(Field, { children: _jsx(Input, { value: editPathUrl, onChange: onChangeURLValue }) })] })), _jsx(ActionButtons, Object.assign({ className: "lt-style" }, { children: isNewPathDisplayed(props.paths, props.editPath) ? (_jsx(Button, Object.assign({ onClick: () => createPath() }, { children: "Create" }))) : (_jsxs(_Fragment, { children: [_jsx(DeleteButton, Object.assign({ onClick: () => deletePath() }, { children: "Delete" })), _jsx(Button, Object.assign({ onClick: () => updatePath() }, { children: "Update" }))] })) }))] }));
};
