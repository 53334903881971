import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
const HeaderContainer = styled.div.withConfig({ displayName: "HeaderContainer", componentId: "-1vmlnbl" }) `
  display: block;
  align-items: center;
  margin-bottom: 1rem;
  img {
    margin-block: 0.5rem;
  }
`;
const HeaderPrimaryTitle = styled.div.withConfig({ displayName: "HeaderPrimaryTitle", componentId: "-10qc4ul" }) `
  font-weight: 600;
  font-size: 28px;
  margin-block: 0 0.5rem;
`;
const BackButton = styled.button.withConfig({ displayName: "BackButton", componentId: "-1rsoaqc" }) `
  && {
    display: flex;
    margin-inline: 0 1.25rem;
  }
`;
const TextButton = styled.div.withConfig({ displayName: "TextButton", componentId: "-uk9yeu" }) `
  padding-left: 0.5rem;
`;
const SubsectionText = styled.div.withConfig({ displayName: "SubsectionText", componentId: "-a8lb80" }) `
  font-size: 14px;
  color: #5c5653;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
const HeaderMatrix = (props) => {
    return (_jsxs(HeaderContainer, { children: [_jsx(HeaderPrimaryTitle, { children: "Matrix" }), _jsx(SubsectionText, { children: "Configure the options for your Matrix" })] }));
};
export default HeaderMatrix;
