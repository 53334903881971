import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import styled from "styled-components";
import MegaMenuOptions from "./MegaMenuOptions";
const NavigationContainer = styled.div.withConfig({ displayName: "NavigationContainer", componentId: "-gadljt" }) `
  margin-inline-end: 3rem;

  h1 {
    font-weight: 700;

    span {
      opacity: 0.4;
    }
  }
`;
const Megamenu = (props) => {
    return (_jsx(NavigationContainer, { children: _jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(MegaMenuOptions, { props: props }) })) }));
};
export default Megamenu;
