import styled from "styled-components";
export const StylingWrapper = styled.ul.withConfig({ displayName: "StylingWrapper", componentId: "-amb85h" }) `
  --lt-primary-color: #512da8;
  --lt-flyout-background-color: #fff;
  --lt-flyout-text-color: #222;
  --lt-flyout-column-width: 196px;
  --shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.025);

  width: 100%;
  display: flex;
  color: var(--lt-flyout-text-color, #222);
  list-style: none;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  ul {
    position: relative;
    width: 100%;
    padding: 0;
    list-style: none;
  }

  svg {
    margin-inline-start: 0.25rem;
    margin-top: 2px;
    max-height: 10px;
    pointer-events: none;
    transition: transform 0.3s;
  }

  .isActive > a > svg {
    transform: rotate(180deg);
  }

  &.level-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: var(--lt-first-level-text);
    background-color: var(--lt-first-level-background);

    a,
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--lt-first-level-text);
      text-transform: var(--lt-first-level-text-format, initial);
    }

    > li {
      > a,
      > span {
        padding: 10px 16px;
        border-radius: 20px;
        color: var(--lt-first-level-text);
        font-weight: 600;

        :hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      &.isActive {
        > a,
        > span {
          color: white;
          background: var(--lt-primary-color);
        }
      }
    }
  }

  &.level-2 {
    justify-content: center;
    position: absolute;
    top: calc(100% + 1px);
    inset-inline: 0;
    padding: 0;
    background: #f7f7f7;
    max-height: calc(50vh - 145px);
    box-shadow: var(--shadow);
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    transform: translateY(-100%);
    z-index: -1;

    .isActive > & {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0);
    }

    a,
    span {
      text-transform: var(--lt-second-level-text-format, capitalize);
      color: #3d3a3b;

      &:hover {
        font-weight: 600;
      }
    }

    .icon-link {
      margin: 2px 5px;
      opacity: 0.35;
    }

    > li {
      > a,
      > span {
        padding-block: 16px;
        margin-inline: 1rem;
        border-block: 4px solid transparent;
      }

      &.isActive {
        > a,
        > span {
          font-weight: 600;
          border-bottom-color: var(--lt-primary-color);
        }
      }
    }
  }
`;
export const Flyout = styled.div.withConfig({ displayName: "Flyout", componentId: "-6agrme" }) `
  position: absolute;
  top: calc(100% + 1px);
  left: var(--flyout-left, auto);
  right: var(--flyout-right, auto);
  background: var(--lt-flyout-background-color, #fff);
  font-weight: 400;
  box-shadow: var(--shadow);
  border-radius: 8px;
  transition: all 0.3s;
  font-size: 0.8rem;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;

  &.isActive {
    visibility: visible;
    pointer-events: auto;
  }

  :has(+ .flyoutHasChildren) {
    width: 525px;
  }

  li {
    > a,
    > span {
      padding: 6px 0.75rem;
      font-weight: 600;
    }
  }

  .level-3 {
    flex-direction: column;
    font-weight: 400;
    transition: all 0.3s;
    overflow: hidden;
    padding: 24px 20px;
    transition: all 0.5s;
    min-height: var(--min-height, 0);

    > li {
      border-radius: 4px;

      > a,
      > span {
        color: #3d3a3b;
      }
    }

    &.flyoutHasChildren {
      width: calc(var(--lt-flyout-column-width) * 2);
      padding-right: var(--lt-flyout-column-width);
    }

    .isActive > .level-4 {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    .style-classic & {
      flex-direction: row;
      flex-wrap: var(--flyout-flow, nowrap);
      gap: 25px 20px;

      > li {
        width: auto;
        min-width: var(--lt-flyout-column-width);
        flex: 0 0 auto;

        > a,
        > span {
          font-weight: 700;
          border-bottom: 1px solid #F2F2F2;
          padding-bottom: 15px;
          margin-bottom: 5px;

          &:empty {
            display: none;
          }
        }
      }
    }

    .style-compact & {
      li {
        width: calc(var(--lt-flyout-column-width) - 2rem);
      }

      > li {
        padding: 6px 0;

        > a,
        > span {
          position: relative;
          color: #3d3a3b;
          font-weight: 700;

          &:before {
            position: absolute;
            content: "";
            top: 0px;
            left: -1.5px;
            bottom: 0px;
            width: 3px;
            background-color: transparent;
            border-radius: 1.5px;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, 0.05);

          > a:before {
            background-color: #cccccc;
          }
        }

        &:hover > a:before,
        &:hover > span:before {
          background-color: #cccccc;
        }

        &.isActive > a,
        &.isActive > span {
          color: var(--lt-primary-color);

          &:before {
            background-color: var(--lt-primary-color);
          }
        }
      }
    }
  }

  .level-4 {
    flex-direction: column;
    transition: all 0.2s;
    transition-delay: 0.15s;

    li {
      padding: 3px 0;

      .style-compact & {
        padding-block: 6px;
      }
    }

    a,
    span {
      color: #3d3a3b;

      &:hover {
        color: var(--lt-primary-color);
      }
    }

    .style-classic & {
      max-height: 200px;
      flex-wrap: wrap;

      li {
        width: var(--lt-flyout-column-width);
      }
    }

    .style-compact & {
      width: var(--lt-flyout-column-width);
      min-height: 100%;
      position: absolute;
      top: 0;
      left: var(--lt-flyout-column-width);
      padding: 24px 20px;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      border-left: 2px solid #f5f5f5;
    }
  }
`;
export const LogoContainer = styled.div.withConfig({ displayName: "LogoContainer", componentId: "-1aoi68u" }) `
  display: flex;
  align-items: center;
  height: 52px;
  margin-inline-start: 10px;
  position: absolute;

  img {
    max-height: 100%;
    cursor: pointer;
  }

  a,
  span {
    display: flex;
    align-items: center;
    height: 100%;
    padding-inline: 10px;
  }
`;
export const FlyoutBackdrop = styled.div.withConfig({ displayName: "FlyoutBackdrop", componentId: "-jqqa94" }) `
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  inset: 0;
  z-index: 8;
`;
