var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import jwt_decode from "jwt-decode";
import { viewerRole, adminRole, editorRole } from "../constants";
import { instance } from "../../../../index";
import envVarConfig from "../../../../env/envVarConfig";
export class AutorizationService {
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            const token = yield instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [envVarConfig.backOfficeAppScope],
            });
            return token.accessToken;
        });
    }
    static checkRole(role) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = jwt_decode(yield AutorizationService.getToken());
            const roles = token["roles"];
            if (roles)
                if (roles.includes(role))
                    return true;
                else
                    return false;
            else
                return false;
        });
    }
    static IsAdmin() {
        return AutorizationService.checkRole(adminRole);
    }
    static IsEditor() {
        return AutorizationService.checkRole(editorRole);
    }
    static IsViewer() {
        return AutorizationService.checkRole(viewerRole);
    }
}
