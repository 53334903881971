var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Dialog, Input, Spinner, TableCellLayout, Toast, ToastTitle, Toaster, createTableColumn, useId, useToastController, } from "@fluentui/react-components";
import { DeleteRegular, EditRegular } from "@fluentui/react-icons";
import { AddRegular, SearchRegular } from "@fluentui/react-icons";
import DeleteAudienceModal from "./modals/DeleteAudienceModal";
import EditAudienceModal from "./modals/EditAudienceModal";
import { AudiencesService } from "./AudiencesService";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-xacqxm" }) `
  margin-inline-end: 3rem;

  > h1 {
    display: flex;
    margin-bottom: 0.5rem;
  }

  > h4 {
    font-weight: 400;
    margin-bottom: 2.5rem;
  }
`;
const FiltersContainer = styled.div.withConfig({ displayName: "FiltersContainer", componentId: "-nq3v2z" }) `
  display: flex;
`;
const SearchInput = styled(Input).withConfig({ displayName: "SearchInput", componentId: "-p1hm6m" }) `
  margin-right: 16px;
  min-width: 300px;
  width: 357px;
`;
const BodyContainer = styled.div.withConfig({ displayName: "BodyContainer", componentId: "-1fl08vf" }) `
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  overflow: auto;
  max-height: calc(100vh - 350px);
`;
const AudiencesTableCellLayout = styled(TableCellLayout).withConfig({ displayName: "AudiencesTableCellLayout", componentId: "-18te73r" }) `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > div {
    overflow: hidden;
  }
`;
const TruncatedSpan = styled.span.withConfig({ displayName: "TruncatedSpan", componentId: "-14xwnry" }) `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActionsTableCellLayout = styled(TableCellLayout).withConfig({ displayName: "ActionsTableCellLayout", componentId: "-n2b3lq" }) `
  justify-content: flex-end;
`;
const HeaderTableCellLayout = styled(TableCellLayout).withConfig({ displayName: "HeaderTableCellLayout", componentId: "-13labsj" }) `
  font-size: 16px;
`;
const Audiences = () => {
    const { GetAudiences } = AudiencesService();
    const [audienceCollection, setAudienceCollection] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [showEditAudienceModal, setShowEditAudienceModal] = useState(false);
    const [audienceToEdit, setAudienceToEdit] = useState();
    const [audienceToDelete, setAudienceToDelete] = useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const toasterId = useId("audienceToaster");
    const { dispatchToast } = useToastController(toasterId);
    const getInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const audiences = yield GetAudiences();
            if (audiences === null || audiences === void 0 ? void 0 : audiences.audienceCollection)
                setAudienceCollection(audiences.audienceCollection);
            else
                setAudienceCollection([]);
        }
        catch (e) {
            console.error(e);
            setIsLoading(false);
        }
        setIsLoading(false);
    });
    const reloadInfo = (audiences) => {
        if (audiences === null || audiences === void 0 ? void 0 : audiences.audienceCollection)
            setAudienceCollection(audiences.audienceCollection);
        else
            setAudienceCollection([]);
    };
    useEffect(() => {
        getInfo();
    }, []);
    const filteredAudiences = audienceCollection.filter((audience) => {
        return audience === null || audience === void 0 ? void 0 : audience.audienceName.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase());
    });
    const columns = [
        createTableColumn({
            columnId: "name",
            renderHeaderCell: () => (_jsx(HeaderTableCellLayout, { children: "Audience" })),
            renderCell: (item) => item === null || item === void 0 ? void 0 : item.audienceName,
        }),
        createTableColumn({
            columnId: "audiences",
            renderHeaderCell: () => (_jsx(HeaderTableCellLayout, { children: "Description" })),
            renderCell: (item) => (_jsx(AudiencesTableCellLayout, { children: _jsx(TruncatedSpan, { children: item === null || item === void 0 ? void 0 : item.description }) })),
        }),
        createTableColumn({
            columnId: "actions",
            renderHeaderCell: () => "",
            renderCell: (item) => (_jsxs(ActionsTableCellLayout, { children: [_jsx(Button, { style: { paddingRight: "19px" }, appearance: "transparent", icon: _jsx(EditRegular, { color: "#512DA8" }), onClick: () => {
                            setShowEditAudienceModal(true);
                            setAudienceToEdit(item);
                        } }), _jsx(Button, { appearance: "transparent", icon: _jsx(DeleteRegular, { color: "#512DA8" }), onClick: (e) => setAudienceToDelete(item) })] })),
        }),
    ];
    const handleEditAudienceModalClose = () => {
        setShowEditAudienceModal(false);
        setAudienceToEdit(undefined);
    };
    const handleDeleteAudienceModalClose = () => {
        setAudienceToDelete(undefined);
    };
    const notifyDeleted = () => {
        notify("Audience deleted successfully");
    };
    const notifSuccess = (isNew) => {
        if (isNew)
            notify("Audience created successfully");
        else
            notify("Audience updated successfully");
    };
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "success" });
    return (_jsxs(Container, { children: [_jsx("h1", { children: "Audiences" }), _jsx("h4", { children: "Manage and create audiences." }), isLoading ? (_jsx(Spinner, { size: "small", label: "We're getting everything ready for you..." })) : (_jsxs(_Fragment, { children: [_jsxs(FiltersContainer, { children: [_jsx(SearchInput, { contentBefore: _jsx(SearchRegular, {}), placeholder: "Search for audiences", value: searchInput, onChange: (e) => {
                                    setSearchInput(e.target.value);
                                } }), _jsx(Button, Object.assign({ appearance: "primary", icon: _jsx(AddRegular, {}), onClick: () => setShowEditAudienceModal(true) }, { children: "Create new audience" }))] }), audienceCollection && (_jsx(BodyContainer, { children: _jsxs(DataGrid, Object.assign({ columns: columns, focusMode: "composite", items: filteredAudiences }, { children: [_jsx(DataGridHeader, { children: _jsx(DataGridRow, { children: ({ renderHeaderCell }) => (_jsx(DataGridHeaderCell, { children: renderHeaderCell() })) }) }), _jsx(DataGridBody, { children: ({ item, rowId }) => (_jsx("div", { children: _jsx(DataGridRow, { children: ({ renderCell }) => {
                                                return (_jsx(DataGridCell, { children: renderCell(item) }));
                                            } }) }, rowId)) })] })) }))] })), _jsx(Dialog, Object.assign({ open: showEditAudienceModal, onOpenChange: handleEditAudienceModalClose }, { children: _jsx(EditAudienceModal, { audience: audienceToEdit, onClose: handleEditAudienceModalClose, onSave: (update, isNew) => {
                        getInfo();
                        notifSuccess(isNew);
                    } }) })), _jsx(Dialog, Object.assign({ open: !!audienceToDelete, onOpenChange: handleDeleteAudienceModalClose }, { children: _jsx(DeleteAudienceModal, { audience: audienceToDelete, onClose: handleDeleteAudienceModalClose, onSave: (updated) => {
                        reloadInfo(updated);
                        notifyDeleted();
                    } }) })), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
};
export default Audiences;
