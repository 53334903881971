import { jsx as _jsx } from "react/jsx-runtime";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
// MSAL imports
import { PublicClientApplication, EventType, } from "@azure/msal-browser";
import { msalConfig } from "./Auth/authConfig";
import { AppContextProvider } from "./Context/AppContext";
export const instance = new PublicClientApplication(msalConfig);
instance.initialize().then(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
    }
    instance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload;
            const account = payload.account;
            instance.setActiveAccount(account);
        }
    });
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(_jsx(StrictMode, { children: _jsx(AppContextProvider, { children: _jsx(Router, { children: _jsx(App, { instance: instance }) }) }) }));
});
