var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogContent, Input, Label, Link, } from "@fluentui/react-components";
import { ArrowUploadRegular } from "@fluentui/react-icons";
import { QuicklinksService } from "../QuicklinksService";
const SectionTitle = styled.span.withConfig({ displayName: "SectionTitle", componentId: "-gyeqgx" }) `
  color: #3d3a3b;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 32px;
  margin-top: 12px;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-164du3q" }) `
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-1rl670d" }) `
  color: #707070;
  margin-bottom: 4px;
`;
const MarginBottomInput = styled(Input).withConfig({ displayName: "MarginBottomInput", componentId: "-133akui" }) `
  margin-bottom: 16px;
`;
const FileDropArea = styled.div.withConfig({ displayName: "FileDropArea", componentId: "-iw539l" }) `
  align-items: center;
  background-color: ${(props) => props.$isDraggingOver ? "#ECE9F5" : "rgba(81, 45, 168, 0.04)"};
  border-color: ${(props) => props.$isDraggingOver ? "#512DA8" : "rgba(81, 45, 168, 0.2)"};
  border-radius: 4px;
  border-style: dashed;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  height: 150px;
  position: relative;
  transition: 250ms all ease-in-out;
  width: 100%;
`;
const FileInput = styled.input.withConfig({ displayName: "FileInput", componentId: "-1yzm4ws" }) `
  inset: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
`;
const FileUploadIcon = styled(ArrowUploadRegular).withConfig({ displayName: "FileUploadIcon", componentId: "-19gs6g" }) `
  color: #cccccc;
  height: 30px;
  margin-bottom: 12px;
  margin-top: 32px;
  width: 30px;
`;
const FileUploadLabel = styled.span.withConfig({ displayName: "FileUploadLabel", componentId: "-azm0dz" }) `
  color: #707070;
`;
const IconGrid = styled.div.withConfig({ displayName: "IconGrid", componentId: "-1b7weam" }) `
  display: flex;
  justify-items: center;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
`;
const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "-12rj3q1" }) `
  align-items: center;
  border: ${(p) => (p.$selected ? "2px solid" : "")};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-1vz4ape" }) `
  padding-top: 24px;
`;
const Icon = styled.img.withConfig({ displayName: "Icon", componentId: "-a7eg6t" }) `
  width: 40px;
  height: 40px;
`;
const LinkIconModalBody = ({ type, onClose, onIconSave, }) => {
    const { GetIcons, AddIcon } = QuicklinksService();
    const fileInputRef = useRef(null);
    const [icon, setIcon] = useState();
    const [iconUrl, setIconUrl] = useState();
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [libraryIcons, setLibraryIcons] = useState([]);
    const [LibraryIcon, setLibraryIcon] = useState(null);
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        const getLibraryIcons = () => __awaiter(void 0, void 0, void 0, function* () {
            setLibraryIcons(yield GetIcons());
        });
        if (type === "library")
            getLibraryIcons();
    }, [type]);
    const titleMap = {
        library: "Select an icon from our library for the quicklink:",
        link: "Provide the link to the location from which you want to upload the icon from:",
        upload: "Upload an icon from your desktop or drag and drop the file into the area below:",
    };
    const handleFileInputClick = (e) => {
        if (e.nativeEvent.pointerType === "mouse") {
            e.preventDefault();
        }
    };
    const handleFileSelect = (e) => {
        var _a;
        e.stopPropagation();
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleIconClick = (icon) => {
        setLibraryIcon(icon);
    };
    const handleIconSave = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (iconUrl)
            onIconSave({
                iconId: "000000000000000000000000",
                url: iconUrl,
                type: "External",
            });
        else if (LibraryIcon)
            onIconSave(LibraryIcon);
        else if (Icon) {
            const formData = new FormData();
            formData.append("File", (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files[0]);
            formData.append("Name", (_b = fileInputRef.current) === null || _b === void 0 ? void 0 : _b.files[0].name);
            const result = yield AddIcon(formData);
            console.debug("> Uploading icon", Icon, result);
            onIconSave({
                type: "Internal",
                iconId: result === null || result === void 0 ? void 0 : result.iconId,
                url: `/QuickLinks/${result === null || result === void 0 ? void 0 : result.tenantId}/icon/${result === null || result === void 0 ? void 0 : result.iconId}/download`,
                preview: result === null || result === void 0 ? void 0 : result.preview,
            });
        }
        onClose();
    });
    return (_jsxs(_Fragment, { children: [_jsxs(StyledDialogContent, { children: [_jsx(SectionTitle, { children: titleMap[type] }), type === "link" ? (_jsxs(_Fragment, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "edit-quicklink-icon", required: true }, { children: "Link" })), _jsx(MarginBottomInput, { id: "edit-quicklink-icon", placeholder: "Insert the icon link", size: "large", type: "url", onChange: (e) => setIconUrl(e.target.value) })] })) : type === "upload" ? (_jsxs(FileDropArea, Object.assign({ "$isDraggingOver": isDraggingOver }, { children: [_jsx(FileInput, { accept: "image/*", ref: fileInputRef, title: "", type: "file", onClick: handleFileInputClick, onChange: () => {
                                    var _a;
                                    setIcon((_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files[0]);
                                }, onDragEnter: () => {
                                    setIsDraggingOver(true);
                                }, onDragLeave: () => {
                                    setIsDraggingOver(false);
                                }, onDrop: () => {
                                    setIsDraggingOver(false);
                                } }), _jsx(FileUploadIcon, {}), _jsx(FileUploadLabel, { children: icon ? icon.name : "Drop the file here or" }), _jsx(Link, Object.assign({ as: "button", style: { zIndex: 2 }, onClick: handleFileSelect }, { children: "Select file" }))] }))) : (_jsx(IconGrid, { children: libraryIcons.map((icon, i) => {
                            return (_jsx(IconWrapper, Object.assign({ "$selected": (icon === null || icon === void 0 ? void 0 : icon.iconId) === (LibraryIcon === null || LibraryIcon === void 0 ? void 0 : LibraryIcon.iconId) }, { children: _jsx(Icon, { src: icon.preview, onClick: () => handleIconClick(icon) }) })));
                        }) }))] }), _jsxs(StyledDialogActions, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handleIconSave }, { children: "Save" }))] })] }));
};
export default LinkIconModalBody;
