import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Stack } from "@fluentui/react/lib/Stack";
import { Button } from "@fluentui/react/lib/Button";
import { Text } from "@fluentui/react/lib/Text";
import { useBranding } from "../BrandingContext";
import { Dropdown, Field, Option } from "@fluentui/react-components";
const Pill = styled.span.withConfig({ displayName: "Pill", componentId: "-munay6" }) `
  background-color: #f3f2f1;
  padding: 2px 8px;
  border-radius: 15px; 
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  font-size: 14px;
`;
const StyledDropdown = styled(Dropdown).withConfig({ displayName: "StyledDropdown", componentId: "-11j03de" }) `
  .ms-Dropdown-title {
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    border-color: #cccccc;
  }
`;
const SiteCollectionSelect = (...props) => {
    const { selectedTheme, setSelectedTheme } = useBranding();
    const { siteCollections } = useBranding();
    const [selectedSites, setSelectedSites] = useState([]);
    useEffect(() => {
        if (selectedTheme) {
            const newSelectedSites = selectedTheme.siteCollectionUrls.map((site) => {
                const siteCollection = siteCollections.find((item) => item.text === site);
                return siteCollection === null || siteCollection === void 0 ? void 0 : siteCollection.key;
            });
            setSelectedSites(newSelectedSites);
        }
    }, []);
    useEffect(() => {
        const newSelectedSites = selectedSites.map((site) => {
            const siteCollection = siteCollections.find((item) => item.text === site);
            return siteCollection === null || siteCollection === void 0 ? void 0 : siteCollection.key;
        });
        setSelectedTheme(Object.assign(Object.assign({}, selectedTheme), { siteCollectionUrls: newSelectedSites }));
    }, [selectedSites]);
    const handleSelectionChange = (event, option) => {
        if (option) {
            if (selectedSites.find((site) => site === option.optionText)) {
                return;
            }
            const newSelectedSites = option.selectedOptions
                ? [...selectedSites, option.optionText]
                : selectedSites.filter((key) => key !== option.optionText);
            setSelectedSites(newSelectedSites);
        }
    };
    const removeSite = (siteToRemove) => {
        const newSelectedSites = selectedSites.filter((site) => site !== siteToRemove);
        setSelectedSites(newSelectedSites);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Field, { children: _jsx(StyledDropdown, Object.assign({ placeholder: "Select site collections", onOptionSelect: handleSelectionChange, multiselect: true, selectedOptions: selectedSites }, { children: siteCollections.map((option) => (_jsx(Option, { children: option.text }, option.key))) })) }), _jsx(Stack, Object.assign({ horizontal: true, wrap: true, tokens: { childrenGap: 8 }, styles: { root: { marginTop: 10 } } }, { children: selectedSites.map((siteKey) => {
                    return (_jsxs(Pill, { children: [_jsx(Text, { children: siteKey }), _jsx(Button, Object.assign({ title: "Remove", ariaLabel: "Remove site", onClick: () => removeSite(siteKey), styles: {
                                    root: {
                                        height: "auto",
                                        width: "auto",
                                        minWidth: "unset",
                                        color: "#605E5C",
                                        background: "transparent",
                                        border: "none",
                                        padding: 0,
                                    },
                                    label: {
                                        fontSize: 14,
                                        color: "#605E5C",
                                    },
                                } }, { children: "X" }))] }, siteKey));
                }) }))] }));
};
export default SiteCollectionSelect;
