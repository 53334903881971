import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { AccordionHeader, Button, Dialog, Menu, MenuTrigger, } from "@fluentui/react-components";
import { EditRegular, MoreHorizontalRegular } from "@fluentui/react-icons";
import EditPolicySourceModal from "./modals/EditPolicySourceModal";
const SyledAccordionHeader = styled(AccordionHeader).withConfig({ displayName: "SyledAccordionHeader", componentId: "-idj0s1" }) `
  background-color: ${(props) => props.$isDraggingOver ? "#ECE9F5" : "#faf9f8 !important"};

  & > button {
    border-radius: 4px;
  }
`;
const AccordionHeaderInnerContainer = styled.div.withConfig({ displayName: "AccordionHeaderInnerContainer", componentId: "-144a0s3" }) `
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const PolicySourceHeader = ({ policySource, onModalOpenChange, onSave }) => {
    const [showEditSourceModal, setShowEditSourceModal] = useState(false);
    const { name, items } = policySource;
    const handleSourceEditClick = (e) => {
        e.stopPropagation();
        onModalOpenChange(true);
        setShowEditSourceModal(true);
    };
    const handleEditSourceModalClose = () => {
        onModalOpenChange(false);
        setShowEditSourceModal(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(SyledAccordionHeader, Object.assign({ expandIconPosition: "end", "$isDraggingOver": false }, { children: _jsxs(AccordionHeaderInnerContainer, { children: [_jsxs("span", { children: [_jsx("b", { children: name }), " (", items.length, ")"] }), _jsx(Button, { as: "div", appearance: "transparent", icon: _jsx(EditRegular, {}), onClick: (e) => {
                                handleSourceEditClick(e);
                            } }), name !== "Uncategorized" && (_jsx(Menu, Object.assign({ positioning: "below-end" }, { children: _jsx(MenuTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, { as: "button", appearance: "transparent", icon: _jsx(MoreHorizontalRegular, {}), onClick: (e) => {
                                        e.stopPropagation();
                                    } }) })) })))] }) })), _jsx(Dialog, Object.assign({ open: showEditSourceModal, onOpenChange: handleEditSourceModalClose }, { children: _jsx(EditPolicySourceModal, { source: policySource, onClose: handleEditSourceModalClose, onSave: onSave }) }))] }));
};
export default PolicySourceHeader;
