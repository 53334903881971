import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useContext } from "react";
import { BrandingAPIService } from "../util/BrandingAPIService/BrandingAPIService";
const defaultBrandingValue = {
    themes: null,
    selectedTheme: null,
    setThemes: (() => { }),
    setSelectedTheme: (() => { }),
    refreshThemes: (() => { }),
    isThemesLoading: false,
    siteCollections: null,
    setSiteCollections: (() => { }),
};
const BrandingContext = createContext(defaultBrandingValue);
export const useBranding = () => useContext(BrandingContext);
export const BrandingProvider = ({ children }) => {
    const [themes, setThemes] = useState(defaultBrandingValue.themes);
    const [selectedTheme, setSelectedTheme] = useState(defaultBrandingValue.selectedTheme);
    const [isThemesLoading, setIsThemesLoading] = useState(false);
    const [siteCollections, setSiteCollections] = useState();
    const refreshThemes = () => {
        setIsThemesLoading(true);
        BrandingAPIService.getThemes()
            .then((data) => {
            if (data === null) {
                setThemes([]);
                return;
            }
            setThemes(data.themes);
            if (selectedTheme) {
                const updatedSelectedTheme = data.themes.find((theme) => theme.id === selectedTheme.id);
                if (updatedSelectedTheme) {
                    setSelectedTheme(updatedSelectedTheme);
                }
            }
            setIsThemesLoading(false);
        })
            .catch((error) => {
            console.error("Failed to fetch themes", error);
            setIsThemesLoading(false);
        });
    };
    React.useEffect(() => {
        console.log("selectedTheme", selectedTheme);
    }, [selectedTheme]);
    React.useEffect(() => {
        setIsThemesLoading(true);
        BrandingAPIService.getThemes()
            .then((data) => {
            if (data === null) {
                setIsThemesLoading(false);
                setThemes([]);
                return;
            }
            setThemes(data.themes);
            setIsThemesLoading(false);
        })
            .catch((error) => {
            console.error("Failed to fetch themes", error);
            setIsThemesLoading(false);
        });
    }, []);
    const value = {
        themes,
        selectedTheme,
        setThemes,
        setSelectedTheme,
        refreshThemes,
        isThemesLoading,
        siteCollections,
        setSiteCollections,
    };
    return (_jsx(BrandingContext.Provider, Object.assign({ value: value }, { children: children })));
};
