import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
export const ConfirmImport = (props) => {
    const storageValue = localStorage.getItem("doNotShowAgain") ? localStorage.getItem('doNotShowAgain').indexOf('true') !== -1 : false;
    const [doNotShowAgain, setDoNotShowAgain] = React.useState(storageValue);
    function doNotShowAgainHandler() {
        setDoNotShowAgain(!doNotShowAgain);
        localStorage.setItem("doNotShowAgain", (!doNotShowAgain).toString());
    }
    return (_jsx(Dialog, Object.assign({ open: props.showModal }, { children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "MegaMenu Configuration Import" }), _jsxs(DialogContent, { children: [_jsx("p", { children: "Importing a MegaMenu Configuration will overwrite the current one!" }), _jsx(Checkbox, { onClick: doNotShowAgainHandler, checked: doNotShowAgain, label: "Do not show this again." })] }), _jsxs(DialogActions, Object.assign({ style: { borderTop: 'none' } }, { children: [_jsx(Button, Object.assign({ onClick: props.confirm, appearance: "primary" }, { children: "OK" })), _jsx(Button, Object.assign({ onClick: props.close }, { children: "Cancel" }))] }))] }) }) })));
};
