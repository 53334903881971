import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { formSteps } from "../../../data/formSteps";
import styled from "styled-components";
import ArrowLeft from "/public/ArrowLeft.svg";
import ArrowRight from "/public/ArrowRight.svg";
import ArrowRightDisabled from "/public/ArrowRightDisabled.svg";
import { Button, Spinner, Toast, Toaster, ToastTitle, useId, useToastController, } from "@fluentui/react-components";
const NavigationWrapper = styled.div.withConfig({ displayName: "NavigationWrapper", componentId: "-c02tuy" }) `
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 24px;
`;
const NavigationButtons = styled.div.withConfig({ displayName: "NavigationButtons", componentId: "-c0g2ew" }) `
  display: flex;
  justify-content: space-between;
`;
const Icon = styled.img.withConfig({ displayName: "Icon", componentId: "-1tv5okw" }) `
  margin: 0 12px;
`;
const NavigationComponent = ({ currentStepName, onCreate, isSaving, hasLogo, }) => {
    const currentIndex = formSteps.findIndex((step) => step.name === currentStepName);
    const isFirstStep = currentIndex === 0;
    const isLastStep = currentIndex === formSteps.length - 1;
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const nextStepRoute = isLastStep ? null : formSteps[currentIndex + 1].route;
    const prevStepRoute = isFirstStep ? null : formSteps[currentIndex - 1].route;
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "error" });
    if (isSaving) {
        return (_jsx("div", Object.assign({ style: { marginTop: "100px" } }, { children: _jsx(Spinner, { size: "small", label: "Creating your theme..." }) })));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationWrapper, { children: _jsxs(NavigationButtons, { children: [prevStepRoute && (_jsx(Link, Object.assign({ to: prevStepRoute }, { children: _jsx(Button, Object.assign({ appearance: "transparent", icon: _jsx(Icon, { src: ArrowLeft, alt: "Back" }) }, { children: "Back" })) }))), !isLastStep ? (_jsx(Link, Object.assign({ to: nextStepRoute }, { children: _jsx(Button, Object.assign({ appearance: "transparent", icon: _jsx(Icon, { src: (currentStepName == "Logo" && hasLogo) ||
                                            currentStepName != "Logo"
                                            ? ArrowRight
                                            : ArrowRightDisabled, alt: "Next" }), iconPosition: "after", disabled: currentStepName == "Logo" && !hasLogo }, { children: "Next" })) }))) : (_jsx(Button, Object.assign({ appearance: "primary", onClick: onCreate }, { children: "Create" })))] }) }), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
    }
};
export default NavigationComponent;
