import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import UnderConstruction from "./UnderConstruction";
import RealmAdvise from "./ixp/components/RealmAdvise";
const Ixp = () => {
    const url = "https://ixp.livetiles.io/admincenter/?realm=" +
        sessionStorage.getItem("Realm");
    return (_jsx(_Fragment, { children: sessionStorage.getItem("Realm") ? (_jsx(_Fragment, { children: _jsx(UnderConstruction, { buttonLink: url, buttonText: "Go to Admin Center" }) })) : (_jsx(RealmAdvise, {})) }));
};
export default Ixp;
