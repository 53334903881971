import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import CardsList from "../../../components/CardsList";
import intranetNavigationData from "../../../data/intranetNavigation";
const NavigationContainer = styled.div.withConfig({ displayName: "NavigationContainer", componentId: "-1xtjxhi" }) `
  margin-inline-end: 3rem;
`;
const Navigation = () => {
    return (_jsxs(NavigationContainer, { children: [_jsx("h1", { children: "Intranet Navigation" }), _jsx(CardsList, { source: intranetNavigationData, path: "/intranet/navigation", layoutStyle: "inline" })] }));
};
export default Navigation;
