import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { AccordionHeader, Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Dialog, } from "@fluentui/react-components";
import { MoreHorizontalRegular } from "@fluentui/react-icons";
import DeleteCategoryModal from "./modals/DeleteCategoryModal";
import EditCategoryModal from "./modals/EditCategoryModal";
const StyledAccordionHeader = styled(AccordionHeader).withConfig({ displayName: "StyledAccordionHeader", componentId: "-1t0xya9" }) `
  background-color: ${(props) => props.$isDraggingOver ? "#ECE9F5" : "#faf9f8 !important"};

  & > button {
    border-radius: 4px;
  }
  padding-right: 4px;
`;
const AccordionHeaderInnerContainer = styled.div.withConfig({ displayName: "AccordionHeaderInnerContainer", componentId: "-1io6urj" }) `
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 4px;
  padding-right: 3px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #3D3A3B;
  height: 44px;
`;
const StyledSpan = styled.span.withConfig({ displayName: "StyledSpan", componentId: "-1m5arb0" }) `
font-weight: 600;
`;
const QuicklinkCategoryHeader = ({ category, isDraggingOver, uncategorizedLinks, onModalOpenChange, refresh, }) => {
    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
    const { name, quicklinks } = category;
    /* Category related handlers */
    const handleCategoryEditClick = (e) => {
        e.stopPropagation();
        onModalOpenChange(true);
        setShowEditCategoryModal(true);
    };
    const handleCategoryDeleteClick = (e) => {
        e.stopPropagation();
        onModalOpenChange(true);
        setShowDeleteCategoryModal(true);
    };
    const handleEditCategoryModalClose = () => {
        onModalOpenChange(false);
        setShowEditCategoryModal(false);
    };
    const handleDeleteCategoryModalClose = () => {
        onModalOpenChange(false);
        setShowDeleteCategoryModal(false);
    };
    return (_jsxs(StyledAccordionHeader, Object.assign({ expandIconPosition: "end", "$isDraggingOver": isDraggingOver }, { children: [_jsxs(AccordionHeaderInnerContainer, { children: [_jsxs("span", { children: [_jsx(StyledSpan, { children: name }), " (", quicklinks === null || quicklinks === void 0 ? void 0 : quicklinks.length, ")"] }), name !== "Uncategorized" && (_jsxs(Menu, Object.assign({ positioning: "below-end" }, { children: [_jsx(MenuTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, { appearance: "transparent", icon: _jsx(MoreHorizontalRegular, {}), onClick: (e) => {
                                        e.stopPropagation();
                                    } }) })), _jsx(MenuPopover, { children: _jsxs(MenuList, { children: [_jsx(MenuItem, Object.assign({ onClick: handleCategoryEditClick }, { children: "Edit category" })), _jsx(MenuItem, Object.assign({ onClick: handleCategoryDeleteClick }, { children: "Delete category" }))] }) })] })))] }), _jsx(Dialog, Object.assign({ open: showEditCategoryModal, onOpenChange: handleEditCategoryModalClose }, { children: _jsx(EditCategoryModal, { category: category, onClose: handleEditCategoryModalClose, uncategorizedLinks: uncategorizedLinks, refresh: refresh }) })), _jsx(Dialog, Object.assign({ open: showDeleteCategoryModal, onOpenChange: handleDeleteCategoryModalClose }, { children: _jsx(DeleteCategoryModal, { category: category, onClose: handleDeleteCategoryModalClose, refresh: refresh }) }))] })));
};
export default QuicklinkCategoryHeader;
