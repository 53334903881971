var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, } from "@fluentui/react-components";
import { QuicklinksService } from "../QuicklinksService";
import { Picker } from "../components/Picker/Picker";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-6xi9ee" }) `
  display: flex;
  flex-direction: column;
  color: #3d3a3b;
`;
const DialogSubtitle = styled.h4.withConfig({ displayName: "DialogSubtitle", componentId: "-18f45xm" }) `
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-uem1n" }) `
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-1tt0911" }) `
  padding-top: 24px;
`;
const PickerWrapper = styled.div.withConfig({ displayName: "PickerWrapper", componentId: "-d528ku" }) `
  margin-left: 3px;
`;
const ManageEditorsModal = ({ editors = [], onClose, }) => {
    const { GetInstance, SetInstance, GetUser } = QuicklinksService();
    const [editedEditors, setEditedEditors] = useState(null);
    const [instance, setInstance] = useState();
    useEffect(() => {
        const getInfo = () => __awaiter(void 0, void 0, void 0, function* () {
            yield GetInstance(true).then((instances) => __awaiter(void 0, void 0, void 0, function* () {
                setInstance(instances[0]);
                Promise.all(instances[0].editors.map((user) => GetUser(user))).then((users) => {
                    setEditedEditors(users);
                });
            }));
        });
        getInfo();
    }, []);
    const handleEditorDelete = (editor) => {
        const newEditedEditors = [...editedEditors];
        const editorIdx = editedEditors.findIndex((editedEditor) => editor.id === editedEditor.id);
        newEditedEditors.splice(editorIdx, 1);
        setEditedEditors(newEditedEditors);
    };
    const handleEditorsSave = () => {
        SetInstance(Object.assign(Object.assign({}, instance), { editors: editedEditors.map((editor) => editor.loginName) }));
        onClose();
    };
    return (_jsx(DialogSurface, { children: editedEditors != null && (_jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [_jsx("span", Object.assign({ style: { paddingBottom: "2px" } }, { children: "Manage editors" })), _jsx(DialogSubtitle, { children: "Editors are able to control the display of the Quick Links web part in SharePoint." })] }), _jsx(StyledDialogContent, { children: _jsx(PickerWrapper, { children: _jsx(Picker, { deleteUser: handleEditorDelete, selectedUsers: editedEditors, setSelectedUsers: setEditedEditors, identityType: 0 }) }) }), _jsxs(StyledDialogActions, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handleEditorsSave }, { children: "Save" }))] })] })) }));
};
export default ManageEditorsModal;
