var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import styled from "styled-components";
import BlogAPIService from "../pages/intranet/util/BlogAPIService/BlogAPISerivce";
import moment from "moment";
import { Avatar } from "@fluentui/react-components";
const BlogWrapper = styled.div.withConfig({ displayName: "BlogWrapper", componentId: "-1coqcvy" }) `
  max-width: 1280px;
`;
const Blog = styled.div.withConfig({ displayName: "Blog", componentId: "-alsnxn" }) `
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  line-height: 1.4rem;
`;
const BlogItem = styled.div.withConfig({ displayName: "BlogItem", componentId: "-haqrzh" }) `
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  overflow: hidden;
  background: white;
  cursor: pointer;

  box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
`;
const BannerImage = styled.img.withConfig({ displayName: "BannerImage", componentId: "-17sze04" }) `
  height: 126px;
  width: 100%;
  flex-shrink: 0;
  object-fit: cover;
`;
const Article = styled.div.withConfig({ displayName: "Article", componentId: "-1c50d7m" }) `
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
`;
const Title = styled.h3.withConfig({ displayName: "Title", componentId: "-1r02rmo" }) `
  color: #0f0e0e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Segoe UI";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 10px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
const Description = styled.p.withConfig({ displayName: "Description", componentId: "-ffdzpy" }) `
  color: var(--Dirty-Gray-800, #5c5653);
  font-family: "Segoe UI";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 0px;
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;
const AuthorDetails = styled.div.withConfig({ displayName: "AuthorDetails", componentId: "-n0wye1" }) `
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #3e3938;
  -webkit-line-clamp: 1;
`;
const AuthorName = styled.div.withConfig({ displayName: "AuthorName", componentId: "-o1yq07" }) `
  color: var(--Dirty-Gray-950, #0f0e0e);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Segoe UI";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const CreatedDate = styled.div.withConfig({ displayName: "CreatedDate", componentId: "-6bo2qd" }) `
  color: var(--Dirty-Gray-700, #aea5a2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Segoe UI";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Details = styled.div.withConfig({ displayName: "Details", componentId: "-1x11t5n" }) `
  display: flex;
  align-items: left;
  font-size: 12px;
  color: #3e3938;
  -webkit-line-clamp: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;
const ReadMore = styled.div.withConfig({ displayName: "ReadMore", componentId: "-1rp0dk5" }) `
  display: flex;
  justify-content: flex-end;
  padding-block: 5px;
  position: relative;
  z-index: 1;

  a {
    text-decoration: none;
    font-weight: 600;
  }

  color: var(--ThemePrimary, #512da8);
  font-family: "Segoe UI";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
`;
const openLink = (link) => {
    window.open(link, "_blank");
};
const BlogList = (props) => {
    const [BlogPostItems, setBlogPostItems] = React.useState([]);
    useEffect(() => {
        const fetchBlogPosts = () => __awaiter(void 0, void 0, void 0, function* () {
            let response = yield BlogAPIService.getLatestPostsData();
            if (response) {
                setBlogPostItems(response.sort((a, b) => b.date.localeCompare(a.date)));
            }
        });
        fetchBlogPosts();
    }, []);
    return (_jsx(BlogWrapper, { children: BlogPostItems.length > 0 && (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Explore the latest on the LiveTiles Blog" }), _jsx(Blog, { children: BlogPostItems.map((blogItem, i) => {
                        return (_jsxs(BlogItem, Object.assign({ onClick: () => openLink(blogItem.link) }, { children: [_jsx(BannerImage, { src: blogItem.bannerImage, alt: blogItem.title }), _jsxs(Article, { children: [_jsxs(AuthorDetails, { children: [_jsx(Avatar, { name: blogItem.author, image: {
                                                        src: blogItem.avatar,
                                                    } }), _jsxs(Details, { children: [_jsx(AuthorName, { children: blogItem.author }), _jsx(CreatedDate, { children: moment(blogItem.date).format("MMMM DD") +
                                                                " at " +
                                                                moment(blogItem.date).format("h:mm a") })] })] }), _jsx(Title, Object.assign({ title: blogItem.title }, { children: blogItem.title })), _jsx(Description, Object.assign({ title: blogItem.description }, { children: blogItem.description }))] })] }), i));
                    }) }), _jsx(ReadMore, { children: _jsx("a", Object.assign({ href: "https://livetilesglobal.com/insights/blog/", target: "_blank", className: "lt-style lt-purple" }, { children: "Read more" })) })] })) }));
};
export default BlogList;
