// create interface for intranetWebpartsData
import * as FluentIcons from "@fluentui/react-icons";
const intranetWebpartsData = [
    {
        title: "Private Links",
        description: "Allows you to create private links and manage them from your site. All users will be able to add important pages, documents, and other resources to them.",
        downloadUrl: "https://files.intranet.livetiles.io/privatelinks/Apps/LiveTiles.PrivateLinks.sppkg",
        icon: FluentIcons.LinkSettings24Regular,
        route: "intranet/webparts/privatelinks",
        docsPage: "intranet/webparts/privatelinks",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-privatelinks.png",
    },
    {
        title: "Quick Links",
        description: "A powerful web part that allows you to create and manage links to important pages, documents, and other resources on your site.",
        downloadUrl: "https://files.intranet.livetiles.io/quicklinks/Apps/LiveTiles.QuickLinks.sppkg",
        icon: FluentIcons.LinkSettings24Regular,
        route: "intranet/webparts/quicklinks",
        docsPage: "intranet/webparts/quicklinks",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-quicklinks.png",
    },
    {
        title: "Branding",
        description: "Tailor your site's look effortlessly, adjusting navigation style, colors, fonts, text alignment, and logos to create a unique and engaging user experience.",
        downloadUrl: "https://files.intranet.livetiles.io/branding/Apps/LiveTiles.Branding.sppkg",
        icon: FluentIcons.Color24Regular,
        route: "intranet/webparts/branding",
        docsPage: "intranet/branding/branding",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-branding.png",
    },
    {
        title: "Everywhere Panel",
        description: "Transform your intranet experience with Everywhere Panel — a dynamic sidebar that follows you throughout the intranet, keeping essential widgets at your fingertips.",
        downloadUrl: "https://install.matchpoint365.com/resources/LiveTiles.Everywhere.Panel.sppkg",
        icon: FluentIcons.PanelLeftText24Regular,
        route: "intranet/webparts/everywherepanel",
        docsPage: "intranet/webparts/everywherepanel",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-everywhere-panel.png",
    },
    {
        title: "Reach News",
        description: "Publish visually appealing articles to all employees, tailored to their preferences. Target news based on location, department, seniority, or other categories for increased relevance and engagement.",
        downloadUrl: "https://files.intranet.livetiles.io/reach-news/Apps/LiveTiles.Reach.News.sppkg",
        icon: FluentIcons.News24Regular,
        route: "intranet/webparts/reachnews",
        docsPage: "intranet/webparts/reachnewswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-reach-news.png",
    },
    {
        title: "Reach Events",
        description: "Whether you’re looking to host onboarding events, corporate training or town halls, having your events promoted, run and managed centrally makes it easy for you and your workforce.",
        downloadUrl: "https://files.intranet.livetiles.io/reach-events/Apps/LiveTiles.Reach.Events.sppkg",
        icon: FluentIcons.CalendarRtl24Regular,
        route: "intranet/webparts/reachevents",
        docsPage: "intranet/webparts/reacheventswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-reach-events.png",
    },
    {
        title: "Reach Posts",
        description: "Our intuitive rich-media editor enables knowledge sharing via links, pictures and videos to specific channels and teams or with the whole organization.",
        downloadUrl: "https://files.intranet.livetiles.io/reach-posts/Apps/LiveTiles.Reach.Posts.sppkg",
        icon: FluentIcons.NoteEdit24Regular,
        route: "intranet/webparts/reachposts",
        docsPage: "intranet/webparts/reachpostswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-reach-posts.png",
    },
    {
        title: "Reach Pages",
        description: "Reach pages provide a structured and intuitive way to share knowledge with staff who are always on the go.",
        downloadUrl: "https://files.intranet.livetiles.io/reach-pages/Apps/LiveTiles.Reach.Pages.sppkg",
        icon: FluentIcons.DocumentCopy24Regular,
        route: "intranet/webparts/reachpages",
        docsPage: "intranet/webparts/reachpageswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-reach-pages.png",
    },
    {
        title: "Tour Guide",
        description: "The Tour Guide webpart is a powerful tool that enables you to create a captivating guided tour of the current page.",
        downloadUrl: "https://files.intranet.livetiles.io/tour-guide/Apps/LiveTiles.Tour.Guide.sppkg",
        icon: FluentIcons.TicketDiagonal24Regular,
        route: "intranet/webparts/tourguide",
        docsPage: "intranet/webparts/tourguidewebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-tour-guide.png",
    },
    {
        title: "Lists",
        description: "Optimize your SharePoint lists with LiveTiles. Choose layouts, set call-to-action links, and enable search and sort features for maximum usability.",
        downloadUrl: "https://files.intranet.livetiles.io/lists/Apps/LiveTiles.Lists.sppkg",
        icon: FluentIcons.DocumentBulletList24Regular,
        route: "intranet/webparts/lists",
        docsPage: "intranet/webparts/listswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-lists.png",
    },
    {
        title: "Canteen Menu",
        description: "Never wonder what's on the menu again! The Canteen Menu Web Part simplifies meal planning for your team. Easily access and display daily or weekly canteen menus within your SharePoint site.",
        downloadUrl: "https://files.intranet.livetiles.io/canteen-menu/Apps/LiveTiles.Canteen.Menu.sppkg",
        icon: FluentIcons.Food24Regular,
        route: "intranet/webparts/canteen",
        docsPage: "intranet/webparts/canteenwebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-canteen-menu.png",
    },
    {
        title: "RSS Reader",
        description: "Stay informed with the RSS Web Part! Effortlessly display and stay up-to-date with your favorite news, blog posts, or announcements right in your SharePoint site.",
        downloadUrl: "https://files.intranet.livetiles.io/rssfeed/Apps/LiveTiles.Rssfeed.sppkg",
        icon: FluentIcons.Rss24Regular,
        route: "intranet/webparts/rss",
        docsPage: "intranet/webparts/rsswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-rss.png",
    },
    {
        title: "Celebrations",
        description: "Make every celebration count with LiveTiles. Choose from six layouts, apply custom filters, and set up targeted alerts for a seamless experience",
        downloadUrl: "https://files.intranet.livetiles.io/celebrations/Apps/LiveTiles.Celebrations.sppkg",
        icon: FluentIcons.Teddy24Regular,
        route: "intranet/webparts/celebrations",
        docsPage: "intranet/webparts/celebrationswebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-celebrations.png",
    },
    {
        title: "FAQs",
        description: "Easily manage your FAQs with LiveTiles. Where ease meets customization. Manage lists, optimize performance, and control layout settings effortlessly.",
        downloadUrl: "https://files.intranet.livetiles.io/faqs/Apps/LiveTiles.FAQs.sppkg",
        icon: FluentIcons.TextBulletListSquareEdit24Regular,
        route: "intranet/webparts/faq",
        docsPage: "intranet/webparts/faqwebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-faqs.png",
    },
    {
        title: "People",
        description: "Connect with your team effortlessly using LiveTiles People. Search, filter, and display user profiles in SharePoint with customizable templates.",
        downloadUrl: "https://files.intranet.livetiles.io/people/Apps/LiveTiles.People.sppkg",
        icon: FluentIcons.PeopleList24Regular,
        route: "intranet/webparts/people",
        docsPage: "intranet/webparts/peoplewebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-people.png",
    },
    {
        title: "Alerts",
        description: "Never miss an update with LiveTiles Alerts! Easily create, manage, and customize alert messages in SharePoint to keep your team in the loop",
        downloadUrl: "https://files.intranet.livetiles.io/alerts/Apps/LiveTiles.Alerts.sppkg",
        icon: FluentIcons.ClockAlarm24Regular,
        route: "intranet/webparts/alerts",
        docsPage: "intranet/webparts/alerts-webpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-alerts.png",
    },
    {
        title: "LiveTiles Connect",
        description: "View and accept Policies or access your Quicklinks from LiveTiles Connect. Configure the LiveTiles Connect web part depending on your needs.",
        downloadUrl: "https://ixppubpweusa01.azureedge.net/livetiles-connect/app/LiveTiles.Connect.sppkg",
        icon: FluentIcons.PlugDisconnectedRegular,
        route: "intranet/webparts/LivetilesConnect",
        docsPage: "intranet/webparts/livetilesconnectwebpart",
        previewScreenshot: "/images/public/assets/preview-screenshots/webpart-livetiles-connect.png",
    },
    {
        title: "Everywhere widget",
        description: "The Everywhere Widget is a powerful web part that provides dynamic content to users. It includes default tabs for notifications and an AI-powered assistant.",
        downloadUrl: "https://files.intranet.livetiles.io/everywhere-widget/Apps/LiveTiles.EverywhereWidget.sppkg",
        icon: FluentIcons.BrainCircuitRegular,
        route: "intranet/webparts/everywherewidget",
        docsPage: "intranet/webparts/everywherewidget",
        previewScreenshot: "/images/public/assets/everywherewidget/webpart-everywhere-widget.png",
    },
    {
        title: "Policies",
        description: "The policies webpart allows users to view all company policies, identify which ones are mandatory, and see due dates for confirmation. Users can easily confirm their compliance with mandatory policies directly within the policies webpart, ensuring they stay up to date with company requirements.",
        downloadUrl: "https://files.intranet.livetiles.io/policies/Apps/LiveTiles.Policies.sppkg",
        icon: FluentIcons.BrainCircuitRegular,
        route: "intranet/webparts/policies",
        docsPage: "intranet/webparts/policies",
        previewScreenshot: "/images/public/assets/policies/webpart-policies.png",
    },
    {
        title: "Weather",
        description: "Get real-time weather updates, 5-day forecasts on your intranet. Stay informed and plan your day with ease—perfect for quick weather checks without leaving your workspace.",
        downloadUrl: "https://files.intranet.livetiles.io/weather/Apps/LiveTiles.Weather.sppkg",
        icon: FluentIcons.WeatherPartlyCloudyDay24Regular,
        route: "intranet/webparts/weather",
        docsPage: "intranet/webparts/weather",
        previewScreenshot: "/images/public/assets/weather/weather-webpart.png",
    },
];
export default intranetWebpartsData;
