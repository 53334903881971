export const handleDrag = (result, items, selectedItemsMap, setItems, setSelectedItemsMap) => {
    // draggable dropped in invalid place
    if (!result.destination) {
        return;
    }
    const srcItemIdx = result.source.index;
    const srcCategoryIdx = items.findIndex((item) => item.id === result.source.droppableId);
    const itemType = "quicklinks" in items[srcCategoryIdx] ? "quicklinks" : "policies";
    const srcItem = items[srcCategoryIdx][itemType][srcItemIdx];
    const selectedItemsNr = Object.values(selectedItemsMap).reduce((acc, selectedItems) => acc + selectedItems.length, 0);
    const isDraggedItemSelected = Object.values(selectedItemsMap).some((selectedItems) => selectedItems.find((item) => item.id === srcItem.id));
    selectedItemsNr > 0 && isDraggedItemSelected
        ? handleMultiDrag(result, items, selectedItemsMap, setItems, setSelectedItemsMap)
        : handleSingleDrag(result, items, setItems);
};
const handleSingleDrag = (result, items, setItems) => {
    const srcCategoryId = result.source.droppableId;
    const destCategoryId = result.destination.droppableId;
    const srcItemIdx = result.source.index;
    const destItemIdx = result.destination.index;
    // draggable didn't change position
    if (srcCategoryId === destCategoryId && srcItemIdx === destItemIdx) {
        return;
    }
    const srcCategoryIdx = items.findIndex((category) => category.id === srcCategoryId);
    const destCategoryIdx = items.findIndex((category) => category.id === destCategoryId);
    const itemType = "quicklinks" in items[srcCategoryIdx] ? "quicklinks" : "policies";
    const editedItems = [...items[destCategoryIdx][itemType]];
    // remove item from old position within same category
    if (srcCategoryId === destCategoryId) {
        editedItems.splice(srcItemIdx, 1);
    }
    // place item in new position
    editedItems.splice(destItemIdx, 0, items[srcCategoryIdx][itemType][srcItemIdx]);
    const editedCategory = Object.assign(Object.assign({}, items[destCategoryIdx]), { [itemType]: editedItems });
    const editedCategories = [...items];
    editedCategories.splice(destCategoryIdx, 1, editedCategory);
    // remove item from old position within different category
    if (srcCategoryId !== destCategoryId) {
        editedCategories[srcCategoryIdx][itemType].splice(srcItemIdx, 1);
    }
    setItems(editedCategories);
};
const handleMultiDrag = (result, items, selectedItemsMap, setItems, setSelectedItemsMap) => {
    var _a;
    const destCategoryId = result.destination.droppableId;
    const destItemIdx = result.destination.index;
    const destCategoryIdx = items.findIndex((category) => category.id === destCategoryId);
    const itemType = "quicklinks" in items[destCategoryIdx] ? "quicklinks" : "policies";
    const draggedItems = Object.values(selectedItemsMap).reduce((acc, selectedItems) => [...acc, ...selectedItems], []);
    let editedItems = [...items[destCategoryIdx][itemType]];
    // remove selected items from old positions in destination category
    if ((_a = selectedItemsMap[destCategoryId]) === null || _a === void 0 ? void 0 : _a.length) {
        editedItems = editedItems.filter((item) => selectedItemsMap[destCategoryId].every((selectedItem) => selectedItem.id !== item.id));
    }
    // place items in new position
    editedItems.splice(destItemIdx, 0, ...draggedItems);
    const editedCategory = Object.assign(Object.assign({}, items[destCategoryIdx]), { [itemType]: editedItems });
    const editedCategories = [...items];
    editedCategories.splice(destCategoryIdx, 1, editedCategory);
    // remove selected items from source items that aren't the destination category
    Object.entries(selectedItemsMap).forEach(([categoryId, selectedItems]) => {
        if (categoryId !== destCategoryId) {
            const category = editedCategories.find((cat) => cat.id === categoryId);
            category[itemType] = category[itemType].filter((item) => selectedItems.every((selectedItem) => selectedItem.id !== item.id));
        }
    });
    setItems(editedCategories);
    setSelectedItemsMap({ [destCategoryId]: [...draggedItems] });
};
