var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogContent, Label, Textarea, Tooltip, } from "@fluentui/react-components";
import { Info16Regular } from "@fluentui/react-icons";
import { PoliciesService } from "../PoliciesService";
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-rbdtik" }) `
  display: flex;
  flex-direction: column;
`;
const ReminderInfo = styled.span.withConfig({ displayName: "ReminderInfo", componentId: "-cn882d" }) `
  color: #707070;
  display: inline-block;
  margin-bottom: 24px;
`;
const StyledSpan = styled.span.withConfig({ displayName: "StyledSpan", componentId: "-ttc9oo" }) `
  color: #707070;
  display: inline-block;
  margin-top: 24px;
`;
const TextareaLabel = styled(Label).withConfig({ displayName: "TextareaLabel", componentId: "-q4vb3u" }) `
  align-items: center;
  display: flex;
  gap: 4px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-17z7mdi" }) `
  padding-top: 24px;
`;
const SendReminderModalBody = ({ policySource, policyItem, onClose, notifyMessage, notifyError, }) => {
    const { RemindUnconfirmedUsers } = PoliciesService();
    const [reminder, setReminder] = useState("You have assigned an unconfirmed policy to read. Please read it carefully and mark it as read.");
    const handleReminderSend = () => __awaiter(void 0, void 0, void 0, function* () {
        onClose();
        notifyMessage("Reminder is being sent.");
        const reminderResult = yield RemindUnconfirmedUsers(policySource === null || policySource === void 0 ? void 0 : policySource.id, policyItem === null || policyItem === void 0 ? void 0 : policyItem.id, reminder);
        if (reminderResult.ok === false) {
            notifyError("Failed to send reminder.");
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(StyledDialogContent, { children: [_jsx(ReminderInfo, { children: "Send reminder policy messages to users by email." }), _jsxs(TextareaLabel, Object.assign({ htmlFor: "policy-reminder-text" }, { children: [_jsx("span", { children: "Email message" }), _jsx(Tooltip, Object.assign({ appearance: "inverted", content: "The reminder will be sent by email to users who still haven't marked the policy as read.", positioning: "after-bottom", relationship: "description", withArrow: true }, { children: _jsx(Info16Regular, {}) }))] })), _jsx(Textarea, { id: "policy-reminder-text", resize: "vertical", value: reminder, onChange: (e) => setReminder(e.target.value) }), _jsxs(StyledSpan, { children: ["This message will be sent by email to", " ", _jsxs("b", { children: [policyItem.unconfirmedUsers, " users."] })] })] }), _jsxs(StyledDialogActions, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handleReminderSend }, { children: "Send" }))] })] }));
};
export default SendReminderModalBody;
