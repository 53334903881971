var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import envVarConfig from "../../../env/envVarConfig";
import { instance } from "../../..";
import { ClientRegistrationAPIService } from "../../intranet/util/ClientRegistrationAPI/ClientRegistrationAPI";
export const AudiencesService = () => {
    const apiPathAudience = envVarConfig.reactApiUrl + "/Audiences";
    const apiPathGraph = envVarConfig.reactApiUrl + "/Graph";
    const getToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const accounts = instance.getAllAccounts();
        const token = yield instance.acquireTokenSilent({
            account: accounts[0],
            scopes: [envVarConfig.backOfficeAppScope],
        });
        return token.accessToken;
    });
    const tenantId = JSON.parse(sessionStorage.getItem("User")).tenantId;
    const getRegion = () => __awaiter(void 0, void 0, void 0, function* () {
        const userRegion = sessionStorage.getItem("Region");
        if (!userRegion) {
            const checkRegionResponse = yield ClientRegistrationAPIService.getClientRegistration(tenantId);
            sessionStorage.setItem("Region", checkRegionResponse.region);
            return sessionStorage.getItem("Region");
        }
        return userRegion;
    });
    const audiencesEndpoints = {
        GetAudiences: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const userRegion = yield getRegion();
                const url = apiPathAudience + `/${tenantId}`;
                const userToken = yield getToken();
                const headers = {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                    "X-Region": userRegion,
                };
                const response = yield fetch(url, { method: "GET", headers });
                if (!response.ok || response.status == 204) {
                    return null;
                }
                const audiences = yield response.json();
                return audiences;
            }
            catch (error) {
                console.error("Error fetching audiences data:", error);
                return null;
            }
        }),
        CreateUpdateAudience: (audience) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const userRegion = yield getRegion();
                const url = apiPathAudience;
                const userToken = yield getToken();
                const options = {
                    body: JSON.stringify(audience),
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "X-Region": userRegion,
                    },
                };
                const response = yield fetch(url, options);
                if (!response.ok) {
                    return null;
                }
                const audiences = yield response.json();
                return audiences;
            }
            catch (error) {
                console.error("Error fetching audiences data:", error);
                return null;
            }
        }),
    };
    const rulesEndpoints = {
        CreateUpdateAudienceRule: (audience) => __awaiter(void 0, void 0, void 0, function* () {
            const userRegion = yield getRegion();
            const url = apiPathAudience + `/${tenantId}?force=true`;
            const userToken = yield getToken();
            const options = {
                body: JSON.stringify(audience),
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                    "X-Region": userRegion,
                },
            };
            const response = yield fetch(url, options);
            if (!response.ok) {
                return null;
            }
            const audiences = yield response.json();
            return audiences;
        }),
        DeleteAudienceRules: (audienceId) => __awaiter(void 0, void 0, void 0, function* () {
            const userRegion = yield getRegion();
            const url = apiPathAudience + `/${tenantId}/${audienceId}`;
            const userToken = yield getToken();
            const headers = {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json",
                "X-Region": userRegion,
            };
            const response = yield fetch(url, { method: "DELETE", headers });
            if (!response.ok) {
                return null;
            }
            const audiences = yield response.json();
            return audiences;
        }),
    };
    const EntityEndpoints = {
        getFilteredEntities: (searchText, type = undefined) => __awaiter(void 0, void 0, void 0, function* () {
            const userToken = yield getToken();
            try {
                if (type !== undefined) {
                    const response = yield fetch(apiPathGraph + "/entities/" + searchText + "/" + type, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                    });
                    return response.json();
                }
                else {
                    const response = yield fetch(apiPathGraph + "/entities/" + searchText, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                    });
                    return response.json();
                }
            }
            catch (error) {
                console.log("Error retrieving users:", error);
                throw error;
            }
        }),
        getEntity: (id, type) => __awaiter(void 0, void 0, void 0, function* () {
            const userToken = yield getToken();
            try {
                const response = yield fetch(apiPathGraph + "/entity/" + id + "/" + type, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                if (response) {
                    return response.json();
                }
            }
            catch (error) {
                console.log("Error retrieving entity:", error);
                throw error;
            }
        }),
    };
    return Object.assign(Object.assign(Object.assign({}, audiencesEndpoints), EntityEndpoints), rulesEndpoints);
};
