import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { ContentEditor } from "./ContentEditor";
export const MatrixMenuWebpartSection = (props) => {
    const [dimensions, setDimensions] = React.useState(props.matrixMenu.dimensions);
    const [paths, setPaths] = React.useState(props.matrixMenu.paths);
    const [editPath, setEditPath] = React.useState([]);
    React.useEffect(() => {
        setDimensions(props.matrixMenu.dimensions);
    }, [props.matrixMenu]);
    function updateDimensions(dimensions) {
        setDimensions([...dimensions]);
        let newMenu = props.matrixMenu;
        newMenu.dimensions = dimensions;
        props.updateMenu(newMenu);
    }
    function updatePaths(paths) {
        setPaths(paths);
        let newMenu = props.matrixMenu;
        newMenu.paths = paths;
        props.updateMenu(newMenu);
    }
    function setNewPath(itemId, dimensionId) {
        let path = [...editPath];
        path.length = dimensionId;
        path[dimensionId - 1] = itemId;
        setEditPath(path);
    }
    function updateEditPath(url) {
        let newMenu = props.matrixMenu;
        const newPath = {
            url: url,
            selection: editPath,
        };
        const foundPath = newMenu.paths.findIndex((path) => path.selection.toString() === newPath.selection.toString());
        if (foundPath > -1) {
            newMenu.paths[foundPath] = newPath;
            setPaths([...newMenu.paths]);
            props.updateMenu(newMenu);
        }
        else {
            newMenu.paths.push(newPath);
            setPaths([...newMenu.paths]);
            props.updateMenu(newMenu);
        }
    }
    function deletePath(editPath) {
        let newMenu = props.matrixMenu;
        const foundPath = newMenu.paths.findIndex((path) => path.selection.toString() === editPath.toString());
        if (foundPath > -1) {
            newMenu.paths.splice(foundPath, 1);
            setPaths([...newMenu.paths]);
            props.updateMenu(newMenu);
        }
    }
    function deleteEditPath() {
        let newMenu = props.matrixMenu;
        const foundPath = newMenu.paths.findIndex((path) => path.selection.toString() === editPath.toString());
        if (foundPath > -1) {
            newMenu.paths.splice(foundPath, 1);
            setPaths(newMenu.paths);
            props.updateMenu(newMenu);
        }
    }
    return (_jsxs(_Fragment, { children: [props.view === 3 ? (_jsx(ContentEditor, { dimensions: dimensions, paths: paths, updateDimensions: updateDimensions, updatePaths: updatePaths, editPath: editPath, view: "content" })) : null, props.view === 4 ? (_jsx(ContentEditor, { dimensions: dimensions, paths: paths, updateDimensions: updateDimensions, updateEditPath: updateEditPath, deleteEditPath: deleteEditPath, deletePath: deletePath, setNewPath: setNewPath, editPath: editPath, view: "pathways" })) : null] }));
};
