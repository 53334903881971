var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner, } from "@fluentui/react-components";
import { AudiencesService } from "../AudiencesService";
import { styled } from "styled-components";
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-120nk29" }) `
  padding-top: 24px;
`;
const DeleteAudienceModal = ({ audience, onClose, onSave, }) => {
    const { DeleteAudienceRules } = AudiencesService();
    const [isSaving, setIsSaving] = React.useState(false);
    const handleAudienceDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsSaving(true);
        try {
            const updated = yield DeleteAudienceRules(audience === null || audience === void 0 ? void 0 : audience.audienceId);
            yield onSave(updated);
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
        setIsSaving(false);
        onClose();
    });
    return audience ? (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Delete audience" }), _jsxs(DialogContent, { children: ["Are you sure you want to delete the selected audience", " ", _jsx("b", { children: audience.audienceName }), ".", _jsx("br", {}), _jsx("br", {}), "Warning: the visibility of any items targeted by this audience may be affected."] }), _jsx(StyledDialogActions, { children: isSaving ? (_jsx(Spinner, { size: "small", label: "Saving..." })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "primary", onClick: handleAudienceDelete }, { children: "Delete" })), _jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" }))] })) })] }) })) : null;
};
export default DeleteAudienceModal;
