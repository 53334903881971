var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import ReactMarkdown from "react-markdown";
import customToc from "./plugins/toc.js";
import styled from "styled-components";
import rehypeHighlight from "rehype-highlight";
import "./../lt-style/lt-style.css";
import { ArrowDownload24Regular } from "@fluentui/react-icons";
import remarkGfm from "remark-gfm";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-2opgr2" }) `
  margin-inline-end: 3rem;

  img {
    display: block;
    max-width: 100%;
    margin-block: 1rem;
  }

  tr {
    border-top: 1px solid #c6cbd1;
    background: #fff;
  }

  th,
  td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  table tr th {
    background: #f6f8fa;
  }

  .content {
    max-width: calc(100% - 360px);
    width: 100%;
    font-size: 14px;
    line-height: 22px;

    code {
      background-color: rgba(0, 0, 0, 0.05);
    }

    a {
      color: var(--color-primary);
      font-weight: 400;
      text-decoration: none;

      &:hover {
        color: #3d3a3b;
      }
    }

    p {
      margin-block: 0 1rem;
    }

    h2 {
      margin-top: 1rem;
      font-size: 20px;
      font-weight: 600;
    }

    h3 {
      font-weight: 600;
      margin-block: 1rem 8px;
    }

    h4 {
      margin-block: 1rem 8px;
    }
  }

  .toc {
    position: sticky;
    top: 43px;
    flex: 0 0 360px;
    order: 1;
    font-size: 16px;
    line-height: 24px;
    padding-inline-start: 2rem;
    max-height: calc(100vh - 158px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.125);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.25);
    }

    a {
      text-decoration: none;
      color: #3d3a3b;
      font-weight: 600;
    }

    p {
      margin-block: 0;
    }

    ul {
      list-style: none;
      padding-inline-start: 0;

      ul {
        a {
          color: var(--color-primary);
          font-weight: 400;

          &:hover {
            color: #3d3a3b;
          }
        }
      }
    }

    > ul {
      position: relative;
      padding-inline-start: 1.5rem;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        background: #f2f2f2;
        border-radius: 2px;
      }

      > li {
        margin-bottom: 0.5rem;
      }
    }
  }

  code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1rem;
    font-size: 0.85rem;
    line-height: 1.45;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
`;
const Document = styled.div.withConfig({ displayName: "Document", componentId: "-1skp4mo" }) `
  display: flex;
  align-items: flex-start;
`;
const Download = styled.div.withConfig({ displayName: "Download", componentId: "-143cyox" }) `
  display: flex;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
  max-width: 400px;
  margin-block: 2rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }

  svg {
    width: 4rem;
    height: 3rem;
  }
`;
const DownloadTitle = styled.div.withConfig({ displayName: "DownloadTitle", componentId: "-epe8lt" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;

  h3 {
    font-weight: 600;
    color: var(--color-primary);

    .content & {
      margin-block: 0;
    }
  }

  h4 {
    padding: 0;
    font-weight: 400;
    opacity: 0.8;

    .content & {
      margin-block: 0;
    }
  }
`;
export const DocsTemplate = (props) => {
    const [content, setContent] = React.useState("");
    React.useEffect(() => {
        fetch(`/source/${props.docsPage}.md`)
            .then((response) => {
            if (response.ok)
                return response.text();
            else
                return Promise.reject("Didn't fetch text correctly");
        })
            .then((text) => {
            setContent(text);
        })
            .catch((error) => console.error(error));
    });
    const generateSlug = (string) => {
        let str = string.replace(/^\s+|\s+$/g, "");
        str = str.toLowerCase();
        str = str
            .replace(/[^a-z0-9 -]/g, "")
            .replace(/\s+/g, "-")
            .replace(/-+/g, "-");
        return str;
    };
    const getTitle = () => {
        return props.title;
    };
    const getFileName = () => {
        var _a;
        return (_a = props.downloadUrl) === null || _a === void 0 ? void 0 : _a.substring(props.downloadUrl.lastIndexOf("/") + 1);
    };
    const openDownload = (d) => {
        return d === undefined
            ? window.open(props.downloadUrl, "_self")
            : window.open(d, "_self");
    };
    const getnDownloadLinks = () => {
        return props.downloadUrls;
    };
    const isRegionApac = () => {
        return sessionStorage.getItem("Region") === "APAC" ? true : false;
    };
    return (_jsxs(Container, { children: [_jsx("h1", { children: props.title }), _jsx(Document, { children: _jsx(ReactMarkdown, { children: content, remarkPlugins: [customToc], rehypePlugins: [rehypeHighlight, remarkGfm], components: {
                        h1: (_a) => {
                            var { node } = _a, props = __rest(_a, ["node"]);
                            return (_jsx("h1", Object.assign({ id: generateSlug(props.children[0]) }, props)));
                        },
                        h2: (_a) => {
                            var { node } = _a, props = __rest(_a, ["node"]);
                            return (_jsx("h2", Object.assign({ id: generateSlug(props.children[0]) }, props)));
                        },
                        h3: (_a) => {
                            var { node } = _a, props = __rest(_a, ["node"]);
                            return (_jsx("h3", Object.assign({ id: generateSlug(props.children[0]) }, props)));
                        },
                        h4: (_a) => {
                            var _b;
                            var { node } = _a, props = __rest(_a, ["node"]);
                            let value = (_b = props.children[0]) === null || _b === void 0 ? void 0 : _b.toString().toLowerCase();
                            return value === "download" ? (_jsxs(Download, Object.assign({ onClick: () => openDownload(undefined) }, { children: [_jsx(ArrowDownload24Regular, { primaryFill: "var(--color-primary)" }), _jsxs(DownloadTitle, { children: [_jsxs("h3", { children: ["Download ", getTitle()] }), _jsx("h4", { children: getFileName() })] })] }))) : value === "downloadbyregion" ? (_jsx(_Fragment, { children: getnDownloadLinks().map((d) => {
                                    if (isRegionApac() && d.includes("APAC")) {
                                        return (_jsxs(Download, Object.assign({ onClick: () => openDownload(d) }, { children: [_jsx(ArrowDownload24Regular, { primaryFill: "var(--color-primary)" }), _jsxs(DownloadTitle, { children: [_jsxs("h3", { children: ["Download ", getTitle()] }), _jsx("h4", { children: d.substring(d.lastIndexOf("/") + 1) })] })] })));
                                    }
                                    else if (!isRegionApac() && !d.includes("APAC")) {
                                        return (_jsxs(Download, Object.assign({ onClick: () => openDownload(d) }, { children: [_jsx(ArrowDownload24Regular, { primaryFill: "var(--color-primary)" }), _jsxs(DownloadTitle, { children: [_jsxs("h3", { children: ["Download ", getTitle()] }), _jsx("h4", { children: d.substring(d.lastIndexOf("/") + 1) })] })] })));
                                    }
                                }) })) : (_jsx("h4", Object.assign({}, props)));
                        },
                    } }) })] }));
};
export default DocsTemplate;
