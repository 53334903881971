import styled from "styled-components";
export const NewPathWrapper = styled.div.withConfig({ displayName: "NewPathWrapper", componentId: "-65jfeq" }) `
  display: flex;
  flex-direction: column;
  padding-block: 0.25rem;
`;
export const ActivePath = styled.div.withConfig({ displayName: "ActivePath", componentId: "-qanxir" }) `
  display: flex;
`;
export const PathItemLabel = styled.div.withConfig({ displayName: "PathItemLabel", componentId: "-zw7hbb" }) `
  padding: 6px 10px;
  background: #ecf2f5;
  border-radius: 3px;
  border: 1px solid var(--lt-blue-lighter);

  & + & {
    margin-left: 1rem;
  }

  .newPath & {
    background-color: var(--lt-purple-bg-lighter);
    border-color: var(--lt-purple);
    color: var(--lt-purple);
  }

  .isSelected & {
    background: white;
  }
`;
export const Separator = styled.div.withConfig({ displayName: "Separator", componentId: "-qfbqo5" }) `
  display: flex;
  height: 1px;
  width: 1rem;
  position: relative;
  top: 17px;
  border-bottom: 1px solid var(--lt-purple);
`;
export const ActionIconWrapper = styled.div.withConfig({ displayName: "ActionIconWrapper", componentId: "-e569q" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  margin-left: auto;
  padding: 3px;
  opacity: 0.5;
  border-radius: 4px;
  cursor: pointer;
  background: #ecf2f5;
  border: 1px solid var(--lt-blue-lighter);
  margin-left: 5px;
`;
