import styled, { css } from "styled-components";
export const listWrapperStyle = css `
  position: relative;
  background: var(--card-bg);
  border: var(--card-border);
  height: calc(50vh - 75px);
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5rem;

  .view-pathways & {
    height: calc(50vh - 100px);
  }

  .activeLevel & {
    background: var(--card-active-bg);
    border: var(--card-active-border);
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d4db;
    border-radius: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }
`;
export const nodeStyle = css `
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: var(--editor-node-shadow);
  padding: 4px 4px 4px 0;
  color: #333;
  background: white;
  box-shadow: 0 0 3px var(--lt-blue-lighter);
  margin-bottom: 7px;
  border-radius: 4px;
  transition: all 0.1s ease;
  border: 1px solid white;

  :last-of-type {
    margin-bottom: 0;
  }

  + & {
    border-block: var(--editor-node-border);
  }

  &.isActive {
    background-color: var(--lt-purple-bg-lighter);
    border-color: var(--lt-purple);
    color: var(--lt-purple);
  }

  &.isSelected {
    background-color: var(--lt-purple-bg-lighter);
    border-color: var(--lt-purple);
    color: var(--lt-purple);
  }
`;
export const nodeTextStyle = css `
  display: flex;
  align-items: center;
  padding-inline: 10px;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
`;
export const iconWrapperStyle = css `
  cursor: pointer;
  display: flex;

  & + & {
    margin-left: 5px;
  }

  svg {
    color: var(--lt-blue-dark);
  }

  &:hover svg {
    color: var(--editor-node-icon-hover);
  }
`;
export const iconsWrapperStartStyle = css `
  display: flex;
  align-items: center;
`;
export const iconsWrapperEndStyle = css `
  display: flex;
  align-items: center;
  margin-inline: auto 5px;
`;
export const iconDragWrapperStyle = css `
  cursor: grab;
`;
export const iconStaticWrapperStyle = css `
  margin-left: 10px;
`;
export const ContentWrapper = styled.div.withConfig({ displayName: "ContentWrapper", componentId: "-13ez5yi" }) `
  display: grid;
  grid-template-columns: repeat(3, 260px) auto;
  grid-gap: 1rem;
  grid-wrap: wrap;
  width: 100%;
  padding: 1.5rem 0;
  flex-basis: 60%;

  > * + * {
    border-left: 0px solid rgba(0, 0, 0, 0.15);
  }

  h3 {
    margin: 0 0 10px;
    font-weight: 500;
  }
`;
export const DimensionWrapper = styled.div.withConfig({ displayName: "DimensionWrapper", componentId: "-178wt10" }) ``;
export const DimensionActionsWrapper = styled.div.withConfig({ displayName: "DimensionActionsWrapper", componentId: "-1u26n9p" }) `
  display: flex;
  margin-left: auto;
`;
export const AddNewNodeWrapper = styled.div.withConfig({ displayName: "AddNewNodeWrapper", componentId: "-1432bpg" }) `
  display: flex;
  justify-content: left;
  margin-top: .5rem;
`;
export const AddNewDimensionWrapper = styled.div.withConfig({ displayName: "AddNewDimensionWrapper", componentId: "-1ikqvvj" }) `
  display: flex;
  justify-content: left;
  align-items: start;
  margin-top: 1.8rem;
`;
export const ListWrapper = styled.div.withConfig({ displayName: "ListWrapper", componentId: "-1sh2kqi" }) `
  ${listWrapperStyle}
`;
export const DimensionHeader = styled.div.withConfig({ displayName: "DimensionHeader", componentId: "-kdp2yw" }) `
  display: flex;
  margin-bottom: 0.5rem;

  h3 {
    font-size: 1rem;
    margin: 3px;
    color: #0f2c42;
  }
`;
export const GroupWrapper = styled.div.withConfig({ displayName: "GroupWrapper", componentId: "-ur8l09" }) `
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0;
  border: var(--card-border);
  border-radius: 4px;
`;
export const GroupHeader = styled.div.withConfig({ displayName: "GroupHeader", componentId: "-1avsism" }) `
  border-bottom: 1px solid var(--lt-blue-lighter);

  .lt-matrix-node-wrapper {
    background: #645772;
    color: white;
    border: 1px solid #645772;
    border-radius: 4px 4px 0 0;
    padding-inline: 0rem;
  }
`;
export const GroupChildren = styled.div.withConfig({ displayName: "GroupChildren", componentId: "-xha7gm" }) `
  padding: 0.5rem;
`;
export const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "-1w9mvib" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  margin-block: auto;
  margin-left: auto;
  padding: 3px;
  opacity: 0.5;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d7d2d1;

  & + & {
    margin-left: 5px;
  }

  &.danger {
    color: var(--lt-red);
    background: var(--lt-red-lighter);
    border: 1px solid var(--lt-red-light);
  }

  :hover {
    opacity: 1;
  }
`;
export const NoDimensionsWrapper = styled.div.withConfig({ displayName: "NoDimensionsWrapper", componentId: "-12bwnq5" }) `
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(50vh - 75px);

  img {
    margin-bottom: 0.5rem;
  }
`;
