var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AutorizationService } from "../AutorizationService/AutorizationService";
import envVarConfig from "../../../../env/envVarConfig";
import { instance } from "../../../../index";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
const apiPathClientRegistration = envVarConfig.reactApiUrl + "/ClientRegistration";
export class ClientRegistrationAPIService {
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                const token = yield instance.acquireTokenSilent({
                    account: accounts[0],
                    scopes: [envVarConfig.backOfficeAppScope],
                });
                return token.accessToken;
            }
        });
    }
    static getClientRegistration(tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userToken = yield ClientRegistrationAPIService.getToken();
                const response = yield fetch(apiPathClientRegistration + "/" + tenantId, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    this.appInsights.trackEvent({
                        name: "ClientRegistration",
                        properties: {
                            endpoint: apiPathClientRegistration + "/" + tenantId,
                            response: JSON.stringify(response),
                        },
                    });
                    console.log("Failed to fetch client registration:", response.status, response.statusText);
                    return null;
                }
            }
            catch (error) {
                this.appInsights.trackException({
                    exception: error,
                    severityLevel: 3,
                });
                console.log("Error fetching client registration:", error);
                throw error;
            }
        });
    }
    static postClientRegistration(registration) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userToken = yield ClientRegistrationAPIService.getToken();
                const response = yield fetch(apiPathClientRegistration, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(registration),
                });
                if (response.ok) {
                    console.log("Client registration saved successfully");
                    return true;
                }
                else {
                    console.log("Failed to save client registration:", response.status);
                    return false;
                }
            }
            catch (error) {
                console.log("Error saving client registration:", error);
                return false;
            }
        });
    }
    static patchClientRegistation(realm) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const tenantId = JSON.parse(sessionStorage.getItem("User")).tenantId;
                const userToken = yield AutorizationService.getToken();
                const response = yield fetch(apiPathClientRegistration, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        realm: realm,
                        tenantId: tenantId,
                    }),
                });
                if (response.ok) {
                    console.log("Client registration updated successfully");
                    return true;
                }
                else {
                    console.log("Failed to update client registration:", response.status);
                    return false;
                }
            }
            catch (error) {
                console.log("Error updating client registration:", error);
                return false;
            }
        });
    }
}
ClientRegistrationAPIService.appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: envVarConfig.instrumentationKey,
        enableAutoRouteTracking: true,
    },
});
