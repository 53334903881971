var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Spinner, } from "@fluentui/react-components";
import MultiBadgeDropdown from "../../../../components/MultiBadgeDropdown";
import { QuicklinksService } from "../QuicklinksService";
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-19gvs5r" }) `
  display: flex;
  flex-direction: column;
`;
const MarginBottomInput = styled(Input).withConfig({ displayName: "MarginBottomInput", componentId: "-1c8q9ps" }) `
  margin-bottom: 16px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-4m8bcq" }) `
  padding-top: 24px;
`;
const EditCategoryModal = ({ category, uncategorizedLinks, onClose, refresh, }) => {
    const { AddCategorie, SetCategorie } = QuicklinksService();
    const [editedCategory, setEditedCategory] = useState(category !== null && category !== void 0 ? category : {
        id: "",
        name: "",
        quicklinks: [],
    });
    const [quicklinks, setQuicklinks] = useState([]);
    const [isSaving, setIsSaving] = React.useState(false);
    useEffect(() => {
        var _a;
        if (!!uncategorizedLinks && (category === null || category === void 0 ? void 0 : category.quicklinks.length) > 0)
            setQuicklinks([...uncategorizedLinks, ...category === null || category === void 0 ? void 0 : category.quicklinks]);
        else if (uncategorizedLinks)
            setQuicklinks(uncategorizedLinks);
        else if (((_a = category === null || category === void 0 ? void 0 : category.quicklinks) === null || _a === void 0 ? void 0 : _a.length) > 0)
            setQuicklinks(category === null || category === void 0 ? void 0 : category.quicklinks);
    }, []);
    const handleCategorySave = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            editedCategory.linkIds = editedCategory.quicklinks.map((link) => link.id);
            if (category)
                yield SetCategorie(editedCategory);
            else
                yield AddCategorie(editedCategory);
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
        setIsSaving(true);
        refresh();
        onClose();
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(DialogTitle, Object.assign({ style: { color: "#3D3A3B", paddingBottom: "20px" } }, { children: [!!category ? "Edit " : "Create new ", "category"] })), _jsxs(StyledDialogContent, { children: [_jsx(Label, Object.assign({ htmlFor: "edit-category-name", required: true }, { children: "Name" })), _jsx(MarginBottomInput, { id: "edit-category-name", placeholder: "Define a category", size: "large", value: editedCategory.name, onChange: (e) => setEditedCategory(Object.assign(Object.assign({}, editedCategory), { name: e.target.value })) }), _jsx(MultiBadgeDropdown, { items: quicklinks, label: "Links", labelId: "edit-category-quicklinks", placeholder: "Select links", selectedItems: editedCategory.quicklinks, onOptionSelect: (links) => setEditedCategory(Object.assign(Object.assign({}, editedCategory), { quicklinks: links })) })] }), _jsx(StyledDialogActions, { children: isSaving ?
                        (_jsx(Spinner, { size: "small", label: "Saving..." })) :
                        (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", disabled: !editedCategory.name, onClick: handleCategorySave }, { children: "Save" }))] })) })] }) }));
};
export default EditCategoryModal;
