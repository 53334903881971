var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DialogTitle, DialogSurface, DialogBody, Button, DialogActions, DialogContent, } from "@fluentui/react-components";
import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { QuicklinksService } from "../QuicklinksService";
import { AddCircle12Filled } from "@fluentui/react-icons";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-1teso7g" }) `
  display: flex;
  flex-direction: column;
  color: #3d3a3b;
  padding-bottom: 30px;
`;
const DialogSubtitle = styled.h4.withConfig({ displayName: "DialogSubtitle", componentId: "-1f38hqr" }) `
  color: #3d3a3b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 0px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-1fgei4a" }) `
  padding-top: 24px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-x0cu5a" }) `
  display: flex;
  flex-direction: column;
`;
const IconGrid = styled.div.withConfig({ displayName: "IconGrid", componentId: "-1p58loq" }) `
  display: flex;
  justify-items: center;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
`;
const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "-7xqkka" }) `
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: relative;
`;
const Icon = styled.img.withConfig({ displayName: "Icon", componentId: "-178rx53" }) `
  width: 40px;
  height: 40px;
`;
const ManageIconsModal = ({ onClose, refresh, }) => {
    const { GetIcons, AddIcon, DeleteIcon } = QuicklinksService();
    const [libraryIcons, setLibraryIcons] = useState([]);
    const [LibraryIcon, setLibraryIcon] = useState(null);
    const [haveChanges, setHaveChanges] = useState(false);
    const fileInputRef = useRef(null);
    useEffect(() => {
        const getLibraryIcons = () => __awaiter(void 0, void 0, void 0, function* () {
            setLibraryIcons(yield GetIcons());
        });
        getLibraryIcons();
    }, []);
    const handleIconClick = (icon) => {
        setLibraryIcon(icon);
    };
    const handleDelteIconClick = (icon) => __awaiter(void 0, void 0, void 0, function* () {
        setHaveChanges(true);
        //setLibraryIcons(await GetIcons());
        yield DeleteIcon(icon);
        libraryIcons.filter((i) => i.iconId !== icon.iconId);
        setLibraryIcons((currentIcons) => currentIcons.filter((i) => i.iconId !== icon.iconId));
    });
    const handleFileSelect = (e) => {
        var _a;
        e.stopPropagation();
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const UploadIcon = (icon) => __awaiter(void 0, void 0, void 0, function* () {
        setHaveChanges(true);
        const formData = new FormData();
        formData.append("File", icon);
        formData.append("Name", icon.name);
        const addedIcon = yield AddIcon(formData);
        //setLibraryIcons(await GetIcons());
        if (addedIcon) {
            setLibraryIcons([...libraryIcons, addedIcon]);
        }
    });
    const close = () => __awaiter(void 0, void 0, void 0, function* () {
        if (haveChanges)
            yield refresh();
        onClose();
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [_jsx("span", Object.assign({ style: { paddingBottom: "5px" } }, { children: "Manage icons" })), _jsx(DialogSubtitle, { children: "Organize your icons library: keep, add, or remove your uploaded icons." })] }), _jsx(StyledDialogContent, { children: _jsx(IconGrid, { children: libraryIcons.map((icon, i) => {
                            return (_jsxs(IconWrapper, Object.assign({ onMouseOver: () => handleIconClick(icon), onMouseLeave: () => handleIconClick(null) }, { children: [_jsx(Icon, { src: icon.preview }), (icon === null || icon === void 0 ? void 0 : icon.iconId) === (LibraryIcon === null || LibraryIcon === void 0 ? void 0 : LibraryIcon.iconId) && (_jsx(AddCircle12Filled, { style: {
                                            height: "28px",
                                            transform: "rotate(45deg)",
                                            width: "28px",
                                            top: "-5px",
                                            right: "-5px",
                                            position: "absolute",
                                            color: "gray",
                                        }, onClick: () => handleDelteIconClick(icon) }))] })));
                        }) }) }), _jsxs(StyledDialogActions, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: close }, { children: "Close" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handleFileSelect }, { children: "Upload new icon" })), _jsx("input", { accept: "image/*", ref: fileInputRef, type: "file", hidden: true, onChange: () => {
                                var _a;
                                UploadIcon((_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files[0]);
                            } })] })] }) }));
};
export default ManageIconsModal;
