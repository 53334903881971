var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { styled } from "styled-components";
import { InviteModal } from "./InviteModal";
import { InvitedUsers } from "./InvitedUsers/InvitedUsers";
import { MegaMenuAPIService } from "../util/MegaMenuAPIService/MegaMenuAPIService";
import ReactDOM from "react-dom";
import InviteIconButton from "/public/Invite.svg";
export const UserAccessMenuWrapper = styled.div.withConfig({ displayName: "UserAccessMenuWrapper", componentId: "-toxk8p" }) ``;
export const UserAccessDescription = styled.span.withConfig({ displayName: "UserAccessDescription", componentId: "-4gyrn" }) `
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #3d3a3b;
`;
export const UAMWrapper = styled.div.withConfig({ displayName: "UAMWrapper", componentId: "-1kcqx6k" }) `
  padding-top: 32px;
`;
export const UAMTitle = styled.h3.withConfig({ displayName: "UAMTitle", componentId: "-a2wwka" }) ``;
export const UAMDescription = styled.span.withConfig({ displayName: "UAMDescription", componentId: "-1h7akzj" }) `
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
`;
export const InviteUsersWrapper = styled.div.withConfig({ displayName: "InviteUsersWrapper", componentId: "-mr3rfc" }) `
  width: 100%;
  display: grid;
  justify-content: center;
  margin-top: 48px;
`;
export const StartInvitingUsersWrapper = styled.div.withConfig({ displayName: "StartInvitingUsersWrapper", componentId: "-17anlax" }) ``;
export const InviteUsersLabel = styled.span.withConfig({ displayName: "InviteUsersLabel", componentId: "-zejeqq" }) `
  text-align: center;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3d3a3b;
`;
export const InviteUsersDescription = styled.span.withConfig({ displayName: "InviteUsersDescription", componentId: "-m9f80p" }) `
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
  color: #707070;
  margin-left: -110px;
  padding-top: 6px;
`;
export const InviteUsersButtonWrapper = styled.div.withConfig({ displayName: "InviteUsersButtonWrapper", componentId: "-kx0jbd" }) `
  padding-top: 20px;
  margin-left: 20px;
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "-pcm9gw" }) `
  margin-top: 12px;
  margin-left: 10px;
  border: none;
  width: 132px;
  height: 32px;
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #512da8;
  cursor: pointer;
`;
export const ButtonText = styled.span.withConfig({ displayName: "ButtonText", componentId: "-k7zpvo" }) `
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  color: white;
`;
export const SpinnerWrapper = styled.div.withConfig({ displayName: "SpinnerWrapper", componentId: "-mjyatx" }) `
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Spinner = styled.div.withConfig({ displayName: "Spinner", componentId: "-1i0vk16" }) `
  top: calc(50% - 12.5px);
  position: relative;
  border: 1px solid #f3f3f3;
  border-top: 1px solid #a7a7a7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const UserAccess = (props) => {
    const [userInviteOpen, setUserInviteOpen] = React.useState(false);
    const [invitedUsers, setInvitedUsers] = React.useState([]);
    const [pendingUsers, setPendingUsers] = React.useState(true);
    React.useEffect(() => {
        if (pendingUsers) {
            getCurrentEditors().then((result) => {
                if (result) {
                    setPendingUsers(false);
                    setInvitedUsers(result);
                }
            });
        }
    }, []);
    function getCurrentEditors() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUsers = yield MegaMenuAPIService.getAssignedUsers("editors");
            return currentUsers;
        });
    }
    function openUserInvite() {
        setUserInviteOpen(true);
    }
    function removeDuplicates(selectedUsers) {
        let noDuplicates = [];
        noDuplicates = selectedUsers.map((s) => {
            if (invitedUsers.filter((i) => i.id === s.id).length === 0) {
                return s;
            }
            else {
                return null;
            }
        });
        return noDuplicates.filter((n) => n !== null);
    }
    function updateUsers(selectedUsers) {
        return __awaiter(this, void 0, void 0, function* () {
            const noDuplicates = removeDuplicates(selectedUsers);
            const newUsers = noDuplicates.concat(invitedUsers);
            const flattened = [].concat.apply([], newUsers);
            const flattenedString = flattened
                .map((user) => (user.type === 1 ? user.id : user.loginName))
                .join(",");
            if (flattenedString.length > 0) {
                setPendingUsers(false);
                setInvitedUsers(flattened);
                yield MegaMenuAPIService.addAssignedUsers(flattenedString, "editors");
            }
        });
    }
    function deleteUser(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const confirm = window.confirm("Are you sure you want to remove " +
                user.displayName +
                " from the Editors list ?");
            if (confirm) {
                const updated = invitedUsers.filter((u) => {
                    return u.id !== user.id;
                });
                setInvitedUsers(updated);
                const deletedString = user.type === 1 ? user.id : user.loginName;
                yield MegaMenuAPIService.removeAssignedUsers(deletedString, "editors");
            }
        });
    }
    return (_jsxs(UserAccessMenuWrapper, { children: [_jsx("h1", { children: "User Access" }), _jsx(UserAccessDescription, { children: "Determine who can access the Operations Center settings." }), _jsxs(UAMWrapper, { children: [_jsx(UAMTitle, { children: "User access management" }), _jsx(UAMDescription, { children: "Search for users and define who is able to access the Operations Center." })] }), pendingUsers ? (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}) })) : invitedUsers && invitedUsers.length !== 0 ? (_jsx(InvitedUsers, { isAdmin: props.isAdmin, deleteUser: deleteUser, openUserInvite: () => {
                    setUserInviteOpen(true);
                }, invitedUsers: invitedUsers }, crypto.randomUUID())) : (_jsxs(InviteUsersWrapper, { children: [_jsx(StartInvitingUsersWrapper, { children: _jsx(InviteUsersLabel, { children: "Start inviting users" }) }), _jsx(InviteUsersDescription, { children: "Users will need to accept the invitation sent to their email adress." }), _jsx(InviteUsersButtonWrapper, { children: _jsxs(ButtonWrapper, Object.assign({ onClick: openUserInvite }, { children: [_jsx("img", { src: InviteIconButton }), _jsx(ButtonText, { children: "Invite users" })] })) })] })), userInviteOpen && (_jsx(_Fragment, { children: ReactDOM.createPortal(_jsx(InviteModal, { updateUsers: updateUsers, close: () => {
                        setUserInviteOpen(false);
                    } }), document.body) }))] }));
};
export default UserAccess;
