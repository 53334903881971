var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import envVarConfig from "../../../env/envVarConfig";
import { instance } from "../../../index";
const userRegion = sessionStorage.getItem("Region");
const apiPath = envVarConfig.reactApiUrl + "/EverywhereWidget";
export class EverywhereWidgetService {
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            const token = yield instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [envVarConfig.backOfficeAppScope],
            });
            return token.accessToken;
        });
    }
}
_a = EverywhereWidgetService;
EverywhereWidgetService.GetEverywhereWidget = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
        };
        const tenantId = JSON.parse(sessionStorage.getItem("User")).tenantId;
        const response = yield fetch(`${apiPath}/${tenantId}`, { headers });
        if (!response.ok) {
            return null;
        }
        const everywhereWidget = yield response.json();
        return everywhereWidget;
    }
    catch (error) {
        console.error("Error fetching everywhere widget data:", error);
        return null;
    }
});
EverywhereWidgetService.SaveEverywhereWidget = (everywhereWidget) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-Region": userRegion,
        };
        const body = JSON.stringify(everywhereWidget);
        const response = yield fetch(apiPath, {
            method: "POST",
            headers: headers,
            body: body,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return yield response.json();
    }
    catch (error) {
        console.error("Error save/update everywhere widget:", error);
        throw error;
    }
});
EverywhereWidgetService.DeleteEverywhereWidget = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-Region": userRegion,
        };
        const response = yield fetch(`${apiPath}/${id}`, {
            method: "Delete",
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return yield response.json();
    }
    catch (error) {
        console.error("Error deleting everywhere widget:", error);
        throw error;
    }
});
