import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { submenuData } from "../data/leftMenu";
const Breadcrumb = () => {
    let location = useLocation();
    let pathnames = location.pathname.split("/").filter((x) => x);
    const isNumeric = (str) => /^\d+$/.test(str);
    if (location.pathname === "/")
        return null;
    return (_jsxs("div", Object.assign({ style: { margin: "16px 0", whiteSpace: "nowrap", overflowX: "auto" } }, { children: [_jsx(Link, Object.assign({ to: "/" }, { children: "Home" })), pathnames.map((value, index) => {
                var _a;
                if (isNumeric(value))
                    return null;
                let title = null;
                if (pathnames.length > 1) {
                    title = (_a = submenuData[pathnames[0]].find((rout) => {
                        return rout.to.split("/").filter((x) => x)[1] === value;
                    })) === null || _a === void 0 ? void 0 : _a.children;
                }
                let to = `/${pathnames.slice(0, index + 1).join("/")}`;
                const isClickable = !submenuData[value];
                return (_jsxs("span", { children: [" > ", isClickable ? (_jsx(Link, Object.assign({ to: to }, { children: title ? title : capitalize(value.replace(/-/g, " ")) }))) : (_jsx("span", { children: title ? title : capitalize(value.replace(/-/g, " ")) }))] }, to));
            })] })));
};
const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export default Breadcrumb;
