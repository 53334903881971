import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { useBranding } from "./BrandingContext";
import NavigationComponent from "./NavigationComponent";
import HeaderPreview from "./components/HeaderPreview";
import LabeledToggle from "./components/LabeledToggle";
import BrandingHeader from "./components/brandingHeader";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-1qtswr2" }) `
  display: flex;
  flex-direction: column;
  
  &.mode-wizard {
    min-height: calc(100vh - 200px);
  }
`;
const UiContainer = styled.div.withConfig({ displayName: "UiContainer", componentId: "-btmcy3" }) `
  display: flex;
  flex-direction: column;
  margin-block: auto;
  gap: 10px;
  width: 100%;

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  h3 {
    color: #3D3A3B;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
const MenuTextUIContianer = styled.div.withConfig({ displayName: "MenuTextUIContianer", componentId: "-1dqcak1" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 25%;
`;
const TogglesContainer = styled.div.withConfig({ displayName: "TogglesContainer", componentId: "-irn5qd" }) `
  display: flex;
  align-items: center;
  margin: 30px auto;
`;
const ToggleContainer = styled.div.withConfig({ displayName: "ToggleContainer", componentId: "-74gx47" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 220px;

  h3 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
  }

  + * {
    border-left: 1px solid #CCCCCC;
  }

  &.disabled {
    
    > *:not(h3) {
      opacity: 0.4;
      pointer-events: none;
    }

    button {
      background-color: #aaaaaa;
    }
  }
`;
const MenuTextOptions = ({ wizardMode }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { selectedTheme, setSelectedTheme } = useBranding();
    const handleColorChange = (value) => {
        setSelectedTheme((prev) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({}, prev), { themeSettings: Object.assign(Object.assign({}, prev === null || prev === void 0 ? void 0 : prev.themeSettings), { menuStyle: Object.assign(Object.assign({}, (_a = prev === null || prev === void 0 ? void 0 : prev.themeSettings) === null || _a === void 0 ? void 0 : _a.menuStyle), { textSettings: Object.assign(Object.assign({}, (_c = (_b = prev === null || prev === void 0 ? void 0 : prev.themeSettings) === null || _b === void 0 ? void 0 : _b.menuStyle) === null || _c === void 0 ? void 0 : _c.textSettings), { textColorPrimary: value }) }) }) }));
        });
    };
    const handleAlignmentChange = (value) => {
        setSelectedTheme((prev) => {
            var _a, _b, _c;
            return (Object.assign(Object.assign({}, prev), { themeSettings: Object.assign(Object.assign({}, prev === null || prev === void 0 ? void 0 : prev.themeSettings), { menuStyle: Object.assign(Object.assign({}, (_a = prev === null || prev === void 0 ? void 0 : prev.themeSettings) === null || _a === void 0 ? void 0 : _a.menuStyle), { textSettings: Object.assign(Object.assign({}, (_c = (_b = prev === null || prev === void 0 ? void 0 : prev.themeSettings) === null || _b === void 0 ? void 0 : _b.menuStyle) === null || _c === void 0 ? void 0 : _c.textSettings), { textAlignCenter: value }) }) }) }));
        });
    };
    return (_jsxs(Container, Object.assign({ className: wizardMode ? "mode-wizard" : "mode-edit" }, { children: [wizardMode ? (_jsx(BrandingHeader, { title: "Customize the menu style", summary: "Select a primary color and a theme to be applied on the layout.", advancedSettings: true })) : null, _jsxs(UiContainer, { children: [_jsxs(MenuTextUIContianer, { children: [_jsx("h2", { children: "Text settings" }), _jsxs(TogglesContainer, { children: [_jsxs(ToggleContainer, Object.assign({ className: ((_b = (_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.style) === "intense" ? "disabled" : "" }, { children: [_jsx("h3", { children: "Text color" }), _jsx(LabeledToggle, { labels: ["Grey", "Primary"], onChangeChecked: handleColorChange, checked: (_e = (_d = (_c = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _c === void 0 ? void 0 : _c.menuStyle) === null || _d === void 0 ? void 0 : _d.textSettings) === null || _e === void 0 ? void 0 : _e.textColorPrimary })] })), _jsxs(ToggleContainer, { children: [_jsx("h3", { children: "Text alignment" }), _jsx(LabeledToggle, { labels: ["Left", "Center"], onChangeChecked: handleAlignmentChange, checked: (_h = (_g = (_f = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _f === void 0 ? void 0 : _f.menuStyle) === null || _g === void 0 ? void 0 : _g.textSettings) === null || _h === void 0 ? void 0 : _h.textAlignCenter })] })] })] }), _jsx(HeaderPreview, {})] }), wizardMode ? (_jsx(NavigationComponent, { currentStepName: "Text Options" })) : null] })));
};
export default MenuTextOptions;
