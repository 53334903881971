import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { Title } from "../../../Nav.styles";
import { Checkbox, Field, Input, Select } from "@fluentui/react-components";
const ContentMenuWrapper = styled.div.withConfig({ displayName: "ContentMenuWrapper", componentId: "-115jyjm" }) `
  width: 100%;
  padding: 0;
  border-top: 1px solid var(--border-color);
`;
export const ContentTab = (props) => {
    const [title, setTitleValue] = React.useState(props.item ? props.item.text : "");
    const [groupId, setGroupId] = React.useState(props.item ? props.item.groupId : 0);
    const [isGroupHeader, setIsGroupHeader] = React.useState(props.item ? props.item.isGroupHeader : false);
    const [idValue, setIdValue] = React.useState(props.item ? props.item.itemId : 0);
    React.useEffect(() => {
        setTitleValue(props.item ? props.item.text : "");
        setGroupId(props.item ? props.item.groupId : 0);
        setIsGroupHeader(props.item ? props.item.isGroupHeader : false);
        setIdValue(props.item ? props.item.itemId : 0);
    }, [props.item]);
    React.useEffect(() => {
        if (props.item) {
            saveNode();
        }
    }, [title, groupId, isGroupHeader]);
    function onChangeTitleValue(e) {
        setTitleValue(e.target.value);
    }
    function onChangeGroupHeaderValue(e) {
        const isChecked = e.target.checked;
        setIsGroupHeader(isChecked);
        if (isChecked) {
            setGroupId(0); // Set parent group to none when isGroupHeader is true
        }
    }
    function onChangeGroupIdValue(e) {
        setGroupId(parseInt(e.target.value));
    }
    function saveNode() {
        const node = {
            itemId: idValue,
            text: title,
            groupId: isGroupHeader ? 0 : groupId,
            isGroupHeader: isGroupHeader,
            dimensionId: props.item.dimensionId,
        };
        props.updateItem(node);
    }
    return (_jsxs(ContentMenuWrapper, { children: [_jsx(Title, { children: "Content" }), _jsxs(_Fragment, { children: [_jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Title" }), _jsx(Field, { children: _jsx(Input, { value: title, onChange: onChangeTitleValue, disabled: !props.item }) })] })), !isGroupHeader && (_jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Parent Group" }), _jsx(Field, { children: _jsxs(Select, Object.assign({ value: groupId, onChange: onChangeGroupIdValue, disabled: !props.item }, { children: [_jsx("option", Object.assign({ value: "0" }, { children: "None" })), props.groups &&
                                            props.groups.map((group) => {
                                                return (_jsx("option", Object.assign({ value: group.itemId }, { children: group.text }), group.itemId));
                                            })] })) })] }))), _jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Group Header" }), _jsx(Checkbox, { checked: isGroupHeader, onChange: onChangeGroupHeaderValue, disabled: !props.item })] }))] })] }));
};
