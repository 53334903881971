import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { AccordionItem, AccordionPanel, Dialog, } from "@fluentui/react-components";
import { StrictModeDroppable } from "../../../components/StrictModeDroppable";
import DeleteQuicklinkModal from "./modals/DeleteQuicklinkModal";
import QuicklinkGroupActions from "./QuicklinkGroupActions";
import QuicklinkCategoryHeader from "./QuicklinkCategoryHeader";
import QuicklinkCategoryList from "./QuicklinkCategoryList";
const DroppableInnerContainer = styled.div.withConfig({ displayName: "DroppableInnerContainer", componentId: "-192p8zh" }) `
  border: ${(props) => (props.$isDraggingOver ? "1px solid #4F3888" : "none")};
  border-radius: 4px;
  margin-top: 12px;
  min-height: 50px;
`;
const StyledAccordionPanel = styled(AccordionPanel).withConfig({ displayName: "StyledAccordionPanel", componentId: "-14dife7" }) `
  margin: 0;
`;
const QuicklinkCategory = ({ audiences, category, isDraggingLinkSelected, selectedLinksNr, selectedLinks = [], uncategorizedLinks, onSelectedLinksChange, refresh, }) => {
    const forFebruary = false;
    const [linksToDelete, setLinksToDelete] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id, name } = category;
    const isDropDisabled = isDraggingLinkSelected && selectedLinksNr === selectedLinks.length;
    const handleLinksDelete = (links, e) => {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        setLinksToDelete(links);
    };
    const handleDeleteQuicklinksModalClose = () => {
        setLinksToDelete(undefined);
    };
    return (_jsx(StrictModeDroppable, Object.assign({ droppableId: id, isDropDisabled: isDropDisabled }, { children: (dropProvided, dropSnapshot) => (_jsxs(DroppableInnerContainer, Object.assign({ ref: dropProvided.innerRef, "$isDraggingOver": dropSnapshot.isDraggingOver }, dropProvided.droppableProps, { children: [_jsxs(AccordionItem, Object.assign({ title: name, value: id, disabled: isModalOpen }, { children: [_jsx(QuicklinkCategoryHeader, { category: category, isDraggingOver: dropSnapshot.isDraggingOver, onModalOpenChange: setIsModalOpen, uncategorizedLinks: uncategorizedLinks, refresh: refresh }), _jsxs(StyledAccordionPanel, { children: [forFebruary && _jsx(QuicklinkGroupActions, { category: category, links: selectedLinks, onQuicklinksDelete: () => handleLinksDelete(selectedLinks) }), _jsx(QuicklinkCategoryList, { audiences: audiences, category: category, isDraggingLinkSelected: isDraggingLinkSelected, selectedLinks: selectedLinks, selectedLinksNr: selectedLinksNr, onQuicklinksDelete: handleLinksDelete, onSelectedLinksChange: onSelectedLinksChange, refresh: refresh }), _jsx(Dialog, Object.assign({ open: !!linksToDelete, onOpenChange: handleDeleteQuicklinksModalClose }, { children: _jsx(DeleteQuicklinkModal, { links: linksToDelete, onClose: handleDeleteQuicklinksModalClose, refresh: refresh }) }))] })] })), dropProvided.placeholder] }))) })));
};
export default QuicklinkCategory;
