import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { styled } from "styled-components";
import { Button, Dropdown, Label, Option } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-1dhj25u" }) `
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-11qmm9g" }) `
  color: #707070;
  margin-bottom: 4px;
`;
const DropdownButtonLabel = styled.span.withConfig({ displayName: "DropdownButtonLabel", componentId: "-5azb" }) `
  color: #707070;
`;
const BadgesContainer = styled.div.withConfig({ displayName: "BadgesContainer", componentId: "-1yjge5b" }) `
  color: #707070;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  width: 100%;
`;
const Badge = styled.div.withConfig({ displayName: "Badge", componentId: "-1lcanwg" }) `
  align-items: center;
  background-color: #f3f2f1;
  border-radius: 100px;
  color: #201f1e;
  display: flex;
  font-size: 14px;
  gap: 8px;
  height: 24px;
  line-height: 20px;
  padding: 2px 8px;
`;
const BadgeDeleteButton = styled(Button).withConfig({ displayName: "BadgeDeleteButton", componentId: "-1udcjp1" }) `
  border: 0;
  height: 100%;
  padding: 0;
  min-width: 20px;
`;
const MultiBadgeDropdown = ({ items, label, labelId, placeholder, selectedItems = [], onOptionSelect, }) => {
    const handleOptionSelect = (_, data) => {
        onOptionSelect(data.selectedOptions);
    };
    return (_jsxs(Container, { children: [label && _jsx(StyledLabel, Object.assign({ id: labelId }, { children: label })), _jsx(Dropdown, Object.assign({ "aria-labelledby": labelId, button: _jsx(DropdownButtonLabel, { children: placeholder }), multiselect: true, selectedOptions: selectedItems, size: "large", onOptionSelect: handleOptionSelect }, { children: items === null || items === void 0 ? void 0 : items.map((item) => (_jsx(Option, Object.assign({ value: item }, { children: (item === null || item === void 0 ? void 0 : item.name) !== "" ? item === null || item === void 0 ? void 0 : item.name : item === null || item === void 0 ? void 0 : item.url }), item === null || item === void 0 ? void 0 : item.id))) })), selectedItems.length ? (_jsx(BadgesContainer, { children: selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.map((item) => (_jsxs(Badge, { children: [(item === null || item === void 0 ? void 0 : item.name) !== "" ? item === null || item === void 0 ? void 0 : item.name : item === null || item === void 0 ? void 0 : item.url, _jsx(BadgeDeleteButton, { appearance: "transparent", icon: _jsx(AddRegular, { style: {
                                    height: "16px",
                                    transform: "rotate(45deg)",
                                    width: "16px",
                                } }), onClick: () => {
                                onOptionSelect(selectedItems.filter((selectedItem) => selectedItem !== item));
                            } })] }, item === null || item === void 0 ? void 0 : item.id))) })) : null] }));
};
export default MultiBadgeDropdown;
