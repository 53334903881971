import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Switch, makeStyles, } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import React from "react";
import { styled } from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { css } from "@codemirror/lang-css";
import { useBranding } from "./BrandingContext";
const AdvancedContainer = styled.div.withConfig({ displayName: "AdvancedContainer", componentId: "-t6c1oy" }) `
  white-space: nowrap;

  &.mode-edit {
    margin-right: 2rem;
  }
`;
const TriggerText = styled.span.withConfig({ displayName: "TriggerText", componentId: "-1bvzxpt" }) `
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #512da8;

  &:hover {
    text-decoration: underline;
  }
`;
const EditorsRow = styled.div.withConfig({ displayName: "EditorsRow", componentId: "-p665f1" }) `
  display: flex;
  gap: 1rem;

  .cm-editor {
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  h4 {
    font-weight: 400;
    margin-bottom: 10px;
  }
`;
const EditorCSS = styled.div.withConfig({ displayName: "EditorCSS", componentId: "-60n8uk" }) `
  min-width: 500px;
  width: 50%;
`;
const EditorJS = styled.div.withConfig({ displayName: "EditorJS", componentId: "-fkx37a" }) `
  min-width: 500px;
  width: 50%;
`;
const ToggleContainer = styled.div.withConfig({ displayName: "ToggleContainer", componentId: "-k5ozzp" }) `
  display: grid;
  grid-template-columns: 180px 1fr;
  align-items: center;
`;
const StyledHR = styled.hr.withConfig({ displayName: "StyledHR", componentId: "-bupppl" }) `
  opacity: 0.2;
  margin-block: 1rem;
`;
const DialogSummary = styled.div.withConfig({ displayName: "DialogSummary", componentId: "-14sujnu" }) `
  margin-block: 0 2rem;
`;
const useStyles = makeStyles({
    dialog: {
        maxWidth: "fit-content",
        width: "80vw",
        "@media (max-width: 780px)": {
            width: "90vw",
        },
    },
});
const Toggles = (props) => {
    var _a, _b;
    const { selectedTheme } = useBranding();
    const [sharepointHeader, setSharepointHeader] = React.useState(((_a = selectedTheme.advanced) === null || _a === void 0 ? void 0 : _a.enableHeader) || false);
    const [sharepointAppBar, setSharepointAppBar] = React.useState(((_b = selectedTheme.advanced) === null || _b === void 0 ? void 0 : _b.enableAppBar) || false);
    const onChangeSharepointHeader = React.useCallback((ev) => {
        setSharepointHeader(ev.currentTarget.checked);
        props.onChangedValueEnableHeader(ev.currentTarget.checked);
    }, [setSharepointHeader]);
    const onChangeSharepointAppBar = React.useCallback((ev) => {
        setSharepointAppBar(ev.currentTarget.checked);
        props.onChangedValueEnableAppBar(ev.currentTarget.checked);
    }, [setSharepointAppBar]);
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "SharePoint components" }), _jsxs(ToggleContainer, { children: [_jsx("span", { children: "Enable SharePoint Header" }), _jsx(Switch, { checked: sharepointHeader, onChange: onChangeSharepointHeader })] }), _jsxs(ToggleContainer, { children: [_jsx("span", { children: "Enable SharePoint App bar" }), _jsx(Switch, { checked: sharepointAppBar, onChange: onChangeSharepointAppBar })] })] }));
};
const Editors = (props) => {
    var _a, _b;
    const { selectedTheme } = useBranding();
    const [valueCSS, setValueCSS] = React.useState(((_a = selectedTheme.advanced) === null || _a === void 0 ? void 0 : _a.customCSS) || "");
    const [valueJS, setValueJS] = React.useState(((_b = selectedTheme.advanced) === null || _b === void 0 ? void 0 : _b.customJS) || "");
    const onChangeCSS = React.useCallback((val, viewUpdate) => {
        setValueCSS(val);
        props.onChangedValueCSS(val);
    }, []);
    const onChangeJS = React.useCallback((val, viewUpdate) => {
        setValueJS(val);
        props.onChangedValueJS(val);
    }, []);
    return (_jsxs(EditorsRow, { children: [_jsxs(EditorCSS, { children: [_jsx("h3", { children: "CSS code" }), _jsx("h4", { children: "Code content" }), _jsx(CodeMirror, { value: valueCSS, height: "50vh", onChange: onChangeCSS, extensions: [css()] })] }), _jsxs(EditorJS, { children: [_jsx("h3", { children: "Java Script code" }), _jsx("h4", { children: "Code content" }), _jsx(CodeMirror, { value: valueJS, height: "50vh", onChange: onChangeJS, extensions: [javascript({ jsx: true })] })] })] }));
};
const AdvancedSettings = (props) => {
    const { selectedTheme, setSelectedTheme } = useBranding();
    const [advancedValues, setAdvancedValues] = React.useState(selectedTheme.advanced);
    const [openDialog, setOpenDialog] = React.useState(false);
    const styles = useStyles();
    const handleCSSChange = (val) => {
        setAdvancedValues((prev) => (Object.assign(Object.assign({}, prev), { customCSS: val })));
    };
    const handleJSChange = (val) => {
        setAdvancedValues((prev) => (Object.assign(Object.assign({}, prev), { customJS: val })));
    };
    const handleEnableHeaderChange = (val) => {
        setAdvancedValues((prev) => (Object.assign(Object.assign({}, prev), { enableHeader: val })));
    };
    const handleEnableAppBarChange = (val) => {
        setAdvancedValues((prev) => (Object.assign(Object.assign({}, prev), { enableAppBar: val })));
    };
    React.useEffect(() => {
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { advanced: {
                customCSS: advancedValues === null || advancedValues === void 0 ? void 0 : advancedValues.customCSS,
                customJS: advancedValues === null || advancedValues === void 0 ? void 0 : advancedValues.customJS,
                enableHeader: advancedValues === null || advancedValues === void 0 ? void 0 : advancedValues.enableHeader,
                enableAppBar: advancedValues === null || advancedValues === void 0 ? void 0 : advancedValues.enableAppBar,
            } })));
    }, [advancedValues]);
    return (_jsx(AdvancedContainer, Object.assign({ className: props.wizardMode ? "mode-wizard" : "mode-edit" }, { children: props.wizardMode ? (_jsxs(Dialog, Object.assign({ open: openDialog, onOpenChange: (event, data) => setOpenDialog(data.open) }, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(TriggerText, { children: "Advanced Settings" }) })), _jsx(DialogSurface, Object.assign({ className: styles.dialog }, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, Object.assign({ action: _jsx(DialogTrigger, Object.assign({ action: "close" }, { children: _jsx(Button, { appearance: "subtle", "aria-label": "close", icon: _jsx(Dismiss24Regular, {}) }) })) }, { children: "Advanced Settings" })), _jsxs(DialogContent, { children: [_jsx(DialogSummary, { children: "Add CSS or Java Script code to your theme." }), _jsx(Toggles, { onChangedValueEnableHeader: handleEnableHeaderChange, onChangedValueEnableAppBar: handleEnableAppBarChange }), _jsx(StyledHR, {}), _jsx(Editors, { onChangedValueCSS: handleCSSChange, onChangedValueJS: handleJSChange })] })] }) }))] }))) : (_jsxs(_Fragment, { children: [_jsx(Toggles, { onChangedValueEnableHeader: handleEnableHeaderChange, onChangedValueEnableAppBar: handleEnableAppBarChange }), _jsx(StyledHR, {}), _jsx(Editors, { onChangedValueCSS: handleCSSChange, onChangedValueJS: handleJSChange })] })) })));
};
export default AdvancedSettings;
