import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { useBranding } from "../BrandingContext";
const RadiusSelectorContainer = styled.div.withConfig({ displayName: "RadiusSelectorContainer", componentId: "-1su9a2j" }) `
  position: relative;
  width: 100%;
  margin-top: 30px;
`;
const RadiusSelectorSteps = styled.div.withConfig({ displayName: "RadiusSelectorSteps", componentId: "-11pi80y" }) `
  display: flex;
`;
const RadiusSelectorStep = styled.div.withConfig({ displayName: "RadiusSelectorStep", componentId: "-1vzphwm" }) `
  position: relative;
  flex: 1 1 100%;
  padding: 20px 10px;
  text-align: center;
  cursor: pointer;
  color: #707070;

  &::before,
  &::after {
    position: absolute;
    content: '';
    bottom: 100%;
    height: 4px;
    background: #D9D9D9;
    width: 50%;
    transform: translateY(50%);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &:first-child::before,
  &:last-child::after {
    display: none;
  }
`;
const RadiusSelectorDisc = styled.div.withConfig({ displayName: "RadiusSelectorDisc", componentId: "-1gn4qb3" }) `
  position: absolute;
  bottom: 100%;
  transform: translateY(50%);
  inset-inline: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #D9D9D9;
  margin: 0 auto;
  z-index: 1;
`;
const SelectedIndicator = styled.div.withConfig({ displayName: "SelectedIndicator", componentId: "-yoeg1q" }) `
  display: none;
  position: absolute;
  bottom: 100%;
  transform: translate(-50%, 50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  left: var(--selected-indicator-left, 50%);
  z-index: 1;
  transition: all 0.3s ease-in-out;
`;
const RadiusSelector = (props) => {
    const { selectedTheme } = useBranding();
    const [value, setValue] = React.useState(props.value);
    const preview = React.useRef(null);
    const indicator = React.useRef(null);
    React.useEffect(() => {
        var _a, _b;
        if (preview.current && ((_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.color) === null || _a === void 0 ? void 0 : _a.primaryColor)) {
            const previewContainer = preview.current;
            previewContainer.style.setProperty("--primary-color", (_b = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.color) === null || _b === void 0 ? void 0 : _b.primaryColor);
        }
    }, []);
    React.useEffect(() => {
        const step = document.querySelector('[data-step]');
        const stepWidth = step.offsetWidth;
        indicator.current.style.setProperty('--selected-indicator-left', `${(value * stepWidth) + (stepWidth / 2)}px`);
        indicator.current.style.setProperty('display', 'block');
    }, [value]);
    const handleClick = (index) => {
        setValue(index);
        props.onChangeRadius(index);
    };
    return (_jsxs(RadiusSelectorContainer, { children: [_jsx(RadiusSelectorSteps, { children: props.steps.map((step, index) => {
                    return (_jsxs(RadiusSelectorStep, Object.assign({ "data-step": index, onClick: () => handleClick(index) }, { children: [_jsx(RadiusSelectorDisc, {}), step.label] }), index));
                }) }), _jsx(SelectedIndicator, { ref: indicator })] }));
};
export default RadiusSelector;
