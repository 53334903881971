import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { Title } from "../../Nav.styles";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { RiCheckboxCircleFill, RiQuestionLine, } from "react-icons/ri";
import { Input } from "@fluentui/react-components";
const LanguageContainer = styled.div.withConfig({ displayName: "LanguageContainer", componentId: "-15nh1wl" }) `
  display: block;
  margin-block: 1rem 2rem;
`;
const LanguageCard = styled.div.withConfig({ displayName: "LanguageCard", componentId: "-d3ocgg" }) `
  min-width: 200px;
  min-height: 102px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 0.75rem;
  align-items: flex-start;
  display: inline-grid;
  position: relative;
  margin-right: 10px;
  border: 1px solid var(--border-color);

  .primaryLanguage & {
    background-color: #f5f4f3;
    margin-right: 0;
  }

  && {
    input.lt-style {
      background: #fff;
    }
  }
`;
const LanguageTitle = styled.div.withConfig({ displayName: "LanguageTitle", componentId: "-4gorl9" }) `
  display: flex;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0.25rem;
  position: relative;
  width: 100%;

  svg {
    margin-inline: 0.5rem;
  }
`;
const LanguageAreas = styled.div.withConfig({ displayName: "LanguageAreas", componentId: "-1djkl3u" }) `
  display: block;
  margin-top: 1rem;
  padding: 0 1rem;

  &.primaryLanguage {
    border-right: 1px solid var(--border-color);
  }
`;
const TranslationWord = styled(Input).withConfig({ displayName: "TranslationWord", componentId: "-1txcyj5" }) `
  && {
    margin-block: 10px 0;
  }

  .primaryLanguage &:disabled {
    opacity: 1;
  }
`;
const RemoveButton = styled.span.withConfig({ displayName: "RemoveButton", componentId: "-7yclbm" }) `
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  cursor: pointer;
`;
const SpanText = styled.span.withConfig({ displayName: "SpanText", componentId: "-vxt1gx" }) `
  display: flex;
  font-weight: 700;
  padding-bottom: 0.75rem;
  color: #5c5653;
`;
function Translations(props) {
    var _a, _b;
    const [node, setNode] = React.useState(props.node);
    const [menu, setMenu] = React.useState(props.menu);
    const [isContentVisible, setIsContentVisible] = useState(true);
    const languageCodeToIndex = {};
    if (node) {
        node.labels.forEach((label, index) => {
            if (label) {
                languageCodeToIndex[label.languageCode] = index;
            }
        });
    }
    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };
    const getLabelValue = (languageCode) => {
        const index = languageCodeToIndex[languageCode];
        return index !== undefined ? node.labels[index].value : "";
    };
    function handleLanguageValueChange(index, newValue) {
        const updatedNode = Object.assign({}, node);
        let updateLanguagueCode = menu.generalOptions.otherLanguages[index - 1].languageCode;
        let label = {
            languageCode: updateLanguagueCode,
            value: newValue,
        };
        updatedNode.labels[index] = label;
        setNode(updatedNode);
    }
    const removeLanguage = (language) => {
        const updatedMenu = Object.assign({}, menu);
        updatedMenu.generalOptions.otherLanguages =
            updatedMenu.generalOptions.otherLanguages.filter((l) => l && l.languageCode !== language.languageCode);
        const removeLabelFromNode = (node) => {
            node.labels = node.labels.filter((l) => l && l.languageCode !== language.languageCode);
            if (node.nodes) {
                node.nodes.forEach((childNode) => removeLabelFromNode(childNode));
            }
        };
        updatedMenu.siteCollection[props.selectedMenuIndex].nodes.forEach((node) => removeLabelFromNode(node));
        props.updateMenu(updatedMenu);
    };
    return (_jsxs(LanguageContainer, { children: [_jsxs(Title, { children: ["Languages", " ", !isContentVisible && (_jsx(FaAngleDown, { onClick: toggleContentVisibility, style: {
                            position: "absolute",
                            right: "2rem",
                            marginLeft: "10px",
                            cursor: "pointer",
                        } })), isContentVisible && (_jsx(FaAngleUp, { onClick: toggleContentVisibility, style: {
                            position: "absolute",
                            right: "2rem",
                            marginLeft: "10px",
                            cursor: "pointer",
                        } })), " "] }), isContentVisible && (_jsxs("div", Object.assign({ style: { display: "flex", paddingInline: ".5rem" } }, { children: [_jsxs(LanguageAreas, Object.assign({ className: "primaryLanguage" }, { children: [_jsx(SpanText, { children: "Primary Language" }), _jsx(LanguageCard, { children: menu &&
                                    menu.generalOptions &&
                                    menu.generalOptions.primaryLanguage && (_jsxs(_Fragment, { children: [_jsxs(LanguageTitle, { children: [" ", menu.generalOptions.primaryLanguage.languageName, " "] }), _jsx(TranslationWord, { placeholder: "Value", defaultValue: getLabelValue(menu.generalOptions.primaryLanguage.languageCode), className: "lt-style", disabled: true })] })) })] })), ((_b = (_a = menu === null || menu === void 0 ? void 0 : menu.generalOptions) === null || _a === void 0 ? void 0 : _a.otherLanguages) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsxs(LanguageAreas, { children: [_jsx(SpanText, { children: "Translated Language" }), menu &&
                                menu.generalOptions &&
                                menu.generalOptions.otherLanguages &&
                                menu.generalOptions.otherLanguages.map((language, index) => {
                                    var _a, _b;
                                    if (language.languageCode === "default") {
                                        return null;
                                    }
                                    return (_jsxs(LanguageCard, { children: [_jsxs(LanguageTitle, { children: [language.languageName, ((_a = node === null || node === void 0 ? void 0 : node.labels[index + 1]) === null || _a === void 0 ? void 0 : _a.value) !== undefined &&
                                                        ((_b = node === null || node === void 0 ? void 0 : node.labels[index + 1]) === null || _b === void 0 ? void 0 : _b.value) !== "" ? (_jsx(RiCheckboxCircleFill, { color: "#71C77A", size: "22" })) : (_jsx(RiQuestionLine, { color: "#FF5050", size: "22" }))] }), _jsx(TranslationWord, { placeholder: "Add translation", onChange: (event) => handleLanguageValueChange(index + 1, event.target.value), defaultValue: getLabelValue(language.languageCode), className: "lt-style", disabled: !node })] }, index));
                                })] }))] })))] }));
}
export default React.memo(Translations);
