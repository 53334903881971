import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import MultiStringInput from "../../util/MultiStringInput/MultiStringInput";
import { RiSettings5Line } from "react-icons/ri";
import { draftStatus } from "../../util/constants";
import { ConfirmImport } from "./ConfirmImport";
import { BsUpload, BsDownload, BsTrash, BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Toast, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import { AddRegular, MoreVertical24Regular } from "@fluentui/react-icons";
const TenantAndSitesMenuWrapper = styled.div.withConfig({ displayName: "TenantAndSitesMenuWrapper", componentId: "-1anwa0u" }) `
  padding: 0.75rem 0;
`;
const ParentSection = styled.div.withConfig({ displayName: "ParentSection", componentId: "-12hyotc" }) `
  padding: 1rem;
  border: 1px solid #d7d2d1;
  border-radius: 4px;
`;
const SectionTitle = styled.div.withConfig({ displayName: "SectionTitle", componentId: "-nbgwpp" }) `
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ArrowIcon = styled.span.withConfig({ displayName: "ArrowIcon", componentId: "-uy1d54" }) `
  margin-left: auto;
`;
const SubsectionText = styled.div.withConfig({ displayName: "SubsectionText", componentId: "-931950" }) `
  font-size: 14px;
  color: #5c5653;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
const TenantRelated = styled.div.withConfig({ displayName: "TenantRelated", componentId: "-1ki1d83" }) ``;
const SiteCollectionRelated = styled.div.withConfig({ displayName: "SiteCollectionRelated", componentId: "-nt6omd" }) ``;
const ActionsContainer = styled.div.withConfig({ displayName: "ActionsContainer", componentId: "-1x5qftq" }) `
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    text-decoration: none;
  }

  > svg {
    margin-inline: 0.5rem;
  }

  button.lt-style {
    margin-block: 6px;
  }
`;
const ButtonWrapperSave = styled.div.withConfig({ displayName: "ButtonWrapperSave", componentId: "-t80y5g" }) `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.7rem;

  > button + button {
    margin-left: 10px;
  }
`;
const ValueTag = styled(Button).withConfig({ displayName: "ValueTag", componentId: "-qdtec8" }) `
  && {
    min-width: 0;
  }
`;
const StatusWrapper = styled(Button).withConfig({ displayName: "StatusWrapper", componentId: "-j2sd0r" }) `
  pointer-events: none;

  &.draft {
    color: #cd7233;
    border-color: #cd7233;
  }

  &.published {
    color: #349552;
    border-color: #349552;
  }
`;
const MoreButton = styled(Button).withConfig({ displayName: "MoreButton", componentId: "-zqrj8a" }) `
  && {
    margin-left: .5rem;
  }
`;
const CustomTable = styled(Table).withConfig({ displayName: "CustomTable", componentId: "-1bgjqsy" }) `
  border: 1px solid #d7d2d1;
  margin-bottom: .75rem;
`;
const CustomTableHeader = styled(TableHeader).withConfig({ displayName: "CustomTableHeader", componentId: "-16b2985" }) `
  background-color: rgb(245, 244, 243);
  
  th {
    font-weight: 600;
  }
`;
const TenantAndSitesMenu = (props) => {
    const [isTenantAndSitesVisible, setIsTenantAndSitesVisible] = React.useState(true);
    const [activeActionsMenuIndex, setActiveActionsMenuIndex] = React.useState(-1);
    const [editingRowIndex, setEditingRowIndex] = React.useState(-1);
    const [isEditing, setIsEditing] = React.useState(false);
    const tableRowRef = React.useRef(null);
    const [editName, setEditName] = React.useState(null);
    const [triggerAddChange, setTriggerAddChange] = React.useState(false);
    const doNotShowAgain = localStorage.getItem("doNotShowAgain")
        ? localStorage.getItem("doNotShowAgain").indexOf("true") !== -1
        : false;
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [addingNewRow, setAddingNewRow] = React.useState(false);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const toggleTenantAndSitesVisibility = () => {
        setIsTenantAndSitesVisible(!isTenantAndSitesVisible);
    };
    const editRow = (index) => {
        setEditingRowIndex(index);
        setActiveActionsMenuIndex(-1);
    };
    const saveChanges = (index) => {
        setEditingRowIndex(-1);
        const newMenu = {
            id: props.megaMenu.id,
            selectedLanguage: props.megaMenu.selectedLanguage,
            generalOptions: {
                useSearchBar: props.megaMenu.generalOptions.useSearchBar,
                useGlobalSearch: props.megaMenu.generalOptions.useGlobalSearch,
                logoUrl: props.megaMenu.generalOptions.logoUrl,
                primaryLanguage: props.megaMenu.generalOptions.primaryLanguage,
                otherLanguages: props.megaMenu.generalOptions.otherLanguages,
            },
            siteCollection: props.siteCollections,
        };
        props.updateMenu(newMenu, index, true);
    };
    const handleConfigureButtonClick = (index) => {
        setActiveActionsMenuIndex(-1);
        setIsEditing(false);
        props.onConfigureClick(index);
    };
    function isPublished(index) {
        var _a, _b;
        const version = (_a = props.megaMenu.siteCollection[index]) === null || _a === void 0 ? void 0 : _a.version;
        if (version) {
            if (version.includes(".0")) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (((_b = props.megaMenu.siteCollection[index]) === null || _b === void 0 ? void 0 : _b.status) === "published") {
                return true;
            }
            else {
                return false;
            }
        }
    }
    const handleSiteCollectionChange = (index, field, value, save) => {
        props.onUpdateSiteCollections(props.siteCollections.map((siteCollection, i) => i === index ? Object.assign(Object.assign({}, siteCollection), { [field]: value }) : siteCollection), save);
    };
    const handleSiteCollectionNameBlur = (index, field, value) => {
        props.onUpdateSiteCollections(props.siteCollections.map((siteCollection, i) => i === index ? Object.assign(Object.assign({}, siteCollection), { [field]: value }) : siteCollection));
        setEditName(null);
    };
    const discardChanges = (index) => {
        if (addingNewRow) {
            setAddingNewRow(false);
            deleteRow(index);
        }
        setIsEditing(false);
        setEditingRowIndex(-1);
    };
    const deleteRow = (index) => {
        const deletedCollectionId = props.siteCollections[index].siteCollectionId;
        props.onUpdateSiteCollections(props.siteCollections.filter((collection, i) => collection.siteCollectionId !== deletedCollectionId));
    };
    const addNewRow = () => {
        setAddingNewRow(true);
        props.onUpdateSiteCollections([
            ...props.siteCollections,
            {
                siteCollectionId: crypto.randomUUID(),
                menuName: "",
                siteCollectionUrls: [],
                status: draftStatus,
                nodes: [],
                styles: {
                    firstLevelBackgroundColor: "#ffffff",
                    firstLevelFontColor: "#000000",
                    firstLevelTextFormat: "uppercase",
                    secondLevelTextFormat: "uppercase",
                    thirdLevelTextFormat: "uppercase",
                    menuStyle: "compact",
                },
            },
        ]);
        setIsEditing(true);
        setTriggerAddChange(false);
        setEditingRowIndex(props.siteCollections.length);
    };
    function handleExport(index) {
        //Create a new independent menu to avoid modifying the original
        const menuToExport = JSON.parse(JSON.stringify(props.siteCollections[index]));
        removeAudienceFields(menuToExport);
        const stringifiedMenu = "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(menuToExport));
        const downloadNode = document.createElement("a");
        downloadNode.setAttribute("href", stringifiedMenu);
        downloadNode.setAttribute("download", props.siteCollections[index].menuName +
            "-" +
            Date.now().toString() +
            ".json");
        document.body.appendChild(downloadNode);
        downloadNode.click();
        downloadNode.remove();
        setActiveActionsMenuIndex(-1);
        notify("Successfully exported MegaMenu Configuration");
        function removeAudienceFields(obj) {
            for (let key in obj) {
                if (key === "audience") {
                    delete obj[key];
                }
                else if (typeof obj[key] === "object" && obj[key] !== null) {
                    removeAudienceFields(obj[key]);
                }
            }
        }
    }
    function updateSiteCollectionNodes(index, siteCollection) {
        const newSiteCollections = props.siteCollections;
        newSiteCollections[index] = siteCollection;
        const newMenu = {
            id: props.megaMenu.id,
            selectedLanguage: props.megaMenu.selectedLanguage,
            generalOptions: {
                useSearchBar: props.megaMenu.generalOptions.useSearchBar,
                useGlobalSearch: props.megaMenu.generalOptions.useGlobalSearch,
                logoUrl: props.megaMenu.generalOptions.logoUrl,
                primaryLanguage: props.megaMenu.generalOptions.primaryLanguage,
                otherLanguages: props.megaMenu.generalOptions.otherLanguages,
            },
            siteCollection: newSiteCollections,
        };
        props.updateMenu(newMenu, index);
    }
    function handleImport(index) {
        setActiveActionsMenuIndex(index);
        if (!doNotShowAgain) {
            setShowConfirm(true);
        }
        else {
            handleConfirmedAction(index);
        }
    }
    function handleConfirmedAction(index) {
        setShowConfirm(false);
        const input = document.getElementById("menuConfigUpload");
        input.click();
        input.onchange = () => {
            const selectedFile = input.files[0];
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const dataJson = JSON.parse(fileReader.result);
                const toUpdate = props.siteCollections[index];
                toUpdate.nodes = dataJson.nodes ? dataJson.nodes : [];
                toUpdate.styles = dataJson.styles;
                updateSiteCollectionNodes(index, toUpdate);
                setActiveActionsMenuIndex(-1);
                notify("Successfully imported MegaMenu Configuration");
            };
            input.value = null;
            fileReader.readAsText(selectedFile);
        };
    }
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "success" });
    return (_jsxs(TenantAndSitesMenuWrapper, Object.assign({ className: "lt-style" }, { children: [_jsxs(ParentSection, { children: [_jsxs(SectionTitle, Object.assign({ className: "lt-style", onClick: toggleTenantAndSitesVisibility }, { children: ["Tenant and Sites", " ", _jsx(ArrowIcon, { children: isTenantAndSitesVisible ? _jsx(FaAngleUp, {}) : _jsx(FaAngleDown, {}) })] })), isTenantAndSitesVisible && (_jsxs(_Fragment, { children: [_jsx(TenantRelated, { children: _jsxs(SubsectionText, Object.assign({ className: "lt-style" }, { children: ["There will always be one MegaMenu defaulted for your Tenant. You can also create multiple MegaMenus, specific for different sites in your Sharepoint.", " "] })) }), _jsxs(SiteCollectionRelated, Object.assign({ className: "lt-style" }, { children: [_jsxs(CustomTable, { children: [_jsx(CustomTableHeader, { children: _jsxs(TableRow, { children: [_jsx(TableHeaderCell, { children: "Menu Name" }), _jsx(TableHeaderCell, { children: "Site collections" }), _jsx(TableHeaderCell, Object.assign({ style: {
                                                                width: "120px"
                                                            } }, { children: "Status" })), _jsx(TableHeaderCell, { style: {
                                                                width: "230px"
                                                            } })] }) }), _jsx(TableBody, { children: props.siteCollections.map((siteCollection, index) => {
                                                    if (siteCollection.status !== draftStatus) {
                                                        return null; // Skip rendering this row but keep the index to avoid overwriting the next row
                                                    }
                                                    return (_jsxs(TableRow, Object.assign({ ref: tableRowRef }, { children: [_jsx(TableCell, Object.assign({ style: {
                                                                    position: editingRowIndex === index
                                                                        ? "relative"
                                                                        : "inherit",
                                                                } }, { children: editingRowIndex === index ? (_jsx("input", { className: "lt-style", type: "text", placeholder: "Choose a name to identify this Menu", value: editName ? editName : siteCollection.menuName, onChange: (e) => setEditName(e.target.value), onBlur: (e) => handleSiteCollectionNameBlur(index, "menuName", e.target.value) })) : (siteCollection.menuName) })), _jsx(TableCell, { children: editingRowIndex === index ? (_jsx(MultiStringInput, { triggerAddChange: triggerAddChange, clientRegion: sessionStorage.getItem("Region"), values: siteCollection.siteCollectionUrls, onChange: (values, save) => {
                                                                        handleSiteCollectionChange(index, "siteCollectionUrls", values, save);
                                                                    }, setTriggerChange: (trigger) => setTriggerAddChange(trigger), siteCollections: props.siteCollections })) : (siteCollection.siteCollectionUrls.map((url, index) => {
                                                                    const displayValue = url === "All Sites"
                                                                        ? url
                                                                        : url.split(".com")[1];
                                                                    return (_jsx(ValueTag, { children: _jsx("span", { children: displayValue }) }, index));
                                                                })) }), _jsx(TableCell, Object.assign({ style: {
                                                                    width: "120px"
                                                                } }, { children: _jsx(StatusWrapper, Object.assign({ className: isPublished(index) ? "published" : "draft" }, { children: isPublished(index) ? "Published" : "Draft" })) })), _jsx(TableCell, Object.assign({ style: {
                                                                    width: "230px"
                                                                } }, { children: editingRowIndex === index ? (_jsxs(ButtonWrapperSave, { children: [_jsx(Button, Object.assign({ appearance: "primary", onClick: () => {
                                                                                setTriggerAddChange(true);
                                                                                setIsEditing(false);
                                                                                setEditingRowIndex(-1);
                                                                                saveChanges(index);
                                                                            }, disabled: (!editName || !editName.trim()) &&
                                                                                (siteCollection.menuName === null ||
                                                                                    siteCollection.menuName === ""), title: !editName || !editName.trim()
                                                                                ? "Menu name is required"
                                                                                : "" }, { children: "Save" })), _jsx(Button, Object.assign({ onClick: () => {
                                                                                discardChanges(index);
                                                                            } }, { children: "Cancel" }))] })) : (_jsxs(ActionsContainer, { children: [_jsx(Link, Object.assign({ to: {
                                                                                pathname: `/intranet/navigation/megamenu/${index}/${siteCollection.menuName.replace(/ /g, "-")}/content`,
                                                                            } }, { children: _jsx(Button, Object.assign({ onClick: () => handleConfigureButtonClick(index), icon: _jsx(RiSettings5Line, {}) }, { children: "Configure" })) })), _jsxs(Menu, Object.assign({}, props, { children: [_jsx(MenuTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(MoreButton, { appearance: "subtle", icon: _jsx(MoreVertical24Regular, {}) }) })), _jsx(MenuPopover, { children: _jsxs(MenuList, { children: [index !== 0 && (_jsxs(_Fragment, { children: [_jsx(MenuItem, Object.assign({ icon: _jsx(BsPencil, { fontSize: "15px" }), onClick: () => { editRow(index); } }, { children: "Edit" })), _jsx(MenuItem, Object.assign({ icon: _jsx(BsTrash, { fontSize: "15px" }), onClick: () => deleteRow(index) }, { children: "Delete" }))] })), _jsx(MenuItem, Object.assign({ icon: _jsx(BsUpload, { fontSize: "15px" }), onClick: () => { handleImport(index); } }, { children: "Import" })), _jsx(MenuItem, Object.assign({ icon: _jsx(BsDownload, { fontSize: "15px" }), onClick: () => handleExport(index) }, { children: "Export" }))] }) })] })), _jsx(ConfirmImport, { close: () => setShowConfirm(false), confirm: () => {
                                                                                handleConfirmedAction(activeActionsMenuIndex);
                                                                            }, showModal: showConfirm && !doNotShowAgain })] })) }))] }), index));
                                                }) })] }), _jsx(Button, Object.assign({ onClick: addNewRow, icon: _jsx(AddRegular, {}) }, { children: "Add MegaMenu" }))] }))] })), " "] }), _jsx("input", { style: { display: "none" }, type: "file", name: "file", id: "menuConfigUpload" }), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] })));
};
export default TenantAndSitesMenu;
