import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { styled } from "styled-components";
import { InvitedUser } from "./InvitedUser";
import { ButtonText, ButtonWrapper, InviteUsersButtonWrapper, } from "../UserAccess";
import SearchIconButton from "/public/Search.svg";
import InviteButton from "/public/Invite.svg";
export const InvitedUsersWrapper = styled.div.withConfig({ displayName: "InvitedUsersWrapper", componentId: "-1078ic2" }) `
  width: 100%;
  display: grid;
`;
export const SearchWrapper = styled.div.withConfig({ displayName: "SearchWrapper", componentId: "-1fn23hv" }) `
  margin-top: 32px;
  height: 32.5px;
  width: 400px;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--light-stroke-stroke-1, #d1d1d1);
  background: var(--light-background-background-1, #fff);
`;
export const ActionsWrapper = styled.div.withConfig({ displayName: "ActionsWrapper", componentId: "-18eb5uh" }) `
  width: 100%;
  display: flex;
`;
const PickerInputInvite = styled.input.withConfig({ displayName: "PickerInputInvite", componentId: "-qzczb5" }) `
  color: var(--form-text);
  background-color: var(--background);
  font-family: inherit;
  font-size: 14px;
  position: relative;
  margin-block: 0.5rem;
  //padding-top: 15px;
  padding-left: 15px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  appearance: none;
`;
export const SearchIcon = styled.img.withConfig({ displayName: "SearchIcon", componentId: "-105fola" }) `
  padding-top: 0px;
  font-size: 16px;
  width: 22px;
  margin-left: 10px;
`;
export const InvitedUsers = (props) => {
    const [searchText, setSearchText] = React.useState("");
    const [filteredUsers, setFilteredUsers] = React.useState(props.invitedUsers);
    function onInputChange(e) {
        const value = e.target.value;
        setSearchText(value);
        if (value === "") {
            setFilteredUsers(props.invitedUsers);
        }
        else {
            const searchedUsers = filteredUsers.filter((f) => {
                var _a, _b, _c, _d;
                return ((_a = f.displayName) === null || _a === void 0 ? void 0 : _a.toLowerCase().startsWith(value.toLowerCase())) ||
                    ((_b = f.displayName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(value.toLowerCase())) ||
                    ((_c = f.loginName) === null || _c === void 0 ? void 0 : _c.toLowerCase().startsWith(value.toLowerCase())) ||
                    ((_d = f.loginName) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(value.toLowerCase()));
            });
            setFilteredUsers(searchedUsers);
        }
    }
    function deleteUser(user) {
        props.deleteUser(user);
    }
    return (_jsxs(InvitedUsersWrapper, { children: [_jsxs(ActionsWrapper, { children: [_jsxs(SearchWrapper, { children: [_jsx(SearchIcon, { src: SearchIconButton }), _jsx(PickerInputInvite, { value: searchText, onChange: onInputChange, placeholder: "Search for name or email" })] }), props.isAdmin && (_jsx(InviteUsersButtonWrapper, { children: _jsxs(ButtonWrapper, Object.assign({ style: { marginTop: "12px", marginLeft: "10px" }, onClick: props.openUserInvite }, { children: [_jsx("img", { src: InviteButton }), _jsx(ButtonText, { children: "Invite users" })] })) }))] }), _jsx(_Fragment, { children: filteredUsers.map((user) => {
                    return (_jsx(InvitedUser, { isAdmin: props.isAdmin, user: user, delete: deleteUser }, crypto.randomUUID()));
                }) })] }));
};
