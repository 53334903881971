import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { SuggestionBox } from "../SuggestionBox/SuggestionBox";
import { PickedUser } from "./PickedUser";
import debounce from "lodash.debounce";
import { SearchBox } from "@fluentui/react-components";
import { AudiencesService } from "../../AudiencesService";
const PickerWrapper = styled.div.withConfig({ displayName: "PickerWrapper", componentId: "-1umsj14" }) `
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 10px;
  margin-left: -3px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: var(--border-radius);
`;
const PickerList = styled.div.withConfig({ displayName: "PickerList", componentId: "-1k9ztm7" }) `
  display: flex;
`;
const PickerInput = styled(SearchBox).withConfig({ displayName: "PickerInput", componentId: "-16cqssv" }) `
  width: 100%;
  max-width: 100% !important;
`;
const PickerInputWrapper = styled.div.withConfig({ displayName: "PickerInputWrapper", componentId: "-1er5oqe" }) `
  font-family: inherit;
  font-size: 14px;
  width: 100%;
`;
export var IdentityType;
(function (IdentityType) {
    IdentityType[IdentityType["User"] = 0] = "User";
    IdentityType[IdentityType["Group"] = 1] = "Group";
})(IdentityType || (IdentityType = {}));
const PickedUsersWrapper = styled.div.withConfig({ displayName: "PickedUsersWrapper", componentId: "-bpofd7" }) `
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 14px;
`;
export const SpinnerWrapper = styled.div.withConfig({ displayName: "SpinnerWrapper", componentId: "-1u0edpt" }) `
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Spinner = styled.div.withConfig({ displayName: "Spinner", componentId: "-1m51gg3" }) `
  top: calc(50% - 12.5px);
  position: relative;
  border: 1px solid #f3f3f3;
  border-top: 1px solid #a7a7a7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const SearchIcon = styled.img.withConfig({ displayName: "SearchIcon", componentId: "-197sm1a" }) `
  padding-top: 0px;
  font-size: 14px;
  width: 22px;
  margin-left: 10px;
`;
export const PickerTitle = styled.span.withConfig({ displayName: "PickerTitle", componentId: "-ng48ll" }) `
  padding-top: 14px;
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #242424;
`;
const SelectedUsersWrapper = styled.div.withConfig({ displayName: "SelectedUsersWrapper", componentId: "-36h46j" }) `
  padding-top: 10px;
  width: 100%;
  display: ${(p) => (p.styleForUser ? "null" : "contents")};
`;
const UserTitle = styled.div.withConfig({ displayName: "UserTitle", componentId: "-xr7k40" }) `
  margin-bottom: 4px;
  font-size: 14px;
`;
export const Picker = (props) => {
    const [searchText, setSearchText] = React.useState("");
    const [identityType, setIdentityType] = React.useState(props.identityType);
    const [isUsers, setIsUsers] = React.useState(props.identityType === IdentityType.User);
    const [isGroups, setIsGroups] = React.useState(props.identityType === IdentityType.Group);
    const [filteredUsers, setFilteredUsers] = React.useState(null);
    const [selectedUsers, setSelectedUsers] = React.useState(props.selectedUsers);
    const [isPendingUsers, setIsPendingUsers] = React.useState(props.isPendingUsers);
    const [countEntities, setCountEntities] = React.useState(0);
    const { getFilteredEntities } = AudiencesService();
    const [showSuggestionBox, setShowSuggestionBox] = React.useState(false);
    const useDebounce = (callback) => {
        const ref = React.useRef();
        React.useEffect(() => {
            ref.current = callback;
        }, [callback]);
        const debouncedCallback = React.useMemo(() => {
            const func = () => {
                //@ts-ignore
                ref.current();
            };
            return debounce(func, 1200);
        }, []);
        return debouncedCallback;
    };
    const debounceCall = useDebounce(() => {
        if ((searchText === null || searchText === void 0 ? void 0 : searchText.length) !== 0) {
            getFilteredEntities(searchText, identityType).then((result) => {
                setFilteredUsers(result);
            });
        }
    });
    React.useEffect(() => {
        setSelectedUsers(props.selectedUsers);
        setSearchText("");
        setFilteredUsers(null);
    }, [props.selectedUsers]);
    React.useEffect(() => {
        if (!props.isPendingUsers) {
            props.isRenderingEntities(true);
        }
        else if (countEntities === (selectedUsers === null || selectedUsers === void 0 ? void 0 : selectedUsers.length) &&
            (selectedUsers === null || selectedUsers === void 0 ? void 0 : selectedUsers.length) !== 1 &&
            props.isPendingUsers) {
            props.isRenderingEntities(true);
        }
        else {
            setIsPendingUsers(false);
            props.isRenderingEntities(false);
        }
    }, [countEntities]);
    function onInputChange(e) {
        const value = e.target.value;
        if (value === "" || value === undefined) {
            setFilteredUsers(null);
        }
        if (value === undefined) {
            setSearchText("");
        }
        else {
            setSearchText(value);
        }
        setShowSuggestionBox(true);
        debounceCall();
    }
    function userAlreadyAdded(user) {
        const foundUsers = selectedUsers.filter((u) => u.id === user.id);
        return foundUsers.length !== 0;
    }
    function addSuggestion(user) {
        if (!userAlreadyAdded(user)) {
            setShowSuggestionBox(false);
            const newUsers = selectedUsers ? [...selectedUsers] : [];
            newUsers.push(user);
            setSelectedUsers(newUsers);
            props.setSelectedUsers(newUsers);
        }
    }
    function removeUser(user) {
        setIsPendingUsers(true);
        props.deleteUser(user);
    }
    //condition if removed show
    return (_jsxs(_Fragment, { children: [_jsxs(PickerWrapper, Object.assign({ className: "lt-style" }, { children: [isUsers ? (_jsx(UserTitle, { children: "Users" })) : isGroups ? (_jsx(UserTitle, { children: "Groups" })) : (_jsx(UserTitle, { children: "Users" })), _jsx(PickerInputWrapper, { children: _jsx(PickerInput, { size: "large", value: searchText, size: "medium", onChange: onInputChange, placeholder: isUsers
                                ? "Search for users"
                                : isGroups
                                    ? "Search for groups"
                                    : "Search for users" }) })] })), (searchText === null || searchText === void 0 ? void 0 : searchText.length) !== 0 && showSuggestionBox && (_jsx(SuggestionBox, { addSuggestion: addSuggestion, filteredUsers: filteredUsers })), _jsx(_Fragment, { children: _jsx(PickerList, { children: _jsxs(PickedUsersWrapper, { children: [(selectedUsers === null || selectedUsers === void 0 ? void 0 : selectedUsers.length) !== 0 ? (isUsers ? (_jsx(PickerTitle, { children: "Selected Users" })) : isGroups ? null : (_jsx(PickerTitle, { children: "Selected Users" }))) : null, _jsx(SelectedUsersWrapper, Object.assign({ styleForUser: identityType === IdentityType.User }, { children: selectedUsers === null || selectedUsers === void 0 ? void 0 : selectedUsers.map((s) => {
                                    return (_jsx(PickedUser, { displayType: identityType, removeUser: removeUser, user: s, onRenderFinished: () => {
                                            setCountEntities(countEntities + 1);
                                        } }));
                                }) }))] }) }) })] }));
};
