import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Switch, Button, Dialog } from "@fluentui/react-components";
import styled from "styled-components";
import { EditRegular, DeleteRegular } from "@fluentui/react-icons";
import AddEditTabModel from "./modals/AddEditTabModel";
import DeleteModal from "./modals/DeleteModal";
const StyledLabel = styled.label.withConfig({ displayName: "StyledLabel", componentId: "-2tjm7p" }) `
  font-weight: 600;
  color: #3d3a3b;
  font-size: 16px;
`;
const Hr = styled.div.withConfig({ displayName: "Hr", componentId: "-50hyow" }) `
  position: relative;
  border-bottom: 2px solid #f2f2f2;
  left: 2%;
  width: 96%;
`;
const ContentBody = styled.div.withConfig({ displayName: "ContentBody", componentId: "-1urpzyx" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  font-size: 16px;
  align-items: center;
  align-content: center;
`;
const EditDeleteDiv = styled.div.withConfig({ displayName: "EditDeleteDiv", componentId: "-1lgy538" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ActionButtons = styled.div.withConfig({ displayName: "ActionButtons", componentId: "-km6bw1" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const EverywhereTab = ({ tab, addTab, updateTab, deleteTab, }) => {
    const [showAddEditTabModal, setShowAddEditTabModal] = React.useState(false);
    const [showDeleteTabModal, setShowDeleteTabModal] = React.useState(false);
    const [active, setActive] = React.useState(tab === null || tab === void 0 ? void 0 : tab.active);
    const handleAddEditTabModalOpen = () => {
        setShowAddEditTabModal(true);
    };
    const handleAddEditTabModalClose = () => {
        setShowAddEditTabModal(false);
    };
    const handleDeleteTabModalOpen = () => {
        setShowDeleteTabModal(true);
    };
    const handleDeleteTabModalClose = () => {
        setShowDeleteTabModal(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ContentBody, { children: [_jsx(StyledLabel, { children: tab.name }), _jsxs(ActionButtons, { children: [!(tab === null || tab === void 0 ? void 0 : tab.isDefault) && (_jsxs(EditDeleteDiv, { children: [_jsx(Button, { icon: _jsx(EditRegular, {}), style: { marginRight: "20px", border: "none" }, onClick: handleAddEditTabModalOpen }), _jsx(Button, { icon: _jsx(DeleteRegular, {}), style: { marginRight: "24px", border: "none" }, onClick: handleDeleteTabModalOpen })] })), _jsx(Switch, { checked: active, onClick: (e) => {
                                    updateTab(Object.assign(Object.assign({}, tab), { active: !active }));
                                    e.stopPropagation();
                                } })] })] }), _jsx(Hr, {}), _jsx(Dialog, Object.assign({ open: showAddEditTabModal, onOpenChange: handleAddEditTabModalClose }, { children: _jsx(AddEditTabModel, { onClose: handleAddEditTabModalClose, tab: tab, updateTab: updateTab, addTab: addTab }) })), _jsx(Dialog, Object.assign({ open: showDeleteTabModal, onOpenChange: handleDeleteTabModalClose }, { children: _jsx(DeleteModal, { onClose: handleDeleteTabModalClose, tab: tab, deleteTab: deleteTab }) }))] }));
};
export default EverywhereTab;
