import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import DocsTemplate from "../DocsTemplate";
const IntranetGovernance = () => {
    return (_jsx(DocsTemplate, { title: "Governance", downloadUrls: [
            "https://install.matchpoint365.com/resources/LiveTiles.Intranet.Governance.sppkg",
            "https://install.matchpoint365.com/resources/LiveTiles.Intranet.Governance.APAC.sppkg",
        ], docsPage: "intranet/governance/governance" }));
};
export default IntranetGovernance;
