import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, } from "@fluentui/react-components";
import { ArrowLeftRegular, ArrowUploadRegular, DeleteRegular, GlobeRegular, LibraryRegular, LinkRegular, } from "@fluentui/react-icons";
import MultiBadgeDropdown from "../../../../components/MultiBadgeDropdown";
import LinkIconModalBody from "./LinkIconModalBody";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-18g5czg" }) `
  align-items: center;
  display: flex;
  color: #3d3a3b;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-fuvbh8" }) `
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`;
const CurrentIconContainer = styled.div.withConfig({ displayName: "CurrentIconContainer", componentId: "-13l2wny" }) `
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #3d3a3b;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 4px 8px;
`;
const CurrentIconLabel = styled.span.withConfig({ displayName: "CurrentIconLabel", componentId: "-1r7gvg7" }) `
  align-items: center;
  display: flex;
  gap: 8px;

  & > svg {
    height: 18px;
    width: 18px;
  }
`;
const IconActionsContainer = styled.div.withConfig({ displayName: "IconActionsContainer", componentId: "-1olbgtl" }) `
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-hdo76l" }) `
  padding-top: 24px;
`;
const EditQuicklinkGroupModal = ({ category, links, property, onClose, }) => {
    const [editedValues, setEditedValues] = useState(property === "audience" || category.name === "Uncategorized"
        ? []
        : [category]);
    const [editedIcon, setEditedIcon] = useState();
    const [editIconType, setEditIconType] = useState();
    const dropdownItems = property === "audience" ? [] : []; //Audiences / Categories
    const handleQuicklinksSave = () => {
        // TODO save quicklinks
        const editedCategories = property === "category" ? editedValues : [category];
        const editedLinks = links.map((link) => (Object.assign(Object.assign({}, link), { audiences: property === "audience" ? editedValues : link.audiences, icon: property === "icon" ? editedIcon : link.icon })));
        console.log("Save links w/ categories:", editedCategories, editedLinks);
        onClose();
    };
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [editIconType ? (_jsx(Button, { appearance: "transparent", icon: _jsx(ArrowLeftRegular, { style: {
                                    height: "20px",
                                    width: "20px",
                                } }), onClick: () => {
                                setEditIconType(undefined);
                            } })) : null, editIconType
                            ? editIconType === "library"
                                ? "Select icon from library"
                                : editIconType === "link"
                                    ? "Upload icon from link"
                                    : "Upload icon"
                            : `Change ${property}`] }), !editIconType ? (_jsxs(StyledDialogContent, { children: [property !== "icon" ? (_jsx(MultiBadgeDropdown, { items: dropdownItems.map((item) => item.name), label: property === "audience" ? "Target audiences" : "Category", labelId: `edit-quicklink-group-${property}`, placeholder: `Select ${property === "audience" ? "audiences" : "categories"}`, selectedItems: editedValues.map((editedValue) => editedValue.name), onOptionSelect: (valueNames) => setEditedValues(valueNames.map((valueName) => dropdownItems.find((dropdownItem) => dropdownItem.name === valueName))) })) : null, property === "icon" ? (_jsxs(_Fragment, { children: [editedIcon ? (_jsxs(CurrentIconContainer, { children: [_jsxs(CurrentIconLabel, { children: [_jsx("span", { children: "Current icon:" }), _jsx(GlobeRegular, {})] }), _jsx(Button, { appearance: "transparent", icon: _jsx(DeleteRegular, {}), onClick: () => { } })] })) : null, _jsxs(IconActionsContainer, { children: [_jsx(Button, Object.assign({ appearance: "secondary", icon: _jsx(LinkRegular, {}), onClick: () => {
                                                setEditIconType("link");
                                            } }, { children: "Link icon" })), _jsx(Button, Object.assign({ appearance: "secondary", icon: _jsx(ArrowUploadRegular, {}), onClick: () => {
                                                setEditIconType("upload");
                                            } }, { children: "Upload icon" })), _jsx(Button, Object.assign({ appearance: "secondary", icon: _jsx(LibraryRegular, {}), onClick: () => {
                                                setEditIconType("library");
                                            } }, { children: "From library" }))] }), editedIcon ? (_jsxs("span", { children: ["You will be changing the icon for", " ", _jsxs("b", { children: [links.length, " links"] }), "."] })) : null] })) : null] })) : (_jsx(LinkIconModalBody, { type: editIconType, onClose: () => {
                        setEditIconType(undefined);
                    }, onIconSave: () => {
                        // TODO
                        setEditedIcon(undefined);
                        setEditIconType(undefined);
                    } })), !editIconType ? (_jsxs(StyledDialogActions, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", disabled: property === "icon" && !editedIcon, onClick: handleQuicklinksSave }, { children: "Save" }))] })) : null] }) }));
};
export default EditQuicklinkGroupModal;
