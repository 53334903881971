import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Button, DialogTrigger } from "@fluentui/react-components";
import { RiDeleteBin6Line } from "react-icons/ri";
const DeleteButton = styled(Button).withConfig({ displayName: "DeleteButton", componentId: "-1a1fpou" }) `
  && {
    color: var(--red-lighter-text);
    border: 1px solid var(--red-lighter-text);
    background-color: var(--red-lighter-bg);
  }
`;
export const DeleteModal = (props) => {
    const extractNode = (node) => {
        const matchedLabel = node.labels.find((label) => { var _a; return (label === null || label === void 0 ? void 0 : label.languageCode.slice(0, 2)) === ((_a = props.selectedLanguage) === null || _a === void 0 ? void 0 : _a.languageCode.slice(0, 2)); });
        return matchedLabel ? matchedLabel.value : "";
    };
    return (_jsxs(Dialog, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(RiDeleteBin6Line, {}) })), _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Delete Navigation" }), _jsx(DialogContent, { children: _jsxs("label", { children: ["You are about to delete navigation ", _jsx("b", { children: extractNode(props.node) }), props.node.nodes.length > 0 ? " along with any sub-items it contains" : "", ". Are you sure you want to, continue?"] }) }), _jsxs(DialogActions, Object.assign({ className: "lt-style" }, { children: [_jsx(DeleteButton, Object.assign({ onClick: () => {
                                        props.deleteNode(props.node);
                                    } }, { children: "Delete" })), _jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, Object.assign({ appearance: "secondary" }, { children: "Cancel" })) }))] }))] }) })] }));
};
export const ModalPortal = (props) => {
    return ReactDOM.createPortal(props.children, document.body);
};
