import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useBranding } from "./BrandingContext";
import NavigationComponent from "./NavigationComponent";
import BrandingHeader from "./components/brandingHeader";
import { Dropdown, Field, Input, Label, useId, Option, Tooltip, } from "@fluentui/react-components";
import { Info16Regular } from "@fluentui/react-icons";
const FontFaceStyles = createGlobalStyle `
  @font-face {
    font-family: 'Custom Font';
    src: url(${(props) => props.customFontURL}) format(${(props) => { var _a; return (_a = props.customFontURL) === null || _a === void 0 ? void 0 : _a.split(".").pop(); }});
  }
`;
const SampleText = styled.div.withConfig({ displayName: "SampleText", componentId: "-b9az1l" }) `
  font-family: ${(props) => props.font === "Sharepoint Default Font" ? props.defaultFont : props.font};
  font-synthesis: weight;
  font-weight: 400;
  line-height: 1.9rem;
`;
const Title1 = styled(SampleText).withConfig({ displayName: "Title1", componentId: "-1fvagsv" }) `
  font-size: 26px;
  font-weight: 700;
`;
const Title2 = styled(SampleText).withConfig({ displayName: "Title2", componentId: "-4h4v5u" }) `
  font-size: 22px;
`;
const Subtitle = styled(SampleText).withConfig({ displayName: "Subtitle", componentId: "-1ebigwv" }) `
  font-size: 16px;
`;
const BodyBold = styled(SampleText).withConfig({ displayName: "BodyBold", componentId: "-lqal22" }) `
  font-size: 12px;
  font-weight: 700;
`;
const BodyRegular = styled(SampleText).withConfig({ displayName: "BodyRegular", componentId: "-ba3ktb" }) `
  font-size: 12px;
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-z8xbbr" }) `
  display: flex;
  flex-direction: column;

  &.mode-wizard {
    min-height: calc(100vh - 200px);
  }
`;
const InnerContainer = styled.div.withConfig({ displayName: "InnerContainer", componentId: "-q4mwb1" }) `
  display: flex;
  flex-direction: row;
`;
const SelectionArea = styled.div.withConfig({ displayName: "SelectionArea", componentId: "-1ncwlcp" }) `
  flex: 1;
  padding: 20px;
  padding-left: 0;
`;
const PreviewArea = styled.div.withConfig({ displayName: "PreviewArea", componentId: "-jvc48m" }) `
  flex: 2;
  padding: 20px;
`;
const PreviewAreaTitle = styled.div.withConfig({ displayName: "PreviewAreaTitle", componentId: "-6a9dwh" }) `
  font-size: 16px;
  color: #3d3a3b;
  font-weight: 600;
`;
const PreviewAreaSubTitle = styled.div.withConfig({ displayName: "PreviewAreaSubTitle", componentId: "-110fzyc" }) `
  margin-top: 16px;
  font-size: 14px;
  color: #cccccc;
`;
const CustomLabel = styled(Label).withConfig({ displayName: "CustomLabel", componentId: "-h4kbqg" }) `
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;
const StyledInfo16Regular = styled(Info16Regular).withConfig({ displayName: "StyledInfo16Regular", componentId: "-17obpie" }) `
  margin: 2px 5px;
`;
const FontManager = (props) => {
    const customInputId = useId("input");
    const fontSelectInputId = useId("input");
    const { selectedTheme, setSelectedTheme } = useBranding();
    const [selectedFont, setSelectedFont] = useState({ key: "", text: "" });
    const [customFontURL, setCustomFontURL] = useState(selectedTheme.themeSettings.font.customFontURL);
    const tooltipContentId = useId("tooltipContent");
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const [fontOptions, setFontOptions] = useState([
        { key: "default", text: "Sharepoint Default Font" },
        { key: "custom", text: "Custom Font" },
        { key: "arial", text: "Arial" },
        { key: "courierNew", text: "Courier New" },
        { key: "georgia", text: "Georgia" },
        { key: "impact", text: "Impact" },
        { key: "lucidaSans", text: "Lucida Sans" },
        { key: "tahoma", text: "Tahoma" },
        { key: "timesNewRoman", text: "Times New Roman" },
        { key: "verdana", text: "Verdana" },
    ]);
    const defaultFont = '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif';
    React.useEffect(() => {
        if (!selectedTheme ||
            !selectedTheme.themeSettings ||
            !selectedTheme.themeSettings.font)
            return;
        const fontFamilyText = Object.keys(fontOptions).find((key) => fontOptions[key].text == selectedTheme.themeSettings.font.fontFamily);
        if (fontFamilyText) {
            setSelectedFont(fontOptions[fontFamilyText]);
        }
    }, []);
    const handleFontSelectChange = (ev, item) => {
        setSelectedFont({
            key: item.optionValue,
            text: item.optionText,
        });
        setSelectedTheme((prevTheme) => {
            const updatedTheme = Object.assign({}, prevTheme);
            updatedTheme.themeSettings.font = { fontFamily: item.optionText };
            return updatedTheme;
        });
    };
    const handleCustomFontURLChange = (event) => {
        setCustomFontURL(event.target.value);
        setSelectedTheme((prevTheme) => {
            const updatedTheme = Object.assign({}, prevTheme);
            updatedTheme.themeSettings.font.customFontURL = event.target.value;
            return updatedTheme;
        });
    };
    return (_jsx("div", { children: _jsxs(Container, Object.assign({ className: props.wizardMode ? "mode-wizard" : "mode-edit" }, { children: [props.wizardMode ? (_jsx(BrandingHeader, { title: "Select a font family", summary: "Select or upload a font family to be applied on theme.", advancedSettings: true })) : null, _jsxs(InnerContainer, { children: [_jsxs(SelectionArea, { children: [_jsx(CustomLabel, Object.assign({ id: fontSelectInputId }, { children: "Choose a font" })), _jsx(Field, { children: _jsx(Dropdown, Object.assign({ "aria-labelledby": fontSelectInputId, id: fontSelectInputId, onOptionSelect: handleFontSelectChange, placeholder: selectedFont.text ? selectedFont.text : "Select a font", selectedOptions: [selectedFont.key], defaultValue: selectedFont.text, defaultSelectedOptions: [selectedFont.text] }, { children: fontOptions.map((option) => (_jsx(Option, Object.assign({ value: option.key }, { children: option.text }), option.key))) })) }), _jsx("br", {}), _jsxs(CustomLabel, Object.assign({ htmlFor: customInputId, disabled: selectedFont.text !== "Custom Font", "aria-owns": tooltipVisible ? tooltipContentId : undefined }, { children: ["Custom Font URL", _jsx(Tooltip, Object.assign({ content: {
                                                children: "This URL must link directly to a browser suppoorted font file (TTF, OTF, WOFF & WOFF2).",
                                                id: tooltipContentId,
                                            }, positioning: "above", withArrow: true, relationship: "label", onVisibleChange: (e, data) => setTooltipVisible(data.visible) }, props, { children: _jsx(StyledInfo16Regular, { tabIndex: 0 }) }))] })), _jsx(Field, { children: _jsx(Input, { id: customInputId, value: customFontURL, onChange: handleCustomFontURLChange, disabled: selectedFont.text !== "Custom Font" }) })] }), _jsxs(PreviewArea, { children: [_jsx(FontFaceStyles, { customFontURL: customFontURL }), _jsx(PreviewAreaTitle, { children: "Application preview" }), _jsx(PreviewAreaSubTitle, { children: "Title 1" }), _jsx(Title1, Object.assign({ font: selectedFont.text, defaultFont: defaultFont }, { children: "This is how your title will look like on your Intranet" })), _jsx(PreviewAreaSubTitle, { children: "Title 2" }), _jsx(Title2, Object.assign({ font: selectedFont.text, defaultFont: defaultFont }, { children: "This is how your title will look like on your Intranet" })), _jsx(PreviewAreaSubTitle, { children: "Subtitle" }), _jsx(Subtitle, Object.assign({ font: selectedFont.text, defaultFont: defaultFont }, { children: "This is how your subtitle title will look like on your Intranet" })), _jsx(PreviewAreaSubTitle, { children: "Body Bold" }), _jsx(BodyBold, Object.assign({ font: selectedFont.text, defaultFont: defaultFont }, { children: "This is how your bold body text will look like on your Intranet" })), _jsx(PreviewAreaSubTitle, { children: "Body Regular" }), _jsx(BodyRegular, Object.assign({ font: selectedFont.text, defaultFont: defaultFont }, { children: "This is how your regular body text will look like on your Intranet" }))] })] }), props.wizardMode ? (_jsx(NavigationComponent, { currentStepName: "Font" })) : null] })) }));
};
export default FontManager;
