import styled from "styled-components";
export const PathwaysWrapper = styled.div.withConfig({ displayName: "PathwaysWrapper", componentId: "-fm2aqv" }) `
  display: flex;
  flex-direction: column;
  grid-column: -2 / -1;
  padding-left: 2rem;
`;
export const PathwaysListWrapper = styled.div.withConfig({ displayName: "PathwaysListWrapper", componentId: "-nhtruz" }) `
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lt-blue-lighter);
  border-radius: 0 0 4px 4px;
  height: calc(50vh - 119px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e1e7ec;
    padding-block: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bdceda;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9fb6c7;
  }
`;
export const PathwaysHeader = styled.div.withConfig({ displayName: "PathwaysHeader", componentId: "-1xydp7p" }) `
  padding: 1rem;
  border: 1px solid var(--lt-blue-lighter);
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  font-weight: 700;
  color: #0f2c42;
`;
export const PathWrapper = styled.div.withConfig({ displayName: "PathWrapper", componentId: "-11qivjk" }) `
  display: flex;
  border-bottom: 1px solid var(--lt-blue-lighter);
  padding: 0.75rem 1rem;
`;
export const PathItemLabel = styled.div.withConfig({ displayName: "PathItemLabel", componentId: "-l99xa2" }) `
  padding: 6px 10px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid var(--lt-blue-lighter);

  & + & {
    margin-left: 1rem;
  }

  .newPath & {
    background-color: var(--lt-purple-bg-lighter);
    border-color: var(--lt-purple);
    color: var(--lt-purple);
  }

  .isSelected & {
    background: white;
  }
`;
export const Separator = styled.div.withConfig({ displayName: "Separator", componentId: "-1uvbspp" }) `
  display: flex;
  height: 1px;
  width: 1rem;
  position: relative;
  top: 50%;
  border-bottom: 1px solid var(--lt-blue-lighter);
`;
export const PathwaysListNotice = styled.div.withConfig({ displayName: "PathwaysListNotice", componentId: "-40by28" }) `
  margin: auto;
  padding: 1rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  opacity: 0.5;
  text-align: center;
`;
