import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled } from "styled-components";
import { Persona } from "@fluentui/react-components";
const UserPersonaContainer = styled.div.withConfig({ displayName: "UserPersonaContainer", componentId: "-3p7ksq" }) `
  align-items: center;
  display: flex;
  gap: 8px;
`;
const PersonaLabel = styled.span.withConfig({ displayName: "PersonaLabel", componentId: "-1gdofe1" }) `
  font-size: 12px;
  font-weight: 700;
`;
const UserPersonaDivider = styled.div.withConfig({ displayName: "UserPersonaDivider", componentId: "-jp16r7" }) `
  background-color: #cccccc;
  border-radius: 50%;
  height: 6px;
  width: 6px;
`;
const UserPersonaEmailLabel = styled.span.withConfig({ displayName: "UserPersonaEmailLabel", componentId: "-kn4xk1" }) `
  color: #707070;
  font-size: 12px;
  font-weight: 400;
`;
const UserPersona = ({ status, user: { firstName, lastName, email, image }, }) => {
    return (_jsxs(UserPersonaContainer, { children: [_jsx(Persona, { avatar: { image: { src: image } }, presence: !!status ? { status } : undefined, primaryText: _jsxs(PersonaLabel, { children: [firstName, " ", lastName] }), textAlignment: "center" }), _jsx(UserPersonaDivider, {}), _jsx(UserPersonaEmailLabel, { children: email })] }));
};
export default UserPersona;
