var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../../../lt-style/lt-style.css";
import * as React from "react";
import { SaveAndPublish } from "../components/SaveAndPublish/SaveAndPublish";
import { MegaMenuAPIService } from "../util/MegaMenuAPIService/MegaMenuAPIService";
import { Container, Editor, GlobalStyle, HeaderContainer, } from "../Nav.styles";
import { MatrixMenuWebpartSection } from "../MatrixMenuWebpart/MatrixMenuWebpartSection";
import { ClientRegistrationAPIService } from "../util/ClientRegistrationAPI/ClientRegistrationAPI";
import { draftStatus, successCode, } from "../util/constants";
import { AutorizationService } from "../util/AutorizationService/AutorizationService";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeaderMatrix from "../components/header/headerMatrix";
export const TopMenuItems = styled.div.withConfig({ displayName: "TopMenuItems", componentId: "-cbmgrf" }) `
  display: flex;
  flex-direction: row;
  color: var(--sidebar-color);
  margin-bottom: 1rem;
`;
export const TopMenuItem = styled(Link).withConfig({ displayName: "TopMenuItem", componentId: "-v6bfm4" }) `
  display: inline-flex;
  padding: 10px var(--default-gutter);
  cursor: pointer;
  color: #5c5653;
  text-decoration: none;
  &:hover {
    background: var(--sidebar-bg-highlight);
  }

  &.active {
    background: var(--sidebar-bg-highlight);
    font-weight: 600;
    border-bottom: 4px solid #512da8;
  }

  &:first-of-type {
    border-top: 0;
  }
  & > svg {
    margin-right: 10px;
    margin-top: 2px;
  }
`;
function NavigationMatrix(props) {
    const [view, setView] = React.useState(3);
    const [menuType, setMenuType] = React.useState("configuration");
    const [selectedNode, setSelectedNode] = React.useState(null);
    const [selectedFirstLevelNode, setSelectedFirstLevelNode] = React.useState(null);
    const [selectedSecondLevelNode, setSelectedSecondLevelNode] = React.useState(null);
    const [selectedThirdLevelNode, setSelectedThirdLevelNode] = React.useState(null);
    const [selectedFourthLevelNode, setSelectedFourthLevelNode] = React.useState(null);
    const [activeGeneralViewNode, setActiveGeneralViewNode] = React.useState(null);
    const [matrixPathUrl, setMatrixPathUrl] = React.useState("");
    const [isConfigurationVisible, setIsConfigurationVisible] = React.useState(true);
    const [activeItem, setActiveItem] = React.useState(3);
    const handleItemClick = (index) => {
        setActiveItem(index);
        setView(index);
    };
    const startingMatrixMenu = {
        status: draftStatus,
        dimensions: [],
        paths: [],
    };
    const [menu, setMenu] = React.useState(null);
    const [matrixMenu, setMatrixMenu] = React.useState(null);
    const [isClientRegistered, setIsClientRegistered] = React.useState(undefined); //we start with undefined as we will the set the value after the check
    const [isEditor, setisEditor] = React.useState(undefined);
    const [isAdmin, setisAdmin] = React.useState(undefined);
    const [hasError, sethasError] = React.useState(false);
    const [isLoading, setisLoading] = React.useState(false);
    const userString = sessionStorage.getItem("User");
    const userRegion = sessionStorage.getItem("Region");
    React.useEffect(() => {
        const user = JSON.parse(userString);
        AutorizationService.IsAdmin().then((hasAdminRoles) => {
            setisAdmin(hasAdminRoles);
        });
        AutorizationService.IsEditor().then((hasEditorRoles) => {
            setisEditor(hasEditorRoles);
        });
        function fetchRegistration() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const response = yield ClientRegistrationAPIService.getClientRegistration(user.tenantId);
                    if (response) {
                        sessionStorage.setItem("Region", response.region);
                        setIsClientRegistered(true);
                    }
                    else {
                        setIsClientRegistered(false);
                    }
                }
                catch (error) {
                    sethasError(true);
                }
                return;
            });
        }
        fetchRegistration();
    }, []);
    React.useEffect(() => {
        const user = JSON.parse(userString);
        if (userRegion == null || userRegion == undefined) {
            return;
        }
        const response = MegaMenuAPIService.GetMatrixNavigation(user.tenantId, userRegion);
        response.then((r) => {
            r.json()
                .then((data) => {
                data != null
                    ? setMatrixMenu(data)
                    : setMatrixMenu(startingMatrixMenu);
            })
                .catch((error) => {
                console.log("error", error);
                setMatrixMenu(startingMatrixMenu);
            });
        });
    }, [isClientRegistered]);
    function updateNode(nodes, newNode) {
        nodes.forEach((n) => {
            const index = nodes.indexOf(selectedNode);
            if (index !== -1) {
                nodes[index] = newNode;
            }
            updateNode(n.nodes, newNode);
        });
    }
    function applyDeletion(nodes, nodeToDelete) {
        nodes.forEach((n) => {
            const index = nodes.indexOf(nodeToDelete);
            if (index !== -1) {
                nodes.splice(index, 1);
            }
            applyDeletion(n.nodes, nodeToDelete);
        });
    }
    function saveMatrix() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield MegaMenuAPIService.saveMatrix(matrixMenu, sessionStorage.getItem("Region"));
            const data = yield response.json();
            if (response.status !== successCode) {
                return;
            }
            const matrixFromServer = {
                id: data.id,
                created: data.created,
                createdBy: data.createdBy,
                modified: data.modified,
                status: data.status,
                modifiedBy: data.modifiedBy,
                dimensions: data.dimensions,
                paths: data.paths,
            };
            setMatrixMenu(matrixFromServer);
        });
    }
    function publishMatrix() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield MegaMenuAPIService.publishMatrix(matrixMenu, sessionStorage.getItem("Region"));
            const data = yield response.json();
            const matrixFromServer = {
                id: data.id,
                created: data.created,
                createdBy: data.createdBy,
                modified: data.modified,
                status: data.status,
                modifiedBy: data.modifiedBy,
                dimensions: data.dimensions,
                paths: data.paths,
            };
            setMatrixMenu(matrixFromServer);
        });
    }
    function revertMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            const user = JSON.parse(userString);
            const userRegion = sessionStorage.getItem("Region");
            MegaMenuAPIService.GetMatrixNavigation(user.tenantId, userRegion).then((response) => {
                response
                    .json()
                    .then((data) => {
                    const MatrixFromServer = {
                        id: data.id,
                        created: data.created,
                        createdBy: data.createdBy,
                        modified: data.modified,
                        status: data.status,
                        modifiedBy: data.modifiedBy,
                        dimensions: data.dimensions,
                        paths: data.paths,
                    };
                    setMatrixMenu(MatrixFromServer);
                })
                    .catch((error) => {
                    console.log("error", error);
                    setMatrixMenu(startingMatrixMenu);
                });
            });
        });
    }
    const hasValue = (item) => {
        if (item == true || item == false)
            return true;
        else
            return false;
    };
    return (_jsxs(Container, Object.assign({ className: `menutype-${menuType}` }, { children: [_jsx(GlobalStyle, {}), _jsx(HeaderContainer, { children: _jsx(HeaderMatrix, {}) }), _jsx(SaveAndPublish, { revertMenu: () => __awaiter(this, void 0, void 0, function* () { return yield revertMenu(); }), saveMenu: () => __awaiter(this, void 0, void 0, function* () { return yield saveMatrix(); }), publishMenu: () => __awaiter(this, void 0, void 0, function* () { return yield publishMatrix(); }), isMatrix: true }), matrixMenu && (_jsxs(Editor, { children: [_jsxs(TopMenuItems, { children: [_jsx(TopMenuItem, Object.assign({ to: "", className: `${activeItem === 3 ? "active" : ""}`, onClick: () => handleItemClick(3) }, { children: "Content" })), _jsx(TopMenuItem, Object.assign({ to: "", className: `${activeItem === 4 ? "active" : ""}`, onClick: () => handleItemClick(4) }, { children: "Pathways" }))] }), _jsx(MatrixMenuWebpartSection, { updateMenu: (menu) => {
                            setMatrixMenu(menu);
                        }, view: view, matrixPathUrl: matrixPathUrl, matrixMenu: matrixMenu })] }))] })));
}
export default NavigationMatrix;
