import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { SuggestionBox } from "../SuggestionBox/SuggestionBox";
import { PickedUser } from "./PickedUser";
import debounce from "lodash.debounce";
import { MegaMenuAPIService } from "../../../util/MegaMenuAPIService/MegaMenuAPIService";
import SearchIconButton from "/public/Search.svg";
import ChevronIcon from "/public/Chevron.svg";
import { Input } from "@fluentui/react-components";
const PickerWrapper = styled.div.withConfig({ displayName: "PickerWrapper", componentId: "-1tvhw0t" }) `
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 10px;
  margin-left: -3px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: var(--border-radius);
`;
const PickerList = styled.div.withConfig({ displayName: "PickerList", componentId: "-q81kru" }) `
  display: flex;
`;
const PickerInput = styled(Input).withConfig({ displayName: "PickerInput", componentId: "-b747m2" }) `
  width: 100%;
`;
const PickerInputInvite = styled.input.withConfig({ displayName: "PickerInputInvite", componentId: "-1euvtol" }) `
  color: var(--form-text);
  background-color: var(--background);
  font-family: inherit;
  font-size: 14px;
  position: relative;
  margin-block: 0.5rem;
  //padding-top: 15px;
  padding-left: 15px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  appearance: none;
`;
export var IdentityType;
(function (IdentityType) {
    IdentityType[IdentityType["User"] = 0] = "User";
    IdentityType[IdentityType["Group"] = 1] = "Group";
})(IdentityType || (IdentityType = {}));
const PickedUsersWrapper = styled.div.withConfig({ displayName: "PickedUsersWrapper", componentId: "-12j5ije" }) `
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
`;
export const SpinnerWrapper = styled.div.withConfig({ displayName: "SpinnerWrapper", componentId: "-3td5wc" }) `
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Spinner = styled.div.withConfig({ displayName: "Spinner", componentId: "-pfk9u7" }) `
  top: calc(50% - 12.5px);
  position: relative;
  border: 1px solid #f3f3f3;
  border-top: 1px solid #a7a7a7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const SearchIcon = styled.img.withConfig({ displayName: "SearchIcon", componentId: "-187wyz4" }) `
  padding-top: 0px;
  font-size: 16px;
  width: 22px;
  margin-left: 10px;
`;
export const FromInviteWrapper = styled.div.withConfig({ displayName: "FromInviteWrapper", componentId: "-29b249" }) `
  display: flex;
  width: 100%;
  border-radius: 3px;
  border: 1px solid ${(p) => (p.activeInviteInput ? "#512DA8" : "#3D3A3B")};
  margin-top: -10px;
  margin-left: 3px;
`;
export const PickerTitle = styled.span.withConfig({ displayName: "PickerTitle", componentId: "-81r9wc" }) `
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #242424;
`;
const SelectedUsersWrapper = styled.div.withConfig({ displayName: "SelectedUsersWrapper", componentId: "-7mbhpv" }) `
  width: 100%;
  display: grid;
`;
const UserTitle = styled.div.withConfig({ displayName: "UserTitle", componentId: "-xk1i50" }) `
  padding-bottom: 20px;
  padding-left: 2px;
  color: var(--NuetralSecondary, #707070);
  font-feature-settings: "clig" off, "liga" off;
  /* bodyText/Regular */
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
`;
const ChevronImg = styled.img.withConfig({ displayName: "ChevronImg", componentId: "-12ubru" }) `
  padding-right: 10px;
`;
export const Picker = (props) => {
    const [activeInviteInput, setActiveInviteInput] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [identityType, setIdentityType] = React.useState(props.identityType);
    const [filteredUsers, setFilteredUsers] = React.useState(null);
    const [selectedUsers, setSelectedUsers] = React.useState(props.selectedUsers);
    const [isPendingUsers, setIsPendingUsers] = React.useState(props.isPendingUsers);
    const [showSuggestionBox, setShowSuggestionBox] = React.useState(false);
    const useDebounce = (callback) => {
        const ref = React.useRef();
        React.useEffect(() => {
            ref.current = callback;
        }, [callback]);
        const debouncedCallback = React.useMemo(() => {
            const func = () => {
                //@ts-ignore
                ref.current();
            };
            return debounce(func, 1200);
        }, []);
        return debouncedCallback;
    };
    const debounceCall = useDebounce(() => {
        if (searchText.length !== 0) {
            MegaMenuAPIService.getFilteredEntities(searchText, identityType).then((result) => {
                setFilteredUsers(result);
            });
        }
    });
    React.useEffect(() => {
        setSelectedUsers(props.selectedUsers);
        setSearchText("");
        setFilteredUsers(null);
        setIsPendingUsers(props.isPendingUsers);
    }, [props.selectedUsers, props.isPendingUsers]);
    function onInputChange(e) {
        const value = e.target.value;
        if (value === "") {
            setFilteredUsers(null);
        }
        setSearchText(value);
        setShowSuggestionBox(true);
        debounceCall();
    }
    function userAlreadyAdded(user) {
        const foundUsers = selectedUsers.filter((u) => u.id === user.id);
        return foundUsers.length !== 0;
    }
    function addSuggestion(user) {
        if (!userAlreadyAdded(user)) {
            setShowSuggestionBox(false);
            const newUsers = selectedUsers ? [...selectedUsers] : [];
            newUsers.push(user);
            setSelectedUsers(newUsers);
            props.setSelectedUsers(newUsers);
        }
    }
    function removeUser(user) {
        setIsPendingUsers(true);
        props.deleteUser(user);
    }
    //condition if removed show
    return (_jsxs(_Fragment, { children: [_jsxs(PickerWrapper, Object.assign({ className: "lt-style" }, { children: [!props.isFromInvite && (_jsx(PickerList, { children: _jsx(PickedUsersWrapper, { children: !isPendingUsers ? (selectedUsers.map((s) => {
                                return _jsx(PickedUser, { removeUser: removeUser, user: s });
                            })) : (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}) })) }) })), props.isFromInvite ? (_jsxs(_Fragment, { children: [_jsx(UserTitle, { children: "Users" }), _jsxs(FromInviteWrapper, Object.assign({ activeInviteInput: activeInviteInput }, { children: [_jsx(SearchIcon, { src: SearchIconButton }), _jsx(PickerInputInvite, { onFocus: () => {
                                            setActiveInviteInput(true);
                                        }, onBlur: () => {
                                            setActiveInviteInput(false);
                                        }, value: searchText, onChange: onInputChange, placeholder: "Search for users" }), _jsx(ChevronImg, { src: ChevronIcon })] }))] })) : (_jsx(PickerInput, { value: searchText, onChange: onInputChange, placeholder: "Search for users by entering at least three characters..." }))] })), searchText.length !== 0 && showSuggestionBox && (_jsx(SuggestionBox, { isFromInvite: props.isFromInvite, addSuggestion: addSuggestion, filteredUsers: filteredUsers })), props.isFromInvite && (_jsx(_Fragment, { children: _jsx(PickerList, { children: _jsxs(PickedUsersWrapper, { children: [selectedUsers.length !== 0 ? (_jsx(PickerTitle, { children: "Selected Users" })) : null, _jsx(SelectedUsersWrapper, { children: !isPendingUsers ? (selectedUsers.map((s) => {
                                    return (_jsx(PickedUser, { isFromInvite: props.isFromInvite, removeUser: removeUser, user: s }));
                                })) : (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}) })) })] }) }) }))] }));
};
