const intranetSubmenu = [
    {
        to: "/intranet/audiences",
        children: "Audiences",
    },
    {
        to: "/intranet/branding",
        children: "Branding",
    },
    {
        to: "/intranet/governance",
        children: "Governance",
    },
    {
        to: "/intranet/metadata",
        children: "Metadata",
    },
    {
        to: "/intranet/navigation",
        children: "Navigation",
    },
    {
        to: "/intranet/policies",
        children: "Policies",
    },
    {
        to: "/intranet/quicklinks",
        children: "Quick Links",
    },
    {
        to: "/intranet/viva",
        children: "Viva Parts",
    },
    {
        to: "/intranet/webparts",
        children: "WebParts",
    },
    {
        to: "/intranet/workspaces",
        children: "Workspaces",
    },
];
const settings = [
    {
        to: "/settings/useraccess",
        children: "User Access",
    },
];
const analytics = [
    {
        to: "/analytics/sharepoint",
        children: "SharePoint",
    },
    {
        to: "/analytics/reach",
        children: "Reach",
    },
];
const everywhere = [
    {
        to: "/everywhere/general",
        children: "General",
    },
    {
        to: "/everywhere/assistant",
        children: "Assistant",
    },
    {
        to: "/everywhere/myarea",
        children: "My Area",
    },
];
export const submenuData = {
    intranet: intranetSubmenu,
    settings: settings,
    analytics: analytics,
    everywhere: everywhere,
};
