import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
const MessageContainer = styled.div.withConfig({ displayName: "MessageContainer", componentId: "-1kkhr4c" }) `
  display: flex;
  margin-left: 35%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  padding: 1.5rem;
  border-radius: var(--border-radius, 4px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: white;
`;
const AccessDenied = () => {
    return (_jsxs(MessageContainer, { children: [_jsx("h1", { children: "Access Denied!" }), _jsx(_Fragment, { children: _jsx("h3", Object.assign({ style: { fontSize: "smaller", fontWeight: "inherit" } }, { children: "Please request access from the Administrator." })) })] }));
};
export default AccessDenied;
