export const formSteps = [
    {
        name: "Name",
        route: "/intranet/branding/name",
    },
    {
        name: "Site Collections",
        route: "/intranet/branding/site-collections",
    },
    {
        name: "Color",
        route: "/intranet/branding/color",
    },
    {
        name: "Logo",
        route: "/intranet/branding/logo",
    },
    {
        name: "Border",
        route: "/intranet/branding/border",
    },
    {
        name: "Text Options",
        route: "/intranet/branding/text-options",
    },
    {
        name: "Submenu Type",
        route: "/intranet/branding/submenu-type",
    },
    {
        name: "Font",
        route: "/intranet/branding/font",
    },
    {
        name: "Livetiles Webparts - Base",
        route: "/intranet/branding/livetiles-webparts-base",
    },
    {
        name: "Livetiles Webparts - Item",
        route: "/intranet/branding/livetiles-webparts-item",
    },
];
