import {toc} from 'mdast-util-toc';

export default function customToc(options) {

  let settings = options || {};
  let depth = settings.maxDepth || 3;
  let tight = settings.tight;
  let skip = settings.skip;

  return transformer;

  function transformer(node) {
    let result = toc(node, {
      maxDepth: depth,
      tight: tight,
      skip: skip,
    });

    if (result.index === null || result.index === -1 || !result.map) {
      return;
    }

    node.children = [].concat(
      result.map,
      node.children.slice(result.endIndex),
    );

    node.children[0] = {
      type: 'div',
      children: [node.children[0]],
      data: {
        hProperties: {
          className: 'toc',
        },
      },
    };

    node.children[1] = {
      type: 'div',
      children: node.children.slice(1),
      data: {
        hProperties: {
          className: 'content',
        },
      },
    };

    node.children.splice(2);  
  }
}
