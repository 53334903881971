import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { StylingView } from "./StylingView";
import { LogoContainer } from "./StylingView.styles";
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "-1dz245e" }) `
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  width: 100%;
`;
const MegaMenuViewWrapper = styled.div.withConfig({ displayName: "MegaMenuViewWrapper", componentId: "-atbpau" }) `
  position: relative;
  z-index: 1050;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--lt-first-level-background, white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 60px;
`;
export const StylingWrapper = (props) => {
    const [logoImagePath, setLogoImagePath] = React.useState("");
    const [logoLinkUrl, setLogoLinkUrl] = React.useState("");
    React.useEffect(() => {
        imageExists(props.megaMenuItem.siteCollection[props.selectedMenuIndex].styles.logoUrl, props.megaMenuItem.siteCollection[props.selectedMenuIndex].styles.logoLinkUrl);
    }, []);
    const imageExists = (image_url, image_link_url) => {
        if (image_url !== null || image_url !== "") {
            setLogoImagePath(image_url);
        }
        if (image_link_url !== null || image_link_url !== "") {
            setLogoLinkUrl(image_link_url);
        }
    };
    return (_jsx(Wrapper, { children: _jsxs(MegaMenuViewWrapper, Object.assign({ className: `lt-megamenu-wrapper style-${props.megaMenuItem.siteCollection[0].styles.menuStyle ? props.megaMenuItem.siteCollection[0].styles.menuStyle : "compact"}`, role: "menu" }, { children: [logoImagePath !== "" && logoImagePath !== null && (_jsx(LogoContainer, { children: logoLinkUrl !== "" ? (_jsx("a", Object.assign({ href: logoLinkUrl !== "" ? logoLinkUrl : window.location.origin }, { children: _jsx("img", { src: logoImagePath, alt: "Logo" }) }))) : (_jsx("img", { src: logoImagePath, alt: "Logo" })) })), _jsx(StylingView, { megaMenuItem: props.megaMenuItem, selectedMenuIndex: props.selectedMenuIndex, generalOptions: props.megaMenuItem.generalOptions, level: 1, menuStyle: props.megaMenuItem.siteCollection[0].styles.menuStyle ? props.megaMenuItem.siteCollection[0].styles.menuStyle : "compact" })] })) }));
};
