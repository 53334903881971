import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { draftUpper, publishedStatus, publishedUpper, } from "../../util/constants";
import { Button, Spinner } from "@fluentui/react-components";
import { ArrowSyncRegular } from "@fluentui/react-icons";
const SaveAndPublishWrapper = styled.div.withConfig({ displayName: "SaveAndPublishWrapper", componentId: "-14rh5eg" }) `
  grid-area: actions;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding: 0 var(--default-gutter);
  background: var(--header-bg);
  color: var(--header-color);

  .lt-style {
    margin-block: 0;
  }
`;
const MenuStatus = styled.span.withConfig({ displayName: "MenuStatus", componentId: "-lqv2ul" }) `
  font-weight: 600;
  color: ${(p) => (p.$isPublished ? "green" : "orange")};
  border-right: 1px solid var(--border-color);
  margin-right: 10px;
  padding-right: 10px;
`;
const SaveButton = styled(Button).withConfig({ displayName: "SaveButton", componentId: "-1xrmeq9" }) `
  && {
    color: var(--purple);
    border-color: var(--purple);
  }
`;
const RevertButton = styled(Button).withConfig({ displayName: "RevertButton", componentId: "-106kn8q" }) `
  && {
    color: var(--red-lighter-text);
    border: 1px solid var(--red-lighter-text);
    background-color: var(--red-lighter-bg);
    margin-right: .5rem;
  }
`;
export const SaveAndPublish = (props) => {
    function isPublished() {
        if (!props.siteCollection) {
            return false;
        }
        const version = props.siteCollection.version;
        if (version) {
            return version.includes(".0");
        }
        else {
            return props.siteCollection.status === publishedStatus;
        }
    }
    const isMenuPublished = isPublished();
    return (_jsx(SaveAndPublishWrapper, { children: props.isLoading ? (_jsx(Spinner, { size: "small", label: "Processing..." })) : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ style: {
                        borderRight: "1px solid var(--border-color)",
                        marginRight: "10px",
                        paddingRight: "10px",
                    } }, { children: [!props.isMatrix && (_jsx(MenuStatus, Object.assign({ "$isPublished": isMenuPublished, className: "lt-style" }, { children: isMenuPublished ? publishedUpper : draftUpper }))), _jsx(RevertButton, Object.assign({ onClick: () => {
                                props.revertMenu();
                            }, icon: _jsx(ArrowSyncRegular, {}) }, { children: "Revert" })), _jsx(SaveButton, Object.assign({ onClick: () => {
                                props.saveMenu();
                            } }, { children: "Save" }))] })), _jsx(Button, Object.assign({ appearance: "primary", onClick: () => {
                        props.publishMenu();
                    } }, { children: "Publish" }))] })) }));
};
