import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { Button, Dialog } from "@fluentui/react-components";
import { DeleteRegular, ImageGlobeRegular, PeopleTeamRegular, ShapesRegular, } from "@fluentui/react-icons";
import EditQuicklinkGroupModal from "./modals/EditQuicklinkGroupModal";
const MassActionsContainer = styled.div.withConfig({ displayName: "MassActionsContainer", componentId: "-1wdp4f5" }) `
  align-items: center;
  background: rgba(81, 45, 168, 0.1);
  display: flex;
  height: 44px;
`;
const MassActionsButton = styled(Button).withConfig({ displayName: "MassActionsButton", componentId: "-qrr1oj" }) `
  font-weight: 400;
`;
const VerticalDivider = styled.div.withConfig({ displayName: "VerticalDivider", componentId: "-1e56vl7" }) `
  background: #cccccc;
  height: 20px;
  margin: 0 24px;
  width: 1px;
`;
const QuicklinkGroupActions = ({ category, links, onQuicklinksDelete, }) => {
    const [quicklinkGroupActionProperty, setQuicklinkGroupActionProperty] = useState();
    /* Mass action handlers */
    const handleSelectionAudienceChange = () => {
        setQuicklinkGroupActionProperty("audience");
    };
    const handleSelectionCategoryChange = () => {
        setQuicklinkGroupActionProperty("category");
    };
    const handleSelectionIconChange = () => {
        setQuicklinkGroupActionProperty("icon");
    };
    const handleEditQuicklinkGroupModalClose = () => {
        setQuicklinkGroupActionProperty(undefined);
    };
    return !links || !links.length ? null : (_jsxs(_Fragment, { children: [_jsxs(MassActionsContainer, { children: [_jsx(MassActionsButton, Object.assign({ appearance: "transparent", icon: _jsx(PeopleTeamRegular, {}), onClick: handleSelectionAudienceChange }, { children: "Change audience" })), _jsx(MassActionsButton, Object.assign({ appearance: "transparent", icon: _jsx(ShapesRegular, {}), onClick: handleSelectionCategoryChange }, { children: "Change category" })), _jsx(MassActionsButton, Object.assign({ appearance: "transparent", icon: _jsx(ImageGlobeRegular, {}), onClick: handleSelectionIconChange }, { children: "Change icon" })), _jsx(MassActionsButton, Object.assign({ appearance: "transparent", icon: _jsx(DeleteRegular, {}), onClick: onQuicklinksDelete }, { children: "Delete" })), _jsx(VerticalDivider, {}), links === null || links === void 0 ? void 0 : links.length, " links selected"] }), _jsx(Dialog, Object.assign({ open: !!quicklinkGroupActionProperty, onOpenChange: handleEditQuicklinkGroupModalClose }, { children: _jsx(EditQuicklinkGroupModal, { category: category, links: links, property: quicklinkGroupActionProperty, onClose: handleEditQuicklinkGroupModalClose }) }))] }));
};
export default QuicklinkGroupActions;
