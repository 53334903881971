import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { IdentityType } from "./Picker";
import styled from "styled-components";
import { IoMdCloseCircle } from "react-icons/io";
import { MegaMenuAPIService } from "../../../util/MegaMenuAPIService/MegaMenuAPIService";
import TrashcanIcon from "/public/Trashcan.svg";
import EllipseIcon from "/public/Ellipse.svg";
const PickedUserWrapper = styled.div.withConfig({ displayName: "PickedUserWrapper", componentId: "-ugtsjn" }) `
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;
  padding: ${(p) => (p.isFromInvite ? "null" : "6px 8px")};
  background-color: ${(p) => (p.isFromInvite ? "white" : "#f3f3f3")};
  border-radius: 4px;
  box-shadow: ${(p) => p.isFromInvite ? "null" : " rgba(0, 0, 0, 0.25) 0px 2px 2px"};
`;
const ProfileImage = styled.img.withConfig({ displayName: "ProfileImage", componentId: "-4wjsc6" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
`;
const ProfileWrapper = styled.div.withConfig({ displayName: "ProfileWrapper", componentId: "-10dqzkh" }) `
  display: flex;
`;
const ProfileName = styled.span.withConfig({ displayName: "ProfileName", componentId: "-qqe56e" }) `
  ${(p) => p.isFromInvite
    ? `
  color: var(--NuetralPrimary, #3D3A3B);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Segoe UI;
font-size: 14px;
padding-top: 4px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-wrap:nowrap;
  `
    : ` font-weight: 600;
  min-width: 125px;`}
`;
const ProfileIdentity = styled.span.withConfig({ displayName: "ProfileIdentity", componentId: "-w06k6a" }) `
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.6;
`;
const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "-fkenq6" }) `
  padding-top: ${(p) => (p.isFromInvite ? "6px" : "")};
  display: flex;
  width: 30%;
  justify-content: end;
  padding-right: 5px;
  padding-left: ${(p) => (p.isFromInvite ? "6px" : "")};
  position: ${(p) => (p.isFromInvite ? "absolute" : "")};
  right: ${(p) => (p.isFromInvite ? "0" : "")};
`;
export const MicrosoftPallete = [
    "#A4262C",
    "#CA5010",
    "#8F7034",
    "#407855",
    "#038387",
    "#0078d4",
    "#40587C",
    "#4052AB",
    "#854085",
    "#8764B8",
    "#737373",
    "#867365",
];
const NoImagePlaceHolder = styled.div.withConfig({ displayName: "NoImagePlaceHolder", componentId: "-1myims4" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${(p) => p.color};
  display: flex;
  justify-content: center;
`;
const NoImageName = styled.span.withConfig({ displayName: "NoImageName", componentId: "-10u9y1s" }) `
  color: white;
  padding-top: 2.5px;
  font-weight: 600;
`;
const ProfileDetails = styled.div.withConfig({ displayName: "ProfileDetails", componentId: "-4i6bzy" }) `
  display: flex;
  flex-direction: ${(p) => (p.isFromInvite ? "null" : "column")};
  padding-inline: 8px;
  line-height: 1rem;
`;
const Elipse = styled.img.withConfig({ displayName: "Elipse", componentId: "-1syb64w" }) `
  width: 11px;
  margin-left: 12px;
  margin-right: 17px;
  margin-top: 7px;
`;
const EmailWrapper = styled.div.withConfig({ displayName: "EmailWrapper", componentId: "-1w6axkv" }) `
  color: var(--NuetralSecondary, #707070);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 7px;
`;
const Trashcan = styled.img.withConfig({ displayName: "Trashcan", componentId: "-1guy14t" }) `
  width: 13px;
  height: 14px;
  cursor: pointer;
`;
export const PickedUser = (props) => {
    const [userInfo, setUserInfo] = React.useState(null);
    const [userColor, setUserColor] = React.useState(null);
    React.useEffect(() => {
        var _a;
        if ((_a = props.user) === null || _a === void 0 ? void 0 : _a.displayName)
            setUserInfo(props.user);
        else {
            MegaMenuAPIService.getEntity(props.user.id, props.user.type).then((result) => {
                setUserInfo(result);
            });
        }
    }, []);
    React.useEffect(() => {
        if (userInfo && userInfo.displayName) {
            const lastLetter = userInfo.displayName.charAt(userInfo.displayName.length - 1);
            const index = lastLetter.charCodeAt(0) % MicrosoftPallete.length;
            setUserColor(MicrosoftPallete[index]);
        }
    }, [userInfo]);
    function getNameNoPhoto() {
        if (!userInfo || !userInfo.displayName)
            return null;
        const split = userInfo.displayName.split(" ");
        if (split.length > 1) {
            return split[0].charAt(0) + split[1].charAt(0);
        }
        else {
            return split[0].charAt(0);
        }
    }
    return (_jsx(_Fragment, { children: userInfo && (_jsxs(PickedUserWrapper, Object.assign({ isFromInvite: props.isFromInvite }, { children: [_jsx(ProfileWrapper, { children: userInfo.image ? (_jsx(ProfileImage, { src: userInfo.image })) : (_jsx(NoImagePlaceHolder, Object.assign({ color: userColor }, { children: _jsx(NoImageName, { children: getNameNoPhoto() }) }))) }), _jsxs(ProfileDetails, Object.assign({ isFromInvite: props.isFromInvite }, { children: [_jsx(ProfileName, Object.assign({ isFromInvite: props.isFromInvite }, { children: userInfo.displayName
                                ? userInfo.displayName
                                : "Error Retreiving Data" })), props.isFromInvite ? null : (_jsx(ProfileIdentity, { children: props.user.type === IdentityType.User ? "User" : "Group" })), props.isFromInvite ? _jsx(Elipse, { src: EllipseIcon }) : null, props.isFromInvite ? (_jsx(EmailWrapper, { children: props.user.loginName })) : null] })), _jsx(ButtonWrapper, Object.assign({ isFromInvite: props.isFromInvite }, { children: props.isFromInvite ? (_jsx(Trashcan, { onClick: () => {
                            props.removeUser(props.user);
                        }, src: TrashcanIcon })) : (_jsx(IoMdCloseCircle, { onClick: () => {
                            props.removeUser(props.user);
                        }, style: { cursor: "pointer" }, size: "20px" })) }))] }))) }));
};
