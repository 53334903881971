import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Cards = styled.div.withConfig({ displayName: "Cards", componentId: "-12z6nfo" }) `
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 1.5rem;
  line-height: 1.4rem;
  margin-block: 1rem;

  &.style-inline,
  &.style-inlineCentered {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    max-width: 1300px;
  }

  &.style-inlineCentered {
    justify-content: center;
    margin: auto;
  }
`;
const Card = styled.div.withConfig({ displayName: "Card", componentId: "-b326gy" }) `
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  overflow: hidden;

  .style-inline &,
  .style-inlineCentered & {
    max-width: 360px;
  }

  .card-title {
    display: flex;

    svg {
      margin-inline-end: 0.25rem;
    }
  }

  .card-description {
    margin-block: 0 1rem;
    font-weight: 400;
    opacity: 0.8;
  }
`;
const CardBody = styled.div.withConfig({ displayName: "CardBody", componentId: "-14pcs30" }) `
  display: flex;
  flex-direction: column;
  flex: 1 0 67%;
  padding: 0.5rem 1rem;

  img {
    max-width: 100%;
    margin-block: 0 .5rem;
  }
`;
const CardActions = styled.div.withConfig({ displayName: "CardActions", componentId: "-ypjyns" }) `
  display: flex;
  margin-top: auto;

  &&& {
    > * + * {
      margin-left: 1rem;
    }
  }
`;
const openLink = (link) => {
    window.open(link, "_self");
};
const CardsList = (props) => {
    const cardsAbc = props.source.sort((a, b) => (a.title > b.title ? 1 : -1));
    return (_jsx(Cards, Object.assign({ className: props.layoutStyle ? `style-${props.layoutStyle}` : `` }, { children: cardsAbc.map((card, i) => {
            const Icon = card.icon;
            return (_jsx(Card, { children: _jsxs(CardBody, { children: [_jsxs("h3", Object.assign({ className: "card-title" }, { children: [card.icon && _jsx(Icon, {}), card.title] })), _jsx("p", Object.assign({ className: "card-description" }, { children: card.description })), card.previewScreenshot && (_jsx("img", { src: card.previewScreenshot, alt: "Preview" })), _jsxs(CardActions, { children: [card.downloadUrl && (_jsx("button", Object.assign({ className: "lt-style lt-grey-lighter", onClick: () => openLink(card.downloadUrl) }, { children: "Download" }))), card.docsPage && (_jsx(Link, Object.assign({ to: `/${card.route}` }, { children: _jsx("button", Object.assign({ className: "lt-style lt-grey-lighter" }, { children: "Learn to use it" })) }))), card.settingsPage && (_jsx(Link, Object.assign({ to: `/${card.route}` }, { children: _jsx("button", Object.assign({ className: "lt-style lt-grey-lighter" }, { children: "Go to settings" })) }))), card.editPage && (_jsx(Link, Object.assign({ to: `/${card.route}` }, { children: _jsx("button", Object.assign({ className: "lt-style lt-grey-lighter" }, { children: "Edit" })) })))] })] }) }, i));
        }) })));
};
export default CardsList;
