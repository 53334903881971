import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import BlogList from "../components/BlogList";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-qqyg2b" }) `
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-inline: 1.5rem 3rem;
  min-height: calc(100vh - 160px);
`;
const HeaderWelcome = styled.div.withConfig({ displayName: "HeaderWelcome", componentId: "-x7t1td" }) `
  max-width: 650px;
  padding-block: 4rem;
  line-height: normal;

  h1 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 1rem;

    span {
      font-weight: 700;
    }
  }
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "-1vogc30" }) `
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  flex-direction: column;
  padding-block: 8em 1em;

  h2 {
    font-size: 25px;
    margin-bottom: 0.5rem;
  }
`;
const HeaderImage = styled.div.withConfig({ displayName: "HeaderImage", componentId: "-v1y0pt" }) `
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-height: 50vh;
  max-width: 70vw;
  z-index: -1;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;
    clip-path: ellipse(72% 85% at 67% 13%);
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(80deg, white 20%, transparent 85%);
  }
`;
const SupportButton = styled.button.withConfig({ displayName: "SupportButton", componentId: "-pi1ayc" }) `
  && {
    padding: 10px 18px;
    font-size: 16px;
    margin-block: 1rem;
  }
`;
const openLink = (link) => {
    window.open(link, "_blank");
};
const Home = (props) => {
    return (_jsxs(Container, { children: [_jsx(HeaderImage, { children: _jsx("img", { src: "/images/public/assets/home/home-image.png", alt: "logo" }) }), _jsxs(HeaderWelcome, { children: [_jsxs("h1", { children: ["Hi ", _jsx("span", { children: props.userName }), " \uD83D\uDC4B"] }), _jsx("h1", { children: "Welcome to the LiveTiles Operations Center. Your deployment, centrally managed." }), _jsx(SupportButton, Object.assign({ className: "lt-style lt-purple", onClick: () => openLink("https://support.livetilesglobal.com/hc/en-us/requests/new") }, { children: "Submit a support request" }))] }), _jsx(Content, { children: _jsx(BlogList, {}) })] }));
};
export default Home;
