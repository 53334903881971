import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import intranetWebpartsData from "../../data/intranetWebparts";
import styled from "styled-components";
import CardsList from "../../components/CardsList";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-kiciqj" }) `
  margin-inline-end: 3rem;
`;
const IntranetWebparts = () => {
    return (_jsxs(Container, { children: [_jsx("h1", { children: "Webparts" }), _jsx(CardsList, { source: intranetWebpartsData, path: "/intranet/webparts", layoutStyle: "grid" })] }));
};
export default IntranetWebparts;
