import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { PathwaysWrapper, PathwaysHeader, PathwaysListWrapper, PathItemLabel, Separator, PathWrapper, PathwaysListNotice, } from "./PathwaysList.styles";
import { IconWrapper } from "../../ContentEditor.styles";
import { RiDeleteBin6Line } from "react-icons/ri";
export const PathwaysList = (props) => {
    function filterArray(paths, editPath) {
        const filteredPaths = paths.filter((path) => {
            let selection = path.selection;
            let pathStart = selection.slice(0, editPath.length);
            return pathStart.toString() === editPath.toString();
        });
        return filteredPaths;
    }
    const filteredPaths = filterArray(props.paths, props.editPath);
    function labelLookup(itemId, i) {
        var _a, _b;
        const label = (_b = (_a = props.dimensions[i]) === null || _a === void 0 ? void 0 : _a.items.find((item) => item.itemId === itemId)) === null || _b === void 0 ? void 0 : _b.text;
        return label;
    }
    function deletePath(editPath) {
        props.deletePath(editPath);
    }
    return (_jsx(_Fragment, { children: props.editPath.length > 0 && (_jsxs(PathwaysWrapper, { children: [_jsxs(PathwaysHeader, { children: ["Pathways related to '", labelLookup(props.editPath[0], 0), "'"] }), _jsxs(PathwaysListWrapper, { children: [filteredPaths.length === 0 && (_jsx(PathwaysListNotice, { children: "No pathways found for this selection." })), filteredPaths === null || filteredPaths === void 0 ? void 0 : filteredPaths.map((path, i) => (_jsxs(PathWrapper, { children: [path.selection.map((itemId, i) => (_jsxs(_Fragment, { children: [_jsx(PathItemLabel, { children: labelLookup(itemId, i) }, i), i + 1 < path.selection.length && _jsx(Separator, {})] }))), _jsx(IconWrapper, Object.assign({ className: "danger", onClick: () => deletePath(path.selection) }, { children: _jsx(RiDeleteBin6Line, {}) }))] }, i)))] })] })) }));
};
