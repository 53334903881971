var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { styled } from "styled-components";
import envVarConfig from "../env/envVarConfig";
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { instance } from "..";
const ReachAnalytics = () => {
    const dashRef = React.useRef(null);
    const getReachToken = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!envVarConfig.condenseScope) {
            console.log("Condense scope was not configured");
            return "";
        }
        const accounts = instance.getAllAccounts();
        const token = yield instance.acquireTokenSilent({
            account: accounts[0],
            scopes: [envVarConfig.condenseScope],
        });
        return token.accessToken;
    });
    const renderDashboard = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!envVarConfig.analyticsSharePointDashboardHostname || !envVarConfig.analyticsReachDashboardId) {
            console.log("Analytics settings were not configured.");
            return;
        }
        if (dashRef.current) {
            const token = yield getReachToken();
            const dashboardId = envVarConfig.analyticsReachDashboardId;
            const dashboardOrigin = `https://${envVarConfig.analyticsSharePointDashboardHostname}`;
            yield embedDashboard({
                id: dashboardId,
                supersetDomain: dashboardOrigin,
                mountPoint: dashRef.current,
                fetchGuestToken: () => __awaiter(void 0, void 0, void 0, function* () {
                    return fetch(`${dashboardOrigin}/reach_guest_token/`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }).then((response) => response.text());
                }),
                dashboardUiConfig: {
                    hideTitle: true,
                    filters: {
                        expanded: true,
                    },
                }
            });
        }
    });
    React.useEffect(() => {
        renderDashboard();
    }, []);
    return (_jsxs(StyledAnalyticsPageContents, { children: [_jsx("div", { children: _jsx("h1", { children: "Reach Analytics" }) }), _jsx(StyledDashContainer, { ref: dashRef })] }));
};
const StyledAnalyticsPageContents = styled.div.withConfig({ displayName: "StyledAnalyticsPageContents", componentId: "-1loln5w" }) `
  height: calc(100% - 85px);
`;
const StyledDashContainer = styled.div.withConfig({ displayName: "StyledDashContainer", componentId: "-q011ef" }) `
  background-color: #fff;
  height: 100%;

  iframe {
    height: 100%;
    border: none;
    width: 100%;
  }
`;
export default ReachAnalytics;
