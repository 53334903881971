var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { instance } from "../../../index";
import envVarConfig from "../../../env/envVarConfig";
function getToken() {
    return __awaiter(this, void 0, void 0, function* () {
        const accounts = instance.getAllAccounts();
        const token = yield instance.acquireTokenSilent({
            account: accounts[0],
            scopes: [envVarConfig.backOfficeAppScope],
        });
        return token.accessToken;
    });
}
function setRequest(method = "GET", body = null, contentType = "application/json") {
    return __awaiter(this, void 0, void 0, function* () {
        const userToken = yield getToken();
        return {
            method: method,
            body: body != null ? JSON.stringify(body) : null,
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": contentType,
            },
        };
    });
}
export const PoliciesService = () => {
    const tenantId = JSON.parse(sessionStorage.getItem("User")).tenantId;
    const policiesUrl = `${envVarConfig.reactApiUrl}/Policies/${tenantId}`;
    const sourceEndpoints = {
        GetSources: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(policiesUrl, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to fetch tenant policies:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error fetching tenant policies:", error);
                throw error;
            }
        }),
        AddSource: (sourceUrl) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}?url=${encodeURIComponent(sourceUrl)}`;
                const response = yield fetch(url, yield setRequest("POST"));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to add a new policy source:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error adding a new policy source:", error);
                throw error;
            }
        }),
        RenameSource: (id, name) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${id}/rename`;
                return yield fetch(url, yield setRequest("PUT", { name: name }));
            }
            catch (error) {
                console.log("Error renaming a policy source:", error);
                throw error;
            }
        }),
    };
    const itemsEndpoints = {
        GetUnconfirmedTargetUsers: (sourceId, itemId, pageNumber, pageSize) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/targets/unconfirmed?PageNumber=${pageNumber}&PageSize=${pageSize}`;
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to fetch policy unconfirmed targets:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error when fetching policy unconfirmed targets:", error);
                throw error;
            }
        }),
        GetConfirmedTargetUsers: (sourceId, itemId, pageNumber, pageSize) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/targets/confirmed?PageNumber=${pageNumber}&PageSize=${pageSize}`;
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to fetch policy confirmed targets:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error when fetching policy confirmed targets: ", error);
                throw error;
            }
        }),
        ExportTargetUsers: (sourceId, itemId) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/targets/export`;
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to fetch policy targets export:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error when fetching policy targets export:", error);
                throw error;
            }
        }),
        TargetItem: (sourceId, itemId, targets) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/target`;
                return yield fetch(url, yield setRequest("PUT", { audiences: targets }));
            }
            catch (error) {
                console.log("Error when targeting audiences of a policy item:", error);
                throw error;
            }
        }),
        UpdateDueDate: (sourceId, itemId, dueDate) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/duedate`;
                return yield fetch(url, yield setRequest("PUT", { dueDate: dueDate }));
            }
            catch (error) {
                console.log("Error when targeting audiences of a policy item:", error);
                throw error;
            }
        }),
        SetPolicyItemAsMandatory: (sourceId, itemId, mandatory) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/mandatory/${mandatory}`;
                return yield fetch(url, yield setRequest("PUT"));
            }
            catch (error) {
                console.log("Error when setting policy item as mandatory:", error);
                throw error;
            }
        }),
        EnableAssistant: (sourceId, itemId, enableAssistant) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/enableassistant/${enableAssistant}`;
                return yield fetch(url, yield setRequest("PUT"));
            }
            catch (error) {
                console.log("Error when enabling policy item AI assistant:", error);
                throw error;
            }
        }),
        RemindUnconfirmedUsers: (sourceId, itemId, reminderMessage) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = `${policiesUrl}/${sourceId}/items/${itemId}/remind`;
                return yield fetch(url, yield setRequest("POST", reminderMessage));
            }
            catch (error) {
                console.log("Error when sending notifications to remind the users to read a policy:", error);
                throw error;
            }
        }),
    };
    return Object.assign(Object.assign({}, sourceEndpoints), itemsEndpoints);
};
