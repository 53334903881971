var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { graphConfig, graphRequest } from "../Auth/authConfig";
import { instance } from "../index";
import jwt_decode from "jwt-decode";
export function callMsGraph() {
    return __awaiter(this, void 0, void 0, function* () {
        const account = instance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const response = yield instance.acquireTokenSilent(Object.assign(Object.assign({}, graphRequest), { account: account }));
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;
        const decodedToken = jwt_decode(response.accessToken);
        headers.append("Authorization", bearer);
        const options = {
            method: "GET",
            headers: headers
        };
        return fetch(graphConfig.graphRootEndpoint, options)
            .then(response => response.json())
            .then(data => {
            return {
                data: data,
                decodedToken: decodedToken
            };
        })
            .catch(error => console.log(error));
    });
}
