import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState } from "react";
const AppContext = createContext(undefined);
export const AppContextProvider = ({ children }) => {
    const [appState, setAppState] = useState({
        isAdmin: false,
        isEditor: false,
        isClientRegistered: false,
        hasError: false,
        isLoading: true,
        opsCenterProps: undefined,
        name: "Admin",
    });
    return (_jsx(AppContext.Provider, Object.assign({ value: { appState, setAppState } }, { children: children })));
    ;
};
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppContextProvider");
    }
    return context;
};
