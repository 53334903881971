import * as FluentIcons from "@fluentui/react-icons";
const vivaCardsData = [
    {
        title: "Office Locations",
        description: "Allows the users to see the office locations through a map.",
        downloadUrl: "https://files.intranet.livetiles.io/office-locations-card/Apps/Livetiles.Office.Locations.sppkg",
        icon: FluentIcons.Location24Regular,
        route: "intranet/viva/office-locations",
        docsPage: "intranet/viva/office-locations",
        previewScreenshot: "/images/public/assets/preview-screenshots/viva-office-locations.png"
    },
    {
        title: "People Search",
        description: "Displays People from your organization inside an Adaptive Card.",
        downloadUrl: "https://files.intranet.livetiles.io/people-search-card/Apps/LiveTiles.People.Search.Card.sppkg",
        icon: FluentIcons.PeopleSearch24Regular,
        route: "intranet/viva/people-search-card",
        docsPage: "intranet/viva/people-search-card",
        previewScreenshot: "/images/public/assets/preview-screenshots/viva-people-search.png"
    },
    {
        title: "Reach Events",
        description: "Displays Reach Events for the next 30 days inside an Adaptive Card.",
        downloadUrl: "https://files.intranet.livetiles.io/events-card/Apps/LiveTiles.Reach.Events.Card.sppkg",
        icon: FluentIcons.Calendar24Regular,
        route: "intranet/viva/reach-events",
        docsPage: "intranet/viva/reach-events",
        previewScreenshot: "/images/public/assets/preview-screenshots/viva-reach-events.png"
    },
    {
        title: "Reach News",
        description: "Displays Reach News inside an Adaptive Card.",
        downloadUrl: "https://files.intranet.livetiles.io/news-card/Apps/LiveTiles.Reach.News.Card.sppkg",
        icon: FluentIcons.News24Regular,
        route: "intranet/viva/reach-news",
        docsPage: "intranet/viva/reach-news",
        previewScreenshot: "/images/public/assets/preview-screenshots/viva-reach-news.png"
    },
    {
        title: "Weather",
        description: "Allows users to search for a location to know about its weather conditions.",
        downloadUrl: "https://files.intranet.livetiles.io/weather-card/Apps/LiveTiles.Weather.Card.sppkg",
        icon: FluentIcons.WeatherPartlyCloudyDay24Regular,
        route: "intranet/viva/weather-card",
        docsPage: "intranet/viva/weather-card",
        previewScreenshot: "/images/public/assets/preview-screenshots/viva-weather.png"
    },
];
export default vivaCardsData;
