import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Image, Switch, } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular, SettingsRegular, MoreHorizontalRegular, } from "@fluentui/react-icons";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-19ndryt" }) `
  align-items: center;
  display: flex;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-4yntwh" }) `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-uvu5w6" }) `
  padding-top: 24px;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-15sjb3m" }) `
  color: #707070;
  margin-bottom: 4px;
  padding-top: 16px;
`;
const ErrorLabel = styled(Label).withConfig({ displayName: "ErrorLabel", componentId: "-pn37sd" }) `
  color: #b50b13 !important;
  margin-bottom: 4px;
  padding-top: 16px;
`;
const StyledImg = styled(Image).withConfig({ displayName: "StyledImg", componentId: "-1o8gdr" }) `
  width: 28px;
  height: 28px;
  margin-top: 16px;
`;
const ShowHideStepsLabel = styled.label.withConfig({ displayName: "ShowHideStepsLabel", componentId: "-phb6s3" }) `
  color: #512da8;
  padding: 16px 0px 8px 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;
const StepsDiv = styled.div.withConfig({ displayName: "StepsDiv", componentId: "-702ebp" }) `
  display: flex;
  flex-direction: column;
`;
const StepsLabel = styled.label.withConfig({ displayName: "StepsLabel", componentId: "-av2vpw" }) `
  padding-top: 5px;
`;
const SemiBold = styled.label.withConfig({ displayName: "SemiBold", componentId: "-11sd14i" }) `
  font-weight: 600;
`;
const LabelWithDots = styled.label.withConfig({ displayName: "LabelWithDots", componentId: "-gq3cm6" }) `
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  width: 135px;
  justify-content: space-between;
`;
const AddEditTabModel = ({ tab, onClose, addTab, updateTab, }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formIsValid, setFormIsvalid] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showHowTo, setShowHowTo] = useState(false);
    const [editedTab, setEditedTab] = useState(tab !== null && tab !== void 0 ? tab : {
        id: "",
        name: "",
        valueName: "",
        active: false,
        isDefault: false,
        iconUrl: "",
        url: "",
        isMicroFrontend: false,
    });
    const isNullOrEmpty = (str) => {
        if (str == null || str == "" || str == undefined)
            return true;
        else
            return false;
    };
    const generateUUID = () => {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    };
    useEffect(() => {
        if (editedTab.isDefault && !isNullOrEmpty(editedTab === null || editedTab === void 0 ? void 0 : editedTab.name))
            setFormIsvalid(true);
        else if (isNullOrEmpty(editedTab === null || editedTab === void 0 ? void 0 : editedTab.iconUrl) ||
            isNullOrEmpty(editedTab === null || editedTab === void 0 ? void 0 : editedTab.name) ||
            isNullOrEmpty(editedTab === null || editedTab === void 0 ? void 0 : editedTab.url) ||
            hasError)
            setFormIsvalid(false);
        else
            setFormIsvalid(true);
    }, [editedTab, hasError]);
    const handleImageError = (error) => {
        setHasError(true);
    };
    const handleSave = () => {
        if (!editedTab.isDefault) {
            const index = editedTab.url.toLowerCase().indexOf(".html");
            if (index > 0) {
                editedTab.url = editedTab.url.substring(0, index + 5);
            }
        }
        if (!tab) {
            editedTab.id = generateUUID();
            editedTab.valueName = editedTab.name;
            addTab(editedTab);
        }
        else
            updateTab(editedTab);
        onClose();
    };
    const handleHowToClicked = () => {
        setShowHowTo(!showHowTo);
    };
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [!tab ? "Add new" : "Edit", " tab"] }), _jsxs(StyledDialogContent, { children: [_jsxs(_Fragment, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "edit-tab-url", required: true }, { children: "URL" })), _jsx(Input, { id: "edit-tab-url", placeholder: "Insert an URL", size: "large", value: editedTab === null || editedTab === void 0 ? void 0 : editedTab.url, onChange: (e) => {
                                        setEditedTab(Object.assign(Object.assign({}, editedTab), { url: e.target.value }));
                                    } })] }), _jsx(Switch, { checked: editedTab.isMicroFrontend, id: "edit-tab-microfrontend", label: "Is a Micro Frontend?", labelPosition: "after", onChange: (e) => {
                                setEditedTab(Object.assign(Object.assign({}, editedTab), { isMicroFrontend: editedTab.isMicroFrontend ? false : true }));
                            } }), _jsxs(_Fragment, { children: [_jsxs(ShowHideStepsLabel, Object.assign({ onClick: handleHowToClicked }, { children: ["How to generate the link from a SharePoint file", " ", showHowTo ? (_jsx(ChevronUpRegular, { style: {
                                                paddingLeft: "5px",
                                                width: "24px",
                                                height: "24px",
                                            } })) : (_jsx(ChevronDownRegular, { style: {
                                                paddingLeft: "5px",
                                                width: "24px",
                                                height: "24px",
                                            } }))] })), showHowTo && (_jsxs(_Fragment, { children: [_jsx(SemiBold, Object.assign({ style: { paddingBottom: "8px" } }, { children: "Follow these steps:" })), _jsxs(StepsDiv, { children: [_jsx(StepsLabel, { children: "1. Select the file;" }), _jsxs(LabelWithDots, { children: ["2. Click on the ", _jsx(MoreHorizontalRegular, {}), " icon;"] }), _jsxs(StepsLabel, { children: ["3. Select the opion ", _jsx(SemiBold, { children: "\u201CCopy link\u201D" }), ";"] }), _jsxs(StepsLabel, { children: ["4. Click on the", " ", _jsxs(SemiBold, { children: ["\u201C", _jsx(SettingsRegular, {}), " Settings\u201D"] }), " ", "button;"] }), _jsxs(StepsLabel, { children: ["5. Select the option", " ", _jsx(SemiBold, { children: "\u201CPeople with existing access\u201D" }), ";"] }), _jsxs(StepsLabel, { children: ["6. Click on ", _jsx(SemiBold, { children: "\u201CApply\u201D" }), " button;"] }), _jsx(StepsLabel, { children: "7. Link is now copied, you can paste it into the field above;" }), _jsx(StepsLabel, { children: "8. Remove what's left of the link after the \u201CHTML\u201D." })] })] }))] }), _jsx(StyledLabel, Object.assign({ htmlFor: "edit-tab-name", required: true }, { children: "Name" })), _jsx(Input, { id: "edit-tab-name", placeholder: "Give it a name", size: "large", value: editedTab === null || editedTab === void 0 ? void 0 : editedTab.name, onChange: (e) => {
                                setEditedTab(Object.assign(Object.assign({}, editedTab), { name: e.target.value }));
                            } }), _jsx(StyledLabel, Object.assign({ htmlFor: "edit-tab-icon", required: true }, { children: "Icon url" })), _jsx(Input, { id: "edit-tab-icon", placeholder: "Inseart icon url", size: "large", value: editedTab === null || editedTab === void 0 ? void 0 : editedTab.iconUrl, onChange: (e) => {
                                setHasError(false);
                                setEditedTab(Object.assign(Object.assign({}, editedTab), { iconUrl: e.target.value }));
                            } }), hasError ? (_jsx(ErrorLabel, { children: "Icon URL is not valid" })) : (_jsx(StyledImg, { src: editedTab.iconUrl, onError: handleImageError.bind(this) }))] }), _jsx(StyledDialogActions, { children: isSaving ? (_jsx(Spinner, { size: SpinnerSize.medium, label: "Saving...", ariaLive: "assertive", labelPosition: "right" })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handleSave, disabled: !formIsValid }, { children: !!tab ? "Save" : "Add tab" }))] })) })] }) }));
};
export default AddEditTabModel;
