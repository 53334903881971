import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
const PreviewContainer = styled.div.withConfig({ displayName: "PreviewContainer", componentId: "-d3xldd" }) `
  display: grid;
  grid-template-columns: 8% 1fr;
  grid-template-rows: 12% 10% 1fr;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 241/182;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(0,0,0,0.025), 
              0 2px 2px rgba(0,0,0,0.025), 
              0 4px 4px rgba(0,0,0,0.025);

  &.intense {
    --sidebar-bg: ${props => props.primaryColor};
    --sidebar-bg-inner: rgba(255, 255, 255, 1);
    --sidebar-shadow: none;
    --header-bg: ${props => props.primaryColor};
    --header-bg-inner: #F7F7F7;
    --header-text: rgba(255, 255, 255, .3);
    --header-text-active: ${props => props.primaryColor};
    --header-border: rgba(255, 255, 255, .3);
  }

  &.soft {
    --sidebar-bg: #f7f7f7;
    --sidebar-bg-inner: ${props => props.primaryColor};
    --sidebar-shadow: 0 1px 3px rgba(0, 0, 0, .25);
    --header-bg: #f7f7f7;
    --header-bg-inner: ${props => props.primaryColor};
    --header-text: ${props => props.primaryColor};
    --header-text-active: #F7F7F7;
    --header-border: rgba(0, 0, 0, .1);
  }

  &.classic,
  &.compact {
    .contentItem {
      opacity: 0.5;    
    }
  }
`;
const Sidebar = styled.div.withConfig({ displayName: "Sidebar", componentId: "-llkywf" }) `
  position: relative;
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--sidebar-bg);
  padding-block: 15%;
  box-shadow: var(--sidebar-shadow);
  z-index: 2;
`;
const SidebarCircle = styled.div.withConfig({ displayName: "SidebarCircle", componentId: "-gp7x3n" }) `
  width: 50%;
  aspect-ratio: 1/1;
  background: var(--sidebar-bg-inner);
  border-radius: 50%;
  margin-block: 20%;
`;
const HeaderUpper = styled.div.withConfig({ displayName: "HeaderUpper", componentId: "-1ylfezp" }) `
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--sidebar-bg);
  border-bottom: 1px solid var(--header-border);
  padding-inline: 2%;
`;
const HeaderUpperCircle = styled.div.withConfig({ displayName: "HeaderUpperCircle", componentId: "-caa2gn" }) `
  height: 45%;
  aspect-ratio: 1/1;
  background: var(--header-bg-inner);
  border-radius: 50%;
  margin-inline: 1%;
`;
const HeaderUpperTab = styled.div.withConfig({ displayName: "HeaderUpperTab", componentId: "-mx80qb" }) `
  height: 45%;
  width: 30%;
  background: var(--header-bg-inner);
  border-radius: 40px;
  margin: auto;
`;
const HeaderLower = styled.div.withConfig({ displayName: "HeaderLower", componentId: "-1dg758z" }) `
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--sidebar-bg);

  .classic &,
  .compact & {
    box-shadow: 0 3px 3px rgba(0, 0, 0, .1);
    z-index: 1;
  }
`;
const HeaderLowerTab = styled.div.withConfig({ displayName: "HeaderLowerTab", componentId: "-fmnihq" }) `
  position: relative;
  height: 45%;
  width: 14%;
  background: var(--header-bg-inner);
  border-radius: 40px;
  margin-inline: 1%;
  
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    top: 50%;
    inset-inline: 6px;
    transform: translateY(-.5px);
    background: var(--header-text-active);
  }
`;
const HeaderLowerText = styled.div.withConfig({ displayName: "HeaderLowerText", componentId: "-1eks6al" }) `
  height: 2px;
  width: 10%;
  background: var(--header-text);
  border-radius: 40px;
  margin-inline: 1%;
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "-181uzoo" }) `
  position: relative;
  display: grid;
  grid-template-rows: 1.5fr 1.2fr .1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 
    "a a a a"
    "b b b ."
    ". . . ."
    "c c d d"
    "c c e ."
    "c c f f"
    "c c g .";
  padding: 5%;
  grid-gap: 7%;

  > * {
    background-image: linear-gradient(to right, #E5E7EB, #F9FAFB);
    border-radius: 8px;
  }
`;
const FlyoutContainer = styled.div.withConfig({ displayName: "FlyoutContainer", componentId: "-1j3aiqj" }) `
  position: absolute;
  top: 0;
  inset-inline: 0;
  display: flex;

  .compact & {
    margin-inline: 10%;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .1);
    overflow: hidden;
  }
`;
const FlyoutLeft = styled.div.withConfig({ displayName: "FlyoutLeft", componentId: "-e7scoq" }) `
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  padding: 8px;
  background: #FFFFFF;

  > div {
    gap: 5px;
  }

  .classic & {
    background: #E8E8E8;
  }

  .compact & {
    border-right: 2px solid #F2F2F2;

    .listHeader {
      background-image: none;
      background-color: #BEBEBE;
    }

    .listItem {
      background-color: #F4F4F4;
    }
  }
`;
const FlyoutRight = styled.div.withConfig({ displayName: "FlyoutRight", componentId: "-1ubxb2q" }) `
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  gap: 5px;
  padding: 8px;
  background: #FFFFFF;

  .classic & {
    background: #F4F4F4;

    .listHeader,
    .listItem {
      background-image: none;
      background-color: #E5E5E5;
    }
  }

  .compact & {
    .listHeader,
    .listItem {
      background-image: linear-gradient(to right, #E5E7EB, #F7F7F7);
    }
  }
`;
const FlyoutHeaderItem = styled.div.withConfig({ displayName: "FlyoutHeaderItem", componentId: "-180mmem" }) `
  background-image: linear-gradient(to right, #FFFFFF, #BEBEBE);
  height: 6px;
  border-radius: 3px;
`;
const FlyoutItem = styled.div.withConfig({ displayName: "FlyoutItem", componentId: "-sdzc1h" }) `
  background: #FFFFFF;
  height: 6px;
  border-radius: 3px;
  max-width: 75%;
`;
const ListContainer = styled.div.withConfig({ displayName: "ListContainer", componentId: "-1qqqq0e" }) `
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-bottom: 5px;
`;
const SampleMenuList = () => {
    return (_jsxs(ListContainer, { children: [_jsx(FlyoutHeaderItem, { className: "listHeader" }), [...Array(4)].map((_, i) => (_jsx(FlyoutItem, { className: "listItem" }, i)))] }));
};
const SkeletonPreview = (props) => {
    return (_jsxs(PreviewContainer, Object.assign({ className: `${props.style} ${props.menutype ? props.menutype : ""}`, primaryColor: props.primaryColor }, { children: [_jsx(Sidebar, { children: [...Array(7)].map((_, i) => (_jsx(SidebarCircle, {}, i))) }), _jsxs(HeaderUpper, { children: [_jsx(HeaderUpperCircle, {}), _jsx(HeaderUpperTab, {}), _jsx(HeaderUpperCircle, {}), _jsx(HeaderUpperCircle, {})] }), _jsxs(HeaderLower, { children: [_jsx(HeaderLowerTab, {}), [...Array(5)].map((_, i) => (_jsx(HeaderLowerText, {}, i)))] }), _jsxs(Content, { children: [_jsx("div", { className: "contentItem", style: { gridArea: "a" } }), _jsx("div", { className: "contentItem", style: { gridArea: "b" } }), _jsx("div", { className: "contentItem", style: { gridArea: "c" } }), _jsx("div", { className: "contentItem", style: { gridArea: "d" } }), _jsx("div", { className: "contentItem", style: { gridArea: "e" } }), _jsx("div", { className: "contentItem", style: { gridArea: "f" } }), _jsx("div", { className: "contentItem", style: { gridArea: "g" } }), (props.menutype === "compact") || (props.menutype === "classic") ? (_jsxs(FlyoutContainer, Object.assign({ className: props.menutype ? props.menutype : "" }, { children: [_jsx(FlyoutLeft, { children: _jsx(SampleMenuList, {}) }), [...Array(props.menutype === "classic" ? 4 : 3)].map((_, i) => (_jsxs(FlyoutRight, { children: [_jsx(SampleMenuList, {}), _jsx(SampleMenuList, {})] }, i)))] }))) : null] })] })));
};
export default SkeletonPreview;
