var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { instance } from "../../../index";
import envVarConfig from "../../../env/envVarConfig";
import { ClientRegistrationAPIService } from "../../intranet/util/ClientRegistrationAPI/ClientRegistrationAPI";
function getToken() {
    return __awaiter(this, void 0, void 0, function* () {
        const accounts = instance.getAllAccounts();
        const token = yield instance.acquireTokenSilent({
            account: accounts[0],
            scopes: [envVarConfig.backOfficeAppScope],
        });
        return token.accessToken;
    });
}
function setRequest(method = "GET", body = null, contentType = "application/json") {
    return __awaiter(this, void 0, void 0, function* () {
        const userToken = yield getToken();
        return {
            method: method,
            body: body != null ? JSON.stringify(body) : null,
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": contentType,
            },
        };
    });
}
function fetchRealm(tenantId) {
    if (!sessionStorage.getItem("Realm")) {
        const clientRegistration = ClientRegistrationAPIService.getClientRegistration(tenantId).then((client) => {
            sessionStorage.setItem("Realm", client.realm);
        });
    }
    return sessionStorage.getItem("Realm");
}
export var IdentityType;
(function (IdentityType) {
    IdentityType[IdentityType["User"] = 0] = "User";
})(IdentityType || (IdentityType = {}));
export const QuicklinksService = () => {
    //SWITCH to use the userTenant as tenantId only when ixp migration is complete
    const userTenant = JSON.parse(sessionStorage.getItem("User")).tenantId;
    const tenantId = fetchRealm(userTenant); //userTenant
    const apiPath = envVarConfig.reactApiUrl + "/QuickLinks/" + tenantId;
    const apiPathGraph = envVarConfig.reactApiUrl + "/Graph";
    const instanceEndpoints = {
        GetInstance: (force = false) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/instance";
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    if (!sessionStorage.getItem("IxpInstanceId") || force) {
                        sessionStorage.setItem("IxpInstanceId", data[0].id);
                    }
                    return data;
                }
                else {
                    console.log("Failed to fetch quick links instance:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error fetching quick links instance:", error);
                throw error;
            }
        }),
        SetInstance: (instance) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/instance/" + instance.id;
                const response = yield fetch(url, yield setRequest("PUT", instance));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to update quick links instance:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error updating quick links instance:", error);
                throw error;
            }
        }),
    };
    const linksEndpoints = {
        GetLinks: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/link?orderby=CategoryName&order=asc";
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to fetch quick links:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error fetching quick links:", error);
                throw error;
            }
        }),
        DeleteLink: (linkId) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/link/" + linkId;
                return yield fetch(url, yield setRequest("DELETE"));
            }
            catch (error) {
                console.log("Error deleting a quick link:", error);
                throw error;
            }
        }),
        DeleteLinks: (links) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/link/deletebulk";
                return yield fetch(url, yield setRequest("POST", links));
            }
            catch (error) {
                console.log("Error deleting multiple quick links at once:", error);
                throw error;
            }
        }),
        UpdateLinks: (links) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/link/bulkOperation";
                const response = yield fetch(url, yield setRequest("PUT", links));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed update multiple quick links at once:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error updating multiple quick links at once:", error);
                throw error;
            }
        }),
        DiscoverLink: (urlToDiscover) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const encodedUrl = encodeURIComponent(urlToDiscover);
                const url = envVarConfig.reactApiUrl + "/QuickLinks/link/inspect/" + encodedUrl;
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to inspect a link:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error inspecting a link:", error);
                throw error;
            }
        }),
        AddLink: (link) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/link";
                const response = yield fetch(url, yield setRequest("POST", link));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to add a quick link:", response.status);
                    return {
                        error: true,
                        message: "Failed to add a quick link: " + response.status,
                    };
                }
            }
            catch (error) {
                console.log("Error adding a quick link:", error);
                throw error;
            }
        }),
        EditLink: (link) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/link/" + link.id;
                const response = yield fetch(url, yield setRequest("PUT", link));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to update a quick link:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error updating a quick link:", error);
                throw error;
            }
        }),
    };
    const CategoriesEndpoints = {
        GetCategories: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/category";
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to get quick links categories:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error getting quick links categories:", error);
                throw error;
            }
        }),
        AddCategorie: (category) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/category";
                const response = yield fetch(url, yield setRequest("POST", category));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to add a quick link category:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error adding a quick link category:", error);
                throw error;
            }
        }),
        SetCategorie: (category) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/category/" + category.id;
                const response = yield fetch(url, yield setRequest("PUT", category));
                if (response.ok) {
                    const data = yield response.json();
                    return data;
                }
                else {
                    console.log("Failed to update a quick link category:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error updating a quick link category:", error);
                throw error;
            }
        }),
        DeleteCategorie: (category) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/category/" + category.id;
                return yield fetch(url, yield setRequest("DELETE"));
            }
            catch (error) {
                console.log("Error deleting a quick link category:", error);
                throw error;
            }
        }),
    };
    const iconEnpoints = {
        GetIcons: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/icon";
                const response = yield fetch(url, yield setRequest());
                if (response.ok) {
                    const data = yield response.json();
                    return data.map((i) => ({
                        type: "Internal",
                        iconId: i.id,
                        url: `/QuickLinks/${tenantId}/icon/${i.id}/download`,
                        preview: i.preview,
                    }));
                }
                else {
                    console.log("Failed to get quick links icons:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error getting quick links icons:", error);
                throw error;
            }
        }),
        AddIcon: (icon) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/icon";
                const userToken = yield getToken();
                const options = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: icon,
                };
                const response = yield fetch(url, options);
                if (response.ok) {
                    const data = yield response.json();
                    return {
                        type: "Internal",
                        iconId: data.id,
                        url: `/QuickLinks/${tenantId}/icon/${data.id}/download`,
                        preview: data.preview,
                        tenantId: tenantId,
                    };
                }
                else {
                    console.log("Failed to add a quick link icon:", response.status);
                    return null;
                }
            }
            catch (error) {
                console.log("Error adding a quick link icon:", error);
                throw error;
            }
        }),
        DeleteIcon: (icon) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const url = apiPath + "/icon/" + icon.iconId;
                return yield fetch(url, yield setRequest("DELETE"));
            }
            catch (error) {
                console.log("Error deleting a quick link icon:", error);
                throw error;
            }
        }),
    };
    const userEndpoints = {
        GetUser: (email) => __awaiter(void 0, void 0, void 0, function* () {
            const userToken = yield getToken();
            try {
                const response = yield fetch(apiPathGraph + "/entity/" + email, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                });
                if (response) {
                    return response.json();
                }
            }
            catch (error) {
                console.log("Error retrieving entity:", error);
                throw error;
            }
        }),
    };
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, linksEndpoints), instanceEndpoints), CategoriesEndpoints), iconEnpoints), userEndpoints);
};
