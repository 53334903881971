var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Spinner, } from "@fluentui/react-components";
import { PoliciesService } from "../PoliciesService";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-b687pc" }) `
  align-items: center;
  display: flex;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-1naj9lz" }) `
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (props.$hasPadding ? "32px" : undefined)};
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-17d9ios" }) `
  padding-top: 20px;
`;
const MarginBottomInput = styled(Input).withConfig({ displayName: "MarginBottomInput", componentId: "-1xo9e26" }) `
  margin-bottom: 16px;
`;
const EditPolicySourceModal = ({ source, onClose, onSave, }) => {
    const [editedPolicySource, setEditedPolicySource] = useState(source);
    const { RenameSource } = PoliciesService();
    const [isSaving, setIsSaving] = React.useState(false);
    const handlePolicySourceSave = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            yield RenameSource(editedPolicySource === null || editedPolicySource === void 0 ? void 0 : editedPolicySource.id, editedPolicySource === null || editedPolicySource === void 0 ? void 0 : editedPolicySource.name);
            console.log(`Rename source ${source} with values ${editedPolicySource}`);
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
        yield onSave();
        setIsSaving(false);
        onClose();
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(StyledDialogTitle, { children: "Edit Source" }), _jsxs(StyledDialogContent, { children: [_jsx(Label, Object.assign({ htmlFor: "edit-category-name", required: true }, { children: "Name" })), _jsx(MarginBottomInput, { id: "edit-category-name", placeholder: "Define a name", size: "large", value: editedPolicySource === null || editedPolicySource === void 0 ? void 0 : editedPolicySource.name, onChange: (e) => setEditedPolicySource(Object.assign(Object.assign({}, editedPolicySource), { name: e.target.value })) })] }), _jsx(StyledDialogActions, { children: isSaving ? (_jsx(Spinner, { size: "small", label: "Saving..." })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handlePolicySourceSave }, { children: "Save" }))] })) })] }) }));
};
export default EditPolicySourceModal;
