var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import envVarConfig from "../env/envVarConfig";
import { ClientRegistrationAPIService } from "../pages/intranet/util/ClientRegistrationAPI/ClientRegistrationAPI";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: envVarConfig.instrumentationKey,
        enableAutoRouteTracking: true,
    },
});
export function fetchRegistration(user) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const userRegion = sessionStorage.getItem("Region");
            if (!userRegion) {
                const response = yield ClientRegistrationAPIService.getClientRegistration(user.tenantId);
                appInsights.trackEvent({
                    name: "ClientRegistration",
                    properties: {
                        response: JSON.stringify(response),
                    },
                });
                sessionStorage.setItem("Region", response.region);
                sessionStorage.setItem("Realm", response.realm);
                return !!response;
            }
            return true;
        }
        catch (error) {
            appInsights.trackException({
                exception: error,
                severityLevel: 3,
            });
            console.error("Error fetching registration:", error);
            return false;
        }
    });
}
