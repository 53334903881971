import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { Spinner, SpinnerWrapper } from "../Picker/Picker";
import styled from "styled-components";
import { Suggestion } from "./Suggestion";
const SuggestionBoxWrapper = styled.div.withConfig({ displayName: "SuggestionBoxWrapper", componentId: "-12zgxeq" }) `
  min-width: 300px;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-top: 0.5rem;
`;
const InviteSuggestionBoxWrapper = styled.div.withConfig({ displayName: "InviteSuggestionBoxWrapper", componentId: "-7j127k" }) `
  max-height: 600px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  margin-top: 15px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13),
    0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.1);
`;
const PeopleTitleWrapper = styled.div.withConfig({ displayName: "PeopleTitleWrapper", componentId: "-hi8y0x" }) `
  font-feature-settings: "clig" off, "liga" off;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #707070;
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 5px;
`;
const NoResultFound = styled.div.withConfig({ displayName: "NoResultFound", componentId: "-1c5wfp0" }) `
  padding: 10px;
  color: #5c5653;
`;
export const SuggestionBox = (props) => {
    function addSuggestion(user) {
        props.addSuggestion(user);
    }
    return (_jsx(_Fragment, { children: props.isFromInvite ? (_jsxs(InviteSuggestionBoxWrapper, { children: [_jsx(PeopleTitleWrapper, { children: "People" }), props.filteredUsers && props.filteredUsers.length > 0 ? (props.filteredUsers.map((u) => {
                    return _jsx(Suggestion, { addSuggestion: addSuggestion, user: u });
                })) : props.filteredUsers && props.filteredUsers.length === 0 ? (_jsx(NoResultFound, { children: _jsx("label", { children: "We couldn't find what you're looking for." }) })) : (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}) }))] })) : (_jsx(SuggestionBoxWrapper, { children: props.filteredUsers && props.filteredUsers.length > 0 ? (props.filteredUsers.map((u) => {
                return _jsx(Suggestion, { addSuggestion: addSuggestion, user: u });
            })) : props.filteredUsers && props.filteredUsers.length === 0 ? (_jsx(NoResultFound, { children: _jsx("label", { children: "We couldn't find what you're looking for." }) })) : (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}) })) })) }));
};
