import envVarConfig from "../env/envVarConfig";
import { LogLevel } from "@azure/msal-browser";
const clientId = envVarConfig.clientId;
const redirectUrl = envVarConfig.redirectUrl;
export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "/",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        iframeHashTimeout: 20000,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
export const loginRequest = {
    scopes: [envVarConfig.backOfficeAppScope],
    prompt: "select_account",
};
export const graphConfig = {
    graphRootEndpoint: "https://graph.microsoft.com/v1.0/sites/root",
};
export const graphRequest = {
    scopes: ["https://graph.microsoft.com/.default"],
};
