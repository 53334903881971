import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, Select } from "@fluentui/react-components";
import { ModalFieldset } from "../Modal.styles";
export const EditingModal = (props) => {
    const [idValue, setIdValue] = React.useState(0);
    const [nodeValue, setNodeValue] = React.useState("");
    const [groupId, setGroupId] = React.useState(0);
    const [isGroupHeader, setIsGroupHeader] = React.useState(false);
    function onChangeGroupIdValue(e) {
        setGroupId(parseInt(e.target.value));
    }
    function onChangeNodeValue(e) {
        setNodeValue(e.target.value);
    }
    function onChangeGroupHeaderValue(e) {
        setIsGroupHeader(e.target.checked);
    }
    function saveNode() {
        const node = {
            itemId: idValue,
            text: nodeValue,
            groupId: isGroupHeader || typeof groupId === "string" ? 0 : groupId,
            isGroupHeader: isGroupHeader,
            dimensionId: props.dimensionId,
        };
        props.updateItem(node);
        setNodeValue(null);
        setGroupId(0);
        setIsGroupHeader(false);
    }
    React.useEffect(() => {
        const titleInput = document.querySelector("input.initialFocus");
        if (titleInput) {
            titleInput.focus();
        }
    }, []);
    return (_jsxs(Dialog, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, { children: "Add item" }) })), _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Create Navigation" }), _jsxs(DialogContent, { children: [_jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Title" }), _jsx(Field, { children: _jsx(Input, { value: nodeValue, onChange: onChangeNodeValue }) })] })), !isGroupHeader && (_jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Parent Group" }), _jsx(Field, { children: _jsxs(Select, Object.assign({ value: groupId, onChange: onChangeGroupIdValue }, { children: [_jsx("option", Object.assign({ value: "0" }, { children: "None" })), props.groups &&
                                                        props.groups.map((group) => {
                                                            return _jsx("option", Object.assign({ value: group.itemId }, { children: group.text }));
                                                        })] })) })] }))), _jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Group Header" }), _jsx(Checkbox, { checked: isGroupHeader, onChange: onChangeGroupHeaderValue })] }))] }), _jsxs(DialogActions, { children: [_jsx(DialogTrigger, { children: _jsx(Button, Object.assign({ onClick: saveNode, appearance: "primary" }, { children: "OK" })) }), _jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, Object.assign({ appearance: "secondary" }, { children: "Cancel" })) }))] })] }) })] }));
};
