import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle `

    *,
    *::after,
    *::before {
        box-sizing: border-box;       
    }

    :root {
        --lt-green: #349552;
        --lt-green-lightest: #eff5f1;
        --lt-blue: #427AA1;
        --lt-blue-lighter: #b4c1cb;
        --lt-blue-lightest: #e6eff7;
        --lt-purple: #5c2d91;        
        --lt-purple-lighter: #7C4DFF;
        --lt-purple-lightest: #DAC4E9;
        --lt-purple-bg-lighter: #E6E2F1;
        --lt-red: #9c2b2b;
        --lt-red-light: #be6464;
        --lt-red-lighter: #f1e5e5;
        --lt-orange: #cd7233;
        --lt-primary: #483a57;
        --lt-primary-lighter: #564866;
        --lt-primary-lightest: #EBF2FA;
        --lt-primary-dark: #32293c;
        --lt-primary-darker: #241d2a;
        --header-bg: #fff;
        --header-color: #0F0E0E;
        --sidebar-bg: #fff;
        --sidebar-bg-highlight: #F6F4FB;
        --sidebar-color: ##0F0E0E;
        --card-bg: #f4f3f5;
        --card-border: 1px solid #dcd4e4;
        --card-active-bg: var(--lt-primary-lightest);
        --card-active-border: 1px solid #bdcddd;
        --editor-bg: #f3f3f3;
        --editor-w-bg: #ffffff;
        --editor-node-color: #444;
        --editor-node-bg: #e7e9ed;
        --editor-node-border: 1px solid #92949b;
        --editor-node-shadow: 0;
        --editor-node-icon: #512DA8;
        --editor-node-icon-hover: #512DA8;
        --editor-level1-bg: transparent;
        --editor-level2-bg: transparent;
        --editor-level3-bg: transparent;
        --editor-search-bg: var(--editor-level2-bg);
        --flyout-max-height: calc(60vh - 170px);
        --border-color: #d9dfe4;
        --default-gutter: 26px;
        --details-title-color: #0F0E0E;
        --details-title-size: 16px;
        --details-title-weight: 700;
    }
    .lt-style.lt-style {
        --green: var(--lt-green);
        --blue: var(--lt-blue);
        --red: var(--lt-red);
        --orange: var(--lt-orange);
        --purple: var(--lt-purple);
        --purple-lighter: var(--lt-purple-lighter);
        --red-lighter: var(--lt-red-lighter);
    }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "-dgrm0e" }) `
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "header actions"
    "editor editor"
    "details details";
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;

  &.menutype-matrix {
    grid-template-areas:
      "header actions"
      "editor editor"
      "editor editor";
  }
`;
export const HeaderContainer = styled.div.withConfig({ displayName: "HeaderContainer", componentId: "-1f7t3kn" }) `
  grid-area: header;
  display: flex;
  align-items: center;
  background: var(--header-bg);
  color: var(--header-color);
  z-index: 10;
  
  .lt-style {
    margin-block: 0;
  }
`;
export const DetailsContainer = styled.div.withConfig({ displayName: "DetailsContainer", componentId: "-1l4aj8s" }) `
  display: flex;
  flex-direction: column;
  grid-area: details;
`;
export const DetailsOptionsContainer = styled.div.withConfig({ displayName: "DetailsOptionsContainer", componentId: "-dy3pj4" }) `
  display: flex;
  flex-direction: column;
  grid-area: editor;
`;
export const Details = styled.div.withConfig({ displayName: "Details", componentId: "-1z00djq" }) `
  grid-area: details;
  overflow-y: auto;
  .menutype-matrix & {
    display: none;
  }
`;
export const Editor = styled.div.withConfig({ displayName: "Editor", componentId: "-vsmv68" }) `
  grid-area: editor;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  background-color: var(--editor-w-bg);
  z-index: 100;

  .menutype-matrix & {
    flex-direction: column;

    > div {
      flex: 0 0 60%;

      + div {
        flex: 0 0 40%;
      }
    }
  }
`;
export const EditorFullHeight = styled.div.withConfig({ displayName: "EditorFullHeight", componentId: "-6mv0ps" }) `
  grid-area: editor;
  display: relative;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  background-color: var(--editor-w-bg);

  .menutype-matrix & {
    flex-direction: column;

    > div {
      flex: 0 0 60%;

      + div {
        flex: 0 0 40%;
      }
    }
  }
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "-6zs5eo" }) `
  position: relative;
  padding: 0.75rem var(--default-gutter);
  margin-bottom: 0.5rem;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  color: var(--details-title-color);
  font-size: var(--details-title-size);
  font-weight: var(--details-title-weight);
`;
