import styled from "styled-components";
export const MatrixMenuPreviewWrapper = styled.div.withConfig({ displayName: "MatrixMenuPreviewWrapper", componentId: "-enp75g" }) `
  position: relative;
  grid-column: 1 / -1;
  max-height: 50px;
  margin-bottom: 0.5rem;
`;
export const SelectBar = styled.div.withConfig({ displayName: "SelectBar", componentId: "-ka3yx4" }) `
  position: relative;
  background: #f4f3f5;
  display: flex;
  z-index: 2;
  border-radius: 4px;
  border: var(--card-border);
  padding-inline: 0.5rem;
`;
export const SelectBarItem = styled.div.withConfig({ displayName: "SelectBarItem", componentId: "-1c0518m" }) `
  display: flex;
  align-items: center;
  padding: 8px 0.5rem 10px;
  color: #201a26;
  opacity: 0.9;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &.isDisabled {
    cursor: auto;
  }

  :hover {
    opacity: 1;
  }

  svg {
    margin-top: 2px;
  }
`;
export const Flyout = styled.div.withConfig({ displayName: "Flyout", componentId: "-cuf6we" }) `
  display: grid;
  //grid-template-columns: repeat(5, 240px) auto;
  grid-gap: 1rem;
  position: absolute;
  top: 75%;
  inset-inline: 0;
  visibility: hidden;
  max-height: 50vh;
  background: var(--lt-primary-dark);
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 0 0 4px 4px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

  &.isActive {
    top: 100%;
    opacity: 1;
    visibility: visible;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d4db;
    border-radius: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb:hover {
    ocpacity: 1;
  }
`;
export const FlyoutItem = styled.div.withConfig({ displayName: "FlyoutItem", componentId: "-bd6e1p" }) `
  padding: 1px 0;
  cursor: pointer;
  opacity: 0.95;
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 1;
  }
`;
export const GroupWrapper = styled.div.withConfig({ displayName: "GroupWrapper", componentId: "-1lg8r4z" }) `
  display: flex;
  margin-bottom: 1rem;
  padding: 0;
`;
export const GroupHeader = styled.div.withConfig({ displayName: "GroupHeader", componentId: "-1sjpjge" }) `
  position: relative;
  font-weight: bold;
  z-index: 1;
  width: 100%;
  :before {
    content: "";
    position: absolute;
    top: 1.5rem;
    inset-inline: 0;
    height: 1px;
    background: var(--lt-primary-lightest);
    opacity: 0.35;
    width: 100%;
  }

  strong {
    position: relative;
    background: var(--lt-primary-dark);
    padding-right: 0.5rem;
  }
`;
export const GroupChildren = styled.div.withConfig({ displayName: "GroupChildren", componentId: "-1din317" }) `
  padding: 0.25rem 0;
  font-weight: normal;
`;
export const OrphanedItems = styled.div.withConfig({ displayName: "OrphanedItems", componentId: "-wf3mck" }) `
  padding-bottom: 0.5rem;
  font-weight: normal;
`;
