var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import envVarConfig from "../../../env/envVarConfig";
import { instance } from "../../../index";
const userRegion = sessionStorage.getItem("Region");
const apiPath = envVarConfig.reactApiUrl + "/EverywhereWidget/";
export class AssistantService {
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            const token = yield instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [envVarConfig.backOfficeAppScope],
            });
            return token.accessToken;
        });
    }
}
_a = AssistantService;
AssistantService.getFiles = (tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
        };
        const response = yield fetch(`${apiPath}get-files/${tenantId}`, {
            method: "GET",
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return yield response.json();
    }
    catch (error) {
        console.error("Error uploading files:", error);
        throw error;
    }
});
AssistantService.uploadFiles = (tenantId, formData) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
        };
        const response = yield fetch(`${apiPath}upload-files/${tenantId}`, {
            method: "POST",
            headers: headers,
            body: formData,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return yield response.json();
    }
    catch (error) {
        console.error("Error uploading files:", error);
        throw error;
    }
});
AssistantService.deleteFile = (tenantId, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
        };
        const response = yield fetch(`${apiPath}delete-file/${tenantId}/${encodeURIComponent(fileName)}`, {
            method: "DELETE",
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.statusText;
    }
    catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
});
AssistantService.deleteFilesBulk = (tenantId, fileNames) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userToken = yield _a.getToken();
        const headers = {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
        };
        const response = yield fetch(`${apiPath}delete-files-bulk/${tenantId}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(fileNames),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.statusText;
    }
    catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
});
