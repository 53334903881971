import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdDragIndicator, MdOutlineModeEditOutline } from "react-icons/md";
import styled from "styled-components";
import { DeleteModal } from "../Modals/DeleteModal/DeleteModal";
import { EditingModal } from "../Modals/EditingModal/EditingModal";
import { iconDragWrapperStyle, iconStaticWrapperStyle, iconWrapperStyle, iconsWrapperEndStyle, iconsWrapperStartStyle, nodeStyle, nodeTextStyle, } from "../ContentEditor.styles";
const NodeWrapper = styled.div.withConfig({ displayName: "NodeWrapper", componentId: "-1sfx032" }) `
  ${nodeStyle}
`;
const NodeText = styled.span.withConfig({ displayName: "NodeText", componentId: "-1k7uwmm" }) `
  ${nodeTextStyle}
`;
const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "-12sbbkd" }) `
  ${iconWrapperStyle}
`;
const IconsWrapperStart = styled.div.withConfig({ displayName: "IconsWrapperStart", componentId: "-1cextgi" }) `
  ${iconsWrapperStartStyle}
`;
const IconsWrapperEnd = styled.div.withConfig({ displayName: "IconsWrapperEnd", componentId: "-1aed3a9" }) `
  ${iconsWrapperEndStyle}
`;
const IconDragWrapper = styled.div.withConfig({ displayName: "IconDragWrapper", componentId: "-ild3i6" }) `
  ${iconDragWrapperStyle}
`;
const IconStaticWrapper = styled.div.withConfig({ displayName: "IconStaticWrapper", componentId: "-13f7c0j" }) `
  ${iconStaticWrapperStyle}
`;
export const EditingSecondLevelNode = (props) => {
    const [node, setNode] = React.useState(props.node);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [isActive, setIsActive] = React.useState(props.isActive);
    function extractNode(node) {
        var _a;
        const matchedLabel = node.labels.find((label) => { var _a; return (label === null || label === void 0 ? void 0 : label.languageCode) === ((_a = props.selectedLanguage) === null || _a === void 0 ? void 0 : _a.languageCode); });
        return matchedLabel ? (matchedLabel.value) : (_jsx("i", Object.assign({ style: { opacity: 0.6 } }, { children: (_a = node.labels[0]) === null || _a === void 0 ? void 0 : _a.value })));
    }
    function updateNode(node) {
        setNode(node);
        props.updateNode(node, props.node);
        setShowEditModal(false);
    }
    return (_jsxs(NodeWrapper, Object.assign({ className: `lt-megamenu-secound-level-node-wrapper ${isActive ? "isActive" : ""} ${props.isInPath ? "isInPath" : ""} ` }, { children: [_jsx(IconsWrapperStart, { children: _jsx(IconDragWrapper, { children: _jsx(MdDragIndicator, {}) }) }), _jsx(NodeText, Object.assign({ onClick: () => {
                    props.setSelectedSecondLevelNode(node);
                } }, { children: extractNode(node) })), _jsxs(IconsWrapperEnd, { children: [_jsx(IconWrapper, Object.assign({ onClick: () => {
                            setShowEditModal(true);
                        } }, { children: _jsx(MdOutlineModeEditOutline, {}) })), _jsx(IconWrapper, { children: _jsx(DeleteModal, { node: node, deleteNode: () => { props.deleteNode(node); }, selectedLanguage: props.selectedLanguage }) }), !isActive && (_jsx(IconStaticWrapper, { children: _jsx(IoIosArrowBack, {}) })), isActive && (_jsx(IconStaticWrapper, { children: _jsx(IoIosArrowForward, {}) }))] }), _jsx(EditingModal, { selectedLanguage: props.selectedLanguage, node: node, updateNode: updateNode, closeModal: () => setShowEditModal(false), showModal: showEditModal })] })));
};
