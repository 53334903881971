var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import QuicklinksLayout from "./QuicklinksLayout";
import { QuicklinksService } from "./QuicklinksService";
import styled from "styled-components";
import { AudiencesService } from "../audiences/AudiencesService";
import { Spinner, Toast, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-saxe06" }) `
  margin-inline-end: 3rem;

  > h1 {
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: 24px;
    color: #3D3A3B;
  }

  > h4 {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2.5rem;
    color: #3D3A3B;
  }
`;
const Quicklinks = () => {
    const { GetLinks, GetCategories } = QuicklinksService();
    const { GetAudiences } = AudiencesService();
    const [quicklinks, setQuicklinks] = useState(null);
    const [categories, setCategories] = useState(null);
    const [audiences, setAudiences] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const getAllInformation = () => __awaiter(void 0, void 0, void 0, function* () {
        const links = yield GetLinks();
        setQuicklinks(links);
        const cat = yield GetCategories();
        setCategories(cat);
        const audiences = yield GetAudiences();
        if (audiences == null)
            setAudiences([]);
        else
            setAudiences(audiences === null || audiences === void 0 ? void 0 : audiences.audienceCollection);
        setIsLoading(false);
    });
    useEffect(() => {
        setIsLoading(true);
        getAllInformation();
    }, []);
    const onRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setQuicklinks(null);
        setAudiences(null);
        setCategories(null);
        try {
            yield getAllInformation();
        }
        catch (e) {
            console.error(e);
            setIsLoading(false);
        }
    });
    function notifyError(message) {
        notify(message);
    }
    const notify = (message) => {
        dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "error" });
    };
    return (_jsxs(Container, { children: [_jsx("h1", { children: "Quick Links" }), _jsx("h4", { children: "Manage and create links to be displayed on the Intranet." }), isLoading &&
                _jsx(Spinner, { size: "small", label: "We're getting everything ready for you..." }), (quicklinks != null && categories != null && audiences != null && !isLoading) ?
                (_jsx(QuicklinksLayout, { links: quicklinks, linkCategories: categories, audiences: audiences, refresh: onRefresh, notifyError: notifyError })) : null, _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
};
export default Quicklinks;
