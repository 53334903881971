import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import ThemeName from "./ThemeName";
import Publisher from "./Publisher";
import TopMenu from "./TopMenu";
import SiteCollection from "./SiteCollection";
import Color from "./Color";
import Logo from "./Logo";
import FontManager from "./FontManager";
import EditMenuStyle from "./EditMenuStyle";
import { useBranding } from "./BrandingContext";
import { useNavigate } from "react-router";
import { BrandingAPIService } from "../util/BrandingAPIService/BrandingAPIService";
import AdvancedSettings from "./AdvancedSettings";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner, Toast, Toaster, ToastTitle, useId, useToastController, } from "@fluentui/react-components";
import LivetilesWebparts from "./LivetilesWebparts";
const PageContainer = styled.div.withConfig({ displayName: "PageContainer", componentId: "-11dubhb" }) `
  display: flex;
  flex-direction: column;
  height: 80vh;
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "-ui2s0f" }) `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 40px; // Add some padding if needed
`;
const EditContainer = styled.div.withConfig({ displayName: "EditContainer", componentId: "-1qbp2xe" }) `
  display: flex;
  flex-direction: column;
`;
const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "-1papdlv" }) `
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
  margin-top: 50px;
`;
const EditTheme = () => {
    const [activeMenuItem, setActiveMenuItem] = React.useState(0);
    const { selectedTheme, setSelectedTheme, refreshThemes } = useBranding();
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [tryToSave, setTryToSave] = React.useState(false);
    const onClose = () => setShowDeleteDialog(false);
    const openDialog = () => setShowDeleteDialog(true);
    const navigate = useNavigate();
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const topMenuItems = [
        {
            name: "Color",
            component: _jsx(Color, { wizardMode: false }),
        },
        {
            name: "Logo",
            component: _jsx(Logo, { wizardMode: false, tryToSave: tryToSave }),
        },
        {
            name: "Menu Style",
            component: _jsx(EditMenuStyle, {}),
        },
        {
            name: "Font",
            component: _jsx(FontManager, { wizardMode: false }),
        },
        {
            name: "Livetiles Webparts",
            component: _jsx(LivetilesWebparts, { wizardMode: false }),
        },
        {
            name: "Advanced Settings",
            component: _jsx(AdvancedSettings, { wizardMode: false }),
        },
    ];
    function updateTheme() {
        setTryToSave(true);
        if (!selectedTheme.logo.name ||
            !selectedTheme.logo.size ||
            Object.keys(selectedTheme.logo).length === 0) {
            notifyNoLogo();
            return;
        }
        setIsSaving(true);
        selectedTheme.isPublished = false;
        BrandingAPIService.saveTheme(selectedTheme).then((response) => {
            notify("Theme saved successfully");
            refreshThemes();
            setIsSaving(false);
            setTryToSave(false);
        });
    }
    function deleteTheme() {
        BrandingAPIService.removeTheme(selectedTheme.id).then((response) => {
            refreshThemes();
            navigate("/intranet/branding");
        });
    }
    const notifyPublish = () => {
        notify("Theme published successfully");
    };
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "success" });
    const notifyNoLogo = () => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: "Please add a logo before saving the theme" }) }), { intent: "error" });
    const handleUpdateTryToSave = (value) => {
        setTryToSave(value);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(PageContainer, { children: [_jsxs(Header, { children: [_jsx(Publisher, { onPublish: notifyPublish, onPublishNoLogo: notifyNoLogo, onUpdateTryToSave: handleUpdateTryToSave }), _jsx(Button, Object.assign({ appearance: "transparent", onClick: openDialog }, { children: "Delete theme" }))] }), _jsxs(EditContainer, { children: [_jsx(ThemeName, { wizardMode: false }), _jsx(SiteCollection, { wizardMode: false }), _jsx(TopMenu, { menuItems: topMenuItems, onActiveItemChange: (index) => setActiveMenuItem(index) }), topMenuItems[activeMenuItem].component, isSaving ? (_jsx("div", Object.assign({ style: { marginTop: "50px" } }, { children: _jsx(Spinner, { size: "small", label: "Saving..." }) }))) : (_jsxs(Actions, { children: [_jsx(Link, Object.assign({ to: "/intranet/branding" }, { children: _jsx(Button, { children: "Cancel" }) })), _jsx(Button, Object.assign({ onClick: updateTheme, appearance: "primary" }, { children: "Save" }))] }))] })] }), showDeleteDialog && (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, Object.assign({ style: { color: "#512DA8", paddingBottom: "20px" } }, { children: "Delete theme" })), _jsx(DialogContent, { children: "Are you sure you want to delete this theme ?" }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ appearance: "primary", onClick: deleteTheme }, { children: "Delete" })), _jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" }))] })] }) })), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
};
export default EditTheme;
