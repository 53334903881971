var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import styled from "styled-components";
import { Dialog, Switch, Button, Toast, Toaster, ToastTitle, useId, useToastController, } from "@fluentui/react-components";
import EverywhereTab from "./EverywhereTab";
import { AddRegular } from "@fluentui/react-icons";
import AddEditTabModel from "./modals/AddEditTabModel";
import { EverywhereWidgetService } from "./EverywhereWidgetService";
import { ColorPicker } from "@fluentui/react";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-fzwqpi" }) `
  margin-inline-end: 3rem;
  font-family: "Segoe UI";

  > h1 {
    color: var(--NuetralPrimary, #3d3a3b);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  > h4 {
    color: var(--NuetralPrimary, #3d3a3b);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0px;
  }

  > h3 {
    color: var(--NuetralPrimary, #3d3a3b);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;
const LogoSelector = styled.div.withConfig({ displayName: "LogoSelector", componentId: "-o31eeb" }) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
const ImageLabel = styled.label.withConfig({ displayName: "ImageLabel", componentId: "-1iymhoi" }) `
  color: var(--NuetralPrimary, #3d3a3b);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
`;
const Logo = styled.img.withConfig({ displayName: "Logo", componentId: "-1nzctcp" }) `
  width: 74px;
  height: 74px;
`;
const PrimaryColorSelector = styled.div.withConfig({ displayName: "PrimaryColorSelector", componentId: "-1xjpch6" }) `
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;
  padding-bottom: 0px;
`;
const Group = styled.div.withConfig({ displayName: "Group", componentId: "-edb37t" }) `
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background-color: #fbfbfb;
  width: 610px;
`;
const Hr = styled.div.withConfig({ displayName: "Hr", componentId: "-fu1t2x" }) `
  position: relative;
  border-bottom: 2px solid #f2f2f2;
  left: 2%;
  width: 96%;
`;
const UploadLogo = styled.div.withConfig({ displayName: "UploadLogo", componentId: "-1xkaa84" }) `
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const BrandingLabel = styled.label.withConfig({ displayName: "BrandingLabel", componentId: "-1nwsmj4" }) `
  font-weight: 600;
  color: #3d3a3b;
  font-size: 16px;
`;
const ColorPickerRow = styled.div.withConfig({ displayName: "ColorPickerRow", componentId: "-ctjp54" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-top: 0px;
  align-items: center;
`;
const PickerToggle = styled.div.withConfig({ displayName: "PickerToggle", componentId: "-1jovt8n" }) `
  position: relative;
  width: 32px;
  height: 32px;
  background: conic-gradient(
    from 90deg,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red,
    violet
  );
  display: inline-block;
  border-radius: 50%;
  margin-block: 0 10px;
  cursor: pointer;
`;
const PickerToggleInner = styled.div.withConfig({ displayName: "PickerToggleInner", componentId: "-7d2mg7" }) `
  position: absolute;
  inset: 4px;
  border-radius: 50%;
  border: 2px solid white;
`;
const ColorLabel = styled.label.withConfig({ displayName: "ColorLabel", componentId: "-1fz2qjg" }) `
  font-weight: 600;
  color: #3d3a3b;
  font-size: 16px;
`;
const ColorSelectorRow = styled.div.withConfig({ displayName: "ColorSelectorRow", componentId: "-1cl9uz9" }) ``;
const ColorSelector = styled(ColorPicker).withConfig({ displayName: "ColorSelector", componentId: "-thb7o6" }) `
  padding-top: 0px;
`;
const ColorPickerContainer = styled.div.withConfig({ displayName: "ColorPickerContainer", componentId: "-c43f0z" }) `
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fbfbfb;
  margin-left: 310px;
  z-index: 2;
  border: 2px solid #f7f7f7;
  border-radius: 12px;

  .ms-ColorPicker-panel {
    padding: 14px 14px 20px;
  }

  .ms-TextField-fieldGroup {
    border-color: #e5e8e8;
    border-radius: 8px;
  }

  tr {
    height: 30px;
    font-weight: 600;
  }

  .mode-edit & {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.025), 0 2px 2px rgba(0, 0, 0, 0.025),
      0 4px 4px rgba(0, 0, 0, 0.025), 0 8px 8px rgba(0, 0, 0, 0.025);
  }
`;
const General = () => {
    var _a, _b;
    const [isLoading, setIsLoading] = useState(false);
    const [showAddEditTabModal, setShowAddEditTabModal] = useState(false);
    const [everywhereWidget, setEverywhereWidget] = useState(null);
    const [logo, setLogo] = useState(null);
    const [displayPicker, setDisplayPicker] = React.useState(false);
    const [selectedColor, setSelectedColor] = React.useState({ str: "#3D3A3B" });
    const fileInputRef = useRef(null);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    useEffect(() => {
        var _a;
        setLogo((_a = everywhereWidget === null || everywhereWidget === void 0 ? void 0 : everywhereWidget.logo) === null || _a === void 0 ? void 0 : _a.file);
        setSelectedColor({ str: everywhereWidget === null || everywhereWidget === void 0 ? void 0 : everywhereWidget.color });
    }, [everywhereWidget]);
    useEffect(() => {
        setIsLoading(true);
        EverywhereWidgetService.GetEverywhereWidget().then((response) => {
            setEverywhereWidget(response);
            setIsLoading(false);
        });
    }, []);
    const handleAddEditTabModalClose = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowAddEditTabModal(false);
    });
    const handleAddEditTabModalOpen = () => {
        setShowAddEditTabModal(true);
    };
    const useBrandingPrimaryColorChanged = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            setEverywhereWidget(yield EverywhereWidgetService.SaveEverywhereWidget(Object.assign(Object.assign({}, everywhereWidget), { useBrandingPrimaryColor: !(everywhereWidget === null || everywhereWidget === void 0 ? void 0 : everywhereWidget.useBrandingPrimaryColor) })));
            setIsLoading(false);
            notify("The use of Branding's primary color was updated successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const handleFileSelect = (e) => {
        var _a;
        e.stopPropagation();
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const getLogoData = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve({
                    name: file.name,
                    type: file.type,
                    file: fileReader.result,
                });
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const addTab = (tab) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const widget = Object.assign(Object.assign({}, everywhereWidget), { tabs: [...everywhereWidget.tabs, tab] });
            setEverywhereWidget(yield EverywhereWidgetService.SaveEverywhereWidget(widget));
            setIsLoading(false);
            notify("A new tab has been added successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const updateTab = (tab) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const widget = Object.assign(Object.assign({}, everywhereWidget), { tabs: everywhereWidget.tabs.map((item) => item.id == tab.id ? tab : item) });
            setEverywhereWidget(yield EverywhereWidgetService.SaveEverywhereWidget(widget));
            setIsLoading(false);
            notify("Tab information has been saved successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const deleteTab = (tab) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const widget = Object.assign(Object.assign({}, everywhereWidget), { tabs: everywhereWidget.tabs.filter((s) => s.id != tab.id) });
            setEverywhereWidget(yield EverywhereWidgetService.SaveEverywhereWidget(widget));
            setIsLoading(false);
            notify("Tab information has been deleted successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const uploadIcon = (icon) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const logo = yield getLogoData(icon);
            const widget = Object.assign(Object.assign({}, everywhereWidget), { logo: logo });
            setEverywhereWidget(yield EverywhereWidgetService.SaveEverywhereWidget(widget));
            setIsLoading(false);
            notify("New image have been uploaded successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "success" });
    const updatePrimaryColor = React.useCallback((ev, colorObj) => setSelectedColor(colorObj), []);
    const handleUpdateColorClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            setDisplayPicker(!displayPicker);
            setEverywhereWidget(yield EverywhereWidgetService.SaveEverywhereWidget(Object.assign(Object.assign({}, everywhereWidget), { color: selectedColor.str })));
            setIsLoading(false);
            notify("New colos have been applied successfully");
        }
        catch (e) {
            setIsLoading(false);
        }
    });
    return (_jsxs(Container, { children: [_jsx("h1", { children: "General" }), _jsx("h4", { children: "Customize the Everywhere Widget's settings such as the action button and tabs." }), isLoading ? (_jsx(Spinner, { size: SpinnerSize.medium, label: "We're getting everything ready for you...", ariaLive: "assertive", labelPosition: "right" })) : (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Action button" }), _jsxs(Group, { children: [_jsxs(LogoSelector, { children: [_jsxs(UploadLogo, { children: [_jsx(ImageLabel, { children: "Image" }), _jsx(Button, Object.assign({ style: {
                                                    color: "#512da8",
                                                    paddingLeft: "0px",
                                                    paddingTop: "9px",
                                                }, appearance: "transparent", onClick: handleFileSelect }, { children: "Upload new image" })), _jsx("input", { accept: "image/*", ref: fileInputRef, type: "file", hidden: true, onChange: () => {
                                                    var _a;
                                                    uploadIcon((_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files[0]);
                                                } })] }), logo && (_jsx(Logo, { src: logo, style: { width: "60px", height: "60px", marginRight: "5px" }, alt: "Icon" }))] }), _jsx(Hr, {}), _jsxs(PrimaryColorSelector, { children: [_jsx(BrandingLabel, { children: "Use branding's primary color" }), _jsx(Switch, { checked: everywhereWidget === null || everywhereWidget === void 0 ? void 0 : everywhereWidget.useBrandingPrimaryColor, onClick: (e) => {
                                            e.stopPropagation();
                                            useBrandingPrimaryColorChanged();
                                        } })] }), !(everywhereWidget === null || everywhereWidget === void 0 ? void 0 : everywhereWidget.useBrandingPrimaryColor) && (_jsxs(_Fragment, { children: [_jsxs(ColorPickerRow, { children: [_jsx(ColorLabel, { children: "Button color" }), _jsx(PickerToggle, Object.assign({ onClick: () => setDisplayPicker(!displayPicker) }, { children: _jsx(PickerToggleInner, { style: { background: selectedColor.str } }) }))] }), displayPicker && (_jsxs(ColorPickerContainer, { children: [_jsx(ColorSelector, { color: selectedColor.str, alphaType: "none", onChange: updatePrimaryColor }), _jsx(Button, Object.assign({ appearance: "primary", style: { margin: "15px" }, onClick: handleUpdateColorClicked }, { children: "Apply color" }))] }))] }))] }), _jsx("h3", { children: "Tabs" }), _jsxs(Group, { children: [(_b = (_a = everywhereWidget === null || everywhereWidget === void 0 ? void 0 : everywhereWidget.tabs) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
                                if (a.shortOrder === null || a.shortOrder === 0)
                                    return 1;
                                if (b.shortOrder === null || b.shortOrder === 0)
                                    return -1;
                                if (a.shortOrder < b.shortOrder)
                                    return -1;
                                if (a.shortOrder > b.shortOrder)
                                    return 1;
                                return 0;
                            })) === null || _b === void 0 ? void 0 : _b.map((tab) => (_jsx(EverywhereTab, { tab: tab, addTab: addTab, updateTab: updateTab, deleteTab: deleteTab }))), _jsx(Button, Object.assign({ style: { color: "#512da8", height: "50px" }, appearance: "transparent", icon: _jsx(AddRegular, {}), onClick: handleAddEditTabModalOpen }, { children: "Add new" }))] })] })), _jsx(Dialog, Object.assign({ open: showAddEditTabModal, onOpenChange: handleAddEditTabModalClose }, { children: _jsx(AddEditTabModel, { onClose: handleAddEditTabModalClose, addTab: addTab, updateTab: updateTab }) })), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
};
export default General;
