var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./index.css";
import React, { useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "./CustomNavigationClient/NavigationClient";
import Permissions from "./Permissions/Permissions";
import { useAppContext } from "./Context/AppContext";
const App = (_a) => {
    var { instance } = _a, opsCenterProps = __rest(_a, ["instance"]);
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    instance.setNavigationClient(navigationClient);
    const { appState, setAppState } = useAppContext();
    useEffect(() => {
        setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { opsCenterProps })));
    }, []);
    return (_jsx(MsalProvider, Object.assign({ instance: instance }, { children: _jsx(Routes, { children: _jsx(Route, { path: "/*", element: _jsx(Permissions, {}) }) }) })));
};
export default App;
