import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AutoSuggest from "react-autosuggest";
import "./MultiStringInput.css";
import { MegaMenuAPIService } from "../../util/MegaMenuAPIService/MegaMenuAPIService";
import { draftStatus } from "../constants";
const MultiStringInputContainer = styled.div.withConfig({ displayName: "MultiStringInputContainer", componentId: "-e0bs0j" }) `
  display: grid;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  padding: 5px;
`;
const ValueTag = styled.div.withConfig({ displayName: "ValueTag", componentId: "-1vm0ij5" }) `
  width:fit-content;
  display: inline-flex;
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0.25rem 0.5rem 0.75rem 0;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
`;
const RemoveButton = styled.button.withConfig({ displayName: "RemoveButton", componentId: "-1lf336l" }) `
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
const StyledInputWrapper = styled.div.withConfig({ displayName: "StyledInputWrapper", componentId: "-1eh158p" }) `
  flex-grow: 1;

  .react-autosuggest__input {
    height: auto;
    padding: 11px 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    font-size: 14px;
  }
  .react-autosuggest__suggestions-list {
    max-height: 200px;
   
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #888 #fff;

    ::-webkit-scrollbar {
    width: 8px;
    }
    ::-webkit-scrollbar-track {
    background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    }
  }

  .react-autosuggest__suggestions-container{
    width:100%;
  }

  .react-autosuggest__suggestion {
    :hover{
      background-color: #e6e6e6;
    }
  }
`;
const PopupContainer = styled.div.withConfig({ displayName: "PopupContainer", componentId: "-d8w82b" }) `
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PopupContent = styled.div.withConfig({ displayName: "PopupContent", componentId: "-8leze5" }) `
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 4px;
  width: 30%;
  text-align: center;
`;
const getSuggestions = (value, sites) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
        ? []
        : sites.filter((site) => site.webUrl.toLowerCase().includes(inputValue));
};
const renderSuggestion = (suggestion) => _jsx("div", { children: suggestion.webUrl });
const MultiStringInput = ({ clientRegion, values, onChange, siteCollections, triggerAddChange, setTriggerChange, }) => {
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [sites, setSites] = useState([]);
    const [showError, setShowError] = useState(false);
    const [sitesInUse, setSitesInUse] = useState(values);
    const addValue = (value) => {
        const valueToAdd = value || inputValue;
        if (valueToAdd.trim()) {
            const siteExists = sites.some((site) => site.webUrl === valueToAdd);
            const urlAlreadyExists = siteCollections
                .filter((siteCollection) => siteCollection.status == draftStatus)
                .some((siteCollection) => siteCollection.siteCollectionUrls.includes(valueToAdd));
            if (siteExists && !urlAlreadyExists) {
                const newValues = sitesInUse.concat(valueToAdd);
                setSitesInUse(newValues);
                onChange(newValues, false);
                setInputValue("");
                setShowError(false);
                setTriggerChange(false);
            }
            else {
                setShowError(true);
                setTriggerChange(false);
            }
        }
    };
    const handleRemoveValue = (index) => {
        const newSites = sitesInUse.filter((_, i) => i !== index);
        setSitesInUse(newSites);
        onChange(sitesInUse.filter((_, i) => i !== index), false);
    };
    const onChangeInput = (event, { newValue }) => {
        setInputValue(newValue);
    };
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, sites));
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };
    const inputProps = {
        placeholder: "Type a value and press Enter",
        value: inputValue,
        onChange: onChangeInput,
        //onKeyDown: handleKeyDown,
    };
    const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        event.preventDefault();
        addValue(suggestionValue);
    };
    useEffect(() => {
        MegaMenuAPIService.getSiteCollection(clientRegion).then((Sites) => {
            setSites(Sites);
        });
    }, []);
    useEffect(() => {
        if (triggerAddChange)
            addValue();
    }, [triggerAddChange]);
    const extractPostCom = (url) => {
        const index = url.indexOf(".com");
        return index !== -1 ? url.substring(index + 4) : url;
    };
    const getSuggestionValue = (suggestion) => suggestion.webUrl;
    return (_jsxs(MultiStringInputContainer, { children: [_jsx(StyledInputWrapper, { children: _jsx(AutoSuggest, { className: "lt-style", suggestions: suggestions, onSuggestionsFetchRequested: ({ value }) => {
                        if (value.length >= 3) {
                            setSuggestions(getSuggestions(value, sites));
                        }
                    }, onSuggestionSelected: onSuggestionSelected, onSuggestionsClearRequested: onSuggestionsClearRequested, getSuggestionValue: getSuggestionValue, renderSuggestion: renderSuggestion, inputProps: inputProps }) }), _jsx("div", Object.assign({ style: { display: "flex", flexWrap: "wrap" } }, { children: sitesInUse.map((value, index) => (_jsxs(ValueTag, { children: [_jsx("span", { children: extractPostCom(value) }), _jsx(RemoveButton, Object.assign({ onClick: () => handleRemoveValue(index) }, { children: "x" }))] }, index))) })), showError && (_jsx(PopupContainer, { children: _jsxs(PopupContent, { children: [_jsx("p", { children: "Invalid URL or URL already in use in another megamenu" }), _jsx("button", Object.assign({ className: "lt-style lt-purple-lighter", onClick: () => setShowError(false) }, { children: "Close" }))] }) }))] }));
};
export default MultiStringInput;
