// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  width: 280px;
  margin: 0;
  padding: 0;
  list-style-type: none !important;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
}

.react-autosuggest__suggestions-container > ul {
  list-style-type: none !important;
  border: 1px solid #aaa;
  padding: 0;
  margin-top: 0;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/pages/intranet/util/MultiStringInput/MultiStringInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,gCAAgC;EAChC,sBAAsB;EACtB,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,8BAA8B;EAC9B,+BAA+B;EAC/B,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,sBAAsB;EACtB,UAAU;EACV,aAAa;AACf;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".react-autosuggest__container {\n  position: relative;\n}\n\n.react-autosuggest__input {\n  width: 100%;\n  height: 30px;\n  padding: 10px 20px;\n  font-family: Helvetica, sans-serif;\n  font-weight: 300;\n  font-size: 16px;\n  border: 1px solid #aaa;\n  border-radius: 4px;\n}\n\n.react-autosuggest__input:focus {\n  outline: none;\n}\n\n.react-autosuggest__container--open .react-autosuggest__input {\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.react-autosuggest__suggestions-container {\n  position: absolute;\n  width: 280px;\n  margin: 0;\n  padding: 0;\n  list-style-type: none !important;\n  background-color: #fff;\n  font-family: Helvetica, sans-serif;\n  font-weight: 300;\n  font-size: 16px;\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n  z-index: 10;\n}\n\n.react-autosuggest__suggestions-container > ul {\n  list-style-type: none !important;\n  border: 1px solid #aaa;\n  padding: 0;\n  margin-top: 0;\n}\n.react-autosuggest__suggestion {\n  cursor: pointer;\n  padding: 10px 20px;\n}\n\n.react-autosuggest__suggestion--focused {\n  background-color: #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
