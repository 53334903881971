import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { IdentityType } from "./Picker";
import styled from "styled-components";
import TrashcanIcon from "/public/Trashcan.svg";
import EllipseIcon from "/public/Ellipse.svg";
import { IoCloseOutline } from "react-icons/io5";
import { QuicklinksService } from "../../QuicklinksService";
const PickedUserWrapper = styled.div.withConfig({ displayName: "PickedUserWrapper", componentId: "-16q2urz" }) `
  display: flex;
  align-items: center;
  margin: 5px 10px 10px 0;
  padding: ${(p) => (p.styleForUser ? "null" : "6px 8px")};
  background-color: ${(p) => (p.styleForUser ? "white" : "#f3f3f3")};
  border-radius: 8px;
`;
const ProfileImage = styled.img.withConfig({ displayName: "ProfileImage", componentId: "-duxhnf" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
`;
const ProfileWrapper = styled.div.withConfig({ displayName: "ProfileWrapper", componentId: "-9etw64" }) `
  display: flex;
`;
const ProfileName = styled.span.withConfig({ displayName: "ProfileName", componentId: "-y8rcgq" }) `
  ${(p) => p.styleForUser
    ? `
    color: var(--NuetralPrimary, #3D3A3B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Segoe UI;
    font-size: 14px;
    padding-top: 4px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap:nowrap;
    `
    : ` 
      color: var(--NuetralPrimary, #3D3A3B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-wrap:nowrap;
    `}
`;
const ProfileIdentity = styled.span.withConfig({ displayName: "ProfileIdentity", componentId: "-1v18i0m" }) `
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.6;
`;
const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "-42nfn6" }) `
  padding-top: ${(p) => (p.styleForUser ? "6px" : "")};
  display: flex;
  justify-content: end;
  padding-right: 5px;
  padding-left: ${(p) => (p.styleForUser ? "6px" : "")};
  position: ${(p) => (p.styleForUser ? "absolute" : "")};
  right: ${(p) => (p.styleForUser ? "0" : "")};
`;
export const MicrosoftPallete = [
    "#A4262C",
    "#CA5010",
    "#8F7034",
    "#407855",
    "#038387",
    "#0078d4",
    "#40587C",
    "#4052AB",
    "#854085",
    "#8764B8",
    "#737373",
    "#867365",
];
const NoImagePlaceHolder = styled.div.withConfig({ displayName: "NoImagePlaceHolder", componentId: "-1p0qmwk" }) `
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${(p) => p.color};
  display: flex;
  justify-content: center;
`;
const NoImageName = styled.span.withConfig({ displayName: "NoImageName", componentId: "-1u36ebe" }) `
  color: white;
  padding-top: 2.5px;
  font-weight: 600;
`;
const ProfileDetails = styled.div.withConfig({ displayName: "ProfileDetails", componentId: "-qgeve0" }) `
  display: flex;
  flex-direction: ${(p) => (p.styleForUser ? "null" : "column")};
  padding-inline: 8px;
  line-height: 1rem;
`;
const Elipse = styled.img.withConfig({ displayName: "Elipse", componentId: "-1gd4jiw" }) `
  width: 11px;
  margin-left: 12px;
  margin-right: 17px;
  margin-top: 7px;
`;
const EmailWrapper = styled.div.withConfig({ displayName: "EmailWrapper", componentId: "-1hxuk2k" }) `
  color: var(--NuetralSecondary, #707070);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 7px;
`;
const Trashcan = styled.img.withConfig({ displayName: "Trashcan", componentId: "-k633uw" }) `
  width: 13px;
  height: 14px;
  cursor: pointer;
`;
export const PickedUser = (props) => {
    const { GetUser } = QuicklinksService();
    const [userInfo, setUserInfo] = React.useState(null);
    const [userColor, setUserColor] = React.useState(null);
    const [styleForUser, setStyleForUser] = React.useState(false);
    React.useEffect(() => {
        var _a, _b;
        if (props.displayType === IdentityType.User) {
            setStyleForUser(true);
        }
        if ((_a = props.user) === null || _a === void 0 ? void 0 : _a.displayName)
            setUserInfo(props.user);
        else {
            console.log(props.user);
            GetUser((_b = props.user) === null || _b === void 0 ? void 0 : _b.loginName).then((result) => {
                setUserInfo(result);
            });
        }
    }, []);
    React.useEffect(() => {
        if (userInfo && userInfo.displayName) {
            const lastLetter = userInfo.displayName.charAt(userInfo.displayName.length - 1);
            const index = lastLetter.charCodeAt(0) % MicrosoftPallete.length;
            setUserColor(MicrosoftPallete[index]);
        }
    }, [userInfo]);
    function getNameNoPhoto() {
        if (!userInfo || !userInfo.displayName)
            return null;
        const split = userInfo.displayName.split(" ");
        if (split.length > 1) {
            return split[0].charAt(0) + split[1].charAt(0);
        }
        else {
            return split[0].charAt(0);
        }
    }
    return (_jsx(_Fragment, { children: userInfo && (_jsxs(PickedUserWrapper, Object.assign({ styleForUser: styleForUser }, { children: [_jsx(ProfileWrapper, { children: styleForUser ? (userInfo.image ? (_jsx(ProfileImage, { src: userInfo.image })) : (_jsx(NoImagePlaceHolder, Object.assign({ color: userColor }, { children: _jsx(NoImageName, { children: getNameNoPhoto() }) })))) : null }), styleForUser ? (_jsxs(ProfileDetails, Object.assign({ styleForUser: styleForUser }, { children: [_jsx(ProfileName, Object.assign({ styleForUser: styleForUser }, { children: userInfo.displayName
                                ? userInfo.displayName
                                : "Error Retrieving Data" })), _jsx(Elipse, { src: EllipseIcon }), _jsx(EmailWrapper, { children: userInfo === null || userInfo === void 0 ? void 0 : userInfo.loginName })] }))) : (_jsx(ProfileName, Object.assign({ styleForUser: styleForUser }, { children: userInfo.displayName
                        ? userInfo.displayName
                        : "Error Retrieving Data" }))), _jsx(ButtonWrapper, Object.assign({ styleForUser: styleForUser }, { children: styleForUser ? (_jsx(Trashcan, { onClick: () => {
                            props.removeUser(props.user);
                        }, src: TrashcanIcon })) : (_jsx(IoCloseOutline, { onClick: () => {
                            props.removeUser(props.user);
                        }, style: { cursor: "pointer" }, size: "20px" })) }))] }))) }));
};
