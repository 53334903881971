import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import styled from "styled-components";
import NavigationMatrix from "./NavigationMatrix";
const NavigationContainer = styled.div.withConfig({ displayName: "NavigationContainer", componentId: "-1jebxx6" }) `
  margin-inline-end: 3rem;
`;
const Matrix = (props) => {
    return (_jsx(NavigationContainer, { children: _jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(NavigationMatrix, { tenantId: props.tenantId, userName: props.userName, userEmail: props.userEmail, clientRegion: props.clientRegion }) })) }));
};
export default Matrix;
