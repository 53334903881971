import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { demoMenu } from "../util/DemoMenu";
import { ContentEditor } from "./EditingView/ContentEditor";
import { createGlobalStyle } from "styled-components";
import { StylingWrapper } from "./StylingView/StylingWrapper";
export var IMegaMenuWebpartSectionView;
(function (IMegaMenuWebpartSectionView) {
    IMegaMenuWebpartSectionView[IMegaMenuWebpartSectionView["GENERAL_SETTINGS"] = 0] = "GENERAL_SETTINGS";
    IMegaMenuWebpartSectionView[IMegaMenuWebpartSectionView["CONTENT_EDITING"] = 1] = "CONTENT_EDITING";
    IMegaMenuWebpartSectionView[IMegaMenuWebpartSectionView["FONTS_COLOR"] = 2] = "FONTS_COLOR";
    IMegaMenuWebpartSectionView[IMegaMenuWebpartSectionView["TRANSLATIONS"] = 3] = "TRANSLATIONS";
})(IMegaMenuWebpartSectionView || (IMegaMenuWebpartSectionView = {}));
const GlobalStyle = createGlobalStyle `
    :root {
        --lt-first-level-background: ${(p) => p.firstLevelBackground};
        --lt-first-level-text: ${(p) => p.firstLevelFontColor};
        --lt-first-level-text-format: ${(p) => p.firstLevelTextFormat};
        --lt-second-level-text-format: ${(p) => p.secondLevelTextFormat};
        --lt-third-level-text-format: ${(p) => p.thirdLevelTextFormat};
        --lt-second-level-width: 240px;
    }
`;
export const MegaMenuWebpartSection = ({ megaMenu, menuIndexValue, selectedFirstLevelNode: firstLevelNode, selectedSecondLevelNode: secondLevelNode, selectedThirdLevelNode: thirdLevelNode, selectedFourthLevelNode: fourthLevelNode, selectedNode, view, setSelectedNode, setSelectedFirstLevelNode, setSelectedSecondLevelNode, setSelectedThirdLevelNode, setSelectedFourthLevelNode, updateMenu, }) => {
    function updateNodes(nodes) {
        const newMegaMenu = Object.assign({}, megaMenu);
        newMegaMenu.siteCollection[menuIndexValue].nodes = nodes;
        updateMenu(newMegaMenu);
    }
    demoMenu.generalOptions = megaMenu.generalOptions;
    return (_jsxs(_Fragment, { children: [_jsx(GlobalStyle, { firstLevelBackground: megaMenu.siteCollection[menuIndexValue].styles
                    .firstLevelBackgroundColor, firstLevelFontColor: megaMenu.siteCollection[menuIndexValue].styles.firstLevelFontColor, firstLevelTextFormat: megaMenu.siteCollection[menuIndexValue].styles.firstLevelTextFormat, secondLevelTextFormat: megaMenu.siteCollection[menuIndexValue].styles.secondLevelTextFormat, thirdLevelTextFormat: megaMenu.siteCollection[menuIndexValue].styles.thirdLevelTextFormat }), view === 1 ? (_jsx(ContentEditor, { selectedNode: selectedNode, selectedLanguage: megaMenu.generalOptions.primaryLanguage, setSelectedNode: (node) => {
                    setSelectedNode(node);
                }, useSearchBar: megaMenu.generalOptions.useSearchBar, updateNodes: updateNodes, nodes: megaMenu.siteCollection[menuIndexValue].nodes, selectedFirstNode: firstLevelNode, setSelectedFirstNode: setSelectedFirstLevelNode, selectedSecondLevelNode: secondLevelNode, setSelectedSecondLevelNode: setSelectedSecondLevelNode, selectedThirdLevelNode: thirdLevelNode, setSelectedThirdLevelNode: setSelectedThirdLevelNode, selectedFourthLevelNode: fourthLevelNode, setSelectedFourthLevelNode: setSelectedFourthLevelNode }, crypto.randomUUID())) : view === 2 ? (_jsx(StylingWrapper, { megaMenuItem: megaMenu, selectedMenuIndex: menuIndexValue }, crypto.randomUUID())) : ("")] }));
};
