import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import NavigationComponent from "./NavigationComponent";
import Edit from "../../../../public/Edit.svg";
import { useBranding } from "./BrandingContext";
import { BrandingAPIService } from "../util/BrandingAPIService/BrandingAPIService";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input } from "@fluentui/react-components";
const Title = styled.h1.withConfig({ displayName: "Title", componentId: "-zv2pt" }) `
  margin-block: 0 10px;
`;
const Subtitle = styled.p.withConfig({ displayName: "Subtitle", componentId: "-1w32g60" }) `
  margin-block: 0 20px;
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-145hp3w" }) `
  min-height: calc(100vh - 200px);
  padding-left: 64px;
  padding-top: 160px;
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div.withConfig({ displayName: "InputContainer", componentId: "-z3aezu" }) `
  width: 100%;
  display: flex;
  margin-block: 15px;
`;
const StyledTextField = styled(Input).withConfig({ displayName: "StyledTextField", componentId: "-1m851q0" }) `
  max-width: 400px;
  width: 100%;

  [role="dialog"] & {
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;
const EditLink = styled.span.withConfig({ displayName: "EditLink", componentId: "-5gob0" }) `
  font-size: 14px;
  font-weight: 400;
  color: #512da8;
  margin-left: 5px;
  cursor: pointer;
`;
const TitleContainer = styled.div.withConfig({ displayName: "TitleContainer", componentId: "-hr9xkg" }) `
  display: flex;
  align-items: center;
`;
const Icon = styled.img.withConfig({ displayName: "Icon", componentId: "-11xmyso" }) `
  margin: 0 0 0 10px;
`;
const ThemeName = (props) => {
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const { selectedTheme, setSelectedTheme, refreshThemes } = useBranding();
    const [themeName, setThemeName] = useState((selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeName) || "");
    React.useEffect(() => {
        setThemeName((selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeName) || "");
    }, [isDialogVisible]);
    React.useEffect(() => {
        if (isDialogVisible)
            return;
        setSelectedTheme(Object.assign(Object.assign({}, selectedTheme), { themeName: themeName }));
    }, [themeName]);
    const closeDialog = () => setIsDialogVisible(false);
    const saveThemeName = () => {
        if (selectedTheme) {
            setSelectedTheme(Object.assign(Object.assign({}, selectedTheme), { themeName: themeName }));
        }
        BrandingAPIService.updateThemeName((selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.id) || "", themeName);
        closeDialog();
    };
    const onThemeNameChange = (ev, data) => {
        setThemeName(data.value);
    };
    return (_jsx("div", { children: props.wizardMode ? (_jsxs(Container, { children: [_jsx(Title, { children: "First, give it a name" }), _jsx(Subtitle, { children: "Define a name for the theme you are creating." }), _jsx(InputContainer, { children: _jsx(StyledTextField, { placeholder: "Enter theme name", defaultValue: (selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeName) || "", onChange: onThemeNameChange, size: "large" }) }), _jsx(NavigationComponent, { currentStepName: "Name" })] })) : (_jsx("div", { children: _jsxs(TitleContainer, { children: [_jsx(Title, { children: selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeName }), _jsx(Icon, { src: Edit, alt: "Edit" }), _jsxs(Dialog, Object.assign({ open: isDialogVisible, onOpenChange: (event, data) => setIsDialogVisible(data.open) }, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(EditLink, { children: "Edit name" }) })), _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Edit theme\u2019s name" }), _jsxs(DialogContent, { children: [_jsx("p", { children: "Define a name for this theme." }), _jsx(StyledTextField, { placeholder: "Enter theme name", defaultValue: (selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeName) || "", onChange: onThemeNameChange })] }), _jsxs(DialogActions, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, Object.assign({ appearance: "secondary" }, { children: "Cancel" })) })), _jsx(Button, Object.assign({ onClick: saveThemeName, appearance: "primary" }, { children: "Save" }))] })] }) })] }))] }) })) }));
};
export default ThemeName;
