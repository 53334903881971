import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import UnderConstruction from "./UnderConstruction";
const Directory = () => {
    const [url, setUrl] = React.useState();
    React.useEffect(() => {
        const region = sessionStorage.getItem("Region") || "EMEA";
        if (region === "EMEA") {
            setUrl("https://app.hyperfish.co.uk");
        }
        else if (region === "APAC") {
            setUrl("https://app.hyperfish.com.au/");
        }
        else {
            setUrl("https://app.hyperfish.com/");
        }
    }, []);
    return (_jsx(UnderConstruction, { buttonLink: url, buttonText: "Go to Directory" }));
};
export default Directory;
