import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import BorderStyle from "./BorderStyle";
import MenuTextOptions from "./MenuTextOptions";
import SubmenuType from "./SubmenuType";
import { styled } from "styled-components";
const EditMenuStyleContainer = styled.div.withConfig({ displayName: "EditMenuStyleContainer", componentId: "-1uwp3mh" }) `
  display: flex;
  flex-direction: column;
`;
const MenuStyleTabs = styled.ul.withConfig({ displayName: "MenuStyleTabs", componentId: "-fkhnzj" }) `
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px auto 30px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #B3B3B3;
  border-radius: 4px;
`;
const MenuStyleTab = styled.li.withConfig({ displayName: "MenuStyleTab", componentId: "-yuc2n9" }) `
  padding: 5px 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & + & {
    border-left: 1px solid #B3B3B3;
  }

  &.active {
    background-color: #F2F2F2;
  }
`;
const menuStyleOptions = [
    {
        name: "Button Shape",
        component: _jsx(BorderStyle, { wizardMode: false })
    },
    {
        name: "Text Settings",
        component: _jsx(MenuTextOptions, { wizardMode: false })
    },
    {
        name: "Submenu",
        component: _jsx(SubmenuType, { wizardMode: false })
    },
];
const EditMenuStyle = () => {
    const [activeTab, setActiveTab] = React.useState(0);
    return (_jsxs(EditMenuStyleContainer, { children: [_jsx(MenuStyleTabs, { children: menuStyleOptions.map((item, index) => (_jsx(MenuStyleTab, Object.assign({ onClick: () => setActiveTab(index), className: activeTab === index ? "active" : "" }, { children: item.name }), index))) }), menuStyleOptions[activeTab].component] }));
};
export default EditMenuStyle;
