import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled } from "styled-components";
const TopMenuItems = styled.ul.withConfig({ displayName: "TopMenuItems", componentId: "-1hxssr3" }) `
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
  font-size: 14px;
`;
const TopMenuItem = styled.li.withConfig({ displayName: "TopMenuItem", componentId: "-c1qdjo" }) `
  position: relative;
  padding: 10px 0;
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }

  &::after {
    position: absolute;
    content: "";
    top: 100%;
    inset-inline: 0;
    height: 3px;
    background: transparent;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
  }

  &.active {
    font-weight: 600;
    
    &::after {
      background-color: #512da8;
    }
  }
`;
const TopMenu = (props) => {
    const [activeMenuItem, setActiveMenuItem] = React.useState(0);
    const handleActiveItemChange = (index, props) => {
        setActiveMenuItem(index);
        props.onActiveItemChange(index);
    };
    return (_jsx(TopMenuItems, { children: props.menuItems.map((item, index) => (_jsx(TopMenuItem, Object.assign({ className: activeMenuItem === index ? "active" : "", onClick: () => handleActiveItemChange(index, props) }, { children: item.name }), item.name))) }));
};
export default TopMenu;
