var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import envVarConfig from "../env/envVarConfig";
const environment = envVarConfig.environment;
// Fix to allow react-beautiful-dnd to work with React.StrictMode
// https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
export const StrictModeDroppable = (_a) => {
    var props = __rest(_a, []);
    const [enabled, setEnabled] = useState(environment !== "local");
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    return enabled ? _jsx(Droppable, Object.assign({}, props)) : null;
};
