var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NavigationClient } from "@azure/msal-browser";
/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
    constructor(navigate) {
        super();
        this.navigate = navigate;
    }
    /**
     * Navigates to other pages within the same web application
     * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
     * @param url
     * @param options
     */
    navigateInternal(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const relativePath = url.replace(window.location.origin, "");
            if (options.noHistory) {
                this.navigate(relativePath, { replace: true });
            }
            else {
                this.navigate(relativePath);
            }
            return false;
        });
    }
}
