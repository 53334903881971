import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { styled } from "styled-components";
import { Picker } from "../components/AudienceTargeting/Picker/Picker";
import DismissIcon from "/public/Dismiss.svg";
export const ModalWrapper = styled.div.withConfig({ displayName: "ModalWrapper", componentId: "-1ra8qkc" }) `
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1040;
  border-radius: 20px;
`;
export const Modal = styled.div.withConfig({ displayName: "Modal", componentId: "-heuyzu" }) `
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  verflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  display: flex;
  width: 600px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  flex-shrink: 0;
  background: white;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2),
    0px 32px 64px 0px rgba(0, 0, 0, 0.24);
`;
export const CloseWrapper = styled.div.withConfig({ displayName: "CloseWrapper", componentId: "-1w2y8j8" }) `
  width: 100%;
  display: flex;
  justify-content: end;
`;
export const CloseImg = styled.img.withConfig({ displayName: "CloseImg", componentId: "-kwxlxl" }) `
  cursor: pointer;
`;
export const ModalMessageWrapper = styled.div.withConfig({ displayName: "ModalMessageWrapper", componentId: "-11ea7rq" }) `
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #242424;
`;
export const ModalSubtitle = styled.div.withConfig({ displayName: "ModalSubtitle", componentId: "-1hzvwmo" }) `
  display: flex;
  width: 546px;
  height: 31px;
  flex-direction: column;
  justify-content: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #242424;
`;
export const InviteActionsWrapper = styled.div.withConfig({ displayName: "InviteActionsWrapper", componentId: "-oqnwey" }) `
  width: 100%;
  display: flex;
`;
export const SendInviteButton = styled.button.withConfig({ displayName: "SendInviteButton", componentId: "-1pqmnmy" }) `
  cursor: ${(p) => (p.hasUsers ? "pointer" : "default")};
  margin-left: 20px;
  color: white;
  border: none;
  margin-top: 13px;
  display: flex;
  width: 130px;
  height: 32px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${(p) => p.hasUsers ? "#512DA8" : "#CCC"};
`;
const UserPickerWrapper = styled.div.withConfig({ displayName: "UserPickerWrapper", componentId: "-d6apgx" }) `
  width: 100%;
  display: grid;
`;
const ActionButtonsWrapper = styled.div.withConfig({ displayName: "ActionButtonsWrapper", componentId: "-xvi0l6" }) `
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 20px;
  padding-bottom: 5px;
`;
const CancelButton = styled.button.withConfig({ displayName: "CancelButton", componentId: "-1f3kdb1" }) `
  margin-right: 16px;
  display: flex;
  padding: 6px 20px;
  align-items: center;
  color: #201f1e;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid var(--grey-palette-grey-110, #8a8886);
  width: 82px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
`;
const SaveButton = styled.button.withConfig({ displayName: "SaveButton", componentId: "-13yfl4g" }) `
  width: 82px;
  height: 32px;
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  background: ${(p) => (p.hasUsers ? "#512DA8" : "#CCC")};
  border: none;
  cursor: pointer;
`;
export const InviteModal = (props) => {
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    function deleteUser(user) {
        const updated = selectedUsers.filter((u) => {
            return u.id !== user.id;
        });
        setSelectedUsers(updated);
    }
    function addUsers(users) {
        setSelectedUsers(users);
        //props.updateUsers(users);
    }
    return (_jsx(ModalWrapper, { children: _jsxs(Modal, { children: [_jsxs(CloseWrapper, { children: [_jsx(ModalMessageWrapper, { children: "Invite users to access the Operations Center" }), _jsx(CloseImg, { onClick: props.close, src: DismissIcon })] }), _jsx(ModalSubtitle, { children: "Determine who can access the Operations Center settings." }), _jsx(InviteActionsWrapper, { children: _jsx(UserPickerWrapper, { children: _jsx(Picker, { isFromInvite: true, deleteUser: deleteUser, selectedUsers: selectedUsers, setSelectedUsers: addUsers, identityType: undefined }) }) }), _jsxs(ActionButtonsWrapper, { children: [_jsx(CancelButton, Object.assign({ onClick: props.close }, { children: "Cancel" })), _jsx(SaveButton, Object.assign({ hasUsers: selectedUsers && selectedUsers.length !== 0, onClick: selectedUsers && selectedUsers.length
                                ? () => {
                                    props.updateUsers(selectedUsers);
                                    props.close();
                                }
                                : null }, { children: "Save" }))] })] }) }));
};
