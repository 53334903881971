import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Switch } from "@fluentui/react-components";
const ToggleContainer = styled.div.withConfig({ displayName: "ToggleContainer", componentId: "-1sljemc" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  > * {
    margin: 0;
  }

  button {
    background-color: rgb(0, 90, 158);
    border-color: transparent;

    .ms-Toggle-thumb,
    &:hover .ms-Toggle-thumb {
      background-color: #fff;
      transition: all 0.3s ease-in-out;
    }
  }
`;
const ToggleLabel = styled.label.withConfig({ displayName: "ToggleLabel", componentId: "-1xbdq26" }) `
  color: #cccccc;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;

  &.active {
    color: #3D3A3B;
  }
`;
const LabeledToggle = (props) => {
    const [checked, setChecked] = React.useState(props.checked);
    const onChangeChecked = React.useCallback((ev) => {
        setChecked(ev.currentTarget.checked);
        props.onChangeChecked(ev.currentTarget.checked);
    }, [setChecked]);
    return (_jsxs(ToggleContainer, { children: [_jsx(ToggleLabel, Object.assign({ className: !checked ? "active" : "" }, { children: props.labels[0] })), _jsx(Switch, { onChange: onChangeChecked, defaultChecked: props.checked ? true : false }), _jsx(ToggleLabel, Object.assign({ className: checked ? "active" : "" }, { children: props.labels[1] }))] }));
};
export default LabeledToggle;
