import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { RiDeleteBin6Line } from "react-icons/ri";
import { iconWrapperStyle, iconsWrapperEndStyle, nodeStyle, nodeTextStyle, } from "../ContentEditor.styles";
import DeleteModal from "../modals/DeleteModal/DeleteModal";
const NodeWrapper = styled.div.withConfig({ displayName: "NodeWrapper", componentId: "-mzvmqn" }) `
  ${nodeStyle}

  .lt-matrix-node-actions {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &:hover {
    .lt-matrix-node-actions {
      opacity: 1;
    }

    .view-pathways & {
      background-color: var(--lt-purple-bg-lighter);
      border-color: var(--lt-purple);
      box-shadow: none;
      color: var(--lt-purple);
    }
  }
`;
const NodeText = styled.span.withConfig({ displayName: "NodeText", componentId: "-1s1ufb6" }) `
  ${nodeTextStyle}
`;
const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "-y8932g" }) `
  ${iconWrapperStyle}
`;
const IconsWrapperEnd = styled.div.withConfig({ displayName: "IconsWrapperEnd", componentId: "-s4x8ta" }) `
  ${iconsWrapperEndStyle}
`;
export const DimensionItem = (props) => {
    const [item, setItem] = React.useState(props.item);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [isContentSelected, setIsContentSelected] = React.useState(false);
    React.useEffect(() => {
        var _a, _b, _c;
        setIsContentSelected(((_a = props.selectedContentItem) === null || _a === void 0 ? void 0 : _a.itemId) === props.item.itemId &&
            ((_b = props.selectedContentItem) === null || _b === void 0 ? void 0 : _b.text) === props.item.text &&
            props.dimensionId === ((_c = props.selectedContentItem) === null || _c === void 0 ? void 0 : _c.dimensionId));
    });
    function deleteItem(item) {
        setShowDeleteModal(false);
        props.deleteItem(item);
    }
    function updateItem(item) {
        setItem(item);
        props.updateItem(item);
        setShowEditModal(false);
    }
    function setSelectedContentItem(item) {
        props.setSelectedContentItem(item, props.dimensionId);
    }
    function setNewPathSelection(item) {
        props.setNewPathSelection(item);
    }
    return (_jsxs(NodeWrapper, Object.assign({ className: `lt-matrix-node-wrapper ${isContentSelected ? "isActive" : ""} ${props.isNewPathSelected && props.view === "pathways" ? "isSelected" : ""}`, onClick: props.view === "content"
            ? () => setSelectedContentItem(props.item)
            : props.editPath.length >= props.dimensionId - 1
                ? () => setNewPathSelection(item)
                : null }, { children: [_jsx(NodeText, { children: props.item.text }), props.view === "content" && (_jsx(IconsWrapperEnd, Object.assign({ className: "lt-matrix-node-actions" }, { children: _jsx(IconWrapper, Object.assign({ onClick: () => {
                        setShowDeleteModal(true);
                    } }, { children: _jsx(RiDeleteBin6Line, {}) })) }))), _jsx(DeleteModal, { node: props.item, deleteItem: deleteItem, dimensionId: props.dimensionId, closeModal: () => setShowDeleteModal(false), showModal: showDeleteModal })] })));
};
