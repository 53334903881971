var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Spinner, } from "@fluentui/react-components";
import { PoliciesService } from "../PoliciesService";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-1sjqrlf" }) `
  align-items: center;
  display: flex;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-ryecvv" }) `
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-j3ig88" }) `
  color: #707070;
  margin-bottom: 4px;
`;
const InputHint = styled.span.withConfig({ displayName: "InputHint", componentId: "-1e1ww00" }) `
  color: #3d3a3b;
  font-size: 12px;
  margin-top: 2px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-ntrwuj" }) `
  padding-top: 24px;
`;
const AddPolicySourceModal = ({ onClose, onSave, }) => {
    const [sourceUrl, setSourceUrl] = useState();
    const [urlIsValid, setUrlIsValid] = useState(false);
    const { AddSource } = PoliciesService();
    const [isAdding, setIsAdding] = React.useState(false);
    const isValidUrl = (urlString) => {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    };
    const handleUrlChange = (value) => {
        setUrlIsValid(isValidUrl(value) != undefined && value.indexOf(".sharepoint.com") > -1);
        if (isValidUrl) {
            setSourceUrl(value);
        }
    };
    const handlePolicySourceAdd = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsAdding(true);
        try {
            yield AddSource(sourceUrl);
            yield onSave();
        }
        catch (error) {
            setIsAdding(false);
        }
        setIsAdding(false);
        onClose();
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(StyledDialogTitle, { children: "Add new policy" }), _jsxs(StyledDialogContent, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "add-policy-url", required: true }, { children: "URL" })), _jsx(Input, { id: "add-policy-url", placeholder: "Insert URL", size: "large", onChange: (e) => handleUrlChange(e.target.value) }), _jsx(InputHint, { children: "Sharepoint library URL only" })] }), _jsx(StyledDialogActions, { children: isAdding ? (_jsx(Spinner, { size: "small", label: "Saving..." })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handlePolicySourceAdd, disabled: !urlIsValid }, { children: "Add policy" }))] })) })] }) }));
};
export default AddPolicySourceModal;
