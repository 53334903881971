import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { useBranding } from "./BrandingContext";
import HeaderPreview from "./components/HeaderPreview";
import NavigationComponent from "./NavigationComponent";
import RadiusSelector from "./components/RadiusSelector";
import BrandingHeader from "./components/brandingHeader";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-1q7bjwb" }) `
  display: flex;
  flex-direction: column;

  &.mode-wizard {
    min-height: calc(100vh - 200px);
  }
`;
const UiContainer = styled.div.withConfig({ displayName: "UiContainer", componentId: "-5c7ewm" }) `
  display: flex;
  flex-direction: column;
  margin-block: auto;
  gap: 20px;
  width: 100%;

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  h3 {
    color: #3D3A3B;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
const BorderUIContianer = styled.div.withConfig({ displayName: "BorderUIContianer", componentId: "-7198pf" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 10px;
  width: 100%;
  max-width: 500px;
`;
const ButtonPreview = styled.div.withConfig({ displayName: "ButtonPreview", componentId: "-1qygd9z" }) `
  background-color: var(--primary-color, #3D3A3B);
  border-radius: var(--button-border-radius, 0);
  color: #fff;
  padding: 8px 20px;
  margin: 10px;
  transition: all 0.3s ease-in-out;
`;
const BorderStyle = (props) => {
    var _a, _b, _c, _d;
    const { selectedTheme, setSelectedTheme } = useBranding();
    const [selectedIndex, setSelectedIndex] = React.useState((_b = (_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _a === void 0 ? void 0 : _a.menuStyle) === null || _b === void 0 ? void 0 : _b.buttonRadiusSelectorIndex);
    const [loaded, setLoaded] = React.useState(false);
    const container = React.useRef(null);
    const selectorSteps = [
        {
            label: "Rounded",
            value: "20px"
        },
        {
            label: "Mid-round",
            value: "10px"
        },
        {
            label: "Square",
            value: "0"
        }
    ];
    React.useEffect(() => {
        var _a, _b;
        container.current.style.setProperty("--primary-color", ((_b = (_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.primaryColor) || "#3D3A3B");
    }, []);
    React.useEffect(() => {
        let radius = selectorSteps[selectedIndex || 0].value;
        container.current.style.setProperty("--button-border-radius", radius);
        setSelectedTheme((prev) => {
            var _a;
            return (Object.assign(Object.assign({}, prev), { themeSettings: Object.assign(Object.assign({}, prev === null || prev === void 0 ? void 0 : prev.themeSettings), { menuStyle: Object.assign(Object.assign({}, (_a = prev === null || prev === void 0 ? void 0 : prev.themeSettings) === null || _a === void 0 ? void 0 : _a.menuStyle), { buttonRadius: radius, buttonRadiusSelectorIndex: selectedIndex }) }) }));
        });
        setLoaded(true);
    }, [selectedIndex]);
    const handleChange = (value) => {
        setSelectedIndex(value);
    };
    return (_jsxs(Container, Object.assign({ className: props.wizardMode ? "mode-wizard" : "mode-edit", ref: container }, { children: [props.wizardMode ? (_jsx(BrandingHeader, { title: "Customize the menu style", summary: "Select a primary color and a theme to be applied on the layout.", advancedSettings: true })) : null, _jsxs(UiContainer, { children: [_jsxs(BorderUIContianer, { children: [_jsx("h2", { children: "Button Shape" }), loaded ? (_jsx(ButtonPreview, { children: "Button" })) : null, _jsx(RadiusSelector, { onChangeRadius: handleChange, value: ((_d = (_c = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _c === void 0 ? void 0 : _c.menuStyle) === null || _d === void 0 ? void 0 : _d.buttonRadiusSelectorIndex) || 0, steps: selectorSteps })] }), _jsx(HeaderPreview, {})] }), props.wizardMode ? (_jsx(NavigationComponent, { currentStepName: "Border" })) : null] })));
};
export default BorderStyle;
