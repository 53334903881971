const contrastRatio = (luminance1, luminance2) => {
    let lighterLum = Math.max(luminance1, luminance2);
    let darkerLum = Math.min(luminance1, luminance2);
    return (lighterLum + 0.05) / (darkerLum + 0.05);
};
const luminance = (r, g, b) => {
    let [lumR, lumG, lumB] = [r, g, b].map(component => {
        let proportion = component / 255;
        return proportion <= 0.03928
            ? proportion / 12.92
            : Math.pow((proportion + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
};
const checkContrast = (color1, color2) => {
    let [luminance1, luminance2] = [color1, color2].map((color) => {
        color = color.startsWith("#") ? color.slice(1) : color;
        let r = parseInt(color.slice(0, 2), 16);
        let g = parseInt(color.slice(2, 4), 16);
        let b = parseInt(color.slice(4, 6), 16);
        return luminance(r, g, b);
    });
    return contrastRatio(luminance1, luminance2);
};
const WCAG_RATIOS = [
    ['aaLarge', 3],
    ['aaUi', 3],
    ['aaNormal', 4.5],
    ['aaaLarge', 4.5],
    ['aaaNormal', 7],
];
const checkWCAGLevels = (ratio) => {
    let WCAGlevel = {};
    for (const [level, minRatio] of WCAG_RATIOS) {
        WCAGlevel[level] = ratio > minRatio;
    }
    return WCAGlevel;
};
const meetsMinimumRequirements = (ratio) => {
    let pass = false;
    let maxLevel = null;
    for (const [level, minRatio] of WCAG_RATIOS) {
        if (ratio < minRatio)
            break;
        pass = true;
        maxLevel = level;
    }
    return { maxLevel };
};
export const a11yContrastChecker = (color1, color2) => {
    let ratio = checkContrast(color1, color2);
    let levels = checkWCAGLevels(ratio);
    let maxLevel = meetsMinimumRequirements(ratio);
    console.log("Contrast checking: ", color1, color2);
    return { ratio, maxLevel, levels };
};
export default a11yContrastChecker;
