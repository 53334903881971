var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Spinner, makeStyles, Switch, } from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import MultiBadgeDropdown from "../../../../components/MultiBadgeDropdown";
import { AudiencesService } from "../../audiences/AudiencesService";
import { PoliciesService } from "../PoliciesService";
import moment from "moment";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-xuqyg2" }) `
  align-items: center;
  display: flex;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-1fmqgp4" }) `
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-1peci73" }) `
  color: #707070;
  margin-bottom: 4px;
`;
const SectionTitle = styled.span.withConfig({ displayName: "SectionTitle", componentId: "-umgpu" }) `
  color: #707070;
  display: inline-block;
  margin-bottom: 8px;
  margin-top: 32px;
`;
const InputHint = styled.span.withConfig({ displayName: "InputHint", componentId: "-1leab1h" }) `
  color: #3d3a3b;
  font-size: 12px;
  margin-top: 2px;
`;
const SwitchLabel = styled.span.withConfig({ displayName: "SwitchLabel", componentId: "-1h25lk2" }) `
  color: #707070;
  padding-left: 0px;
`;
const DropdownWrapper = styled.div.withConfig({ displayName: "DropdownWrapper", componentId: "-cw0d6g" }) `
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 32px;
`;
const SwitchWrapper = styled.div.withConfig({ displayName: "SwitchWrapper", componentId: "-no9rdh" }) `
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-1p8b5ax" }) `
  padding-top: 24px;
`;
const StyledDiv = styled.div.withConfig({ displayName: "StyledDiv", componentId: "-106zn6n" }) `
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
const useStyles = makeStyles({
    inputControl: {
        maxWidth: "300px",
    },
});
const EditPolicyModal = ({ policySource, policyItem, onClose, onSave, }) => {
    const [editedPolicy, setEditedPolicy] = useState(policyItem !== null && policyItem !== void 0 ? policyItem : {
        id: "",
        itemId: "",
        title: "",
        mandatory: false,
        versions: [],
        absoluteUrl: "",
        status: "",
        enabledAssistant: false,
        targetAudiences: [],
        unconfirmedUsers: 0,
        readConfirmations: [],
        dueDate: null,
    });
    const [audiences, setAudiences] = useState([]);
    const [selectedAudiences, setSelectedAudiences] = useState([]);
    const [urlIsValid, setUrlIsValid] = useState(false);
    const { TargetItem, UpdateDueDate, SetPolicyItemAsMandatory } = PoliciesService();
    const { GetAudiences } = AudiencesService();
    const [dueDate, setDueDate] = useState(null);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isMandatory, setIsMandatory] = useState(false);
    const styles = useStyles();
    const getInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((editedPolicy === null || editedPolicy === void 0 ? void 0 : editedPolicy.dueDate) == undefined) {
            setDueDate(null);
        }
        else if ((editedPolicy === null || editedPolicy === void 0 ? void 0 : editedPolicy.dueDate) !== null) {
            setDueDate(moment(editedPolicy.dueDate).toDate());
        }
        const allAudiences = yield GetAudiences();
        const preparedAudiences = allAudiences.audienceCollection.map((item) => ({
            id: item.audienceId,
            name: item.audienceName,
        }));
        const policyTargets = preparedAudiences.filter((s) => policyItem.targetAudiences.includes(s.id));
        setIsMandatory(editedPolicy.mandatory);
        setAudiences(preparedAudiences);
        setSelectedAudiences(policyTargets);
    });
    useEffect(() => {
        if (!!policyItem) {
            setUrlIsValid(true);
        }
        getInfo();
    }, []);
    const handlePolicySave = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            editedPolicy.targetAudiences = selectedAudiences === null || selectedAudiences === void 0 ? void 0 : selectedAudiences.map((aud) => aud.id);
            yield setEditedPolicy(editedPolicy);
            yield TargetItem(policySource.id, editedPolicy.id, editedPolicy.targetAudiences);
            if (dueDate !== editedPolicy.dueDate) {
                yield UpdateDueDate(policySource.id, editedPolicy.id, dueDate);
            }
            if (isMandatory !== editedPolicy.mandatory) {
                yield SetPolicyItemAsMandatory(policySource.id, editedPolicy.id, isMandatory);
            }
            yield onSave();
        }
        catch (error) {
            setIsSaving(false);
        }
        setIsSaving(false);
        onClose();
    });
    const onFormatDate = (date) => {
        return !date ? "" : moment(date).format("DD/MM/YYYY");
    };
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [!policyItem ? "Add new" : "Edit", " policy"] }), _jsxs(StyledDialogContent, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "edit-policy-url", required: true }, { children: "URL" })), _jsx(Input, { id: "edit-policy-url", placeholder: "Insert URL", size: "large", value: policySource.url, disabled: true }), _jsx(InputHint, { children: "Sharepoint library URL only" }), _jsxs(SwitchWrapper, { children: [_jsx(SectionTitle, { children: "Mandatory" }), _jsx(Switch, { checked: isMandatory, labelPosition: "above", label: _jsx(SwitchLabel, { children: "Enabling this option will require audience target users to read it." }), onChange: (e) => setIsMandatory(e.target.checked) })] }), _jsxs(StyledDiv, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "edit-policy-dueDate" }, { children: "Due date" })), _jsx(DatePicker, { minDate: new Date(), placeholder: "Select a date...", formatDate: onFormatDate, allowTextInput: true, className: styles.inputControl, onSelectDate: setDueDate, value: dueDate })] }), !!policyItem && (_jsx(MultiBadgeDropdown, { items: audiences, label: "Target audiences", labelId: "edit-policy-audiences", placeholder: "Select audiences", selectedItems: selectedAudiences, onOptionSelect: (audiences) => setSelectedAudiences(audiences) }))] }), _jsx(StyledDialogActions, { children: isSaving ? (_jsx(Spinner, { size: "small", label: "Saving..." })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handlePolicySave, disabled: !urlIsValid }, { children: !!policyItem ? "Save" : "Add policy" }))] })) })] }) }));
};
export default EditPolicyModal;
