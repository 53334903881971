import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { ModalFieldset, } from "../Modal.styles";
import ReactDOM from "react-dom";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input, Switch } from "@fluentui/react-components";
export const EditingModal = (props) => {
    var _a, _b, _c, _d;
    function extractNode(node) {
        const matchedLabel = node.labels.find((label) => { var _a; return (label === null || label === void 0 ? void 0 : label.languageCode) === ((_a = props.selectedLanguage) === null || _a === void 0 ? void 0 : _a.languageCode); });
        return matchedLabel ? matchedLabel.value : "";
    }
    const [nodeValue, setNodeValue] = React.useState(props.node ? extractNode(props.node) : "");
    const [linkValue, setLinkValue] = React.useState(props.node ? (_b = (_a = props.node) === null || _a === void 0 ? void 0 : _a.link) === null || _b === void 0 ? void 0 : _b.url : "");
    const [openInNewTab, setOpenInNewTab] = React.useState(props.node ? (_d = (_c = props.node) === null || _c === void 0 ? void 0 : _c.link) === null || _d === void 0 ? void 0 : _d.openInNewTab : false);
    function onChangeNodeValue(e) {
        setNodeValue(e.target.value);
    }
    function onChangeLinkValue(e) {
        setLinkValue(e.target.value);
    }
    const onChangeCheckBoxValue = React.useCallback((ev) => {
        setOpenInNewTab(ev.currentTarget.checked);
    }, [setOpenInNewTab]);
    function saveNode() {
        var _a, _b, _c, _d;
        let node = null;
        const existingNode = (_a = props.node) === null || _a === void 0 ? void 0 : _a.labels.some((label) => { var _a; return label.languageCode === ((_a = props.selectedLanguage) === null || _a === void 0 ? void 0 : _a.languageCode); });
        if (!props.node || !existingNode) {
            node = {
                labels: [
                    ...(((_b = props.node) === null || _b === void 0 ? void 0 : _b.labels) || []),
                    {
                        languageCode: (_c = props.selectedLanguage) === null || _c === void 0 ? void 0 : _c.languageCode,
                        value: nodeValue,
                    },
                ],
                link: {
                    url: linkValue,
                    openInNewTab: openInNewTab,
                },
                nodes: ((_d = props.node) === null || _d === void 0 ? void 0 : _d.nodes) || [],
                key: crypto.randomUUID(),
            };
        }
        else {
            node = props.node;
            const selectedIndex = node.labels.findIndex((label) => { var _a; return label.languageCode === ((_a = props.selectedLanguage) === null || _a === void 0 ? void 0 : _a.languageCode); });
            node.labels[selectedIndex].value = nodeValue;
            node.link.url = linkValue;
            node.link.openInNewTab = openInNewTab;
        }
        props.updateNode(node);
        props.closeModal();
    }
    React.useEffect(() => {
        const titleInput = document.querySelector("input.initialFocus");
        if (titleInput) {
            titleInput.focus();
        }
    }, []);
    return (_jsx(Dialog, Object.assign({ open: props.showModal }, { children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: props.node !== undefined && props.node !== null
                            ? "Edit Navigation"
                            : "Create Navigation" }), _jsx(DialogContent, { children: _jsxs(_Fragment, { children: [_jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Name" }), _jsx(Field, { children: _jsx(Input, { value: nodeValue, onChange: onChangeNodeValue }) })] })), _jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "URL" }), _jsx(Field, { children: _jsx(Input, { value: linkValue, onChange: onChangeLinkValue }) })] })), _jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Open link in a new window" }), _jsx(Switch, { checked: openInNewTab, onChange: onChangeCheckBoxValue })] }))] }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: saveNode, appearance: "primary" }, { children: "OK" })), _jsx(Button, Object.assign({ onClick: () => {
                                    props.closeModal();
                                } }, { children: "Cancel" }))] })] }) }) })));
};
export const ModalPortal = (props) => {
    return ReactDOM.createPortal(props.children, document.body);
};
