import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Picker } from "./Picker/Picker";
export const AudienceTargeting = (props) => {
    const [node, setNode] = React.useState(props.node);
    const [selectedUsers, setSelectedUsers] = React.useState(props.node.audience && props.node.audience.entities
        ? props.node.audience.entities
        : []);
    function deleteUser(user) {
        const updated = selectedUsers.filter((u) => {
            return u.id !== user.id;
        });
        const audience = {
            entities: updated,
        };
        setSelectedUsers(updated);
        const newNode = Object.assign(Object.assign({}, node), { audience: audience });
        setNode(newNode);
        props.updateNode(newNode);
    }
    function addUsers(users) {
        setSelectedUsers(users);
        const audience = {
            entities: users,
        };
        const newNode = Object.assign(Object.assign({}, node), { audience: audience });
        setNode(newNode);
        props.updateNode(newNode);
    }
    return (_jsx("div", { children: _jsx(Picker
        /*key={crypto.randomUUID()} */ , { 
            /*key={crypto.randomUUID()} */ deleteUser: deleteUser, selectedUsers: selectedUsers, setSelectedUsers: addUsers, identityType: undefined }) }));
};
