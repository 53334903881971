import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
const ColorPickerContainer = styled.div.withConfig({ displayName: "ColorPickerContainer", componentId: "-1ttphcs" }) `
  position: relative;
`;
const ColorPickerInput = styled.input.withConfig({ displayName: "ColorPickerInput", componentId: "-wi7ucp" }) `
  position: absolute;
  z-index: -1;
  visibility: hidden;
`;
const ColorPickerLabel = styled.label.withConfig({ displayName: "ColorPickerLabel", componentId: "-11o7s7n" }) `
  position: relative;
  z-index: 1;
  margin-top: 0.25rem;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 300;
  padding-left: 2.5rem;
  padding-right: 30px;
  display: flex;
  text-transform: uppercase;
  curosor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
const ColorPickerIcon = styled.span.withConfig({ displayName: "ColorPickerIcon", componentId: "-1sheml9" }) `
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
`;
const ColorPicker = ({ level, color, onChange, }) => {
    const labelRef = useRef(null);
    useEffect(() => {
        labelRef.current.style.setProperty("--color", color);
    }, [color]);
    const openColorPicker = () => {
        document.getElementById("color-picker" + level).click();
    };
    const handleChange = (e) => {
        onChange(e.target.value);
    };
    return (_jsxs(ColorPickerContainer, { children: [_jsx(ColorPickerInput, { className: "color-picker-input", id: "color-picker" + level, type: "color", value: color, onChange: handleChange }), _jsxs(ColorPickerLabel, Object.assign({ htmlFor: "color-picker" + level, onClick: openColorPicker, ref: labelRef }, { children: [_jsx("span", Object.assign({ className: "color-picker-label", "data-color": color }, { children: color })), _jsx(ColorPickerIcon, { children: "\uD83C\uDFA8" })] }))] }));
};
export default ColorPicker;
