import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { BsChevronDown } from "react-icons/bs";
import { Flyout, StylingWrapper } from "./StylingView.styles";
import { HiLink } from "react-icons/hi";
export const StylingView = (props) => {
    var _a, _b;
    const list = React.useRef(null);
    const [displayChildren, setDisplayChildren] = React.useState({});
    const [flyoutItem, setFlyoutItem] = React.useState(null);
    React.useEffect(() => {
        // Close menu when clicking outside
        function handleClickOutside(event) {
            if (list.current && !list.current.contains(event.target)) {
                setDisplayChildren({});
                setFlyoutItem(null);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        // on viewport resize close the flyout
        window.addEventListener("resize", () => {
            setFlyoutItem(null);
        });
    }, []);
    // Returns text based on current language
    function extractNodeValue(node) {
        let value = "";
        node.labels.forEach((l) => {
            if (l != null) {
                if (props.generalOptions.primaryLanguage &&
                    l.languageCode === props.generalOptions.primaryLanguage.languageCode) {
                    value = l.value;
                }
                else {
                    if (l.languageCode === props.generalOptions.primaryLanguage.languageName) {
                        value = l.value;
                    }
                }
            }
        });
        return value;
    }
    // Handle when a link is clicked
    const handleLinkClick = (item) => {
        var _a;
        if (((_a = item.link) === null || _a === void 0 ? void 0 : _a.url) === undefined || item.link.url === "")
            return;
        event.preventDefault();
        if (item.link.openInNewTab) {
            window.open(item.link.url, "_blank");
        }
        else {
            window.open(item.link.url, "_self");
        }
    };
    // Return true if link icon should be shown
    const showLinkIcon = (item) => {
        var _a;
        if (((_a = item.link) === null || _a === void 0 ? void 0 : _a.url) !== undefined && item.link.url !== "") {
            if ((!props.level || props.level === 2 || props.level === 3) &&
                item.nodes &&
                item.nodes.length > 0)
                return true;
            return false;
        }
        handleLinkClick(item);
    };
    // Handle when a menu item is clicked
    const handleItemClick = (event, item, level, props) => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!item.nodes.length && item.link.url === "")
            return;
        var nav = document.querySelector(".lt-megamenu-wrapper");
        if (level === 2) {
            (_a = list.current
                .querySelector(".level-3")) === null || _a === void 0 ? void 0 : _a.style.setProperty("--min-height", `0`);
            nav.style.setProperty("--flyout-right", "auto");
            if (event.target && item.nodes.length) {
                nav.style.setProperty("--flyout-left", event.target.offsetLeft + "px");
            }
            setFlyoutItem((prev) => (Object.assign(Object.assign({}, prev), { item: item, hasChildren: false })));
            if (props.menuStyle === "classic") {
                setTimeout(() => {
                    const navRect = nav.getBoundingClientRect();
                    const parentRect = event.target.getBoundingClientRect();
                    const flyoutList = list.current.querySelectorAll(".level-3 > li");
                    let flyoutWidth = 0;
                    flyoutList.forEach((item) => {
                        flyoutWidth += item.clientWidth;
                    });
                    // if flyout extends beyond viewport, move inside
                    if ((parentRect.left + flyoutWidth + 2) >= navRect.width) {
                        nav.style.setProperty("--flyout-left", "auto");
                        nav.style.setProperty("--flyout-right", "0");
                    }
                    if (flyoutWidth + 2 >= navRect.width) {
                        nav.style.setProperty("--flyout-left", "0");
                        nav.style.setProperty("--flyout-flow", "wrap");
                    }
                }, 0);
            }
        }
        if (level === 3) {
            if (props.menuStyle === "classic")
                return;
            setFlyoutItem((prev) => (Object.assign(Object.assign({}, prev), { hasChildren: item.nodes.length > 0 ? true : false })));
            // Animate flyout height to match 4th level
            list.current.closest(".level-3").style.setProperty("--min-height", `0`);
            setTimeout(() => {
                if (item.nodes.length) {
                    const height = list.current.querySelector(".level-3 > .isActive > ul").clientHeight;
                    list.current
                        .closest(".level-3")
                        .style.setProperty("--min-height", `${height}px`);
                }
            }, 0);
        }
        if (displayChildren[(_b = item.labels[0]) === null || _b === void 0 ? void 0 : _b.value] && props.level)
            return;
        if (((_c = item.link) === null || _c === void 0 ? void 0 : _c.url) !== undefined && item.link.url === "") {
            setDisplayChildren({
                [(_d = item.labels[0]) === null || _d === void 0 ? void 0 : _d.value]: !displayChildren[(_e = item.labels[0]) === null || _e === void 0 ? void 0 : _e.value],
            });
            return;
        }
        if (showLinkIcon(item)) {
            setDisplayChildren({
                [(_f = item.labels[0]) === null || _f === void 0 ? void 0 : _f.value]: !displayChildren[(_g = item.labels[0]) === null || _g === void 0 ? void 0 : _g.value],
            });
        }
        else {
            // handleLinkClick(item);
            // do nothing this is a preview
        }
    };
    // Return UL classNames
    const ulClasses = () => {
        let classes = "";
        classes += `level-${props.level ? props.level : 1} `;
        classes += (flyoutItem === null || flyoutItem === void 0 ? void 0 : flyoutItem.hasChildren) ? "flyoutHasChildren" : "";
        return classes;
    };
    let items = props.megaMenuItem.siteCollection
        ? props.megaMenuItem.siteCollection[0].nodes
        : props.megaMenuItem.nodes;
    if (props.level === 3 && props.menuStyle === "classic") {
        const itemsWithoutChildren = items.filter((item) => item.nodes.length === 0);
        const itemsWithChildren = items.filter((item) => item.nodes.length > 0);
        const childlessItems = {
            labels: [
                {
                    languageCode: "",
                    languageName: "",
                    value: "",
                },
            ],
            nodes: itemsWithoutChildren,
        };
        if (itemsWithoutChildren.length > 0) {
            items = [childlessItems, ...itemsWithChildren];
        }
    }
    return (_jsxs(StylingWrapper, Object.assign({ ref: list, className: ulClasses() }, { children: [items.map((item) => {
                var _a, _b;
                return (_jsxs("li", Object.assign({ className: `${displayChildren[(_a = item.labels[0]) === null || _a === void 0 ? void 0 : _a.value] && item.nodes.length
                        ? "isActive"
                        : ""}` }, { children: [item.nodes && (_jsxs("span", Object.assign({ onClick: () => {
                                handleItemClick(event, item, props.level, props);
                            } }, { children: [extractNodeValue(item), showLinkIcon(item) && (_jsx(HiLink, { className: "icon-link", onClick: () => {
                                        handleLinkClick(item);
                                    } })), item.nodes.length !== 0 && !props.flyout ? (_jsxs(_Fragment, { children: [" ", _jsx(BsChevronDown, {})] })) : null] }))), (item.nodes && props.level === 1) || props.flyout ? (_jsx(StylingView, { level: props.level + 1, megaMenuItem: item, isMobile: props.isMobile, generalOptions: props.generalOptions, menuStyle: props.menuStyle })) : null] }), (_b = item.labels[0]) === null || _b === void 0 ? void 0 : _b.value));
            }), flyoutItem && ((_a = flyoutItem.item) === null || _a === void 0 ? void 0 : _a.nodes.length) && (_jsx(Flyout, Object.assign({ className: "isActive" }, { children: flyoutItem && ((_b = flyoutItem.item) === null || _b === void 0 ? void 0 : _b.nodes) && (_jsx(StylingView, { level: props.level + 1, megaMenuItem: flyoutItem.item, generalOptions: props.generalOptions, flyout: true, menuStyle: props.menuStyle })) })))] })));
};
