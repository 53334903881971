import { Button, Combobox } from "@fluentui/react-components";
import { styled } from "styled-components";
export const LanguageCard = styled.div.withConfig({ displayName: "LanguageCard", componentId: "-11ogvcd" }) `
  display: inline-flex;
  padding: 0.25rem 0.75rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;

  span {
    display: inline-block;
    margin-right: 4px;
  }

  button {
    display: inline-flex;
    align-items: center;
    padding-right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  button:hover {
    color: #333;
  }

  &.primary {
    order: -1;
    color: white;
    background-color: var(--lt-purple, #000);

    button {
      color: white;
    }
  }
`;
export const LanguageSelectorContainer = styled.div.withConfig({ displayName: "LanguageSelectorContainer", componentId: "-10xobnq" }) `
  && {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    min-width: var(--fieldsetWidth, 0);
  }
`;
export const LanguageCardsContainer = styled.div.withConfig({ displayName: "LanguageCardsContainer", componentId: "-5mr5jx" }) `
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .5rem;
`;
export const GeneralOptionsContainer = styled.div.withConfig({ displayName: "GeneralOptionsContainer", componentId: "-n6tl2" }) `
  padding: 0.75rem 0;
`;
export const GeneralOptionsWrapper = styled.div.withConfig({ displayName: "GeneralOptionsWrapper", componentId: "-17y1j2s" }) `
  padding: 1rem;
  border: 1px solid #d7d2d1;
  border-radius: 4px;
`;
export const ExtendedTitle = styled.span.withConfig({ displayName: "ExtendedTitle", componentId: "-h73oaj" }) `
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
`;
export const ArrowIcon = styled.span.withConfig({ displayName: "ArrowIcon", componentId: "-1w8pw4c" }) `
  margin-left: auto;
`;
export const CustomFieldSet = styled.fieldset.withConfig({ displayName: "CustomFieldSet", componentId: "-uzlkv" }) `
  padding-inline: 0 !important;
`;
export const CustomCombobox = styled(Combobox).withConfig({ displayName: "CustomCombobox", componentId: "-1ike66p" }) `
  padding-left: 22px;

  &:before {
    content: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M14 2.75C9.99594 2.75 6.75 5.99594 6.75 10C6.75 11.7319 7.35725 13.3219 8.37045 14.5688L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303C3.73594 20.7966 4.1526 20.8208 4.44621 20.6029L4.53033 20.5303L9.43118 15.6295C10.6781 16.6427 12.2681 17.25 14 17.25C18.0041 17.25 21.25 14.0041 21.25 10C21.25 5.99594 18.0041 2.75 14 2.75ZM14 4.25C17.1756 4.25 19.75 6.82436 19.75 10C19.75 13.1756 17.1756 15.75 14 15.75C10.8244 15.75 8.25 13.1756 8.25 10C8.25 6.82436 10.8244 4.25 14 4.25Z' fill='%23707070'/%3e %3c/svg%3e");
    position: absolute;
    left: 8px;
    top: 6px;
  }
`;
export const CustomComboboxDropdown = styled.div.withConfig({ displayName: "CustomComboboxDropdown", componentId: "-16yts6f" }) `
  max-height: 360px;
  overflow-y: auto;
`;
export const DisableButton = styled(Button).withConfig({ displayName: "DisableButton", componentId: "-1y0hd24" }) `
  && {
    color: var(--red-lighter-text);
    border: 1px solid var(--red-lighter-text);
    background-color: var(--red-lighter-bg);
    margin-right: .5rem;
  }
`;
