var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useBranding } from "./BrandingContext";
import { Badge, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Radio, RadioGroup, Spinner, Toast, Toaster, ToastTitle, useId, useToastController, } from "@fluentui/react-components";
import { Add20Regular } from "@fluentui/react-icons";
import uploadIcon from "/public/logoUpload.svg";
import uploadIconActive from "/public/logoUploadActive.svg";
import SiteCollectionSelect from "./components/SiteCollectionSelect";
import { RiFolder4Line } from "react-icons/ri";
import { MegaMenuAPIService } from "../util/MegaMenuAPIService/MegaMenuAPIService";
import Publisher from "./Publisher";
import moment from "moment";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-11srrmz" }) `
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;

  & img {
    margin-bottom: 20px;
  }
`;
const ContainerNoThemes = styled.div.withConfig({ displayName: "ContainerNoThemes", componentId: "-1a4ozg3" }) `
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  gap: 12px;

  > * {
    max-width: 500px;
  }

  & img {
    max-width: 65px;
    margin-bottom: 20px;
  }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "-rmvx1y" }) `
  width: 80%;
  margin-bottom: 10px;
  text-align: center;

  &.noTheme {
    font-size: 20px;
  }
`;
const Subtitle = styled.div.withConfig({ displayName: "Subtitle", componentId: "-1dfpuhy" }) `
  width: 80%;
  margin-bottom: 20px;
  text-align: center;

  &.noTheme {
    font-size: 16px;
    color: #888;
  }
`;
const ThemeCard = styled.div.withConfig({ displayName: "ThemeCard", componentId: "-x6e4je" }) `
  width: 300px; // Fixed width for the cards
  min-height: 146px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ThemeCardModified = styled.time.withConfig({ displayName: "ThemeCardModified", componentId: "-9npsmi" }) `
  font-size: 12px;
  font-weight: 400;
  display: flex;
  position: relative;
  justify-content: flex-start;
`;
const ThemeCardStatusTopRow = styled.div.withConfig({ displayName: "ThemeCardStatusTopRow", componentId: "-1ep4hac" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const ThemeTitle = styled.h3.withConfig({ displayName: "ThemeTitle", componentId: "-174sdr3" }) `
  font-size: 16px;
  color: #333;
  margin-block: 0;
`;
const ThemeStatus = styled.span.withConfig({ displayName: "ThemeStatus", componentId: "-1uq6aoo" }) `
  font-size: 14px;
  color: #888;
  margin-bottom: 15px;
`;
const ThemeActions = styled.div.withConfig({ displayName: "ThemeActions", componentId: "-guxzq" }) `
  display: flex;
  gap: 12px;
  margin-top: auto;
`;
const ThemeUpload = styled.div.withConfig({ displayName: "ThemeUpload", componentId: "-1dttsaf" }) `
  background: rgba(175, 173, 178, 0.04);
  border: 1px dashed rgba(81, 45, 168, 0.2);
  border-radius: 10px;
  padding: 20px 60px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3d3a3b;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s ease-in-out;

  .upload-icon-active {
    display: none;
  }

  &.drag-over {
    border-color: rgba(81, 45, 168, 1);

    .upload-icon {
      display: none;
    }

    .upload-icon-active {
      display: block;
    }
  }

  input {
    display: none;
  }

  p {
    margin-block: 0 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    + p {
      font-size: 12px;
      font-weight: 400;
    }
  }

  span {
    cursor: pointer;
    color: #512da8;
  }
`;
const ThemeUploadIcon = styled.img.withConfig({ displayName: "ThemeUploadIcon", componentId: "-74eo3w" }) ``;
const SiteSelectContainer = styled.div.withConfig({ displayName: "SiteSelectContainer", componentId: "-wx8wsu" }) `
  margin-block: 0.5rem;
`;
const ThemeInfoContainer = styled.div.withConfig({ displayName: "ThemeInfoContainer", componentId: "-1k5tfr8" }) `
  height: 32px;
  margin-block: 12px;
`;
const ThemeInfo = styled.div.withConfig({ displayName: "ThemeInfo", componentId: "-1jb1j7j" }) `
  display: flex;
  align-items: center;
  background: rgba(81, 45, 168, 0.05);
  border-radius: 6px;
  padding: 7px 15px;
  font-size: 12px;

  svg {
    font-size: 18px;
  }
`;
const HeaderContainer = styled.div.withConfig({ displayName: "HeaderContainer", componentId: "-dp8wc2" }) `
  margin-bottom: 1rem;

  h1 {
    margin-block: 0 10px;
  }

  p {
    margin-block: 0 20px;
  }
`;
const ThemeInfoName = styled.span.withConfig({ displayName: "ThemeInfoName", componentId: "-1rolfkp" }) `
  font-weight: 600;
  margin-inline: 8px;
`;
const ThemeInfoSize = styled.span.withConfig({ displayName: "ThemeInfoSize", componentId: "-jetbon" }) ``;
const DismissThemeIcon = styled.span.withConfig({ displayName: "DismissThemeIcon", componentId: "-5t6tqg" }) `
  margin-left: auto;
  cursor: pointer;
`;
const SiteCollectionsContainer = styled.div.withConfig({ displayName: "SiteCollectionsContainer", componentId: "-1w3b3ii" }) `
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-block: 10px;

  > .fui-Badge {
    margin-block: 0;
  }
`;
const SiteCollection = styled(Badge).withConfig({ displayName: "SiteCollection", componentId: "-j3ezah" }) `
  margin-block: 0.5rem;
`;
const SpinnerContainer = styled.div.withConfig({ displayName: "SpinnerContainer", componentId: "-1bppo7b" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 50vw;
`;
const SiteCollections = (props) => {
    const { siteCollections, setSiteCollections } = useBranding();
    const getSiteCollectionText = (siteCollectionKey) => {
        if (!siteCollections)
            return null;
        const siteCollection = siteCollections.find((site) => site.key === siteCollectionKey);
        return siteCollection ? siteCollection.text : "";
    };
    return (_jsxs(SiteCollectionsContainer, { children: [props.theme.siteCollectionUrls
                .slice(0, 3)
                .map((siteCollection, index) => (_jsx(_Fragment, { children: getSiteCollectionText(siteCollection) !== "" && (_jsx(SiteCollection, Object.assign({ color: "informative" }, { children: getSiteCollectionText(siteCollection) }), index)) }))), props.theme.siteCollectionUrls.length > 3 && (_jsxs(SiteCollection, Object.assign({ color: "informative" }, { children: ["+", props.theme.siteCollectionUrls.length - 3] }))), props.theme.siteCollectionUrls.length === 0 && (_jsx(SiteCollection, Object.assign({ color: "informative" }, { children: "Tenant" })))] }));
};
function ThemeSummary() {
    const { themes, selectedTheme, setSelectedTheme, isThemesLoading } = useBranding();
    const { siteCollections, setSiteCollections } = useBranding();
    const [importDialog, setImportDialog] = React.useState(false);
    const [importDialogInputs, setImportDialogInputs] = React.useState(null);
    const [importThemeApplication, setImportThemeApplication] = React.useState("tenant");
    const [importUploadFileName, setImportUploadFileName] = React.useState(null);
    const themeInputRef = React.useRef(null);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    React.useEffect(() => {
        const fetchSiteCollections = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield MegaMenuAPIService.getSiteCollection(sessionStorage.getItem("Region"));
                const sortedSiteCollections = response.map((site) => ({
                    key: site.id,
                    text: site.name,
                }));
                setSiteCollections(sortedSiteCollections);
            }
            catch (error) {
                console.error("Error fetching site collections:", error);
            }
        });
        fetchSiteCollections();
    }, []);
    const handleEdit = (theme) => {
        setSelectedTheme(theme);
    };
    const handleExport = (theme) => {
        const stringifiedMenu = "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(theme));
        const downloadNode = document.createElement("a");
        downloadNode.setAttribute("href", stringifiedMenu);
        downloadNode.setAttribute("download", theme.themeName + "-" + Date.now().toString() + ".json");
        document.body.appendChild(downloadNode);
        downloadNode.click();
        downloadNode.remove();
        notify("Successfully exported theme configuration");
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        event.currentTarget.classList.add("drag-over");
    };
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.currentTarget.classList.remove("drag-over");
    };
    const handleDrop = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        event.currentTarget.classList.contains("drag-over") &&
            event.currentTarget.classList.remove("drag-over");
        const droppedFiles = Array.from(event.dataTransfer.files);
        const themeData = yield getThemeData(droppedFiles[0]);
        const parsedThemeData = JSON.parse(themeData);
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { id: "", themeName: parsedThemeData.themeName, logo: parsedThemeData.logo, customCSS: parsedThemeData.customCSS, customJSCode: parsedThemeData.customJSCode, themeSettings: parsedThemeData.themeSettings, siteCollectionUrls: selectedTheme.siteCollectionUrls, advanced: parsedThemeData.advanced })));
    });
    const inputUpload = () => {
        themeInputRef.current.click();
    };
    const disableLinkStyle = {
        textDecoration: "none",
        color: "inherit",
    };
    const getThemeData = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
            setImportUploadFileName(file.name);
        });
    };
    const handleSelected = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        const droppedFiles = Array.from(event.target.files);
        const themeData = yield getThemeData(droppedFiles[0]);
        const parsedThemeData = JSON.parse(themeData);
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { id: "", themeName: parsedThemeData.themeName, logo: parsedThemeData.logo, customCSS: parsedThemeData.customCSS, customJSCode: parsedThemeData.customJSCode, themeSettings: parsedThemeData.themeSettings, siteCollectionUrls: selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.siteCollectionUrls })));
    });
    const handleModalThemeNameBlur = (event) => {
        event.preventDefault();
        setImportDialogInputs((prev) => (Object.assign(Object.assign({}, prev), { themeName: event.target.value })));
    };
    const handleModalCreate = () => {
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { themeName: importDialogInputs.themeName })));
    };
    const dismissThemeUpload = () => {
        setSelectedTheme(null);
        setImportUploadFileName(null);
    };
    const defaultTheme = {
        id: "",
        themeName: "",
        logo: null,
        themeSettings: {
            menuStyle: {
                buttonRadius: "",
                submenuType: "compact",
                textSettings: {
                    textColorPrimary: false,
                    textAlignCenter: false,
                },
                buttonRadiusSelectorIndex: 0,
            },
            color: {
                primaryColor: "#000000",
                style: "soft",
            },
            font: {
                fontFamily: "",
                customFont: null,
            },
        },
        siteCollectionUrls: [],
        advanced: {
            customCSS: "",
            customJS: "",
            enableAppBar: false,
            enableHeader: false,
        },
        publishedVersion: false,
    };
    const handleCreate = () => {
        setSelectedTheme(defaultTheme);
    };
    const handleOpenImportDialog = () => {
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { siteCollectionUrls: [] })));
        setImportDialog(true);
    };
    const notifyPublish = () => {
        setTimeout(() => {
            notify("Theme published successfully");
        }, 100);
    };
    const notify = (message) => dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { children: message }) }), { intent: "success" });
    if (isThemesLoading) {
        return (_jsxs(_Fragment, { children: [_jsxs(HeaderContainer, { children: [_jsx("h1", { children: "Branding" }), _jsxs("p", { children: [" ", "Create and edit themes according to your the company's branding."] })] }), _jsx(SpinnerContainer, { children: _jsx(Spinner, { size: "medium", label: "We're getting everything ready for you... ", labelPosition: "below" }) })] }));
    }
    if (!themes)
        return null;
    if ((themes === null || themes === void 0 ? void 0 : themes.length) == 0) {
        return (_jsxs(ContainerNoThemes, { children: [_jsx("img", { src: "/images/public/design_ideas.png", alt: "Branding" }), _jsx(Title, Object.assign({ className: "noTheme" }, { children: "Create a theme for your Intranet " })), _jsx(Subtitle, Object.assign({ className: "noTheme" }, { children: "Customize a Intranet them by adjusting colors, fonts and logo according to the company's branding." })), _jsx(Link, Object.assign({ style: disableLinkStyle, to: "/intranet/branding/name" }, { children: _jsx(Button, Object.assign({ appearance: "primary", size: "large", onClick: handleCreate }, { children: "Create Theme" })) }))] }));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs(HeaderContainer, { children: [_jsx("h1", { children: "Branding" }), _jsxs("p", { children: [" ", "Create and edit themes according to your the company's branding."] })] }), _jsxs(Menu, { children: [_jsx(MenuTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(MenuButton, Object.assign({ appearance: "primary", icon: _jsx(Add20Regular, {}) }, { children: "New Theme" })) })), _jsx(MenuPopover, { children: _jsxs(MenuList, { children: [_jsx(Link, Object.assign({ style: disableLinkStyle, onClick: () => handleCreate(), to: "/intranet/branding/name" }, { children: _jsx(MenuItem, { children: "Start from scratch" }) })), _jsx(MenuItem, Object.assign({ onClick: handleOpenImportDialog }, { children: "Import existing theme" }))] }) })] }), _jsx(Container, { children: themes.map((theme, index) => (_jsxs(ThemeCard, { children: [_jsxs(ThemeCardStatusTopRow, { children: [theme.modified ? (_jsxs(ThemeCardModified, { children: ["Edited ", moment(theme.modified).fromNow()] })) : (_jsx("div", {})), _jsx(Publisher, { themeId: theme.id, insideCard: true, onPublish: notifyPublish })] }), _jsx(ThemeTitle, { children: theme.themeName }), _jsx(SiteCollections, { theme: theme }), _jsxs(ThemeActions, { children: [_jsx(Button, Object.assign({ onClick: () => handleExport(theme) }, { children: "Export" })), _jsx(Link, Object.assign({ to: "/intranet/branding/edit" }, { children: _jsx(Button, Object.assign({ onClick: () => handleEdit(theme) }, { children: "Edit" })) }))] })] }, index))) }), _jsx(Dialog, Object.assign({ open: importDialog, onOpenChange: (event, data) => {
                        setImportDialog(data.open);
                    } }, { children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Import existing theme" }), _jsxs(DialogContent, { children: [_jsx("p", { children: "Create a new theme by uploading an existing one." }), _jsx("h4", { children: "First, give it a name" }), _jsx(Field, Object.assign({ required: true }, { children: _jsx(Input, { onBlur: handleModalThemeNameBlur }) })), _jsx("h4", { children: "Apply theme to:" }), _jsx(Field, { children: _jsxs(RadioGroup, Object.assign({ onChange: (_, data) => setImportThemeApplication(data.value), defaultValue: "tenant" }, { children: [_jsx(Radio, { value: "tenant", label: "Tenant" }), _jsx(Radio, { value: "sitecollection", label: "Site Collection" })] })) }), importThemeApplication &&
                                            importThemeApplication === "sitecollection" && (_jsx(SiteSelectContainer, { children: _jsx(SiteCollectionSelect, {}) })), _jsx("h4", { children: "Upload existing theme" }), _jsxs(ThemeUpload, Object.assign({ onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDrop }, { children: [_jsx(ThemeUploadIcon, { className: "upload-icon", src: uploadIcon }), _jsx(ThemeUploadIcon, { className: "upload-icon-active", src: uploadIconActive }), _jsxs("p", { children: ["Drag and drop the file or", " ", _jsx("span", Object.assign({ onClick: inputUpload }, { children: "upload from desktop" })), "."] }), _jsx("p", { children: "file format only" }), _jsx("input", { type: "file", ref: themeInputRef, onChange: (e) => handleSelected(e), accept: ".json" })] })), _jsx(ThemeInfoContainer, { children: importUploadFileName ? (_jsxs(ThemeInfo, { children: [_jsx(RiFolder4Line, {}), _jsx(ThemeInfoName, { children: importUploadFileName }), _jsx(DismissThemeIcon, Object.assign({ onClick: dismissThemeUpload }, { children: "X" }))] })) : null })] }), _jsxs(DialogActions, { children: [_jsx(DialogTrigger, Object.assign({ disableButtonEnhancement: true }, { children: _jsx(Button, Object.assign({ appearance: "secondary" }, { children: "Cancel" })) })), _jsx(Link, Object.assign({ to: "/intranet/branding/name", onClick: handleModalCreate }, { children: _jsx(Button, Object.assign({ appearance: "primary" }, { children: "Create Theme" })) }))] })] }) }) })), _jsx(Toaster, { toasterId: toasterId, position: "top-end", mountNode: undefined })] }));
    }
}
export default ThemeSummary;
