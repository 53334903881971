var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Dropdown, Option, Spinner, Checkbox, } from "@fluentui/react-components";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import MultiBadgeDropdown from "../../../../components/MultiBadgeDropdown";
import LinkIconModalBody from "./LinkIconModalBody";
import { QuicklinksService } from "../QuicklinksService";
import { AudiencesService } from "../../audiences/AudiencesService";
import { LinkIcon } from "../LinkIcon";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-yvr1s6" }) `
  align-items: center;
  display: flex;
  color: #3d3a3b;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-1hvq1hi" }) `
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-18ys13y" }) `
  color: #707070;
  margin-bottom: 4px;
`;
const SectionTitle = styled.span.withConfig({ displayName: "SectionTitle", componentId: "-wbpsvh" }) `
  display: inline-block;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 32px;
  color: #3d3a3b;
`;
const MarginBottomInput = styled(Input).withConfig({ displayName: "MarginBottomInput", componentId: "-l0cju5" }) `
  margin-bottom: 16px;
`;
const CategoryAudiencesContainer = styled.div.withConfig({ displayName: "CategoryAudiencesContainer", componentId: "-t99js6" }) `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-s49zct" }) `
  padding-top: 24px;
`;
const DropdownWrapper = styled.div.withConfig({ displayName: "DropdownWrapper", componentId: "-1fw59a8" }) `
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const StyledCheckbox = styled(Checkbox).withConfig({ displayName: "StyledCheckbox", componentId: "-xs0al1" }) `
  margin-top: 10px;
`;
const EditQuicklinkModal = ({ link, onClose, refresh, notifyError, }) => {
    var _a, _b, _c, _d;
    const { DiscoverLink, GetCategories, EditLink, AddLink, DeleteIcon } = QuicklinksService();
    const { GetAudiences } = AudiencesService();
    const [allCategories, setAllCategories] = useState([]);
    const [allAudiences, setAllAudiences] = useState([]);
    const [editedQuicklink, setEditedQuicklink] = useState(link !== null && link !== void 0 ? link : {
        audiences: [],
        description: "",
        icon: null,
        id: "",
        name: "",
        url: "",
        mobileHide: false,
        showOnEverywhereWidget: false,
    });
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [nameDisabled, setNameDisabled] = useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [selectedAudiences, setSelectedAudiences] = React.useState([]);
    const [editIconType, setEditIconType] = useState();
    const [defaultFavIconUrl, setDefaultFavIconUrl] = useState();
    useEffect(() => {
        var _a;
        setSaveDisabled(link ? false : true);
        setNameDisabled(link ? false : true);
        setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { categoryId: (_a = link === null || link === void 0 ? void 0 : link.category) === null || _a === void 0 ? void 0 : _a.id }));
        const getAllInformation = () => __awaiter(void 0, void 0, void 0, function* () {
            const allCategories = yield GetCategories();
            setAllCategories(allCategories);
            const audienceCollection = yield GetAudiences();
            const allAudiences = audienceCollection === null || audienceCollection === void 0 ? void 0 : audienceCollection.audienceCollection.map((s) => ({
                id: s.audienceId,
                name: s.audienceName,
            }));
            setAllAudiences(allAudiences);
            const selectedAudiences = allAudiences === null || allAudiences === void 0 ? void 0 : allAudiences.filter((sub) => editedQuicklink.audiences.includes(sub.id));
            console.log("selectedAudiences", selectedAudiences);
            setSelectedAudiences(selectedAudiences);
        });
        getAllInformation();
    }, []);
    useEffect(() => {
        if ((editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.name) &&
            (editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.name) != "" &&
            (editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.url) &&
            (editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.url) != "")
            setSaveDisabled(false);
        else
            setSaveDisabled(true);
        DiscoverLink(editedQuicklink.url).then((data) => {
            setDefaultFavIconUrl(data.favIcon);
        });
    }, [editedQuicklink]);
    const handleQuicklinkChange = (key, value) => {
        setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { [key]: value }));
    };
    const handleAudienceChange = () => {
        setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { ["audiences"]: selectedAudiences }));
    };
    const isHttpUrl = (url) => {
        const urlRegex = /^(http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    };
    const handleUrlChanged = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value && value != (link === null || link === void 0 ? void 0 : link.url)) {
            if (isHttpUrl(value)) {
                const result = yield DiscoverLink(value);
                setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { name: result.title, icon: {
                        iconId: "000000000000000000000000",
                        type: "External",
                        url: result.favIcon,
                    } }));
                setNameDisabled(false);
                return;
            }
            setNameDisabled(false);
            setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { name: "", icon: null }));
            return;
        }
    });
    const handleQuicklinkSave = (props) => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        try {
            setIsSaving(true);
            handleAudienceChange();
            if (link)
                yield EditLink(editedQuicklink);
            else
                yield AddLink(editedQuicklink).then((data) => {
                    if (data === null || data === void 0 ? void 0 : data.error) {
                        notifyError(data.message);
                        setIsSaving(false);
                    }
                });
            // change here
            if (((_e = editedQuicklink.icon) === null || _e === void 0 ? void 0 : _e.type) == "External") {
                //await DeleteIcon(editedQuicklink.icon?.iconId);
            }
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
        setIsSaving(true);
        refresh();
        onClose();
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [editIconType ? (_jsx(Button, { appearance: "transparent", icon: _jsx(ArrowLeftRegular, { style: {
                                    height: "20px",
                                    width: "20px",
                                } }), onClick: () => {
                                setEditIconType(undefined);
                            } })) : null, editIconType
                            ? editIconType === "library"
                                ? "Select icon from library"
                                : editIconType === "link"
                                    ? "Upload icon from link"
                                    : "Upload icon"
                            : `${!!link ? "Edit" : "Add new"} link`] }), !editIconType ? (_jsxs(StyledDialogContent, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "edit-quicklink-url", required: true }, { children: "URL" })), _jsx(MarginBottomInput, { id: "edit-quicklink-url", placeholder: "Insert URL", size: "large", value: editedQuicklink.url, onChange: (e) => handleQuicklinkChange("url", e.target.value), onBlur: (e) => handleUrlChanged(e.target.value) }), _jsx(StyledLabel, Object.assign({ htmlFor: "edit-quicklink-name", required: true }, { children: "Name" })), _jsx(MarginBottomInput, { id: "edit-quicklink-name", placeholder: "Define a name", size: "large", value: editedQuicklink.name, onChange: (e) => handleQuicklinkChange("name", e.target.value), disabled: nameDisabled }), _jsx(StyledLabel, Object.assign({ htmlFor: "edit-quicklink-description" }, { children: "Description" })), _jsx(Input, { id: "edit-quicklink-description", placeholder: "Define a description", size: "large", value: editedQuicklink.description, onChange: (e) => handleQuicklinkChange("description", e.target.value) }), _jsx(StyledCheckbox, { checked: editedQuicklink.mobileHide, onChange: (e) => handleQuicklinkChange("mobileHide", e.target.checked), label: "Hide on mobile" }), _jsx(SectionTitle, { children: "Category and Audiences" }), _jsxs(CategoryAudiencesContainer, { children: [_jsxs(DropdownWrapper, { children: [_jsx(StyledLabel, Object.assign({ id: "edit-policy-category" }, { children: "Category" })), _jsx(Dropdown, Object.assign({ "aria-labelledby": "edit-policy-category", placeholder: "Select category", selectedOptions: !!((_a = editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.category) === null || _a === void 0 ? void 0 : _a.id)
                                                ? [(_b = editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.category) === null || _b === void 0 ? void 0 : _b.id]
                                                : [], size: "large", value: (_d = (_c = editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.category) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "", onOptionSelect: (_, data) => {
                                                setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { categoryId: data.optionValue, category: allCategories.filter((s) => s.id == data.optionValue)[0] }));
                                            } }, { children: allCategories.map((category) => (_jsx(Option, Object.assign({ value: category.id }, { children: category.name }), category.id))) }))] }), _jsx(MultiBadgeDropdown, { items: allAudiences, label: "Target audiences", labelId: "edit-quicklink-audiences", placeholder: "Select audiences", selectedItems: selectedAudiences, onOptionSelect: (audiences) => {
                                        setSelectedAudiences(audiences);
                                        handleQuicklinkChange("audiences", audiences.map((s) => s.id));
                                    } })] }), _jsx(SectionTitle, { children: "Icon" }), _jsx(LinkIcon, { icon: editedQuicklink === null || editedQuicklink === void 0 ? void 0 : editedQuicklink.icon, setEditIconType: setEditIconType, defaultFavIconUrl: defaultFavIconUrl, updateIcon: (icon) => {
                                setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { icon: icon }));
                            } })] })) : (_jsx(LinkIconModalBody, { type: editIconType, onClose: () => {
                        setEditIconType(undefined);
                    }, onIconSave: (icon) => {
                        setEditedQuicklink(Object.assign(Object.assign({}, editedQuicklink), { icon: icon }));
                        setEditIconType(undefined);
                    } })), !editIconType ? (_jsx(StyledDialogActions, { children: isSaving ? (_jsx(Spinner, { size: "small", label: "Saving..." })) : (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", onClick: handleQuicklinkSave, disabled: saveDisabled }, { children: !!link ? "Save" : "Add link" }))] })) })) : null] }) }));
};
export default EditQuicklinkModal;
