import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { GroupWrapper, GroupHeader, GroupChildren, MatrixMenuPreviewWrapper, Flyout, SelectBar, SelectBarItem, OrphanedItems, FlyoutItem, } from "./Preview.styles";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
export const Preview = (props) => {
    const [dimensions, setDimensions] = React.useState(props.dimensions);
    const [filteredDimensions, setFilteredDimensions] = React.useState([]);
    const [selectedPath, setSelectedPath] = React.useState([]);
    const [selectedPathIdArray, setSelectedPathIdArray] = React.useState([]);
    const [activeFlyout, setActiveFlyout] = React.useState(0);
    React.useEffect(() => {
        dimensions.forEach((dimension, index) => {
            dimension.items.forEach((item) => {
                item.dimensionId = index + 1;
            });
        });
        const handleClickOutside = (event) => {
            if (event.target.closest(".flyout") === null) {
                setActiveFlyout(0);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    React.useEffect(() => {
        const newFilteredDimensions = [];
        dimensions.forEach((dimension, index) => {
            const filteredItems = dimension.items.filter((item) => {
                // always display first dimension items
                if (item.dimensionId === 1)
                    return true;
                // filter items against paths based on selected parent
                const paths = props.paths.filter((path) => selectedPathIdArray[item.dimensionId - 2] ===
                    path.selection[item.dimensionId - 2]);
                return paths.some((path) => {
                    if (path.selection[item.dimensionId - 1] === item.itemId) {
                        return true;
                    }
                    // if item is a child of a group, display the group header
                    if (item.items && item.isGroupHeader) {
                        if (item.items.some((item) => item.itemId === path.selection[item.dimensionId - 1])) {
                            item.items = item.items.filter((item) => item.itemId === path.selection[item.dimensionId - 1]);
                            return true;
                        }
                    }
                });
            });
            const filteredDimension = {
                dimensionId: dimension.dimensionId,
                text: dimension.text,
                items: filteredItems,
            };
            newFilteredDimensions.push(filteredDimension);
        });
        setFilteredDimensions(selectedPath.length > 0 ? newFilteredDimensions : dimensions);
    }, [selectedPath]);
    function findGroups(dimension) {
        const groups = dimension.items.filter((item) => item.isGroupHeader === true);
        const children = dimension.items.filter((item) => item.isGroupHeader === false && item.groupId);
        children.forEach((item) => {
            const index = groups.findIndex((groupItem) => groupItem.itemId === item.groupId);
            if (index !== -1) {
                if (!groups[index].items) {
                    groups[index].items = [];
                }
                if (!groups[index].items.find((groupItem) => groupItem.itemId === item.itemId)) {
                    groups[index].items.push(item);
                }
            }
        });
        return groups;
    }
    function findOrphans(dimension) {
        return dimension.items.filter((item) => item.isGroupHeader === false && item.groupId === 0);
    }
    function handleItemSelect(item) {
        setActiveFlyout(0);
        if (props.paths.find((path) => {
            if (path.selection[item.dimensionId] === item.itemId &&
                path.selection.length === parseInt(item.dimensionId) &&
                item.url !== "")
                return true;
        }))
            return;
        let newSelectedPath = selectedPath.slice(0, item.dimensionId);
        const newPathItem = {
            itemId: item.itemId,
            text: item.text,
        };
        newSelectedPath[item.dimensionId - 1] = newPathItem;
        setSelectedPath(newSelectedPath);
        let newPathIds = selectedPathIdArray.slice(0, item.dimensionId);
        newPathIds[item.dimensionId - 1] = item.itemId;
        setSelectedPathIdArray(newPathIds);
    }
    return (_jsxs(MatrixMenuPreviewWrapper, { children: [_jsx(SelectBar, { children: Array.apply(null, { length: dimensions.length }).map((e, i) => {
                    var _a, _b;
                    return (_jsxs(SelectBarItem, Object.assign({ onClick: selectedPathIdArray.length >= i
                            ? () => setActiveFlyout(dimensions[i].dimensionId)
                            : null, className: selectedPathIdArray.length >= i ? "" : "isDisabled" }, { children: [dimensions[i].text, ":\u00A0", _jsx("strong", { children: ((_a = selectedPath[i]) === null || _a === void 0 ? void 0 : _a.text) ? (_b = selectedPath[i]) === null || _b === void 0 ? void 0 : _b.text : "-" }), activeFlyout === dimensions[i].dimensionId ? (_jsx(RiArrowDropUpLine, { size: 30 })) : (_jsx(RiArrowDropDownLine, { size: 30 }))] }), 1));
                }) }), filteredDimensions.map((dimension, i) => {
                const orphaneditems = findOrphans(dimension);
                const groups = findGroups(dimension);
                return (_jsxs(Flyout, Object.assign({ className: `flyout ${activeFlyout === dimension.dimensionId ? "isActive" : ""}` }, { children: [groups === null || groups === void 0 ? void 0 : groups.map((group, i) => {
                            var _a;
                            return (_jsx(GroupWrapper, { children: _jsxs(GroupHeader, { children: [_jsx(FlyoutItem, Object.assign({ onClick: () => handleItemSelect(group) }, { children: _jsx("strong", { children: group.text }) })), _jsx(GroupChildren, { children: (_a = group.items) === null || _a === void 0 ? void 0 : _a.map((item, i) => (_jsx(FlyoutItem, Object.assign({ onClick: () => handleItemSelect(item) }, { children: item.text }), i))) })] }) }, i));
                        }), _jsx(OrphanedItems, { children: orphaneditems.map((item, i) => (_jsx(FlyoutItem, Object.assign({ onClick: () => handleItemSelect(item) }, { children: item.text }), i))) })] })));
            })] }));
};
