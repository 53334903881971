import { styled, css } from "styled-components";
export const DroppableContainer = styled.div.withConfig({ displayName: "DroppableContainer", componentId: "-1ruiqiq" }) `
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: calc(48vh - 150px);
`;
export const ListWrapper = styled.div.withConfig({ displayName: "ListWrapper", componentId: "-esjyct" }) `
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(48vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5rem;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;
export const nodeStyle = css `
  position: relative;
  min-height: 36px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: var(--editor-node-shadow);
  padding: 5px 8px;
  color: #333;
  background: white;
  box-shadow: 0 0 3px #aec1cb;
  margin-bottom: 7px;
  border-radius: 4px;
  transition: all 0.1s ease;
  word-break: break-all;

  + & {
    border-block: var(--editor-node-border);
  }

  &.isActive {
    background-color: var(--lt-purple-bg-lighter);
    color: #512da8;
    border: solid 1px #512da8;
    svg {
      opacity: 1;
    }
  }

  &.isInPath {
    border: none;
  }

  svg {
    opacity: 0;
    transition: all 0.1s ease;
  }
  &:hover {
    background-color: var(--lt-purple-bg-lighter);
    color: #512da8;
    svg {
      opacity: 1;
    }
  }
`;
export const nodeTextStyle = css `
  display: flex;
  align-items: center;
  padding-inline: 10px;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
`;
export const levelNoticeStyle = css `
  margin: auto;
  padding: 1rem;
  font-weight: 600;
  color: #000000;
  opacity: 0.5;
  text-align: center;
`;
export const iconWrapperStyle = css `
  cursor: pointer;

  & + & {
    margin-left: 5px;
  }

  svg {
    color: var(--lt-blue-dark);
  }

  .isActive & svg {
    color: #512da8;
  }

  &:hover svg {
    color: var(--editor-node-icon-hover);
  }
`;
export const iconsWrapperStartStyle = css `
  display: flex;
  align-items: center;
`;
export const iconsWrapperEndStyle = css `
  display: flex;
  align-items: center;
  margin-inline: auto 5px;
`;
export const iconDragWrapperStyle = css `
  cursor: grab;
`;
export const iconStaticWrapperStyle = css `
  margin-left: 10px;
`;
