var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner, } from "@fluentui/react-components";
import { QuicklinksService } from "../QuicklinksService";
const DeleteQuicklinkModal = ({ links, onClose, refresh, }) => {
    const { DeleteLink, DeleteLinks } = QuicklinksService();
    const [isSaving, setIsSaving] = React.useState(false);
    const handleQuicklinksDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            if (links.length == 1)
                yield DeleteLink(links[0].id);
            else
                yield DeleteLinks(links.map((link) => { return link.id; }));
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
        setIsSaving(false);
        refresh();
        onClose();
    });
    return links ? (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, Object.assign({ style: { color: "#3D3A3B", paddingBottom: "20px" } }, { children: "Delete link" })), _jsxs(DialogContent, { children: ["Are you sure you want to delete", " ", links.length !== 1 ? (_jsxs("b", { children: [links.length, " links"] })) : (_jsxs("span", { children: [_jsx("b", { children: links[0].name }), " link"] })), "?"] }), _jsx(DialogActions, { children: isSaving ?
                        (_jsx(Spinner, { size: "small", label: "Saving..." })) :
                        (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "primary", onClick: handleQuicklinksDelete }, { children: "Delete" })), _jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" }))] })) })] }) })) : null;
};
export default DeleteQuicklinkModal;
