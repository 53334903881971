var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import envVarConfig from "../../../../env/envVarConfig";
import { instance } from "../../../../index";
const userRegion = sessionStorage.getItem("Region");
const apiPath = envVarConfig.reactApiUrl + "/Branding";
function getUserAndTenantId() {
    const userString = sessionStorage.getItem("User");
    const user = userString ? JSON.parse(userString) : null;
    const tenantId = user ? user.tenantId : "";
    return { user, tenantId };
}
export class BrandingAPIService {
    static generateUUID() {
        let dt = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    }
    static getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = instance.getAllAccounts();
            const token = yield instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [envVarConfig.backOfficeAppScope],
            });
            return token.accessToken;
        });
    }
    static getTenantThemes(tenantId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userToken = yield this.getToken();
                const headers = {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                };
                const response = yield fetch(`${apiPath}/${tenantId}`, { headers });
                if (!response.ok) {
                    return null;
                }
                const tenantThemes = yield response.json();
                return tenantThemes;
            }
            catch (error) {
                console.error("Error fetching tenant themes:", error);
                return null;
            }
        });
    }
    static removeTheme(themeId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { tenantId } = getUserAndTenantId();
                const userToken = yield this.getToken();
                const options = {
                    method: "Delete",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "X-Region": userRegion,
                    },
                };
                const response = yield fetch(`${apiPath}/${tenantId}/${themeId}`, options);
                return response;
            }
            catch (error) {
                console.error("Error removing theme:", error);
                throw error;
            }
        });
    }
    static saveTheme(theme) {
        return __awaiter(this, void 0, void 0, function* () {
            if (theme.id === "")
                theme.id = BrandingAPIService.generateUUID();
            try {
                const { tenantId } = getUserAndTenantId();
                let tenantThemes = yield this.getTenantThemes(tenantId);
                let method = "POST";
                if (!tenantThemes) {
                    tenantThemes = {
                        id: "",
                        themes: [theme],
                        tenantId: tenantId,
                    };
                }
                else {
                    const themeIndex = tenantThemes.themes.findIndex((t) => t.id === theme.id);
                    if (themeIndex !== -1) {
                        tenantThemes.themes[themeIndex] = theme;
                    }
                    else {
                        tenantThemes.themes.push(theme);
                    }
                }
                const userToken = yield this.getToken();
                const options = {
                    body: JSON.stringify(tenantThemes),
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "X-Region": userRegion,
                    },
                };
                const response = yield fetch(`${apiPath}/save/${theme.id}`, options);
                return response;
            }
            catch (error) {
                console.error("Error saving theme:", error);
                throw error;
            }
        });
    }
    static publishTheme(theme) {
        return __awaiter(this, void 0, void 0, function* () {
            if (theme.id === "")
                theme.id = BrandingAPIService.generateUUID();
            try {
                const { tenantId } = getUserAndTenantId();
                let tenantThemes = yield this.getThemes();
                if (!tenantThemes) {
                    tenantThemes = {
                        id: "",
                        themes: [theme],
                        tenantId: tenantId,
                    };
                }
                else {
                    const themeIndex = tenantThemes.themes.findIndex((t) => t.id === theme.id);
                    if (themeIndex !== -1) {
                        tenantThemes.themes = tenantThemes.themes.map((t, index) => index === themeIndex ? Object.assign(Object.assign({}, t), theme) : t);
                    }
                    else {
                        tenantThemes.themes = [...tenantThemes.themes, theme];
                    }
                }
                const userToken = yield this.getToken();
                const options = {
                    body: JSON.stringify(tenantThemes),
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "X-Region": userRegion,
                    },
                };
                const response = yield fetch(`${apiPath}/publish/${theme.id}`, options);
                return response;
            }
            catch (error) {
                console.error("Error saving theme:", error);
                throw error;
            }
        });
    }
    static updateThemeName(themeId, newThemeName) {
        return __awaiter(this, void 0, void 0, function* () {
            const { tenantId } = getUserAndTenantId();
            try {
                const userToken = yield this.getToken();
                const headers = {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "X-Region": userRegion,
                };
                const body = JSON.stringify({ themeName: newThemeName });
                const response = yield fetch(`${apiPath}/${tenantId}/${themeId}/UpdateThemeName`, {
                    method: "PATCH",
                    headers: headers,
                    body: body,
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return yield response.json();
            }
            catch (error) {
                console.error("Error updating theme name:", error);
                throw error;
            }
        });
    }
    static updateSiteCollectionUrls(themeId, siteCollectionUrls) {
        return __awaiter(this, void 0, void 0, function* () {
            const { tenantId } = getUserAndTenantId();
            try {
                const userToken = yield this.getToken();
                const headers = {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "X-Region": userRegion,
                };
                const body = JSON.stringify({ siteCollectionUrls: siteCollectionUrls });
                const response = yield fetch(`${apiPath}/${tenantId}/${themeId}/UpdateSiteCollectionUrls`, {
                    method: "PATCH",
                    headers: headers,
                    body: body,
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return yield response.json();
            }
            catch (error) {
                console.error("Error updating SiteCollectionUrls:", error);
                throw error;
            }
        });
    }
}
_a = BrandingAPIService;
BrandingAPIService.getThemes = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { tenantId } = getUserAndTenantId();
        let tenantThemes = yield _a.getTenantThemes(tenantId);
        return tenantThemes;
    }
    catch (error) {
        console.error("Error fetching tenant themes:", error);
        return null;
    }
});
