const intranetNavigationData = [
    {
        title: 'MegaMenu',
        description: 'Allows the user to configure and update the MegaMenu Navigation for their organization.',
        route: 'intranet/navigation/megamenu',
        editPage: true,
    },
    {
        title: 'Matrix',
        description: 'Allows the user to configure and update the Matrix Navigation for their organization.',
        route: 'intranet/navigation/matrix',
        editPage: true,
    }
];
export default intranetNavigationData;
