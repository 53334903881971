import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { ArrowUploadRegular, DeleteRegular, LibraryRegular, LinkRegular, } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { DefaultIcon } from "./DefaultIcon";
import envVarConfig from "../../../env/envVarConfig";
const CurrentIconContainer = styled.div.withConfig({ displayName: "CurrentIconContainer", componentId: "-3khkw" }) `
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #3d3a3b;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 4px 8px;
`;
const CurrentIconLabel = styled.span.withConfig({ displayName: "CurrentIconLabel", componentId: "-jt9y84" }) `
  align-items: center;
  display: flex;
  gap: 8px;

  & > svg {
    height: 18px;
    width: 18px;
  }
`;
const IconActionsContainer = styled.div.withConfig({ displayName: "IconActionsContainer", componentId: "-1w3wx7b" }) `
  display: flex;
  gap: 12px;
`;
export const LinkIcon = ({ icon, defaultFavIconUrl, setEditIconType, updateIcon, }) => {
    const [hasError, setHasError] = useState(false);
    const handleImageError = (error) => {
        setHasError(true);
    };
    useEffect(() => {
        setHasError(false);
    }, [icon]);
    return (_jsxs(_Fragment, { children: [_jsxs(CurrentIconContainer, { children: [_jsxs(CurrentIconLabel, { children: [_jsx("span", { children: "Current icon:" }), !hasError && icon != null ? (_jsx("img", { src: ((icon === null || icon === void 0 ? void 0 : icon.type) == "External" ? "" : envVarConfig.reactApiUrl) +
                                    (icon === null || icon === void 0 ? void 0 : icon.url), alt: "Icon", onError: handleImageError.bind(this), height: "22px" })) : (_jsx(DefaultIcon, {}))] }), _jsx(Button, { appearance: "transparent", icon: _jsx(DeleteRegular, {}), disabled: (icon === null || icon === void 0 ? void 0 : icon.iconId) === "000000000000000000000000", onClick: () => {
                            updateIcon({
                                iconId: "000000000000000000000000",
                                type: "External",
                                url: defaultFavIconUrl ? defaultFavIconUrl : "",
                            });
                        } })] }), _jsxs(IconActionsContainer, { children: [_jsx(Button, Object.assign({ appearance: "secondary", icon: _jsx(LinkRegular, {}), onClick: () => {
                            setEditIconType("link");
                        } }, { children: "Link icon" })), _jsx(Button, Object.assign({ appearance: "secondary", icon: _jsx(ArrowUploadRegular, {}), onClick: () => {
                            setEditIconType("upload");
                        } }, { children: "Upload icon" })), _jsx(Button, Object.assign({ appearance: "secondary", icon: _jsx(LibraryRegular, {}), onClick: () => {
                            setEditIconType("library");
                        } }, { children: "From library" }))] })] }));
};
