export const languages = [
    { languageCode: "ar", languageName: "Arabic - العربية" },
    { languageCode: "bn", languageName: "Bengali - বাংলা" },
    { languageCode: "da", languageName: "Danish" },
    { languageCode: "nl", languageName: "Dutch" },
    { languageCode: "en", languageName: "English" },
    { languageCode: "fi", languageName: "Finnish" },
    { languageCode: "fr", languageName: "French" },
    { languageCode: "de-de", languageName: "German" },
    { languageCode: "el", languageName: "Greek" },
    { languageCode: "he", languageName: "Hebrew" },
    { languageCode: "hi", languageName: "Hindi" },
    { languageCode: "it", languageName: "Italian" },
    { languageCode: "ja", languageName: "Japanese" },
    { languageCode: "ko", languageName: "Korean" },
    { languageCode: "zh", languageName: "Mandarin Chinese" },
    { languageCode: "no", languageName: "Norwegian" },
    { languageCode: "fa", languageName: "Persian" },
    { languageCode: "pt", languageName: "Portuguese" },
    { languageCode: "ru", languageName: "Russian" },
    { languageCode: "es", languageName: "Spanish" },
    { languageCode: "sv", languageName: "Swedish" },
    { languageCode: "th", languageName: "Thai" },
    { languageCode: "tr", languageName: "Turkish" },
    { languageCode: "ur", languageName: "Urdu" },
    { languageCode: "vi", languageName: "Vietnamese" },
];
