import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { ContentWrapper, DimensionWrapper, DimensionHeader, IconWrapper, ListWrapper, GroupWrapper, GroupHeader, AddNewNodeWrapper, AddNewDimensionWrapper, GroupChildren, DimensionActionsWrapper, NoDimensionsWrapper, } from "./ContentEditor.styles";
import { DimensionItem } from "./components/DimensionItem";
import { RiDeleteBin6Line, RiPencilFill } from "react-icons/ri";
import { PathwaysList } from "./tabs/PathwaysTab/PathwaysList";
import { ContentTab } from "./tabs/ContentTab/ContentTab";
import { DimensionModal } from "./modals/DimensionModal/DimensionModal";
import { EditingModal } from "./modals/EditingModal/EditingModal";
import { PathwaysTab } from "./tabs/PathwaysTab/PathwaysTab";
import { Preview } from "./components/Preview/Preview";
import { Button } from "@fluentui/react-components";
export const ContentEditor = (props) => {
    const [dimensions, setDimensions] = React.useState(props.dimensions);
    const [showItemModal, setShowItemModal] = React.useState(false);
    const [showDimensionModal, setShowDimensionModal] = React.useState(false);
    const [editedDimension, setEditedDimension] = React.useState(null);
    const [editLevel, setEditLevel] = React.useState(0);
    const [editPath, setEditPath] = React.useState(props.editPath);
    const [selectedContentItem, setSelectedContentItem] = React.useState(null);
    React.useEffect(() => {
        setDimensions(props.dimensions);
    }, [props.dimensions]);
    function addNewNode(dimensionLevel) {
        setEditLevel(dimensionLevel);
        setShowItemModal(true);
    }
    function addNewDimension() {
        setEditedDimension(null);
        setShowDimensionModal(true);
    }
    function deleteItem(deleteItem, dimensionLevel) {
        const dimension = dimensions.findIndex((dimension) => dimension.dimensionId === dimensionLevel);
        const index = dimensions[dimension].items.findIndex((item) => item.itemId === deleteItem.itemId);
        dimensions[dimension].items.splice(index, 1);
        setDimensions(dimensions);
        props.updateDimensions(dimensions);
    }
    function updateItem(newItem) {
        var _a;
        const dimension = dimensions.findIndex((dimension) => dimension.dimensionId === newItem.dimensionId);
        const item = dimensions[dimension].items.findIndex((item) => item.itemId === newItem.itemId);
        if (dimensions[dimension].items[item].isGroupHeader === true &&
            newItem.isGroupHeader === false) {
            (_a = dimensions[dimension].items) === null || _a === void 0 ? void 0 : _a.forEach((dimItem) => {
                if (dimItem.groupId === newItem.itemId)
                    dimItem.groupId = 0;
            });
            newItem.items = [];
        }
        dimensions[dimension].items[item] = newItem;
        props.updateDimensions(dimensions);
        setShowItemModal(false);
    }
    function addItem(item) {
        const newId = dimensions.find((dimension) => dimension.dimensionId === item.dimensionId).items.length;
        item.itemId = newId + 1;
        dimensions
            .find((dimension) => dimension.dimensionId === item.dimensionId)
            .items.push(item);
        props.updateDimensions(dimensions);
        setShowItemModal(false);
    }
    function addDimension(dimension) {
        dimensions.push(dimension);
        props.updateDimensions(dimensions);
        setShowDimensionModal(false);
    }
    function editDimension(dimension) {
        const index = dimensions.findIndex((item) => item.dimensionId === dimension.dimensionId);
        dimensions[index] = dimension;
        props.updateDimensions(dimensions);
        setShowDimensionModal(false);
    }
    function deleteDimension(dimension) {
        const index = dimensions.findIndex((item) => item.dimensionId === dimension.dimensionId);
        dimensions.splice(index, dimensions.length - index);
        props.updateDimensions(dimensions);
        setShowDimensionModal(false);
        const filteredPaths = props.paths.filter((path) => {
            return path.selection.length <= index;
        });
        props.updatePaths(filteredPaths);
    }
    function openDimensionEdit(dimension) {
        setEditedDimension(dimension);
        setShowDimensionModal(true);
    }
    function findGroups(dimension) {
        const groups = dimension === null || dimension === void 0 ? void 0 : dimension.items.filter((item) => item.isGroupHeader === true);
        const children = dimension === null || dimension === void 0 ? void 0 : dimension.items.filter((item) => item.isGroupHeader === false && item.groupId);
        groups === null || groups === void 0 ? void 0 : groups.forEach((group) => {
            group.items = [];
        });
        children === null || children === void 0 ? void 0 : children.forEach((item) => {
            const index = groups.findIndex((groupItem) => groupItem.itemId === item.groupId);
            if (index !== -1) {
                if (!groups[index].items) {
                    groups[index].items = [];
                }
                if (!groups[index].items.find((groupItem) => groupItem.itemId === item.itemId)) {
                    groups[index].items.push(item);
                }
            }
        });
        return groups;
    }
    function findOrphans(dimension) {
        return dimension.items.filter((item) => item.isGroupHeader === false && item.groupId === 0);
    }
    function setNewPathSelection(itemId, dimensionId) {
        setEditPath([]);
        props.setNewPath(itemId, dimensionId);
    }
    function handleSelectedContentItem(item, dimensionId) {
        const selected = Object.assign(Object.assign({}, item), { dimensionId: dimensionId, dimension: dimensions[dimensionId - 1] });
        setSelectedContentItem(selected);
    }
    function isNewPathSelected(dimensionId, itemId) {
        return props.editPath[dimensionId - 1] === itemId ? true : false;
    }
    const dimensionItemProps = {
        view: props.view,
        updateItem: updateItem,
        setSelectedContentItem: handleSelectedContentItem,
        selectedContentItem: selectedContentItem,
        editPath: props.editPath,
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ContentWrapper, Object.assign({ className: `view-${props.view}`, view: props.view }, { children: [props.view === "pathways" && (_jsx(Preview, { paths: props.paths, dimensions: props.dimensions })), props.view === "content" && props.dimensions.length === 0 && (_jsxs(NoDimensionsWrapper, { children: [_jsx("img", { src: "/images/public/empty_dimension.svg", alt: "empty-dimension", width: "140" }), _jsx("h3", { children: "No dimensions were added yet" }), _jsx(AddNewDimensionWrapper, { children: _jsx(Button, Object.assign({ onClick: () => addNewDimension(), appearance: "primary" }, { children: "Add first dimension" })) })] })), props.dimensions.map((dimension, i) => {
                        const orphaneditems = findOrphans(dimension);
                        const groups = findGroups(dimension);
                        return (_jsxs(DimensionWrapper, { children: [_jsxs(DimensionHeader, { children: [_jsx("h3", { children: dimension.text }), props.view === "content" && (_jsxs(DimensionActionsWrapper, { children: [_jsx(IconWrapper, Object.assign({ onClick: () => openDimensionEdit(dimension) }, { children: _jsx(RiPencilFill, {}) })), _jsx(IconWrapper, Object.assign({ onClick: () => deleteDimension(dimension), className: "danger" }, { children: _jsx(RiDeleteBin6Line, {}) }))] }))] }), _jsxs(ListWrapper, { children: [groups === null || groups === void 0 ? void 0 : groups.map((group, i) => {
                                            var _a;
                                            return (_jsxs(GroupWrapper, { children: [_jsx(GroupHeader, { children: _jsx(DimensionItem, Object.assign({ item: group, groups: groups, dimensionId: dimension.dimensionId, deleteItem: () => deleteItem(group, dimension.dimensionId), setNewPathSelection: () => setNewPathSelection(group.itemId, dimension.dimensionId), isNewPathSelected: isNewPathSelected(dimension.dimensionId, group.itemId) }, dimensionItemProps)) }), _jsx(GroupChildren, { children: (_a = group.items) === null || _a === void 0 ? void 0 : _a.map((item, i) => (_jsx(DimensionItem, Object.assign({ item: item, groups: groups, dimensionId: dimension.dimensionId, deleteItem: () => deleteItem(item, dimension.dimensionId), setNewPathSelection: () => setNewPathSelection(item.itemId, dimension.dimensionId), isNewPathSelected: isNewPathSelected(dimension.dimensionId, item.itemId) }, dimensionItemProps), i))) })] }, i));
                                        }), orphaneditems.map((item, i) => (_jsx(DimensionItem, Object.assign({ item: item, groups: groups, dimensionId: dimension.dimensionId, deleteItem: () => deleteItem(item, dimension.dimensionId), setNewPathSelection: () => setNewPathSelection(item.itemId, dimension.dimensionId), isNewPathSelected: isNewPathSelected(dimension.dimensionId, item.itemId) }, dimensionItemProps), i)))] }), props.view === "content" && (_jsx(AddNewNodeWrapper, { children: _jsx(EditingModal, { updateItem: addItem, dimensionId: dimension.dimensionId, groups: groups }) }))] }, i));
                    }), props.view === "content" &&
                        dimensions.length < 3 &&
                        props.dimensions.length !== 0 && (_jsx(AddNewDimensionWrapper, { children: _jsx(Button, Object.assign({ onClick: () => addNewDimension() }, { children: "Add new dimension" })) })), _jsx(DimensionModal, { closeModal: () => setShowDimensionModal(false), addDimension: addDimension, editDimension: editDimension, dimension: editedDimension, dimensions: dimensions, showModal: showDimensionModal }), props.view === "pathways" && (_jsx(PathwaysList, { paths: props.paths, dimensions: props.dimensions, editPath: props.editPath, deletePath: props.deletePath }))] })), props.view === "content" && (_jsx(ContentTab, { item: selectedContentItem, updateItem: updateItem, dimensionId: editLevel, groups: findGroups(selectedContentItem ? selectedContentItem.dimension : { items: [] }) })), props.view === "pathways" && (_jsx(PathwaysTab, { paths: props.paths, dimensions: props.dimensions, editPath: props.editPath, updateEditPath: props.updateEditPath, deleteEditPath: props.deleteEditPath }))] }));
};
