import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import envVarConfig from "../../env/envVarConfig";
const StyledLeftMenuSecondLevel = styled.div.withConfig({ displayName: "StyledLeftMenuSecondLevel", componentId: "-1fo8y0c" }) `
  position: absolute;
  left: ${(props) => (props.$isVisible ? "12.5%" : "0")};
  top: 0;
  bottom: 0;
  width: 12.5%;

  padding: 10px;
  transition: all 0.35s ease-in-out;
  display: flex;
  flex-direction: column;
  background: #ebe4ef;
  box-shadow: 3px 1.600000023841858px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;
export const MenuItems = styled.div.withConfig({ displayName: "MenuItems", componentId: "-85iyqt" }) `
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;
export const MenuItem = styled(Link).withConfig({ displayName: "MenuItem", componentId: "-605a30" }) `
  text-decoration: none;
  list-style: none;
  display: flex;
  width: 100%;
  height: 2.5rem;
  padding-left: 0px;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-left: 1.88rem;
  color: #707070;
  font-size: 1em;

  &:hover {
    background: rgba(218, 196, 233, 0.3);
  }

  &.active {
    background: rgba(218, 196, 233, 0.3);
    font-weight: 600;
    border-right: 4px solid #512da8;
    color: #0f0e0e;
  }

  &:first-of-type {
    border-top: 0;
  }
  & > svg {
    margin-right: 10px;
    margin-top: 2px;
  }
`;
const MenuItemsSecondLevel = styled(MenuItems).withConfig({ displayName: "MenuItemsSecondLevel", componentId: "-1rlg1py" }) `
  align-items: center;
  justify-content: flex-start;
  padding-top: 10.5rem;
  & > a {
    padding: 0.625rem 1rem 0.625rem 1.5rem;
  }
`;
const MenuItemSecondLevel = styled(MenuItem).withConfig({ displayName: "MenuItemSecondLevel", componentId: "-cr8qqp" }) `
  &.active {
    font-weight: 600;
    color: #0f0e0e;
    border-right: none;
    background: #ebe4ef;
  }
`;
const CloseButton = styled.button.withConfig({ displayName: "CloseButton", componentId: "-4cmar5" }) `
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: #707070;
`;
export const checkActive = (location, path) => path === "/"
    ? location.pathname === "/"
        ? "active"
        : ""
    : location.pathname.startsWith(path)
        ? "active"
        : "";
const LeftSubMenu = ({ items, onClose }) => {
    const location = useLocation();
    return (_jsxs(StyledLeftMenuSecondLevel, Object.assign({ "$isVisible": !!items }, { children: [_jsx(CloseButton, Object.assign({ onClick: onClose }, { children: "X" })), _jsx(MenuItemsSecondLevel, { children: items === null || items === void 0 ? void 0 : items.map((item) => {
                    const className = checkActive(location, item.to);
                    if (item.children === "Branding" &&
                        envVarConfig.environment === "production") {
                        return null;
                    }
                    return (_createElement(MenuItemSecondLevel, Object.assign({}, item, { className: className, key: item.to })));
                }) })] })));
};
export default LeftSubMenu;
