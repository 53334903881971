var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import envVarConfig from "../../../../env/envVarConfig";
const apiPath = envVarConfig.reactApiUrl;
export class BlogAPIService {
    static getLatestPostsData() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`${apiPath}/LiveTilesBlog/Posts/Latest`);
            if (response.ok) {
                const data = yield response.json();
                return data;
            }
            else {
                console.log("Failed to fetch blog news:", response.status);
                return null;
            }
        });
    }
}
export default BlogAPIService;
