import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Button, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, } from "@fluentui/react-components";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-xdudut" }) `
  align-items: center;
  display: flex;
  padding-bottom: 20px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-1a1lm1h" }) `
  padding-top: 24px;
`;
const DeleteModal = ({ tab, onClose, deleteTab, }) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const handleSave = () => {
        deleteTab(tab);
        onClose();
    };
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(StyledDialogTitle, { children: "Delete tab" }), _jsxs(DialogContent, { children: ["Are you sure you want to delete tab ", _jsxs("b", { children: [tab.name, "?"] })] }), _jsx(StyledDialogActions, { children: isSaving ?
                        (_jsx(Spinner, { size: SpinnerSize.medium, label: "Saving...", ariaLive: "assertive", labelPosition: "right" })) :
                        (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ appearance: "primary", onClick: handleSave }, { children: "Delete" })), _jsx(Button, Object.assign({ appearance: "secondary", onClick: onClose }, { children: "Cancel" }))] })) })] }) }));
};
export default DeleteModal;
