var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useMsal, AuthenticatedTemplate, useMsalAuthentication, } from "@azure/msal-react";
/* import { graphRequest } from '../Auth/authConfig';
 */ import styled from "styled-components";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Register } from "../pages/intranet/components/register/Register";
import AccessDenied from "../pages/intranet/components/Pages/AccessDenied";
import { useAppContext } from "../Context/AppContext";
import { Layout } from "../components/Layout/Layout";
import { fetchRegistration } from "./authService";
import { MegaMenuAPIService } from "../pages/intranet/util/MegaMenuAPIService/MegaMenuAPIService";
import { InteractionRequiredAuthError, InteractionStatus, InteractionType, } from "@azure/msal-browser";
import { loginRequest } from "../Auth/authConfig";
import { callMsGraph } from "./graph";
import envVarConfig from "../env/envVarConfig";
import { createLightTheme } from "@fluentui/react-components";
import { browserName } from "react-device-detect";
const Loading = styled.div.withConfig({ displayName: "Loading", componentId: "-14jmx8e" }) `
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 1);
`;
const liveTiles = {
    10: "#040207",
    20: "#1B122B",
    30: "#2B1A4F",
    40: "#39216D",
    50: "#46278D",
    60: "#5430AA",
    70: "#6440B1",
    80: "#512da8",
    90: "#805FBF",
    100: "#8E6EC6",
    110: "#9C7ECD",
    120: "#A98ED3",
    130: "#B69FDA",
    140: "#C3AFE1",
    150: "#D0C0E7",
    160: "#DDD1EE",
};
const lightTheme = Object.assign({}, createLightTheme(liveTiles));
const Permissions = () => {
    var _a, _b;
    const { appState, setAppState } = useAppContext();
    const { accounts, inProgress, instance } = useMsal();
    const navigate = useNavigate();
    const [isUserAssigned, setIsUserAssigned] = useState(false);
    const [tenantURL, setTenantURL] = useState(null);
    const silentRequest = {
        scopes: [envVarConfig.backOfficeAppScope],
        loginHint: (_a = accounts[0]) === null || _a === void 0 ? void 0 : _a.username,
    };
    const accountIdentifiers = {
        username: (_b = accounts[0]) === null || _b === void 0 ? void 0 : _b.username,
    };
    // using firefox, we need to use popup instead of silent as it goes into a loop
    const { login, result, error } = useMsalAuthentication(browserName === "Firefox" ? InteractionType.Popup : InteractionType.Silent, silentRequest, accountIdentifiers);
    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, loginRequest);
        }
    }, [error, login]);
    useEffect(() => {
        if (!tenantURL && inProgress === InteractionStatus.None) {
            callMsGraph()
                .then((result) => {
                if (result && result.data) {
                    const { decodedToken, data: graphData } = result;
                    if (graphData) {
                        setTenantURL(graphData.webUrl);
                        sessionStorage.setItem("TenantURL", graphData.webUrl);
                        const user = {
                            tenantId: decodedToken.tid,
                            name: decodedToken.given_name,
                            userName: decodedToken.upn,
                            tenantURL: graphData.webUrl,
                        };
                        sessionStorage.setItem("User", JSON.stringify(user));
                        setIsUserAssigned(true);
                    }
                    else {
                        console.error("Graph data is null.");
                    }
                }
            })
                .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(Object.assign(Object.assign({}, loginRequest), { account: instance.getActiveAccount() }));
                }
            });
        }
    }, [inProgress, tenantURL, instance]);
    useEffect(() => {
        const userString = sessionStorage.getItem("User");
        const user = JSON.parse(userString);
        if (!user) {
            return;
        }
        function performPermissions() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const permissions = yield MegaMenuAPIService.getAssignedRoles();
                    console.log(permissions, "permissions");
                    const { isAdmin, isEditor } = permissions;
                    setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { isAdmin: isAdmin, isEditor: isEditor })));
                    if (user) {
                        if (!isAdmin && !isEditor) {
                            navigate("/accessdenied");
                        }
                    }
                }
                catch (error) {
                    console.error("Error performing permissions:", error);
                    setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { hasError: true, isLoading: false })));
                }
            });
        }
        function performRegistration() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const userRegion = sessionStorage.getItem("Region");
                    if (userRegion) {
                        setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { isClientRegistered: true, isLoading: false })));
                    }
                    else {
                        const isRegistered = yield fetchRegistration(user);
                        if (!isRegistered) {
                            setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { isClientRegistered: false, isLoading: false })));
                            navigate("/register");
                        }
                        else {
                            if (userRegion) {
                                sessionStorage.setItem("Region", userRegion);
                            }
                            setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { isClientRegistered: true, isLoading: false })));
                        }
                    }
                }
                catch (error) {
                    console.error("Error performing registration:", error);
                    setAppState((prevAppState) => (Object.assign(Object.assign({}, prevAppState), { hasError: true, isLoading: false })));
                }
            });
        }
        performPermissions();
        performRegistration();
    }, [setAppState, navigate, isUserAssigned]);
    if (appState.isLoading) {
        let loader = require("../pages/intranet/components/Loader/LoaderBlocks.svg");
        return (_jsx(Loading, { children: _jsx("img", { src: loader, alt: "LiveTiles Intranet Operation Center" }) }));
    }
    return !appState.isClientRegistered ? (_jsx(Routes, { children: _jsx(Route, { path: "/register", element: _jsx(Register, {}) }) })) : !appState.isAdmin && !appState.isEditor ? (_jsx(Routes, { children: _jsx(Route, { path: "/accessdenied", element: _jsx(AccessDenied, {}) }) })) : (_jsx(AuthenticatedTemplate, { children: _jsx(Layout, { lightTheme: lightTheme }) }));
};
export default Permissions;
