import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { ModalFieldset, } from "../Modal.styles";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, Input } from "@fluentui/react-components";
export const DimensionModal = (props) => {
    const [titleValue, setTitleValue] = React.useState("");
    React.useEffect(() => {
        if (props.dimension !== undefined && props.dimension !== null) {
            setTitleValue(props.dimension.text);
        }
    }, [props.dimension]);
    function onChangeTitleValue(e) {
        setTitleValue(e.target.value);
    }
    function addDimension() {
        const dimension = {
            dimensionId: props.dimensions.length + 1,
            text: titleValue,
            items: [],
        };
        props.addDimension(dimension);
        setTitleValue(null);
    }
    function editDimension() {
        const dimension = {
            dimensionId: props.dimension.dimensionId,
            text: titleValue,
            items: props.dimension.items,
        };
        props.editDimension(dimension);
        setTitleValue(null);
    }
    return (_jsx(Dialog, Object.assign({ open: props.showModal }, { children: _jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: props.dimension !== undefined && props.dimension !== null
                            ? "Edit Dimension"
                            : "Create Dimension" }), _jsx(DialogContent, { children: _jsxs(ModalFieldset, Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Title" }), _jsx(Field, { children: _jsx(Input, { value: titleValue, onChange: onChangeTitleValue }) })] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: props.dimension !== undefined && props.dimension !== null
                                    ? editDimension
                                    : addDimension, appearance: "primary" }, { children: "OK" })), _jsx(Button, Object.assign({ onClick: () => {
                                    props.closeModal();
                                } }, { children: "Cancel" }))] })] }) }) })));
};
