var defaultConfig = {
    environment: "local",
    kcUrl: "https://dev.login.livetiles.io",
    reactApiUrl: "https://localhost:7240/api",
    redirectUrl: "http://localhost:3000",
    clientId: "e5e47daf-6f30-4c7c-81ff-deb9ed1e8de3",
    quicklinksApiUrl: "https://dev.ixp.livetiles.io/services/quicklinks",
    audienceApiUrl: "https://dev.ixp.livetiles.io/services/audiences/api",
    policiesApiUrl: "https://dev.ixp.livetiles.io/services/policies/api",
    tockenExchangeUrl: "https://dev.ixp.livetiles.io/services/idam/api/v1/token-exchange",
    backOfficeAppScope: "https://livetiles.nyc/Back-Office-Api-dev/.default",
    analyticsSharePointDashboardId: "de326e29-d8be-408a-9fa0-7b2e83cd62c3",
    analyticsSharePointDashboardHostname: "superset.test.analytics.livetiles.io",
    analyticsReachDashboardId: "2bea68bc-88a7-4476-b7ec-047ecc32aafb",
    condenseScope: "api://781a9147-6ddc-43fb-9cce-e01d7838ffe6/.default",
    instrumentationKey: "7de23d40-9705-4005-a0b0-325eefe1eaef",
};
var injectedConfig = {
    // these strings, will be overwritten by the nginx sub_filter.
    environment: "__LVT_OPS_ENVIRONMENT__",
    kcUrl: "__LVT_OPS_KEYCLOAK_URL__",
    reactApiUrl: "__LVT_OPS_BACK_OFFICE_URL__",
    redirectUrl: "__LVT_OPS_REDIRECT_URL__",
    clientId: "__LVT_OPS_CLIENT_ID__",
    quicklinksApiUrl: "__LVT_OPS_QUICKLINKS_URL__",
    audienceApiUrl: "__LVT_OPS_AUDIENCES_URL__",
    policiesApiUrl: "__LVT_OPS_POLICIES_URL__",
    tockenExchangeUrl: "__LVT_OPS_TOKEN_EXCHANGE_URL__",
    backOfficeAppScope: "__LVT_OPS_BACKOFFICE_APP_SCOPE__",
    analyticsSharePointDashboardId: "__LVT_OPS_ANALYTICS_SHAREPOINT_DASHBOARD_ID__",
    analyticsSharePointDashboardHostname: "__LVT_OPS_ANALYTICS_SHAREPOINT_DASHBOARD_HOSTNAME__",
    analyticsReachDashboardId: "__LVT_OPS_ANALYTICS_REACH_DASHBOARD_ID__",
    condenseScope: "__LVT_OPS_CONDENSE_SCOPE__",
    instrumentationKey: "__LVT_OPS_APP_INSIGHTS_KEY__",
};
// modify defaultConfig with injected values
Object.entries(injectedConfig)
    .filter(([key, value]) => value.startsWith("__LVT_OPS")) // filter out values, that weren't replaced by nginx sub_filter
    .forEach(([key, value]) => Reflect.deleteProperty(injectedConfig, key));
export default Object.assign(Object.assign({}, defaultConfig), injectedConfig);
